import {
	adExamplesIconMobile,
	aiPhotoshootIcon,
	deforumIcon,
	galleryIcon,
	generativeFillIcon,
	lookAiIcon,
	musicGeneratorIcon,
	photoAiIcon,
	restlyeIcon,
	templatesIcon,
	textToImageIcon,
	textToReelIcon,
	textToVideoIcon,
	ThemeToggle,
	videoBGRemoverIcon,
} from "@/comps/header/icon"
import {
	appStoreConstant,
	discordLink,
	facebookLink,
	instagramLink,
	pinterestLink,
	snapchatLink,
	tiktokLink,
	youtubeLink,
} from "@/utils/branch"
import { devices } from "@/utils/breakpoints"
import clsx from "clsx"
import { useTranslation } from "next-i18next"

import { ReactNode, useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { assetUrl } from "@/utils/cdn"
import useAuth, { useClientAuth } from "@/utils/client-auth"
import {
	aiAdsGenratorIcon,
	aiScriptGenerator,
	productUrlToAdsIcon,
	scriptToAdsGenerator,
	videoGptIcon,
} from "../ai-tools/icons"

const menuStyle = css`
	position: relative;
	flex-shrink: 0;

	font-size: 18px;
	font-weight: 600;

	width: var(--w-screen);
	height: 52px;

	padding-left: 20px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	background-color: var(--color-background-cell);

	border-top: 1px solid var(--color-separator);

	&:hover {
		background-color: var(--color-blue-100);
	}

	&:focus {
		background-color: var(--color-blue-100);
	}
`

interface MenuItemProps {
	text: string
	link?: string
	onClick?: () => void
	className?: string
	icon?: ReactNode
	newTab?: boolean
}

const MenuGroupSingle = styled.button`
	${menuStyle};
`

interface MenuGroupProps {
	text: string
	items: MenuItemProps[]
}

function MenuGroup(props: MenuGroupProps) {
	const { text, items } = props

	const [show, setShow] = useState(false)

	const { t } = useTranslation("common")

	const toggleShow = () => setShow(!show)

	return (
		<>
			<MenuGroupSingle
				onClick={toggleShow}
				className={clsx(
					"flex !justify-between pr-5 transition-colors",
					show && "!bg-color-background",
				)}>
				<div className="flex flex-row items-center gap-1">
					{t(text)}
				</div>

				<div className="button relative max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] cursor-pointer">
					<span
						className={clsx(
							show && "rotate-90",
							"before-line absolute bg-blue-600 transition-transform duration-[250ms] ease-out",
						)}></span>
					<span
						className={clsx(
							"bg-blue-600",
							"after-line absolute transition-transform duration-[250ms] ease-out",
						)}></span>
				</div>
			</MenuGroupSingle>
			{show &&
				items.map((item) => {
					if (item.link) {
						return (
							<a
								key={item.text}
								href={item.link}
								onClick={item.onClick ?? toggleShow}
								className="flex h-[50px] w-full shrink-0 flex-row items-center gap-2 bg-color-background px-6 text-[16px] font-400 text-blue-700">
								<div className="[--icon-color:var(--color-blue-600)]">
									{item.icon}
								</div>
								{t(item.text)}
							</a>
						)
					} else {
						return (
							<div
								key={item.text}
								className="flex h-[31px] w-full shrink-0 flex-row items-center gap-2 bg-blue-200 px-6 text-[15px] font-500 tracking-[0.6px] text-blue-600">
								<div className="[--icon-color:var(--color-blue-500)]">
									{item.icon}
								</div>
								{t(item.text).toUpperCase()}
							</div>
						)
					}
					// <MenuItem
					//   className="!bg-color-background !pl-12 !text-blue-700 ![--icon-color:var(--color-blue-800)]"
					//   key={item.text}
					//   text={item.text}
					//   link={item.link}
					//   icon={item.icon}
					//   onClick={item.onClick ?? toggleShow}
					// />
				})}
		</>
	)
}

const MenuItemSingle = styled.a`
	${menuStyle};
`

const MenuItem = (props: MenuItemProps) => {
	const { t } = useTranslation()

	const { userInfo } = useAuth()

	let visited = null
	if (typeof window !== "undefined" && userInfo) {
		visited = localStorage.getItem(
			`visited-train-model-id-${userInfo.userId}`,
		)
	}

	const showNew = !visited

	return (
		<MenuItemSingle
			className={clsx(
				props.className,
				"text-blue-900 [--icon-color:var(--color-blue-900)]",
			)}
			href={props.link}
			rel="noopener noreferrer"
			target={props.newTab ? "_blank" : undefined}
			onClick={props.onClick}>
			<div
				className={clsx(
					"flex flex-row items-center gap-2",
					props.link === "/tools" && "text-primary-500",
				)}>
				{props.icon}
				{t(props.text)}
				{props.link ===
					"/tools/ai-photoshoot-generator" && (
					<>
						<div
							className={clsx(
								"flex h-5 w-[37px] items-center justify-center",
								"rounded bg-primary-500 text-center text-[10px] font-700 text-color-white",
								"transition-opacity",
								showNew ? "opacity-100" : "opacity-0",
							)}>
							{t("lbl_new")}
						</div>
					</>
				)}
			</div>
		</MenuItemSingle>
	)
}

const MenuItemContainer = styled.div`
	${menuStyle};

	border-bottom: 1px solid var(--color-separator);

	justify-content: space-between;
	padding-right: 20px;

	&:hover,
	&:focus {
		background-color: unset !important;
	}
`

const ThemeText = styled.div<{
	light?: boolean
	dark?: boolean
}>`
	.light && {
		${(props) => props.dark && "display: none;"}
	}

	.dark && {
		${(props) => props.light && "display: none;"}
	}
`

const Socials = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 12px;

	height: 104px;
	width: var(--w-screen);
	padding: 32px 0;

	background-color: var(--color-background-cell);
`

const SocialAnchor = styled.a``

const SocialIcon = styled.img`
	width: 40px;
	height: 40px;

	border-radius: 24px;
	background-color: var(--color-blue-400);
`

export const MenuContainer = styled.div<{
	show: boolean
	dashboard?: boolean
}>`
	overflow-y: scroll;

	@media ${devices.tablet} {
		display: none;
	}

	${(props) =>
		props.dashboard
			? css`
					top: 57px;
					width: 100%;
			  `
			: css`
					top: 48px;
			  `}

	${(props) =>
		props.show
			? css`
					height: 100vh;
					padding-bottom: 100px;

					body {
						overflow: hidden;
					}
			  `
			: css`
					height: 0;
			  `}

  position: fixed;
	left: 0;

	background-color: var(--color-background-cell);
	border-bottom: 1px solid var(--color-separator);

	z-index: 99;

	display: flex;
	flex-direction: column;
	align-items: center;

	transition: 300ms;
	transition-timing-function: ease-in-out;
`

const ArtificialCloser = styled.div`
	flex: 1;

	height: 100%;
	align-self: stretch;
`

interface MenuProps {
	show: boolean
	hide: () => void
}

const Menu = (props: MenuProps) => {
	const { show, hide } = props

	useEffect(() => {
		const shouldShow = show ? "false" : "true"

		document.documentElement.setAttribute(
			"data-scroll",
			shouldShow,
		)
	}, [show])

	const { t } = useTranslation()

	const { userInfo } = useClientAuth()

	const handleNewClick = () => {
		localStorage.setItem(
			`visited-train-model-id-${userInfo?.userId}`,
			"true",
		)
	}

	return (
		<MenuContainer show={show}>
			<MenuGroup
				text="txt_video_gpt"
				items={[
					{
						icon: aiAdsGenratorIcon("22", "22"),
						text: "txt_ai_ads_generator",
						link: "/tools/ai-ad-generator",
					},
					{
						icon: productUrlToAdsIcon("22", "22"),
						text: "txt_product_url_to_ads",
						link: "/tools/url-to-video-maker",
					},
					{
						icon: videoGptIcon("22", "22"),
						text: "txt_video_gpt",
						link: "/tools/video-gpt",
					},
					{
						icon: aiScriptGenerator("22", "22"),
						text: "txt_script_generator",
						link: "/tools/ai-script-generator",
					},
					{
						icon: scriptToAdsGenerator("22", "22"),
						text: "txt_script_to_ads_generator",
						link: "/tools/ai-script-to-video-generator",
					},
				]}
			/>
			<MenuGroup
				text="lbl_ai_studio"
				items={[
					{
						text: "lbl_video",
					},
					{
						link: "/tools/video-gpt",
						text: "txt_text_to_reel",
						icon: textToReelIcon("22", "22"),
					},
					{
						text: "txt_deforum_ai",
						link: "/tools/ai-video-transformation",
						icon: deforumIcon("22", "22"),
					},
					{
						text: "txt_restyle_ai",
						link: "/tools/ai-animation-generator",
						icon: restlyeIcon("22", "22"),
					},
					{
						text: "txt_text_to_video",
						link: "/tools/text-to-video",
						icon: textToVideoIcon("22", "22"),
					},
					{
						text: "txt_video_bg_remover",
						link: "/tools/video-background-remover",
						icon: videoBGRemoverIcon("22", "22"),
					},
					{
						text: "txt_ai_music_generator",
						link: "/tools/royalty-free-music",
						icon: musicGeneratorIcon("22", "22"),
					},
					{
						text: "lbl_image",
					},
					{
						text: "txt_ai_photoshoot",
						link: "/tools/ai-photoshoot-generator",
						icon: aiPhotoshootIcon("22", "22"),
						onClick: handleNewClick,
					},
					{
						text: "txt_photo_ai",
						link: "/tools/ai-photo-editor",
						icon: photoAiIcon("22", "22"),
					},
					{
						text: "txt_text_to_image",
						link: "/tools/text-to-image",
						icon: textToImageIcon("22", "22"),
					},
					{
						text: "txt_generative_fill",
						link: "/tools/ai-image-replacer",
						icon: generativeFillIcon("22", "22"),
					},
					{
						text: "txt_look_ai",
						link: "/tools/ai-portrait-generator",
						icon: lookAiIcon("22", "22"),
					},
					{
						text: "txt_all_tools",
						link: "/tools",
					},
				]}
			/>
			<MenuGroup
				text="lbl_discover"
				items={[
					{
						text: "lbl_ai_gallery",
						link: "/ai-gallery",
						icon: galleryIcon("22", "22"),
					},
					{
						text: "lbl_templates",
						link: "/video-templates",
						icon: templatesIcon("22", "22"),
					},
					{
						text: "lbl_ad_examples",
						link: "/ad-examples",
						icon: adExamplesIconMobile("22", "22"),
					},
				]}
			/>
			<MenuItem
				link={appStoreConstant}
				newTab
				text="lbl_mobile_app"
			/>
			<MenuItem
				link="/pricing"
				text="lbl_pricing"
				onClick={hide}
			/>
			{userInfo !== null && userInfo.isAnonymous && (
				<MenuItemContainer>
					<ThemeText>{t("txt_dark_mode")}</ThemeText>
					<ThemeToggle />
				</MenuItemContainer>
			)}
			<Socials>
				<SocialAnchor
					href={instagramLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/instagram.svg")}
						alt="Instagram Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={tiktokLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/tiktok.svg")}
						alt="Tiktok Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={youtubeLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/youtube.svg")}
						alt="Youtube Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={discordLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/discord.svg")}
						alt="Discord Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={pinterestLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/pinterest.svg")}
						alt="Pinterest Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={facebookLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/facebook.svg")}
						alt="Facebook Logo"
					/>
				</SocialAnchor>
				<SocialAnchor
					href={snapchatLink}
					target="_blank"
					rel="noopener noreferrer">
					<SocialIcon
						src={assetUrl("/comps/snapchat.svg")}
						alt="Snapchat Logo"
					/>
				</SocialAnchor>
			</Socials>
			<ArtificialCloser onClick={hide} />
		</MenuContainer>
	)
}

export default Menu
