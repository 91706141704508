import Header from "@/comps/header"
import { SuccessMessage } from "@/comps/message"
import Meta from "@/comps/meta"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { wrapSSRAuth } from "@/utils/ssr-auth"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { TrainingStatusResponse } from "pages/api/tools-models-status"
import { TrainingListResponse } from "pages/api/training-list"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import FilterContent from "sections/train-model/filter-content"
import ModelMobileCard from "sections/train-model/model-mobile-card"
import {
  SelectModelPopup,
  SelectModelProps,
} from "sections/train-model/select-model-popup"
import { z } from "zod"
import { trainModelDraftSchema } from "./create"

export const backgroundGradient = clsx(
  "tablet:[background:radial-gradient(circle_at_left,_#dae3fc,_#e7ddf5,_#f5ece5)]",
  "dark:tablet:[background:radial-gradient(circle_at_left,_#37415a,_#341e34,_#362d22)]",
  "[background:linear-gradient(to_right,_#d4dffc,_#e3eafc)] dark:[background:linear-gradient(to_right,_#3b4560,_#2a3142)]"
)

function getAllModels(uid: string | null) {
  return async function () {
    return await axios
      .get<TrainingListResponse>(
        `/api/training-list?id=${uid}`
      )
      .then((res) => res.data)
  }
}

function getTrainings(uid: string | null) {
  return async function () {
    return await axios
      .get<TrainingStatusResponse>(
        `/api/tools-models-status?id=${uid}`
      )
      .then((res) => res.data)
  }
}

export type TrainMoldelDraftsType = z.infer<
  typeof trainModelDraftSchema
>

interface TrainModelProps {
  page: z.infer<typeof querySchema>["page"]
}

export default function TrainModel(props: TrainModelProps) {
  const { page } = props
  const { t } = useTranslation()
  const [filter, setFilter] = useState<
    "models" | "drafts" | "training"
  >(page)
  const [drafts, setDrafts] = useState<
    TrainMoldelDraftsType[] | null
  >(null)
  const [trainings, setTrainings] =
    useState<TrainingStatusResponse | null>(null)
  const [selectPopupState, setSelectPopupState] =
    useState<SelectModelProps | null>(null)

  const { userInfo } = useAuth()
  const uid = userInfo.userId
  const router = useRouter()

  const query = useQuery({
    queryKey: ["all-models", uid],
    queryFn: getAllModels(uid),
  })

  const queryTrainings = useQuery({
    queryKey: ["trainings"],
    queryFn: getTrainings(uid),
  })

  useEffect(() => {
    if (!queryTrainings.data) {
      setTrainings(null)
      return
    }

    setTrainings(queryTrainings.data)
  }, [queryTrainings.data])

  const db = useIndexedDB(
    "ai_art",
    "model_drafts",
    trainModelDraftSchema
  )

  useEffect(() => {
    if (db.status !== "ready") {
      return
    }

    db.getAll().then((result) => {
      if (result === null) {
        console.error("Draft not found")
      }

      setDrafts(result)
    })
  }, [db])

  return (
    <>
      <Meta
        title={t("txt_model_title")}
        description={t("txt_home_description")}
        cover={assetUrl("/covers/model.jpg")}
      />
      <Header />

      <div
        className="relative hidden h-full w-full flex-col items-center pb-[50px] desktop:flex"
        id="background"
        onClick={(e) => {
          const target = e.target
          if (target instanceof HTMLElement) {
            if (target.id === "background") {
              const popup = document.getElementById("popup")
              if (popup) {
                popup.style.overflow = "hidden"
              }
            }
          }
        }}>
        <div
          className={clsx(
            backgroundGradient,
            "flex h-[95px] w-full justify-center pt-[32px]"
          )}>
          <span className="text-[24px] font-700 text-blue-800">
            {t("txt_models")}
          </span>
        </div>

        <div className="flex flex-col items-start gap-[14px] pt-[20px]">
          <div className="flex w-[1063px] items-center gap-[76px] border-b-2 border-color-separator pl-[30px]">
            <button
              className={clsx(
                filter === "models"
                  ? "text-blue-800"
                  : "text-blue-500",
                "relative py-[10px] text-[17px] font-600 hover:text-blue-800"
              )}
              onClick={() => {
                setFilter("models")
                router.push(
                  router.asPath,
                  { query: { page: "models" } },
                  { shallow: true }
                )
              }}>
              <div>
                <span>{t("txt_models")}</span>
                <small className="pl-[6px]">
                  (
                  {query.data
                    ? query.data?.jobs.length
                    : "-"}
                  )
                </small>
              </div>

              {filter === "models" && (
                <div
                  className={clsx(
                    "absolute -bottom-[2px] left-1/2 h-[3px] w-[49px] rounded-t-[2px] bg-blue-800",
                    "-translate-x-1/2 animate-appear"
                  )}
                />
              )}
            </button>
            <button
              className={clsx(
                filter === "drafts"
                  ? "text-blue-800"
                  : "text-blue-500",
                "relative py-[10px] text-[17px] font-600 hover:text-blue-800"
              )}
              onClick={() => {
                setFilter("drafts")
                router.push(
                  router.asPath,
                  { query: { page: "drafts" } },
                  { shallow: true }
                )
              }}>
              <span>
                {t("txt_drafts")}
                <small className="pl-[6px]">
                  {`(${
                    drafts && drafts?.length !== 0
                      ? drafts?.length
                      : "-"
                  })`}
                </small>
              </span>
              {filter === "drafts" && (
                <div
                  className={clsx(
                    "absolute -bottom-[2px] left-1/2 h-[3px] w-[49px] rounded-t-[2px] bg-blue-800",
                    "-translate-x-1/2 animate-appear"
                  )}
                />
              )}
            </button>
            <button
              className={clsx(
                filter === "training"
                  ? "text-blue-800"
                  : "text-blue-500",
                "relative py-[10px] text-[17px] font-600 hover:text-blue-800"
              )}
              onClick={() => {
                setFilter("training")
                router.push(
                  router.asPath,
                  { query: { page: "training" } },
                  { shallow: true }
                )
              }}>
              <span>
                {t("txt_training")}
                <small className="pl-[6px]">
                  {`(${
                    trainings &&
                    trainings.models.filter(
                      (m) => m.status !== "failed"
                    ).length !== 0
                      ? trainings.models.filter(
                          (model) =>
                            model.status !== "failed"
                        ).length
                      : "-"
                  })`}
                </small>
              </span>
              {filter === "training" && (
                <div
                  className={clsx(
                    "absolute -bottom-[2px] left-1/2 h-[3px] w-[49px] rounded-t-[2px] bg-blue-800",
                    "-translate-x-1/2 animate-appear"
                  )}
                />
              )}
            </button>
          </div>

          {query.data?.jobs && (
            <FilterContent
              setSelectPopupState={setSelectPopupState}
              state={filter}
              jobs={query.data.jobs}
              drafts={drafts}
              trainings={trainings}
              deleteDraft={(id: string) => {
                if (db.status === "ready") {
                  db.clearData(id)
                  db.getAll().then((result) => {
                    if (result === null) {
                      console.error("Draft not found")
                    }

                    setDrafts(result)
                  })
                }
              }}
            />
          )}
        </div>

        <SelectModelPopup
          id={selectPopupState?.id ?? 0}
          samples={selectPopupState?.samples ?? null}
        />
      </div>

      <ModelMobileCard />
    </>
  )
}

interface DeletePopupProps {
  deletePopup: boolean
  setDeletePopup: (v: boolean) => void
  modelName: string
  id: number
  functionAfterDelete: () => void
  deleteDraft?: () => void
}

export function DeletePopup(props: DeletePopupProps) {
  const {
    deletePopup,
    setDeletePopup,
    id,
    modelName,
    functionAfterDelete,
    deleteDraft,
  } = props
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  return (
    <div
      className={clsx(
        deletePopup ? "flex" : "hidden",
        "absolute left-1/2 top-[250px] w-[389px] -translate-x-1/2 p-[24px]",
        " z-50 animate-appear flex-col gap-[14px] rounded-[12px] bg-color-popup-cell shadow-2xl"
      )}
      onClick={(e) => {
        const target = e.target
        if (target instanceof HTMLElement) {
          if (target.id === "background") {
            setDeletePopup(false)
          }
        }
      }}
      id="popup">
      <p className="texxt-blue-700 text-[24px] font-500">
        {t("fs_are_you_sure", {
          model: modelName,
        })}
      </p>
      <p className="texxt-[18px] font-400 text-blue-600">
        {t("txt_delete_warning")}
      </p>
      <div className="flex w-full items-center justify-between pt-[15px]">
        <button
          onClick={() => setDeletePopup(false)}
          className="h-[44px] w-[164px] rounded-[10px] border border-color-separator text-[16px] font-600 text-blue-600 hover:bg-blue-200">
          {t("lbl_cancel")}
        </button>
        <button
          className="h-[44px] w-[164px] rounded-[10px] bg-color-error text-[16px] font-600 text-color-white hover:bg-[#DE0202]"
          onClick={
            deleteDraft === undefined
              ? async () => {
                  await axios
                    .post(
                      "/api/training-delete",

                      { id }
                    )
                    .then(() =>
                      notify(
                        <SuccessMessage>
                          {t("fs_successfully_submited", {
                            model: modelName,
                          })}
                        </SuccessMessage>
                      )
                    )
                  setDeletePopup(false)
                  functionAfterDelete()
                }
              : () => {
                  deleteDraft()
                  notify(
                    <SuccessMessage>
                      {t("fs_successfully_submited", {
                        model: modelName,
                      })}
                    </SuccessMessage>
                  )
                  setDeletePopup(false)
                }
          }>
          {t("lbl_delete")}
        </button>
      </div>
    </div>
  )
}

export function getModelType(
  model:
    | "model_male"
    | "model_female"
    | "model_perfume"
    | "model_shoes"
    | "model_sunglasses"
): string {
  switch (model) {
    case "model_male":
      return "Male"
    case "model_female":
      return "Female"
    case "model_perfume":
      return "Perfume"
    case "model_shoes":
      return "Shoes"
    case "model_sunglasses":
      return "Sunglasses"
  }
}

export function getMonthDayFromDate(
  dateString: string
): string {
  const date = new Date(dateString)
  const monthIndex = date.getMonth()
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const month = monthNames[monthIndex]
  const day = date.getDate()

  return `${month} ${day}`
}

const querySchema = z.object({
  page: z
    .enum(["models", "drafts", "training"])
    .catch("models"),
})

export const getServerSideProps =
  wrapSSRAuth<TrainModelProps>(async (context) => {
    const { page } = querySchema.parse(context.query)
    return { page }
  }, [])
