import { AxiosError } from "axios"
import { ApiError } from "./handlers"

export type ErrorType =
	| {
		type: "common"
		message: string
	}
	| {
		type: "special"
		message: string
		explanation: string | null
	}

export function errorExtractor(error: unknown): ErrorType {
	if (error instanceof AxiosError) {
		const data = error.response?.data as ApiError
		switch (data.code) {
			case "custom":
				return {
					type: "special",
					message: data.message,
					explanation: data.explanation,
				}
			default:
				return {
					type: "common",
					message: data.message,
				}
		}
	}

	return {
		type: "common",
		message: "Something went wrong",
	}
}
