import { FollowButton } from "@/comps/advanced-profile"
import { moreIcon } from "@/comps/ai-tools/icons"
import { CustomThemedResource } from "@/comps/image"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { appStoreConstant } from "@/utils/branch"
import { assetUrl, HEADER_WEB_VERSION } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import { redirectToLogin } from "@/utils/login-redirect"
import prettyMillions from "@/utils/millions"
import { NotificationContext } from "@/utils/notification"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { GalleryTools } from "pages/ai-gallery"
import { DeforumPrivacyRequest } from "pages/api/deforum-privacy"
import type { GalleryRelatedResponse } from "pages/api/gallery-related-art"
import { RelatedSearchResponse } from "pages/api/gallery-related-searches"
import { GalleryReportRequest } from "pages/api/gallery-report"
import { ActionOptions } from "pages/api/gallery-view"
import { getModelType } from "pages/models"
import { ModelType } from "pages/models/create"
import { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { ThanksPopup } from "sections/gallery/thanks-popup"
import {
  DialogProps,
  SocialButton,
  socials,
} from "sections/history/generations"
import {
  formatTimes,
  transformTool,
  transformToolToIcon,
} from "sections/history/history-popup"
import { MoreButton } from "sections/history/more-button"
import { RenderContent } from "sections/history/render-content"
import { RenderTextContent } from "sections/history/render-text-content"
import { ReportPopup } from "sections/history/report-popup"
import { SaveMediaPopup } from "sections/history/save-media-popup"
import { ShareContent } from "sections/history/share-content"
import { RelatedSection } from "sections/history/single-generated"
import { FollowStatus } from "types/endpoints/user-profile"
import { RelatedMasonry } from "./related-masonry"

export function getKeywords(id: number) {
  return async function () {
    return await axios
      .post<RelatedSearchResponse>(
        "/api/gallery-related-searches",
        { id },
        {
          headers: {
            Authorization: "Bearer sdgdfsgsdfgsdfg",
            "web-version": HEADER_WEB_VERSION,
          },
        },
      )
      .then((response) => response.data)
  }
}

export function getRelatedArt(id: number, limit: number) {
  return async function () {
    return await axios
      .post<GalleryRelatedResponse>(
        "/api/gallery-related-art",
        { id, limit },
        {
          headers: {
            Authorization: "Bearer sdgdfsgsdfgsdfg",
            "web-version": HEADER_WEB_VERSION,
          },
        },
      )
      .then((response) => response.data.slice(0, limit - 1))
  }
}

export interface GalleryPopupProps {
  id: number
  tool: string
  imageOrVideo: string | string[]
  createdAt: number | string
  prompt: string
  privacy: "public" | "private"
  customPrompt: string
  setDialogContent?: (content: DialogProps) => void
  promptName: string
  aspectRatio?: number
  onClose: () => void
  handleRetry: () => void
  username: string
  profile_pic?: string | null
  followers: number
  closeLink?: string
  notShallow: boolean
  thumb?: string
  model?: string
  modelType?: ModelType | string
  handleDelete?: (id: number) => void
  model_id?: number | null
  previewImage: string
  ownerId: string
  followStatus?: FollowStatus
  initial?: string
  showFor: boolean
  likes: number
  views: number
  showRelated: boolean
}

export function GalleryPopup(init: GalleryPopupProps) {
  const [props, setProps] = useState(init)
  const {
    imageOrVideo,
    onClose,
    createdAt,
    privacy,
    id,
    tool,
    initial,
    handleRetry,
    username,
    followers,
    profile_pic,
    closeLink,
    notShallow,
    handleDelete,
    ownerId,
    showFor,
    prompt,
    customPrompt,
    likes,
    views,
    promptName,
    previewImage,
  } = props

  const queryClient = useQueryClient()
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  const [isMobileView, _] = useState(
    window.matchMedia("(max-width: 767px)").matches,
  )
  const [isPublic, setPublic] = useState(
    privacy === "public",
  )
  const [payload, setPayload] =
    useState<GalleryReportRequest>({
      gallery_id: id,
      info: {
        reason: "",
        message: "",
      },
    })
  const [showDropdown, setShowDropdown] =
    useState<boolean>(false)
  const [shareDialog, setShareDialog] =
    useState<DialogProps>({
      open: false,
      isPublic: false,
      link: `${APP_HOST}/ai-gallery/single/${id}`,
      id: null,
    })
  const [showMediaDialog, setShowMediaDialog] =
    useState(false)
  const [offset, setOffset] = useState(0)
  const [reportPopup, setReportPopup] = useState(false)
  const { userInfo } = useAuth()
  const isSelf = ownerId === userInfo.userId
  const [followStatus, setFollowStatus] =
    useState<FollowStatus>(
      props.followStatus ?? "not_following",
    )
  const [thanksPopup, setThanksPopup] = useState(false)
  const [liked, setLiked] = useState(false)

  useEffect(() => {
    if (reportPopup) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [reportPopup])

  function setAsShared() {
    const options: ActionOptions = {
      action: "share",
      gallery_id: id,
    }
    axios
      .post("/api/gallery-view", options)
      .catch((e) => console.error(e))
  }

  async function handleMakePublic(
    privacy: "public" | "private",
    id: number,
  ) {
    try {
      const payload: DeforumPrivacyRequest = {
        privacy:
          privacy === "public" ? "private" : "public",
        job: id,
      }

      await axios.post("/api/deforum-privacy", payload)
      queryClient.invalidateQueries("deforum-history")
    } catch (error) {
      console.error(error)
      notify(
        <ErrorMessage>
          {t(
            privacy === "public"
              ? "txt_could_not_make_public"
              : "txt_could_not_make_private",
          )}
        </ErrorMessage>,
      )
    }
  }

  async function handleSocialShare(
    desktop?: boolean,
    desktopFunction?: (link: string) => string,
    url?: (link: string) => string,
  ): Promise<string> {
    try {
      if (!shareDialog.isPublic && !showFor) {
        await handleMakePublic("private", id)
      }

      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })

      if (desktop && desktopFunction)
        return desktopFunction(shareDialog.link)
      else if (url) return url(shareDialog.link)
      else throw Error("No function")
    } catch (e) {
      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
      return ""
    }
  }

  const router = useRouter()

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (!notShallow) {
          router.push(
            closeLink ? `${closeLink}` : `/ai-gallery`,
            undefined,
            {
              shallow: true,
            },
          )
        }
        onClose()
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener(
        "keydown",
        handleKeyPress,
      )
    }
  }, [closeLink, notShallow, onClose, router])

  function backgroundClickHandler(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    const target = e.target
    if (target instanceof HTMLElement) {
      if (target.id === "background") {
        if (!notShallow) {
          router.push(
            closeLink ? `${closeLink}` : `/ai-gallery`,
            undefined,
            {
              shallow: true,
            },
          )
        }
        onClose()
      }
      if (target.id !== "media-box") {
        setShowMediaDialog(false)
      }

      if (target.id === "thanks-popup-background") {
        setThanksPopup(false)
      }
    }
  }

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    setAsShared()

    try {
      await navigator.clipboard.writeText(
        `${APP_HOST}/ai-gallery/single/${id}`,
      )
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  async function handleLike() {
    if (userInfo && userInfo.isAnonymous) {
      redirectToLogin()
      return
    }
    try {
      const endpoint = liked
        ? "/api/gallery-unlike"
        : "/api/gallery-like"
      await axios.post(endpoint, {
        gallery_id: id,
      })
      setLiked(!liked)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div
      className={clsx(
        reportPopup
          ? "overflow-hidden"
          : "overflow-y-scroll",
        "no-scrollbar fixed inset-0 z-[100] h-screen w-screen",
        "transition-opacity duration-300",
        "bg-color-cell tablet:overscroll-contain tablet:bg-color-black/40",
        "cursor-auto desktop:overflow-hidden",
      )}
      id="background"
      onClick={(e) => backgroundClickHandler(e)}>
      <div
        className={clsx(
          "no-scrollbar relative w-screen bg-color-cell tablet:rounded-xl",
          "tablet:left-1/2 tablet:top-[100px] tablet:h-[1090px] tablet:w-[564px] tablet:-translate-x-1/2 ",
          " responsive-zoom desktop:h-[857px] desktop:w-[1206px]",
          "desktop:top-1/2 desktop:-translate-y-1/2",
        )}>
        {thanksPopup && (
          <div
            id="thanks-popup-background"
            className={clsx(
              "fixed z-[998] h-full w-full rounded-xl bg-color-popup",
            )}
          />
        )}
        <ThanksPopup
          open={thanksPopup}
          setOpen={setThanksPopup}
          username={username}
          id={id}
          image={
            typeof imageOrVideo === "string"
              ? imageOrVideo
              : imageOrVideo[offset]
          }
          setAsShared={setAsShared}
          shareDialog={shareDialog}
          handleSocialShare={handleSocialShare}
        />
        <div
          className={clsx(
            "relative flex flex-col",
            "desktop:w-full desktop:flex-row",
          )}>
          {/*IMAGES_SECTION*/}
          <RenderContent
            id={id}
            tool={tool}
            imageOrVideo={imageOrVideo}
            createdAt={createdAt}
            closeLink={closeLink}
            prompt={prompt}
            privacy={privacy}
            customPrompt={customPrompt}
            notShallow={false}
            promptName={promptName}
            offset={offset}
            onClose={onClose}
            handleRetry={handleRetry}
            previewImage={previewImage}
            setOffset={setOffset}
            viewFor="gallery"
          />

          {/*SECOND_SECTION*/}
          <div
            className={clsx(
              "mb-[60px] hidden flex-col gap-[12px] py-[14px] tablet:flex desktop:mb-0",
              "no-scrollbar shrink-0 overflow-y-scroll desktop:w-[431px] desktop:py-[16px]",
              "relative z-50 bg-color-cell tablet:h-[559px] desktop:h-[857px]",
              "rounded-xl tablet:rounded-t-none desktop:rounded-l-none desktop:rounded-r-xl",
            )}>
            {username[0] !== "-" && (
              <div className="flex items-center justify-between px-4">
                <div className="flex flex-row items-center gap-[6px]">
                  <a href={`/${username}?active=2`}>
                    <img
                      src={
                        profile_pic ??
                        assetUrl("/general/avatar.svg")
                      }
                      onError={(e) =>
                        e.currentTarget.setAttribute(
                          "src",
                          assetUrl(
                            "/icons/avatar-default.svg",
                          ),
                        )
                      }
                      alt="profile picture"
                      className="h-10 w-10 shrink-0 rounded-full border border-color-separator object-cover"
                    />
                  </a>
                  <div className="flex flex-col gap-[1px]">
                    <a href={`/${username}?active=2`}>
                      <p className="max-w-[100px] truncate text-[16px] font-700 text-blue-800">
                        {username}
                      </p>
                    </a>
                    <p className="text-[14px] font-500 text-blue-600">
                      {prettyMillions(followers ?? 0) +
                        " followers"}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    isSelf && "hidden",
                    "flex items-center gap-[11px]",
                  )}>
                  <div className="flex w-[150px] items-center justify-center overflow-hidden rounded-[6px]">
                    <FollowButton
                      followStatus={followStatus}
                      uid={ownerId}
                      setFollowStatus={setFollowStatus}
                      userPage={false}
                    />
                  </div>
                  <div
                    className={clsx(
                      "group relative cursor-pointer",
                      "-mb-[30px] pb-[30px]",
                    )}>
                    <div
                      className={clsx(
                        "h-[44px] w-[44px] [--icon-color:var(--color-blue-900)] hover:bg-blue-100",
                        "flex items-center justify-center rounded-md",
                      )}>
                      {moreIcon()}
                    </div>
                    <button
                      onClick={() => {
                        setReportPopup?.(true)
                      }}
                      className={clsx(
                        "fixed hidden w-[123px] rounded-[12px] px-[10px] py-[8px] group-hover:flex",
                        "z-[200] items-center gap-[8px] shadow-xl hover:bg-blue-100",
                        "-right-[30px] top-[740px] bg-color-popup-cell desktop:top-[80px]",
                      )}>
                      <CustomThemedResource
                        source="/general/report"
                        format="svg"
                      />
                      <span className="text-[16px] font-500 text-blue-800">
                        {t("txt_report")}
                      </span>
                    </button>
                    {reportPopup && setReportPopup && (
                      <ReportPopup
                        payloadLocation="gallery"
                        payload={payload}
                        setPayload={setPayload}
                        setReportPopup={setReportPopup}
                        endpoint="gallery-report"
                        galleryOrTemplate="gallery"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {username[0] !== "-" && (
              <div className="mx-auto h-[1px] w-[calc(100%-32px)] shrink-0 bg-color-separator"></div>
            )}

            <div className="flex shrink-0 flex-col gap-[8px] px-[16px] desktop:w-[431px]">
              <div
                className={clsx(
                  "flex items-center justify-between",
                )}>
                <div className="flex items-center gap-[12px] [--icon-color:var(--color-blue-600)]">
                  {props.model === undefined &&
                    transformToolToIcon(
                      tool as GalleryTools,
                    )}
                  <span
                    className={clsx(
                      "text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-blue-700",
                    )}>
                    {props.model !== undefined
                      ? props.model
                      : transformTool(tool as GalleryTools)}
                  </span>
                </div>
                <span
                  className={clsx(
                    "text-[16px] font-[500] leading-[26px] tracking-[0.28px] text-blue-500",
                  )}>
                  {formatTimes(createdAt)}
                </span>
              </div>
            </div>

            <div className="mx-auto h-[1px] w-[calc(100%-32px)] shrink-0 bg-color-separator"></div>

            <div
              className={clsx(
                "relative z-50 flex shrink-0 items-start gap-[10px] px-[16px]",
                "no-scrollbar -mb-[30px] pb-[30px] desktop:overflow-x-auto",
              )}>
              <SaveMediaPopup
                setAsShared={setAsShared}
                offset={offset}
                showMediaDialog={showMediaDialog}
                setShowMediaDialog={setShowMediaDialog}
                imageOrVideo={imageOrVideo}
                showDropdown={showDropdown}
                setThanksPopup={setThanksPopup}
                setShowDropdown={setShowDropdown}
              />

              <div className="h-[50px] w-[1px] bg-blue-300 desktop:shrink-0"></div>

              <button
                onClick={handleCopyText}
                className={clsx(
                  "relative flex items-center justify-center",
                  "group transition-colors hover:bg-blue-300",
                  "disabled:hover:bg-transparent disabled:opacity-50",
                  "h-[52px] w-[52px] shrink-0 overflow-visible rounded-full bg-color-surface",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/copy-primary.svg",
                  )}
                  alt="copy icon"
                  className="h-[20px] w-[20px]"
                />
                <div
                  className={clsx(
                    "pointer-events-none absolute -bottom-[30px] w-[80px]",
                    "rounded-[4px] px-3 py-1 opacity-0 hover:opacity-0 group-hover:opacity-100",
                    "bg-color-tooltip transition-all",
                    "text-xs font-500 text-blue-900",
                  )}>
                  {t("txt_copy_link")}
                </div>
              </button>
              {socials.map((props) => (
                <SocialButton
                  {...props}
                  title=""
                  tooltipTitle={props.title}
                  handleSocialShare={handleSocialShare}
                  dialogContent={shareDialog}
                  setAsShared={setAsShared}
                  key={props.icon ?? props.light}
                />
              ))}
            </div>

            <RenderTextContent {...props} />

            {props.modelType !== undefined && (
              <div className="flex">
                <div
                  className={clsx(
                    "ml-[16px] flex items-center gap-[4px] rounded-[5px]",
                    "bg-color-background px-[10px] py-[4px]",
                  )}>
                  <img
                    src={assetUrl(
                      "/general/model-icon.svg",
                    )}
                    className="h-[14px] w-[14px]"
                    alt="model icon"
                  />
                  <span className="text-[14px] font-500 text-blue-700">
                    {getModelType(
                      props.modelType as ModelType,
                    )}
                  </span>
                </div>
              </div>
            )}

            <button
              onClick={handleRetry}
              className={clsx(
                "mx-auto my-[4px] hidden w-[calc(100%-32px)] shrink-0 items-center",
                "justify-center gap-[12px] rounded-[10px] bg-primary-500 py-[12px]",
                "hover:opacity-70 desktop:my-[10px] desktop:flex",
              )}>
              <img
                src={assetUrl(
                  "/general/regenerate-white.svg",
                )}
                alt="plus icon"
              />
              <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                {t("txt_try_now")}
              </span>
            </button>

            <div className="w-full shrink-0 px-4">
              <a
                href={appStoreConstant}
                className={clsx(
                  "relative mx-auto flex w-full items-center justify-center",
                  "gap-[12px] rounded-[10px] bg-primary-500 py-[12px]",
                  "hover:opacity-70 desktop:hidden",
                )}>
                <img
                  src={assetUrl(
                    "/general/regenerate-white.svg",
                  )}
                  alt="plus icon"
                />
                <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                  {t("txt_try_now")}
                </span>
              </a>
            </div>

            <RelatedMasonry
              initialProps={props}
              setProps={setProps}
            />
          </div>

          {/*MOBILE_SECOND_SECTION */}
          <div
            className={clsx(
              "fixed bottom-0 left-0 z-40 flex w-full flex-col gap-[19px] p-[12px] tablet:hidden",
              "border-t border-color-separator bg-color-white",
            )}>
            <a
              href={appStoreConstant}
              className={clsx(
                "relative flex w-full items-center justify-center gap-[12px]",
                "rounded-[10px] bg-primary-500 py-[12px] hover:opacity-80",
              )}>
              <div className="absolute inset-0 -z-10 h-full w-full rounded-[10px] bg-color-black/80"></div>
              <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                {t("txt_try_now")}
              </span>
            </a>
          </div>

          {reportPopup && setReportPopup && !isSelf && (
            <ReportPopup
              payloadLocation="gallery"
              payload={payload}
              setPayload={setPayload}
              endpoint="gallery-report"
              setReportPopup={setReportPopup}
              galleryOrTemplate="gallery"
            />
          )}

          <div
            className={clsx(
              " absolute right-[17px] top-[17px] z-50",
              "tablet:top-[680px] desktop:top-[18px]",
              "flex items-center gap-[12px]",
              username && "tablet:hidden",
            )}>
            <MoreButton
              handleMakePublic={handleMakePublic}
              setShowDropdown={setShowDropdown}
              setShareDialog={setShareDialog}
              handleDelete={handleDelete}
              setPublic={setPublic}
              showDropdown={showDropdown}
              dark={!isMobileView}
              isPublic={isPublic}
              privacy={privacy}
              href={initial ?? ""}
              id={id}
              username={username}
              closeLink={closeLink}
              setReportPopup={setReportPopup}
              reportPopup={!isSelf && reportPopup}
            />
          </div>
        </div>
      </div>
      <div className="rounded-t-[12px] tablet:hidden">
        <RelatedSection
          id={id}
          showRelated={true}
          profilePic={
            profile_pic === undefined ? null : profile_pic
          }
          ownerId={ownerId}
          setFollowStatus={setFollowStatus}
          followStatus={followStatus}
          username={username}
          tool={tool}
          createdAt={createdAt}
          followers={followers}
          customPrompt={customPrompt}
          likes={likes}
          views={views}
          singlePageUse={false}
          liked={liked}
          handleLike={handleLike}
        />
      </div>
      {shareDialog.open && (
        <ShareContent
          shareDialog={shareDialog}
          setShareDialog={setShareDialog}
          isPublic={isPublic}
          id={id}
          handleMakePublic={handleMakePublic}
        />
      )}
    </div>
  )
}
