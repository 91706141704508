import styled from "styled-components"

const Line = styled.div`
	height: 1px;
	align-self: stretch;

	background-color: var(--color-blue-300);
`

export default Line
