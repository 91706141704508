import {
  getCategoriesForFilter,
  getInitialFilters,
} from "@/ssr/gallery"
import { getTemplates } from "@/ssr/templates"
import {
  SSRTemporaryRedirect,
  wrapSSRAuth,
} from "@/utils/ssr-auth"
import {
  AvailableFilters,
  AvailableFiltersTemplates,
  FilterStateTemplates,
  TemplatesWithSections,
} from "pages/ai-gallery"
import GalleryAll from "sections/gallery/all-gallery"
import { z } from "zod"

export const templateSchema = z.object({
  id: z.string(),
  likes: z.number(),
  setups: z.number(),
  preview_image_url: z.string(),
  preview_gif_url: z.string().nullable(),
  preview_webp_url: z.string().nullable(),
  price: z.number(),
  is_pro: z.boolean(),
  created_by_user: z.object({
    profile_pic: z
      .object({
        original: z.string().nullable().default(null),
      })
      .nullable(),
    username: z.string(),
  }),
})

export type TemplateEntity = z.infer<typeof templateSchema>

export const featuredResultData = z.object({
  section: z.enum(["featured", "rec", "used"]),
  data: z.array(templateSchema),
})

export const categorySchema = z.object({
  section: z.literal("category"),
  data: z.array(templateSchema),
  category: z.object({
    id: z.number(),
    name: z.string(),
  }),
})

export const categoriesResultData =
  featuredResultData.merge(categorySchema)

export const templatesWithCategoriesResponseSchema =
  z.object({
    result: z.object({
      data: z.array(categoriesResultData),
    }),
  })

export const searchedTemplates = z.object({
  result: z.object({
    tutorials: z.array(templateSchema),
  }),
})

export const featuredResponseSchema = z.object({
  result: z.array(featuredResultData),
})

export type Template = ReturnType<typeof templateExtractor>

const indexFiltersWithoutApplied = {
  selectedCategory: -2,
  price: "all",
  searchQuery: null,
  sortBy: "rec",
} as const

export const indexFiltersTemplates: FilterStateTemplates = {
  ...indexFiltersWithoutApplied,
  applyFilters: indexFiltersWithoutApplied,
}

export function templateExtractor(entity: TemplateEntity) {
  const {
    id,
    likes,
    setups,
    preview_gif_url,
    preview_image_url,
    preview_webp_url,
    created_by_user,
    price,
    is_pro,
  } = entity

  return {
    id,
    likes,
    setups,
    preview: {
      webp: preview_webp_url,
      gif: preview_gif_url,
      jpg: preview_image_url,
    },
    price,
    prime: is_pro,
    creator: {
      username: created_by_user.username,
      picture:
        created_by_user.profile_pic?.original ?? null,
    },
  }
}

export function featuredExtractor(
  entity:
    | z.infer<typeof featuredResultData>
    | z.infer<typeof categoriesResultData>,
) {
  let section
  let id

  if (entity.section === "category") {
    section = entity.category.name
    id = entity.category.id
  } else {
    section = entity.section
    id = null
  }

  return {
    id,
    section,
    data: entity.data.map(templateExtractor),
  }
}

export type TemplatesPageProps = {
  jobs: TemplatesWithSections[]
  initial: FilterStateTemplates
  availableFiltersTemplates: AvailableFiltersTemplates
  availableFiltersGallery: AvailableFilters
  pageTitle: string | null
}

export default function Templates(
  props: TemplatesPageProps,
) {
  return (
    <GalleryAll
      galleryJobs={null}
      templatesJobs={props.jobs}
      adExamplesJobs={null}
      initialFiltersTemplates={props.initial}
      initialFiltersGallery={null}
      availableFiltersTemplates={
        props.availableFiltersTemplates
      }
      availableFiltersGallery={
        props.availableFiltersGallery
      }
      initialFiltersAdExamples={null}
      pageTitle={props.pageTitle}
      metaLinks={null}
      initialPage="video-templates"
    />
  )
}

export const getServerSideProps = wrapSSRAuth(
  async (context, userInfo) => {
    if (!userInfo.isAnonymous) {
      throw new SSRTemporaryRedirect(
        "/discover?filter=video-templates",
      )
    }

    let categoryId = Number(context.query.categoryId)

    if (isNaN(categoryId)) {
      categoryId = -2
    }

    const token = userInfo.accessToken

    const applyFilters = {
      ...indexFiltersWithoutApplied,
      selectedCategory: categoryId,
    } as const

    const initial = {
      ...applyFilters,
      applyFilters,
    }

    const [
      jobs,
      availableFiltersTemplates,
      availableFiltersGallery,
    ] = await Promise.all([
      getTemplates(token, initial),
      getCategoriesForFilter(token),
      getInitialFilters(),
    ])

    const props: TemplatesPageProps = {
      jobs,
      initial,
      availableFiltersTemplates,
      availableFiltersGallery,
      pageTitle: null,
    }

    return props
  },
  [],
)
