import clsx from "clsx"
import React, { ReactNode, useState } from "react"
import styled, { css } from "styled-components"

const TooltipContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;

  display: flex;

  & * {
    ${(props) =>
      props.disabled &&
      css`
        pointer-events: none;
      `};
  }
`

const TooltipPopupContainer = styled.div<{
  active: boolean
  margin?: number
}>`
  position: absolute;
  top: calc(100% + 4px + ${(props) => props.margin ?? 0}px);
  z-index: 229;

  width: 100%;
  max-width: 350px;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: none;

  opacity: 0;
  ${(props) =>
    props.active &&
    css`
      transition: opacity 300ms ease-in-out 300ms;
      opacity: 1;
    `};
`

const TooltipPopup = styled.div<{
  width?: number
}>`
  color: var(--color-blue-100);
  background-color: var(--color-tooltip);
  text-wrap: normal;

  padding: 8px;
  border-radius: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  ${(props) =>
    props.width !== undefined &&
    css`
      width: ${props.width}px;
    `};
`

const TooltipTail = styled.div`
  background-color: var(--color-tooltip);

  margin-bottom: -7px;

  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-radius: 3px;
`

interface TooltipProps {
  children: React.ReactNode
  title: string
  disabled?: boolean
  margin?: number
  width?: number
  className?: string
  showAlways?: boolean
}

const Tooltip = (props: TooltipProps) => {
  const {
    children,
    className,
    title,
    disabled,
    margin,
    width,
    showAlways,
  } = props

  const [active, setActive] = useState(false)

  return (
    <TooltipContainer
      className={className}
      disabled={disabled}
      onMouseOver={() => {
        if (showAlways) setActive(true)
        else if (disabled) setActive(true)
      }}
      onMouseLeave={() => setActive(false)}>
      {children}
      <TooltipPopupContainer
        active={active}
        margin={margin}>
        <TooltipTail />
        <TooltipPopup width={width}>{title}</TooltipPopup>
      </TooltipPopupContainer>
    </TooltipContainer>
  )
}

interface NewTooltipProps {
  children: React.ReactNode
  title: string
  className?: string
}

export function NewTooltip(props: NewTooltipProps) {
  const { children, className, title } = props

  return (
    <div className={clsx(className ?? "relative flex")}>
      {children}
      <div
        className={clsx(
          "absolute left-1/2 -translate-x-1/2 peer-hover:opacity-100",
          "translate-y-[calc(100%+5px)] transition-opacity",
          "z-[999] opacity-0 duration-300",
        )}>
        <div
          className={clsx(
            "-mb-[7px] h-[12px] w-[12px] rotate-45 rounded-[3px] bg-color-tooltip",
            "absolute -top-[5px] left-1/2 -translate-x-1/2",
          )}
        />
        <div
          className={clsx(
            "bg-color-tooltip p-[8px] text-blue-100",
            "rounded-[8px] text-[12px] font-500 leading-[14px]",
          )}>
          {title}
        </div>
      </div>
    </div>
  )
}

interface TextTooltipProps {
  title: ReactNode
  disabled?: boolean
  className?: string
  tooltipClassName?: string
  children: React.ReactNode
  showAlways?: boolean
}

export function TextTooltip(props: TextTooltipProps) {
  const {
    title,
    className,
    tooltipClassName,
    children,
    disabled,
    showAlways,
  } = props

  const [active, setActive] = useState(false)

  return (
    <div
      className={clsx("relative", className)}
      onMouseOver={() => {
        if (showAlways) setActive(true)
        else if (disabled) setActive(true)
      }}
      onMouseLeave={() => setActive(false)}>
      {children}
      <div
        className={clsx(
          "absolute left-1/2 top-full -translate-x-1/2 rotate-45",
          "h-5 w-5 translate-y-1 rounded-[4px]",
          "opacity-0",
          "bg-color-tooltip transition-all delay-150 duration-200",
          active ? "opacity-100" : "",
        )}
      />
      <span
        className={clsx(
          "pointer-events-none absolute w-full max-w-[350px] rounded-[8px] opacity-0",
          "z-[9999] px-3 py-1",
          "left-1/2 top-full -translate-x-1/2 translate-y-2",
          "bg-color-tooltip text-xs font-500 text-blue-100 transition-all delay-150 duration-200",
          active ? "opacity-100" : "",
          tooltipClassName,
        )}>
        {title}
      </span>
    </div>
  )
}

export default Tooltip
