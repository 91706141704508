import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"

import { devices } from "@/utils/breakpoints"
import { NotificationContext } from "@/utils/notification"
import { useTranslation } from "next-i18next"
import { useContext, useState } from "react"

import { assetUrl } from "@/utils/cdn"
import styled, { css, keyframes } from "styled-components"
import { CustomThemedResource } from "../image"

const appearBottom = keyframes`
	to {
		transform: translateY(0);
		opacity: 1;
	}
`

const eachAppearDelay = 50
const eachDuration = 150
const initialWait = 400

const IconContainer = styled.a<{
  hideDesktop?: boolean
  hideMobile?: boolean
  disabled?: boolean
  index: number
}>`
  width: 52px;
  height: 52px;

  padding: 11px;
  border-radius: 40px;

  flex-shrink: 0;

  transition: background-color 300ms ease-in-out,
    opacity 200ms ease-in-out;

  background-color: var(--color-background);

  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-300);
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: unset;

      &:hover {
        background-color: var(--color-background);
      }
    `};

  opacity: 0;
  transform: translateY(50px);

  animation: ${appearBottom} ${eachDuration}ms forwards
    ${(props) =>
      props.index * eachAppearDelay + initialWait}ms
    ease-out;

  ${(props) =>
    props.hideMobile &&
    css`
      display: none;
    `};
  @media ${devices.desktop} {
    display: unset;

    ${(props) =>
      props.hideDesktop &&
      css`
        display: none;
      `};
  }
`

interface ShareProps {
  url: string
  index: number
}

export const Facebook = (props: ShareProps) => (
  <IconContainer
    index={props.index}
    target="_blank"
    rel="noopener noreferrer"
    href={
      "https://www.facebook.com/sharer/sharer.php?u=" +
      props.url
    }>
    <img
      src={assetUrl("/comps/facebook-colorful.svg")}
      alt="facebook icon"
      className="h-[30px] w-[30px]"
    />
  </IconContainer>
)

export const Messenger = (props: ShareProps) => (
  <>
    <IconContainer
      index={props.index}
      hideDesktop
      href={`fb-messenger://share?link=${props.url}&app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}}`}>
      <img
        src={assetUrl("/comps/messenger-colorful.svg")}
        alt="facebook icon"
        className="h-[30px] w-[30px]"
      />
    </IconContainer>
    <IconContainer
      index={props.index}
      hideMobile
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.facebook.com/dialog/send?link=${
        props.url
      }&app_id=${
        process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
      }&redirect_uri=${encodeURIComponent(
        "https://zoomerang.app",
      )}`}>
      <img
        src={assetUrl("/comps/messenger-colorful.svg")}
        alt="facebook icon"
        className="h-[30px] w-[30px]"
      />
    </IconContainer>
  </>
)

export const WhatsApp = (props: ShareProps) => (
  <IconContainer
    index={props.index}
    target="_blank"
    rel="noopener noreferrer"
    href={`https://wa.me/?text=${props.url}`}>
    <img
      src={assetUrl("/comps/whatsapp-colorful.svg")}
      alt="facebook icon"
      className="h-[30px] w-[30px]"
    />
  </IconContainer>
)

export const Email = (props: ShareProps) => (
  <IconContainer
    index={props.index}
    target="_blank"
    rel="noopener noreferrer"
    href={`mailto:?body=${props.url}`}>
    <img
      src={assetUrl("/comps/email-colorful.svg")}
      alt="facebook icon"
      className="h-[30px] w-[30px]"
    />
  </IconContainer>
)

export const Viber = (props: ShareProps) => (
  <IconContainer
    index={props.index}
    target="_blank"
    rel="noopener noreferrer"
    href={`viber://forward?text=${props.url}`}>
    <CustomThemedResource
      className="h-[30px] w-[30px]"
      source="/comps/viber-colorful"
      format="svg"
    />
  </IconContainer>
)

export const Pinterest = (props: ShareProps) => (
  <IconContainer
    index={props.index}
    target="_blank"
    rel="noopener noreferrer"
    href={`https://pinterest.com/pin/create/button/?url=${props.url}`}>
    <img
      src={assetUrl("/comps/pinterest-colorful.svg")}
      alt="facebook icon"
      className="h-[30px] w-[30px]"
    />
  </IconContainer>
)

export const URLIcon = (props: ShareProps) => {
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()

  const [disabled, setDisabled] = useState(false)

  return (
    <IconContainer
      index={props.index}
      disabled={disabled}
      onClick={async () => {
        if (disabled) return

        try {
          setDisabled(true)
          await navigator.clipboard.writeText(props.url)
          notify(
            <SuccessMessage>
              {t("txt_clipboard_success")}
            </SuccessMessage>,
          )
          setTimeout(() => setDisabled(false), 3500)
        } catch (_) {
          notify(
            <ErrorMessage>
              {t("txt_clipboard_failure")}
            </ErrorMessage>,
          )
        }
      }}>
      <img
        src={assetUrl("/comps/url-colorful.svg")}
        alt="facebook icon"
        className="h-[30px] w-[30px]"
      />
    </IconContainer>
  )
}

const ArrowIconContainer = styled.div<{ show: boolean }>`
  padding: 4px;
  width: 24px;
  height: 24px;

  path {
    fill: var(--color-blue-500);
  }

  transform: rotate(
    ${(props) => (props.show ? 0 : 180)}deg
  );

  transition: transform 200ms ease-in-out,
    background-color 300ms ease-in-out;

  cursor: pointer;
  border-radius: 20px;

  :hover {
    background-color: var(--color-background);
  }
`

interface ArrowIconProps {
  show: boolean
  onClick: () => void
}

export const ArrowIcon = (props: ArrowIconProps) => (
  <ArrowIconContainer
    show={props.show}
    onClick={props.onClick}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 6L7.46967 5.46967C7.61032 5.32902 7.80109 5.25 8 5.25C8.19891 5.25 8.38968 5.32902 8.53033 5.46967L8 6ZM12.5303 9.46967C12.8232 9.76256 12.8232 10.2374 12.5303 10.5303C12.2374 10.8232 11.7626 10.8232 11.4697 10.5303L12.5303 9.46967ZM4.53033 10.5303C4.23744 10.8232 3.76256 10.8232 3.46967 10.5303C3.17678 10.2374 3.17678 9.76256 3.46967 9.46967L4.53033 10.5303ZM8.53033 5.46967L12.5303 9.46967L11.4697 10.5303L7.46967 6.53033L8.53033 5.46967ZM8.53033 6.53033L4.53033 10.5303L3.46967 9.46967L7.46967 5.46967L8.53033 6.53033Z"
        fill="#A8A8A8"
      />
    </svg>
  </ArrowIconContainer>
)
