import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import dynamic from "next/dynamic"
import { useState } from "react"
import { PositionType } from "../fabric-canvas"
import { CustomThemedResource } from "../image"

const FabricCanvas = dynamic(
  () => import("../fabric-canvas"),
  {
    loading: () => <div />,
    ssr: false,
  },
)

interface ModelCompositionProps {
  aspectRatio: number
  compInfo:
    | {
        image: string
        thumb?: string | undefined
        preview?: string | undefined
      }[]
    | null
    | undefined
  selectedComposition: string | null
  setSelectedComposition: (v: string | null) => void
  imagePosition: PositionType | null
  setImagePosition: (v: PositionType) => void
  setRotation: (v: number) => void
  setCenterX: (v: number) => void
  setCenterY: (v: number) => void
}

export function ModelComposition(
  props: ModelCompositionProps,
) {
  const {
    aspectRatio,
    compInfo,
    selectedComposition,
    setSelectedComposition,
    imagePosition,
    setImagePosition,
    setRotation,
    setCenterX,
    setCenterY,
  } = props

  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const { userInfo } = useAuth()

  let visited = null
  if (typeof window !== "undefined") {
    visited = localStorage.getItem(
      `visited-composition-id-${userInfo.userId}`,
    )
  }

  const showNew = !visited

  return (
    <div
      className={clsx(
        "relative flex w-full flex-col items-start rounded-[14px] bg-color-cell px-[14px] py-[17px]",
        "gap-[17px]",
        open
          ? "animate-appear"
          : "max-h-[60px] min-h-[60px] overflow-hidden",
      )}>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-[10px]">
          <img
            src={assetUrl("/general/composition-icon.svg")}
            alt="composition icon"
          />
          <span className="text-[16px] font-600 text-blue-800">
            {t("lbl_composition")}
          </span>
          <div
            className={clsx(
              !showNew && "hidden",
              "rounded-[4px] bg-primary-100 px-[4px] py-[2px]",
            )}>
            <span className="txt-[12px] font-700 text-primary-500">
              {t("lbl_new")}
            </span>
          </div>
          <div
            className={clsx(
              open ? "group relative" : "hidden",
            )}>
            <img
              src={assetUrl("/general/info.svg")}
              alt="info icon"
              className={clsx(
                "-mb-[20px] cursor-pointer pb-[20px]",
              )}
            />

            <div
              className={clsx(
                "absolute hidden rounded-[8px] bg-blue-800 px-[5px] py-[9px]",
                "left-1/2 top-[32px] z-[100] w-[330px] -translate-x-1/2 group-hover:flex",
              )}>
              <div className="relative -top-[15px] left-1/2 h-[15px] w-[15px] -translate-x-1/2 rotate-45 rounded-none rounded-tl-[4px] bg-blue-800" />
              <span className="text-[14px] font-400 text-color-cell">
                {t("txt_composition_presentation")}
              </span>
            </div>
          </div>
        </div>

        <button
          className={clsx("flex w-full justify-end")}
          onClick={() => setOpen(!open)}>
          <CustomThemedResource
            source="/general/arrow"
            format="svg"
            alt="arrow icon"
            className={clsx(
              open ? "rotate-90" : "rotate-0",
            )}
          />
        </button>
      </div>

      <div
        className={clsx(
          open ? "flex" : "hidden",
          "w-full justify-center",
        )}>
        <FabricCanvas
          aspectRatio={aspectRatio}
          width={331}
          height={331}
          imageUrl={selectedComposition ?? ""}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setRotation={setRotation}
          setCenterX={setCenterX}
          setCenterY={setCenterY}
        />
      </div>

      <div className="max-w-[561px] overflow-hidden">
        {compInfo && (
          <div className="no-scrollbar flex h-[64px] items-center gap-[5px] overflow-hidden overflow-x-scroll">
            <button
              onClick={() => {
                setSelectedComposition(null)
              }}
              className={clsx(
                selectedComposition === null
                  ? "border-primary-500 bg-primary-100"
                  : "border-color-cell bg-blue-100 hover:bg-blue-300",
                "flex h-[64px] w-[64px] min-w-[64px] items-center justify-center rounded-[6px] border-2",
              )}>
              <img
                src={assetUrl("/general/none.svg")}
                alt="composition style thumb"
                className="h-[24px] !w-[24px]"
              />
            </button>
            {compInfo?.map((elem) => {
              return (
                <button
                  key={elem.image}
                  onClick={() => {
                    setSelectedComposition(
                      elem.preview ?? elem.image,
                    )
                  }}
                  className={clsx(
                    selectedComposition === elem.preview ||
                      selectedComposition === elem.image
                      ? "border-primary-500 bg-primary-100"
                      : "border-color-cell bg-blue-100 hover:bg-blue-300",
                    "h-[64px] w-[64px] min-w-[64px] rounded-[6px] border-2",
                  )}>
                  <img
                    src={
                      elem.thumb ??
                      elem.preview ??
                      elem.image
                    }
                    alt="composition style thumb"
                    className="h-[64px] !w-[64px]"
                  />
                </button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
