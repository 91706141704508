import {
  DesktopOrTablet,
  devices,
  Mobile,
} from "@/utils/breakpoints"

import styled from "styled-components"

import Copyright from "./copyright"
import Line from "./line"
import Links from "./links"

const FooterContainer = styled.div`
  z-index: 77;
  margin-top: 32px;
  position: relative;

  @media ${devices.tablet} {
    margin-top: 60px;
  }

  @media ${devices.desktop} {
    margin-top: 100px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: var(--w-screen);
  background-color: var(--color-blue-200);
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin: 0 16px;

  @media ${devices.tablet} {
    width: 728px;
    align-self: unset;
  }

  @media ${devices.desktop} {
    width: 1170px;
  }
`

interface FooterProps {
  className?: string
}

const FooterOld = (props: FooterProps) => (
  <FooterContainer className={props.className}>
    <TopContainer>
      <Links />
      <Line />
      <Copyright />
    </TopContainer>
  </FooterContainer>
)

const Footer = (props: FooterProps) => (
  <FooterContainer className={props.className}>
    <TopContainer>
      <Links />
      <DesktopOrTablet className="tablet:w-[728px] desktop:w-[1170px]">
        <Line />
      </DesktopOrTablet>
    </TopContainer>
    <Mobile className="w-full">
      <Line />
    </Mobile>
    <TopContainer>
      <Copyright />
    </TopContainer>
  </FooterContainer>
)

const WrappedFooterContainer = styled(FooterContainer)`
  margin-top: 0;
`

export const WrappedFooter = (props: FooterProps) => (
  <WrappedFooterContainer className={props.className}>
    <TopContainer>
      <Links />
      <DesktopOrTablet className="tablet:w-[728px] desktop:w-[1170px]">
        <Line />
      </DesktopOrTablet>
    </TopContainer>
    <Mobile className="w-full">
      <Line />
    </Mobile>
    <TopContainer>
      <Copyright />
    </TopContainer>
  </WrappedFooterContainer>
)

export default Footer
