import { FontSize } from "./globals"

const getLetterSpacing = (size: FontSize) => {
	switch (size) {
		case "50":
		case "48":
		case "44":
		case "40":
		case "36":
		case "32":
			return "0.035em"
		default:
			return "0.02em"
	}
}

export default getLetterSpacing
