import {
  AIPhotoshoot,
  deforumIcon,
  globusIcon,
  lookAiIcon,
  photoAiIcon,
  restyleIcon,
  textToImageIcon,
  textToVideoIcon,
} from "@/comps/ai-tools/icons"
import {
  allProducts,
  OtherProduct,
} from "@/comps/ai-tools/other-products-section"
import { createInputs } from "@/comps/editors/deform"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { appStoreConstant } from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import {
  AITool,
  DEFORUM_STORAGE_KEY,
  DynamicEditorProps,
} from "controllers/editors"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { GalleryTools } from "pages/ai-gallery"
import { DeforumPrivacyRequest } from "pages/api/deforum-privacy"
import { getModelType } from "pages/models"
import { ModelType } from "pages/models/create"
import { Arrow } from "pages/tools"
import {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { useQueryClient } from "react-query"
import {
  DialogProps,
  SocialButton,
  socials,
} from "sections/history/generations"
import { MoreButton } from "./more-button"
import { RenderContent } from "./render-content"
import { RenderTextContent } from "./render-text-content"
import { SaveMediaPopup } from "./save-media-popup"
import { ShareContent } from "./share-content"

export interface HistoryPopupProps {
  id: number
  tool: string
  imageOrVideo: string | string[]
  createdAt: number | string
  prompt: string
  privacy: "public" | "private"
  customPrompt: string
  setDialogContent?: (content: DialogProps) => void
  promptName: string
  aspectRatio?: number
  onClose: () => void
  handleRetry: () => void
  notShallow: boolean
  thumb?: string
  model?: string
  modelType?: ModelType | string
  handleDelete?: (id: number) => void
  model_id?: number | null
  previewImage: string
  initial?: string
  closeLink?: string
  selectCreatorTool?: (
    tool: AITool,
    content: DynamicEditorProps,
  ) => void
}

export function HistoryPopup(props: HistoryPopupProps) {
  const {
    imageOrVideo,
    onClose,
    createdAt,
    privacy,
    id,
    tool,
    initial,
    handleRetry,
    notShallow,
    handleDelete,
    selectCreatorTool,
  } = props

  const queryClient = useQueryClient()
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  const [isMobileView, _] = useState(
    window.matchMedia("(max-width: 767px)").matches,
  )
  const [isPublic, setPublic] = useState(
    privacy === "public",
  )
  const [showDropdown, setShowDropdown] =
    useState<boolean>(false)
  const [shareDialog, setShareDialog] =
    useState<DialogProps>({
      open: false,
      isPublic: false,
      link: `${APP_HOST}/profile/generations/${id}`,
      id: null,
    })
  const [showMediaDialog, setShowMediaDialog] =
    useState(false)
  const [offset, setOffset] = useState(0)

  async function handleMakePublic(
    privacy: "public" | "private",
    id: number,
  ) {
    try {
      const payload: DeforumPrivacyRequest = {
        privacy:
          privacy === "public" ? "private" : "public",
        job: id,
      }

      await axios.post("/api/deforum-privacy", payload)
      queryClient.invalidateQueries("deforum-history")
    } catch (error) {
      console.error(error)
      notify(
        <ErrorMessage>
          {t(
            privacy === "public"
              ? "txt_could_not_make_public"
              : "txt_could_not_make_private",
          )}
        </ErrorMessage>,
      )
    }
  }

  async function handleSocialShare(
    desktop?: boolean,
    desktopFunction?: (link: string) => string,
    url?: (link: string) => string,
  ): Promise<string> {
    try {
      if (!shareDialog.isPublic) {
        await handleMakePublic("private", id)
      }

      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })

      if (desktop && desktopFunction)
        return desktopFunction(shareDialog.link)
      else if (url) return url(shareDialog.link)
      else throw Error("No function")
    } catch (e) {
      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
      return ""
    }
  }

  const router = useRouter()

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (!notShallow) {
          router.push(
            `/profile/generations/all`,
            undefined,
            {
              shallow: true,
            },
          )
        }
        onClose()
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener(
        "keydown",
        handleKeyPress,
      )
    }
  }, [notShallow, onClose, router])

  function backgroundClickHandler(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    const target = e.target
    if (target instanceof HTMLElement) {
      if (target.id === "background") {
        if (!notShallow) {
          router.push(
            `/profile/generations/all`,
            undefined,
            {
              shallow: true,
            },
          )
        }
        onClose()
      }
      if (target.id !== "media-box") {
        setShowMediaDialog(false)
      }
    }
  }

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    try {
      await navigator.clipboard.writeText(
        `${APP_HOST}/profile/generations/${id}`,
      )
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  return (
    <div
      className={clsx(
        "no-scrollbar fixed inset-0 z-[100] h-screen w-screen overflow-y-scroll",
        "transition-opacity duration-300",
        "bg-color-background tablet:overscroll-contain tablet:bg-color-black/40",
        "cursor-auto desktop:overflow-hidden",
      )}
      id="background"
      onClick={backgroundClickHandler}>
      <div
        className={clsx(
          "no-scrollbar relative w-screen bg-color-cell tablet:rounded-xl",
          "tablet:left-1/2 tablet:top-[100px] tablet:h-[1090px] tablet:w-[564px] tablet:-translate-x-1/2 ",
          " responsive-zoom desktop:h-[857px] desktop:w-[1206px]",
          "desktop:top-1/2 desktop:-translate-y-1/2",
        )}>
        <div
          className={clsx(
            "relative flex flex-col",
            "desktop:w-full desktop:flex-row",
          )}>
          {/*IMAGES_SECTION*/}
          <RenderContent
            {...props}
            offset={offset}
            setOffset={setOffset}
            viewFor="history"
          />

          {/*SECOND_SECTION*/}
          <div
            className={clsx(
              "mb-[60px] hidden flex-col gap-[12px] py-[14px] tablet:flex desktop:mb-0",
              "no-scrollbar shrink-0 overflow-y-scroll desktop:w-[431px] desktop:py-[16px]",
              "relative z-50 bg-color-cell tablet:h-[559px] desktop:h-[857px]",
              "rounded-xl tablet:rounded-t-none desktop:rounded-l-none desktop:rounded-r-xl",
            )}>
            <div className="flex shrink-0 flex-col gap-[8px] px-[16px] desktop:w-[431px]">
              <div className="flex flex-col">
                <div className="flex items-center gap-[12px] [--icon-color:var(--color-blue-600)]">
                  {props.model === undefined &&
                    transformToolToIcon(
                      tool as GalleryTools,
                    )}
                  <span
                    className={clsx(
                      "text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-blue-700",
                    )}>
                    {props.model !== undefined
                      ? props.model
                      : transformTool(tool as GalleryTools)}
                  </span>
                </div>
                <span
                  className={clsx(
                    "text-[16px] font-[500] leading-[26px] tracking-[0.28px] text-blue-500",
                  )}>
                  {formatTimes(createdAt)}
                </span>
              </div>
            </div>

            <div className="mx-auto h-[1px] w-[calc(100%-32px)] shrink-0 bg-color-separator"></div>

            <div
              className={clsx(
                "relative z-50 flex shrink-0 items-start gap-[10px] px-[16px]",
                "no-scrollbar -mb-[30px] pb-[30px] desktop:overflow-x-auto",
              )}>
              <SaveMediaPopup
                offset={offset}
                showMediaDialog={showMediaDialog}
                setShowMediaDialog={setShowMediaDialog}
                imageOrVideo={imageOrVideo}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
              />

              <div className="h-[50px] w-[1px] bg-blue-300 desktop:shrink-0"></div>

              <button
                onClick={handleCopyText}
                className={clsx(
                  "relative flex items-center justify-center",
                  "group transition-colors hover:bg-blue-300",
                  "disabled:hover:bg-transparent disabled:opacity-50",
                  "h-[52px] w-[52px] shrink-0 overflow-visible rounded-full bg-color-background",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/copy-primary.svg",
                  )}
                  alt="copy icon"
                  className="h-[20px] w-[20px]"
                />
                <div
                  className={clsx(
                    "pointer-events-none absolute -bottom-[30px] w-[80px]",
                    "rounded-[4px] px-3 py-1 opacity-0 hover:opacity-0 group-hover:opacity-100",
                    "bg-color-tooltip transition-all",
                    "text-xs font-500 text-blue-100",
                  )}>
                  {t("txt_copy_link")}
                </div>
              </button>
              {socials.map((props) => (
                <SocialButton
                  {...props}
                  title=""
                  tooltipTitle={props.title}
                  handleSocialShare={handleSocialShare}
                  dialogContent={shareDialog}
                  key={props.icon ?? props.light}
                />
              ))}
            </div>

            <RenderTextContent {...props} />

            {props.modelType !== undefined && (
              <div className="flex">
                <div
                  className={clsx(
                    "ml-[16px] flex items-center gap-[4px] rounded-[5px]",
                    "bg-color-background px-[10px] py-[4px]",
                  )}>
                  <img
                    src={assetUrl(
                      "/general/model-icon.svg",
                    )}
                    className="h-[14px] w-[14px]"
                    alt="model icon"
                  />
                  <span className="text-[14px] font-500 text-blue-700">
                    {getModelType(
                      props.modelType as ModelType,
                    )}
                  </span>
                </div>
              </div>
            )}

            <button
              onClick={handleRetry}
              className={clsx(
                "mx-auto my-[4px] hidden w-[calc(100%-32px)] shrink-0 items-center",
                "justify-center gap-[12px] rounded-[10px] bg-primary-500 py-[12px]",
                "hover:bg-primary-600 desktop:my-[10px] desktop:flex",
              )}>
              <img
                src={assetUrl(
                  "/general/regenerate-white.svg",
                )}
                alt="plus icon"
              />
              <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                {props.model
                  ? t("lbl_remake")
                  : t("txt_regenerate")}
              </span>
            </button>

            <div className="w-full shrink-0 px-4">
              <a
                href={appStoreConstant}
                className={clsx(
                  "relative mx-auto flex w-full items-center justify-center",
                  "gap-[12px] rounded-[10px] bg-primary-500 py-[12px]",
                  "hover:opacity-70 desktop:hidden",
                )}>
                <img
                  src={assetUrl(
                    "/general/regenerate-white.svg",
                  )}
                  alt="plus icon"
                />
                <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                  {t("txt_regenerate")}
                </span>
              </a>
            </div>
            <span className="px-4 text-[16px] font-700 tracking-normal text-blue-700 desktop:mt-auto">
              {t("txt_other_ai_tools")}
            </span>

            <div
              className={clsx(
                "no-scrollbar flex w-full gap-2 overflow-x-scroll desktop:gap-0",
                "tablet:px-[16px] desktop:flex-col",
              )}>
              {allProducts
                .filter((t) => t.tool !== tool)
                .map((product, index) => {
                  return (
                    <SingleProductHistory
                      product={product}
                      key={index}
                      selectCreatorTool={selectCreatorTool}
                      onClose={onClose}
                    />
                  )
                })}
            </div>
          </div>

          {/*MOBILE_SECOND_SECTION */}
          <div
            className={clsx(
              "absolute bottom-0 left-0 z-[101] flex w-full flex-col gap-[19px] px-[16px] pb-[46px] tablet:hidden",
              "bg-gradient-to-t from-color-black/75 via-color-black/40 to-color-black/5 backdrop-blur-[1px]",
            )}>
            <div className="flex flex-col gap-[8px]">
              <div
                className={clsx(
                  "flex items-center justify-between",
                  "tablet:absolute tablet:-top-[360px] tablet:flex-col tablet:items-start",
                )}>
                <div className="flex items-center gap-[12px] [--icon-color:var(--color-white)]">
                  {transformToolToIcon(
                    tool as GalleryTools,
                  )}
                  <span
                    className={clsx(
                      " text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-color-white ",
                      "tablet:text-blue-700",
                    )}>
                    {transformTool(tool as GalleryTools)}
                  </span>
                </div>
                <span
                  className={clsx(
                    "text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-blue-400",
                  )}>
                  {formatTimes(createdAt)}
                </span>
              </div>

              <div className="flex flex-col">
                {tool === "text_to_image" ||
                  (tool === "text_to_video" && (
                    <div className="h-[1px] w-full bg-color-separator opacity-40 dark:opacity-100" />
                  ))}
                <RenderTextContent {...props} />
              </div>
            </div>

            <a
              href={appStoreConstant}
              className={clsx(
                "relative flex w-full items-center justify-center gap-[12px]",
                "rounded-[10px] bg-primary-100 py-[12px] hover:bg-primary-200",
              )}>
              <img
                src={assetUrl("/general/regenerate.svg")}
                alt="plus icon"
              />
              <div className="absolute inset-0 -z-10 h-full w-full rounded-[10px] bg-color-black/80"></div>
              <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-primary-500">
                {t("txt_regenerate")}
              </span>
            </a>
          </div>

          <div
            className={clsx(
              " absolute right-[17px] top-[17px] z-50",
              "tablet:top-[680px] desktop:top-[18px]",
              "flex items-center gap-[12px]",
            )}>
            {!isPublic && (
              <button
                className={clsx(
                  "hidden cursor-pointer items-center gap-[6px] rounded-[10px]",
                  "bg-blue-100 px-[18px] py-[12px] tablet:flex",
                  "[--icon-color:var(--color-blue-800)] hover:bg-blue-300",
                )}
                onClick={async () => {
                  setPublic(!isPublic)
                  setShowDropdown(!showDropdown)
                  await handleMakePublic(privacy, id)
                }}>
                {globusIcon()}
                <span className="text-[14px] font-500 text-blue-800">
                  {isPublic
                    ? t("txt_make_private")
                    : t("txt_make_public")}
                </span>
              </button>
            )}
            <MoreButton
              handleMakePublic={handleMakePublic}
              setShowDropdown={setShowDropdown}
              setShareDialog={setShareDialog}
              handleDelete={handleDelete}
              setPublic={setPublic}
              showDropdown={showDropdown}
              dark={!isMobileView}
              isPublic={isPublic}
              privacy={privacy}
              href={initial ?? ""}
              id={id}
            />
          </div>
        </div>
      </div>
      {shareDialog.open && (
        <ShareContent
          shareDialog={shareDialog}
          setShareDialog={setShareDialog}
          isPublic={isPublic}
          id={id}
          handleMakePublic={handleMakePublic}
        />
      )}
    </div>
  )
}

export function formatTimes(
  timestamp: number | string,
): string {
  const date = new Date(timestamp)
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  }
  return date.toLocaleDateString("en-US", options)
}

interface OtherProductsSectionProps {
  product: OtherProduct
  selectCreatorTool?: (
    tool: AITool,
    content: DynamicEditorProps,
  ) => void
  onClose?: () => void
}

export function SingleProductHistory(
  props: OtherProductsSectionProps,
) {
  const { product, selectCreatorTool, onClose } = props
  const { t } = useTranslation("common")
  const { tool } = product
  const router = useRouter()

  async function handleClickAction() {
    const inputs = createInputs({
      type: "custom",
      content: [""],
    })
    const promptType = "custom"

    if (!selectCreatorTool) {
      return
    }

    switch (tool) {
      case "deform":
        selectCreatorTool(tool, {
          tool: "deform",
          id: DEFORUM_STORAGE_KEY,
          inputs,
          promptType,
          location: "local",
          blob: null,
        })
        onClose && onClose()
        break

      case "ai_photo":
      case "ai_restyle":
        selectCreatorTool(tool, {
          tool,
          id: DEFORUM_STORAGE_KEY,
          input: "",
          promptType: promptType,
          location: "local",
          blob: null,
        })
        onClose && onClose()
        break

      case "text_to_image":
        selectCreatorTool(tool, {
          tool,
          id: DEFORUM_STORAGE_KEY,
          promptType,
          text: "",
          location: "local",
          blob: null,
        })
        onClose && onClose()
        break
      default:
        router.push(product.url)
    }
  }

  return (
    <>
      <a
        href={product.url}
        onClick={(e) => {
          if (selectCreatorTool) {
            e.preventDefault()
            handleClickAction()
          }
        }}
        className={clsx(
          "flex w-[269px] shrink-0 flex-row items-center justify-between gap-[10px] rounded-[8px] bg-blue-100 py-[12px] pl-[12px] pr-[12px] hover:bg-blue-200",
          "cursor-pointer desktop:w-full desktop:rounded-none desktop:border-b desktop:border-blue-300 first:desktop:rounded-t-[8px] last:desktop:rounded-b-[8px] last:desktop:border-none",
        )}>
        <div
          className={clsx(
            "flex flex-row items-center gap-[10px]",
          )}>
          <video
            muted
            loop
            playsInline
            autoPlay
            src={assetUrl(
              `/ai-tools/${product.location}/intro.mp4#t=0.001`,
            )}
            className={clsx(
              "aspect-1 h-[88px] rounded-[6px] object-cover object-top",
              "transition-all duration-300",
              "desktop:hidden",
            )}
          />
          <div className="flex flex-col gap-2 [--icon-color:var(--color-blue-700)] desktop:flex-row desktop:items-center desktop:gap-3 ">
            {product.icon}
            <div className="flex flex-1 flex-col items-start gap-1 text-[16px] font-500 text-blue-700 ">
              <p className="leading-[19px] tablet:leading-normal">
                {t(product.label)}
              </p>
              <p className="truncate text-[14px] font-400 leading-[15px] text-blue-600 tablet:w-[120px] desktop:w-[310px]">
                {t(`${product.label}_description`)}
              </p>
            </div>
          </div>
        </div>
        <div className="[--icon-color:var(--color-placeholder)]">
          <Arrow />
        </div>
      </a>
    </>
  )
}

export function transformTool(word: GalleryTools): string {
  const transformations: Record<string, string> = {
    deform: "Deform",
    ai_photo: "AI Photo",
    ai_restyle: "AI Restyle",
    text_to_image: "Text to Image",
    text_to_video: "Text to Video",
    ai_look: "Look AI",
    ai_suggest: "AI Photoshoot",
  }

  return transformations[word] || word
}

export function transformToolToIcon(
  word: GalleryTools,
): ReactNode {
  const transformations: Record<string, ReactNode> = {
    deform: deforumIcon(),
    ai_photo: photoAiIcon(),
    ai_restyle: restyleIcon(),
    text_to_image: textToImageIcon(),
    text_to_video: textToVideoIcon(),
    ai_look: lookAiIcon(),
    ai_suggest: AIPhotoshoot(),
  }

  return transformations[word] || <></>
}
