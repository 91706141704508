import type { FollowStatus } from "types/endpoints/user-profile"
import type {
  BackEndDescriptionTag,
  BackEndDescriptionTagArray,
  BackEndResponse,
  BackEndTemplate,
  BackEndTutorialTemplate,
  Response,
  TaggedTextSlice,
  Template,
  TutorialCategory,
  TutorialTemplate,
} from "../types/endpoints/tutorial"

export const followStatusMap: Record<number, FollowStatus> =
  {
    0: "not_following",
    1: "following",
    2: "follow_back",
    3: "requested",
  }

export const getTaggedSlices = (
  tags: BackEndDescriptionTagArray | null,
  text: string | null,
): TaggedTextSlice[] => {
  const withUsernames = extractUsernames(
    text || "",
    tags ?? [],
  )

  const formatted = extractHashtags(withUsernames)

  return formatted
}

const extractUsernames = (
  description: string,
  tags: BackEndDescriptionTag[],
): TaggedTextSlice[] => {
  const initial: TaggedTextSlice[] = []
  let beginning = 0
  const formatted = tags.reduce((acc, tag) => {
    const text: TaggedTextSlice = {
      format: "text",
      content: description?.slice(beginning, tag.s) || "",
    }

    beginning = tag.e
    const user: TaggedTextSlice = {
      format: "username",
      label: description?.slice(tag.s + 1, tag.e) ?? "",
      uid: tag.uid,
    }
    return [...acc, text, user]
  }, initial)

  formatted.push({
    format: "text",
    content:
      description?.slice(beginning, description?.length) ||
      "",
  })
  return formatted
}

const stringToHashtag = (
  text: string,
): TaggedTextSlice[] => {
  const words = text.split(" ")
  const tagged: TaggedTextSlice[] = words.map((word) => {
    if (word[0] === "#")
      return {
        format: "hashtag",
        content: word.slice(1),
      } as const
    return { format: "text", content: word + " " } as const
  })

  interface ReducedText {
    tags: TaggedTextSlice[]
    last: string
  }

  const initial: ReducedText = { tags: [], last: "" }

  const reduced = tagged.reduce((acc, curr) => {
    if (curr.format === "text") {
      acc.last += curr.content
      return acc
    }

    const newTag: TaggedTextSlice = {
      format: "text",
      content: acc.last,
    }

    return {
      tags: [...acc.tags, newTag, curr],
      last: "",
    }
  }, initial)

  if (reduced.last)
    reduced.tags.push({
      format: "text",
      content: reduced.last,
    })

  return reduced.tags
}
const getCategoryName = (
  categories?: TutorialCategory[],
): null | string => {
  if (!categories) return null
  if (!categories.length) return null

  const [category] = categories
  return category.name ?? null
}

const getCategoryId = (
  categories?: TutorialCategory[],
): null | number => {
  if (!categories) return null
  if (!categories.length) return null

  const [category] = categories
  return category.id ?? null
}

const templatePrivacy = [
  "public",
  "private",
  "friends",
] as const

const extractHashtags = (
  description: TaggedTextSlice[],
): TaggedTextSlice[] =>
  description.reduce((acc, tag) => {
    if (tag.format === "text")
      return [...acc, ...stringToHashtag(tag.content)]
    return [...acc, tag]
  }, new Array<TaggedTextSlice>())

export const templatesExtractor = (
  templates: BackEndTutorialTemplate[],
): TutorialTemplate[] => {
  return templates.map((template) => {
    const {
      created_by_user: {
        username,
        profile_pic,
        followers,
        uid,
        follow_status,
      },
    } = template

    const original = profile_pic
      ? profile_pic.original
      : null

    return {
      ...singleTemplateExtractor(template),
      creator: {
        uid,
        follow_status:
          followStatusMap[follow_status ?? 99] ?? null,
        username,
        picture: original ?? null,
        followers,
      },
    }
  })
}

export const singleExtractor = (
  template: BackEndTutorialTemplate,
): TutorialTemplate => {
  const {
    created_by_user: {
      username,
      profile_pic,
      followers,
      uid,
      follow_status,
    },
  } = template

  const original = profile_pic ? profile_pic.original : null

  return {
    ...singleTemplateExtractor(template),
    creator: {
      uid,
      follow_status:
        followStatusMap[follow_status ?? 99] ?? null,
      username,
      picture: original ?? null,
      followers,
    },
  }
}

export const extractResponse = (
  response: BackEndResponse,
): Response => {
  if (!response.status) {
    console.error("/tutorials", response)
    throw "Status is false"
  }

  const { tutorials } = response.result

  return {
    templates: tutorials.map(singleExtractor),
  }
}

export const singleTemplateExtractor = (
  template: BackEndTemplate,
): Template => {
  const {
    id,
    name,
    description,
    views,
    likes,
    comments,
    created_at,
    preview_webp_url,
    preview_image_url,
    preview_gif_url,
    video_stream_small_url,
    preview_video_stream_url,
    preview_thumbnail_url,
    share_url,
    setups,
    is_pro,
    allow_comments,
    review_status,
    type,
    desc_tags,
    price,
    categories,
    privacy,
  } = template

  const formatted = getTaggedSlices(desc_tags, description)

  type TemplatePrice =
    | { pricing: "free" | "pro" }
    | { pricing: "coins"; price: number }

  let pricing: TemplatePrice = { pricing: "free" }
  if (is_pro) pricing = { pricing: "pro" }
  else if (price > 0) pricing = { pricing: "coins", price }

  const created = Number(created_at)

  if (Number.isNaN(created)) throw new Error("Date is NaN")

  const categoryName = getCategoryName(categories)
  const categoryId = getCategoryId(categories)
  const video =
    video_stream_small_url || preview_video_stream_url

  if (!video) {
    throw new Error("No video url")
  }

  return {
    privacy: templatePrivacy[privacy],
    id,
    name,
    description: formatted,
    likes,
    comments,
    created,
    share: share_url,
    preview: {
      webp: preview_webp_url,
      gif: preview_gif_url,
      jpg: preview_image_url,
    },
    first: preview_thumbnail_url,
    views,
    setups,
    video,
    ...pricing,
    category: categoryName,
    categoryId,
    allowComments: allow_comments,
    reviewStatus: review_status,
    isFeatured: type === 1,
  }
}
