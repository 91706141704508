import { CustomThemedResource } from "@/comps/image"
import setMaxCookie from "@/utils/maxcookie"
import clsx from "clsx"
import { parse } from "cookie"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import Button from "./button"

const disabledPages = [
	"/vidcon",
	"/webview",
	"/landing-preview",
	"/forms",
]

const rightPages = [
	"/chat",
	"/discover",
	"/home",
	"/ai-studio",
]

const Cookies = () => {
	const [allowed, setAllowed] = useState(true)

	const { t } = useTranslation()

	const allowCookie = () => {
		setAllowed(true)
		setMaxCookie("cookies", "true")
	}

	useEffect(() => {
		const { cookies } = parse(document.cookie)
		if (cookies) allowCookie()
		else setAllowed(false)
	}, [])

	const router = useRouter()
	const isDisabled = Boolean(
		disabledPages.find((page) =>
			router.pathname.startsWith(page),
		),
	)
	if (isDisabled) {
		return <></>
	}

	const showRight = Boolean(
		rightPages.find((page) =>
			router.asPath.startsWith(page),
		),
	)

	return (
		<div
			className={clsx(
				"fixed z-[200] flex items-center gap-4 p-3 min-[500px]:h-[150px] min-[500px]:px-6 min-[500px]:py-4",
				"left-[10px] w-[calc(100%-20px)] rounded-[8px] transition-all duration-700 ease-in-out",
				"min-[500px]:w-[450px] min-[500px]:rounded-xl",
				"border border-solid  border-color-separator bg-color-surface-cell backdrop-blur-md",
				allowed ? "opacity-0" : "opacity-100",
				allowed
					? "bottom-[-150px]"
					: "bottom-[10px] min-[500px]:bottom-4",
				showRight
					? "tablet:left-auto tablet:right-4"
					: "min-[500px]:left-4",
			)}>
			<CustomThemedResource
				alt="Cookie icon"
				source="/general/cookie-new"
				format="svg"
				className="hidden min-[500px]:block"
			/>
			<div className="flex flex-1 flex-row items-center gap-3 min-[500px]:flex-col">
				<span className="max-w-[300px] text-base text-blue-800">
					{t("common:txt_cookies")}
				</span>
				<Button
					text={"txt_its_okay"}
					onClick={allowCookie}
					className="!h-[44px] !w-[98px] !min-w-0 !p-0 min-[500px]:!w-full min-[500px]:!max-w-[300px]"
				/>
			</div>
		</div>
	)
}

export default Cookies
