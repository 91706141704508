export const scrollToSection = (
  section: string,
  padding?: number,
) => {
  return () => {
    const sectionElement = document.getElementById(section)

    if (sectionElement) {
      const { y } = sectionElement.getBoundingClientRect()
      const scrollTop = window.scrollY + y
      window.scroll({
        top: scrollTop - (padding ?? 110),
        behavior: "smooth",
      })
    }
  }
}
