import {
  flattenAndFilterArrays,
  getGeneralNextPage,
} from "@/utils/scroll-context"
import axios from "axios"
import { useRouter } from "next/router"
import {
  AdExamplesHomeSingle,
  AdExamplesType,
  ApplyFiltersAdExamples,
  ApplyFiltersGallery,
  ApplyFiltersTemplates,
  AspectAdExamples,
  AvailableFilters,
  AvailableFiltersTemplates,
  FilterStateAdExamples,
  FilterStateGallery,
  FilterStateTemplates,
  GalleryTools,
  MediaType,
  PriceTypeTemplates,
  SortBy,
  SortByTemplates,
  TemplatesWithSections,
} from "pages/ai-gallery"
import { AdExamplesSearchedResponse } from "pages/api/ad-examples-searched"
import {
  GalleryRequest,
  GalleryResponse,
} from "pages/api/gallery-all"
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react"
import {
  QueryFunction,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "react-query"
import {
  GALLERY_ITEMS_IN_PAGE,
  Pages,
} from "sections/gallery/all-gallery"

type FilterStateActionGallery =
  | {
      type: "set_sort_by"
      sortBy: SortBy
    }
  | {
      type: "set_media_type"
      mediaType: MediaType
    }
  | {
      type: "set_search_query"
      searchQuery: string | null
    }
  | {
      type: "select_style"
      name: string
      tools: GalleryTools[]
    }
  | {
      type: "remove_style"
      name: string
    }
  | {
      type: "clear_style"
    }
  | {
      type: "clear_tools"
    }
  | {
      type: "set_include_custom"
      state: boolean
    }
  | {
      type: "select_tool"
      tool: GalleryTools
    }
  | {
      type: "remove_tool"
      tool: GalleryTools
    }
  | {
      type: "apply_filters"
      filters: ApplyFiltersGallery
    }
  | {
      type: "clear_all"
    }

type FilterStateActionTemplates =
  | {
      type: "set_sort_by"
      sortBy: SortByTemplates
    }
  | {
      type: "set_search_query"
      searchQuery: string | null
    }
  | {
      type: "select_category"
      id: number
    }
  | {
      type: "select_price"
      price: PriceTypeTemplates
    }
  | {
      type: "apply_filters"
    }
  | {
      type: "clear_all"
    }

type FilterStateActionAdExamples =
  | {
      type: "set_search_query"
      searchQuery: string | null
    }
  | {
      type: "set_aspect"
      aspect: AspectAdExamples
    }
  | {
      type: "apply_filters"
    }
  | {
      type: "clear_all"
    }

type GalleryViewState = {
  dispatch: Dispatch<FilterStateActionGallery>
  state: FilterStateGallery
  query: UseInfiniteQueryResult<SingleGalleryPage, unknown>
  availableFilters: AvailableFilters
}

type TemplatesViewState = {
  dispatch: Dispatch<FilterStateActionTemplates>
  state: FilterStateTemplates
  query: UseInfiniteQueryResult<SinglePage, unknown>
  availableFilters: AvailableFiltersTemplates
}

type AdExamplesViewState = {
  dispatch: Dispatch<FilterStateActionAdExamples>
  state: FilterStateAdExamples
  query: UseInfiniteQueryResult<
    SinglePageAdExamples,
    unknown
  >
}

const GalleryViewContext =
  createContext<GalleryViewState | null>(null)

export function useGalleryViewContext(): GalleryViewState {
  const result = useContext(GalleryViewContext)
  if (!result) {
    throw new Error("You forgot the context")
  }

  return result
}

const TemplatesViewContext =
  createContext<TemplatesViewState | null>(null)

export function useTemplatesViewContext(): TemplatesViewState {
  const result = useContext(TemplatesViewContext)
  if (!result) {
    throw new Error("You forgot the context")
  }

  return result
}

const AdExamplesViewContext =
  createContext<AdExamplesViewState | null>(null)

export function useAdExamplesViewContext(): AdExamplesViewState {
  const result = useContext(AdExamplesViewContext)
  if (!result) {
    throw new Error("You forgot the context")
  }

  return result
}

export interface GalleryViewProviderProps {
  children: ReactNode
  initialFilters: FilterStateGallery
  availableFilters: AvailableFilters
  initialData?: GalleryResponse
  page: Pages
  pageNumber: number
}

export interface TemplatesViewProviderProps {
  children: ReactNode
  initialFilters: FilterStateTemplates
  availableFilters: AvailableFiltersTemplates
  initialData?: TemplatesWithSections[]
  page: Pages
}

export interface AdExamplesViewProviderProps {
  children: ReactNode
  initialFilters: FilterStateAdExamples
  initialData?: AdExamplesType
  page: Pages
  pageNumber: number
}

export function deepCompare<T extends object>(
  a: T,
  b: T,
): boolean {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}

export const TEMPLATES_ITEMS_IN_PAGE = 20
export function GalleryViewProvider(
  props: GalleryViewProviderProps,
) {
  const {
    initialFilters,
    initialData,
    availableFilters,
    children,
    page,
    pageNumber,
  } = props

  const [state, dispatch] = useReducer(
    createReducerWithInitialFiltersGallery(
      initialFilters,
      availableFilters,
    ),
    initialFilters,
  )

  const query = useInfiniteQuery({
    queryKey: ["gallery", state.applyFilters],
    queryFn: getGallery(state),
    getNextPageParam: (last) => last.next,
    staleTime: Infinity,
    cacheTime: 0,
    initialData: () => {
      if (!initialData) {
        return undefined
      }

      if (!deepCompare(initialFilters, state)) {
        return undefined
      }

      return {
        pages: [
          {
            data: initialData,
            next: pageNumber * GALLERY_ITEMS_IN_PAGE,
          },
        ],
        pageParams: [0],
      }
    },
    enabled: page === "ai-gallery",
  })

  return (
    <GalleryViewContext.Provider
      value={{
        state,
        dispatch,
        query,
        availableFilters,
      }}>
      {children}
    </GalleryViewContext.Provider>
  )
}

export function TemplatesViewProvider(
  props: TemplatesViewProviderProps,
) {
  const {
    initialFilters,
    initialData,
    availableFilters,
    children,
    page,
  } = props

  const [state, dispatch] = useReducer(
    createReducerWithInitialFiltersTemplates(
      initialFilters,
      availableFilters,
    ),
    initialFilters,
  )

  const router = useRouter()
  const query = useInfiniteQuery({
    queryKey: [
      "templates",
      state.applyFilters,
      router.asPath,
    ],
    queryFn: getTemplatesWithCategories(state),
    getNextPageParam: (last) => last.next,
    initialData: () => {
      if (!initialData) {
        return undefined
      }

      if (!deepCompare(initialFilters, state)) {
        return undefined
      }

      let next = TEMPLATES_ITEMS_IN_PAGE

      if (initialData[0].section !== "searched") {
        next = 3
      }

      return {
        pages: [
          {
            data: initialData,
            next,
          },
        ],
        pageParams: [0],
      }
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: page === "video-templates",
  })

  return (
    <TemplatesViewContext.Provider
      value={{
        state,
        dispatch,
        query,
        availableFilters,
      }}>
      {children}
    </TemplatesViewContext.Provider>
  )
}

export function AdExamplesViewProvider(
  props: AdExamplesViewProviderProps,
) {
  const {
    initialFilters,
    initialData,
    children,
    page,
    pageNumber,
  } = props

  const [state, dispatch] = useReducer(
    createReducerWithInitialFiltersAdExamples(
      initialFilters,
    ),
    initialFilters,
  )

  const query = useInfiniteQuery({
    queryKey: ["ad-examples", state.applyFilters],
    queryFn: getAdExamples(state),
    getNextPageParam: (last) => last.next,
    initialData: () => {
      if (!initialData) {
        return undefined
      }

      if (!deepCompare(initialFilters, state)) {
        return undefined
      }

      let next = pageNumber * TEMPLATES_ITEMS_IN_PAGE

      if (initialData.type !== "searched") {
        next = 3 * pageNumber
      }

      return {
        pages: [
          {
            data: initialData,
            next,
          },
        ],
        pageParams: [0],
      }
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: page === "ad-examples",
  })

  return (
    <AdExamplesViewContext.Provider
      value={{
        state,
        dispatch,
        query,
      }}>
      {children}
    </AdExamplesViewContext.Provider>
  )
}

function createReducerWithInitialFiltersGallery(
  initialFilters: FilterStateGallery,
  availableFilters: AvailableFilters,
) {
  return function galleryViewReducer(
    state: FilterStateGallery,
    action: FilterStateActionGallery,
  ): FilterStateGallery {
    switch (action.type) {
      case "set_media_type": {
        const { mediaType } = action
        return {
          ...state,
          mediaType,
        }
      }

      case "set_search_query": {
        const { searchQuery } = action
        return {
          ...state,
          searchQuery,
        }
      }

      case "set_sort_by": {
        const { sortBy } = action
        return {
          ...state,
          sortBy,
        }
      }

      case "set_include_custom": {
        return {
          ...state,
          includeCustoms: action.state,
        }
      }

      case "select_style": {
        const styles = availableFilters.styles.filter(
          (style) =>
            style.name === action.name &&
            action.tools.includes(style.tool),
        )

        const ids = styles.map((style) => style.id)

        return {
          ...state,
          selectedStyles: [
            ...state.selectedStyles,
            { name: action.name, ids },
          ],
        }
      }

      case "select_tool": {
        return {
          ...state,
          selectedTools: [
            ...state.selectedTools,
            action.tool,
          ],
        }
      }

      case "remove_tool": {
        const selectedTools = state.selectedTools.filter(
          (selected) => selected !== action.tool,
        )

        return {
          ...state,
          selectedTools,
        }
      }

      case "clear_tools": {
        return {
          ...state,
          selectedTools: [],
        }
      }

      case "clear_style": {
        return {
          ...state,
          selectedStyles: [],
        }
      }

      case "remove_style": {
        const selectedStyles = state.selectedStyles.filter(
          (style) => style.name !== action.name,
        )

        return {
          ...state,
          selectedStyles,
        }
      }
      case "apply_filters": {
        const { applyFilters: _, ...currentState } = state
        return {
          ...state,
          applyFilters: currentState,
        }
      }
      case "clear_all": {
        return {
          ...initialFilters,
        }
      }
    }
  }
}

function createReducerWithInitialFiltersTemplates(
  initialFilters: FilterStateTemplates,
  availableFilters: AvailableFiltersTemplates,
) {
  return function galleryViewReducer(
    state: FilterStateTemplates,
    action: FilterStateActionTemplates,
  ): FilterStateTemplates {
    switch (action.type) {
      case "select_category": {
        return {
          ...state,
          selectedCategory: action.id,
        }
      }
      case "select_price": {
        return {
          ...state,
          price: action.price,
        }
      }
      case "set_search_query": {
        return {
          ...state,
          searchQuery: action.searchQuery,
        }
      }
      case "set_sort_by": {
        return {
          ...state,
          sortBy: action.sortBy,
        }
      }
      case "apply_filters": {
        const { applyFilters: _, ...currentState } = state
        return {
          ...state,
          applyFilters: currentState,
        }
      }
      case "clear_all": {
        return {
          ...initialFilters,
          searchQuery: state.searchQuery,
        }
      }
    }
  }
}

function createReducerWithInitialFiltersAdExamples(
  initialFilters: FilterStateAdExamples,
) {
  return function adExamplesViewReducer(
    state: FilterStateAdExamples,
    action: FilterStateActionAdExamples,
  ): FilterStateAdExamples {
    switch (action.type) {
      case "set_search_query": {
        return {
          ...state,
          searchQuery: action.searchQuery,
        }
      }
      case "set_aspect": {
        return {
          ...state,
          aspect: action.aspect,
        }
      }
      case "apply_filters": {
        const { applyFilters: _, ...currentState } = state
        return {
          ...state,
          applyFilters: currentState,
        }
      }
      case "clear_all": {
        return {
          ...initialFilters,
          searchQuery: state.searchQuery,
        }
      }
    }
  }
}

export const mediaTypeMap = {
  image: "image",
  video: "video",
  all: null,
} as const

export interface SingleGalleryPage {
  data: GalleryResponse
  next: number | undefined
}

export const getGallery =
  (
    filters: ApplyFiltersGallery,
  ): QueryFunction<SingleGalleryPage> =>
  async ({ pageParam }) => {
    const payload = filterStateToPayload(
      filters,
      pageParam ?? 0,
    )

    const response = await axios
      .post<GalleryResponse>("/api/gallery-all", payload)
      .then((response) => response.data)

    return {
      data: response,
      next: getGeneralNextPage(
        response.gallery.length,
        pageParam,
      ),
    }
  }

export interface SinglePage {
  data: TemplatesWithSections[]
  next: number | undefined
}

export const getTemplatesWithCategories =
  (
    state: ApplyFiltersTemplates,
  ): QueryFunction<SinglePage> =>
  async ({ pageParam }) => {
    const offset = pageParam ?? 0

    let result: TemplatesWithSections[] = []

    if (
      state.searchQuery === null &&
      state.selectedCategory === -2 &&
      state.price === "all"
    ) {
      if (offset === 0) {
        result = await axios
          .get<TemplatesWithSections[]>(
            "/api/tutorial-discover",
          )
          .then((res) => {
            return res.data
          })
      }

      const response = await axios
        .post<TemplatesWithSections[]>(
          "/api/tutorial-discover-categories",
          { offset },
        )
        .then((res) => {
          return res.data
        })

      return {
        data: result.concat(response),
        next: getGeneralNextPage(
          response.length,
          pageParam,
        ),
      }
    } else if (state.selectedCategory === -1) {
      const response = await axios
        .post<TemplatesWithSections>(
          "/api/tutorial-featured",
          {
            offset,
            limit: TEMPLATES_ITEMS_IN_PAGE,
            segment: "featured",
          },
        )
        .then((res) => {
          return res.data
        })

      return {
        data: result.concat(response),
        next: getGeneralNextPage(
          response.data.length,
          pageParam,
        ),
      }
    } else {
      let price_from = -2
      let price_to = -2

      if (state.price === "prime") {
        price_from = -1
        price_to = -1
      } else if (state.price === "coin") {
        price_from = 1
        price_to = 2000
      }

      const payload = {
        offset,
        limit: TEMPLATES_ITEMS_IN_PAGE,
        categoryId: state.selectedCategory,
        price_from,
        price_to,
        sort: state.sortBy,
        text: state.searchQuery,
      }

      const response = await axios
        .post<TemplatesWithSections>(
          "/api/tutorial-search",
          payload,
        )
        .then((res) => {
          return res.data
        })

      return {
        data: result.concat(response),
        next: getGeneralNextPage(
          response.data.length,
          pageParam,
        ),
      }
    }
  }

export interface SinglePageAdExamples {
  data: AdExamplesType
  next: number | undefined
}

export const getAdExamples =
  (
    state: ApplyFiltersAdExamples,
  ): QueryFunction<SinglePageAdExamples> =>
  async ({ pageParam }) => {
    const offset = pageParam ?? 0

    if (
      state.searchQuery === null &&
      state.aspect === null
    ) {
      const response = await axios
        .post<AdExamplesHomeSingle[]>(
          "/api/ad-examples-home",
          { offset, limit: 3 },
        )
        .then((res) => {
          return res.data
        })

      return {
        data: { type: "home", content: response },
        next: getGeneralNextPage(
          response.length,
          pageParam,
        ),
      }
    } else {
      const response = await axios
        .post<AdExamplesSearchedResponse>(
          "/api/ad-examples-searched",
          {
            offset,
            limit: TEMPLATES_ITEMS_IN_PAGE,
            search_query: state.searchQuery ?? "",
            aspect: state.aspect,
          },
        )
        .then((res) => {
          return res.data
        })

      return {
        data: {
          type: "searched",
          content: response.results,
          total: response.total,
        },
        next: getGeneralNextPage(
          response.results.length,
          pageParam,
        ),
      }
    }
  }

export function filterStateToPayload(
  filters: ApplyFiltersGallery,
  offset: number,
): GalleryRequest {
  const payload: GalleryRequest = {
    offset,
    limit: GALLERY_ITEMS_IN_PAGE,
    owned_by: filters.ownedBy,
    search_query: filters.searchQuery,
    categories: filters.selectedCategories.map(
      (category) => category.id,
    ),
    media_type: mediaTypeMap[filters.mediaType],
    styles: filters.selectedStyles
      .map((style) => style.ids)
      .flat(),
    include_custom: filters.includeCustoms,
    sort: filters.sortBy,
    tools:
      filters.selectedTools.length !== 0
        ? filters.selectedTools
        : [
            "ai_photo",
            "ai_restyle",
            "deform",
            "text_to_image",
            "text_to_video",
            "ai_look",
            "ai_suggest",
          ],
  }

  return payload
}

export interface PaginatedGallery {
  data: GalleryResponse
  next?: number
}

export const flattenAndFilterGallery = (
  pages: PaginatedGallery[],
) =>
  flattenAndFilterArrays(
    pages,
    (page) => page.data.gallery,
    (data) => data.id,
  )

export function arraysAreEqual<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}
