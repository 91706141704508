import { config, useSpring } from "react-spring"

type BoopProps = {
	scale: number
	trigger: boolean
	close: () => void
}

const useBoop = (props: BoopProps) => {
	const { scale, trigger, close } = props

	return useSpring({
		display: "inline-block",
		transform: trigger ? `scale(${scale})` : "scale(1)",
		opacity: trigger ? 1 : 0,
		onRest: close,
		config: config.gentle,
	})
}

export { useBoop }
