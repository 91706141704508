import { assetUrl } from "@/utils/cdn"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { withNotify } from "@/utils/trigger"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { DeforumImageUrlResponse } from "pages/api/deforum-image-url"
import {
  AllParams,
  PromptOption,
  PromptParams,
} from "pages/api/deforum-params"
import {
  AISubmitRequest,
  AISubmitResponse,
} from "pages/api/deforum-submit"
import { TrainingListShortResponse } from "pages/api/training-list-short"
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useDropzone } from "react-dropzone"
import { useQuery, useQueryClient } from "react-query"
import {
  ChannelsParams,
  DynamicEditorProps,
  isImageOrVideo,
  useEditorParamsContext,
} from "sections/editor/editor"
import { z } from "zod"
import {
  createPromptStateFrom,
  ParamtersTrigger,
} from "../ai-config"
import {
  ButtonArrowIcon,
  CheckboxIcon,
  ClearIcon,
  CloseIcon,
  ImageIcon,
  InfoIcon,
  TrashIcon,
} from "../ai-tools/icons"
import { DropDown } from "../dropdown"
import type { PositionType } from "../fabric-canvas"
import { CustomThemedResource } from "../image"
import { ErrorMessage, WarningMessage } from "../message"
import {
  DropAreaSmall,
  getFormatsFromMimeTypes,
  mimeTypes,
} from "../simple-drop-area"
import { SubscriptionPopup } from "../subscription-popup"
import { ParamsContext } from "./deform"
import { ModelComposition } from "./model-composition"

interface MediaDimensions {
  width: number
  height: number
}

export function calculateDimensions(
  resolutionWidth: number,
  aspectRatio: number,
): MediaDimensions {
  const height = resolutionWidth / aspectRatio
  return {
    width: resolutionWidth,
    height: Math.ceil(height),
  }
}

function getModelsInfo(uid: string) {
  return async function () {
    return await axios
      .get<TrainingListShortResponse>(
        `/api/training-list-short?id=${uid}`,
      )
      .then((res) => res.data)
  }
}

const remoteStorageSchema = z.object({
  location: z.literal("remote"),
  url: z.string().nullable(),
})

const localStorageSchema = z.object({
  location: z.literal("local"),
  blob: z.unknown(),
})

export const DEFORUM_STORAGE_KEY = "last"
export const textToImageStorageSchema = z
  .object({
    tool: z
      .literal("text_to_image")
      .default("text_to_image"),
    id: z.literal(DEFORUM_STORAGE_KEY),
    promptType: z.string().nullable(),
    text: z.string(),
  })
  .and(z.union([remoteStorageSchema, localStorageSchema]))

type TextToImageStorageType = z.infer<
  typeof textToImageStorageSchema
>
export const MIN_RESOLUTION = 450

export default function TextToImageEditor(props: {
  content: DynamicEditorProps
  params: {
    styles: PromptOption[]
    params: AllParams
  } | null
  channels: ChannelsParams | null
  blob: Blob | null
}) {
  if (props.content.tool !== "text_to_image") {
    throw new Error("You are not in text to image editor.")
  }

  const { content, params: parameters, blob } = props

  if (parameters === null) {
    throw new Error("Parameters for filters are null.")
  }

  const editor = useEditorParamsContext()
  const { styles, params } = parameters

  const { t } = useTranslation()

  const [file, setFile] = useState<Blob | null>(blob)
  const [promptType, setPromptType] = useState<string>(
    content.promptType ?? styles[0].id,
  )
  const { notify } = useContext(NotificationContext)
  const [textLength, setTextLength] = useState(
    content.text.length,
  )
  const [aspectRatio, setAspectRation] = useState(0.75)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("")
  const [offset, setOffset] = useState(0)
  const { userInfo } = useAuth()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )
  const [selectedModel, setSelectedModel] = useState<{
    name: string
    id: number
  } | null>(content.isModelUse ?? null)
  const [selectedComposition, setSelectedComposition] =
    useState<string | null>(null)
  const [imagePosition, setImagePosition] =
    useState<PositionType | null>(null)
  const [rotation, setRotation] = useState(0)
  const [centerX, setCenterX] = useState(0)
  const [centerY, setCenterY] = useState(0)
  const [firstRender, setFirstRender] = useState(true)
  const [isWriteModel, setIsWriteModel] =
    useState<boolean>(false)

  const inputRef = useRef<HTMLDivElement>(null)
  const queryModel = useQuery({
    queryKey: ["models-info"],
    queryFn: getModelsInfo(userInfo.userId),
  })

  const { result } = queryModel.data || {}

  const queryClient = useQueryClient()

  const router = useRouter()

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const [actionLoading, setActionLoading] = useState(false)

  const indexed = useIndexedDB(
    "ai_art",
    "text_to_image",
    textToImageStorageSchema,
  )

  async function addToStorage() {
    if (indexed.status !== "ready") {
      console.error("Indexed is not ready")
      return
    }

    if (!inputRef.current) {
      console.error("No container attached")
      return
    }

    const payload: TextToImageStorageType = {
      tool: "text_to_image",
      id: DEFORUM_STORAGE_KEY,
      promptType,
      text: inputRef.current.textContent ?? "",
      location: "local",
      blob: file,
    }

    await indexed.setData(payload)
  }

  const openExplorer = useCallback(() => {
    const fileInput = document.createElement("input")

    fileInput.type = "file"
    fileInput.accept = getAcceptedMimes()

    fileInput.onchange = async (_) => {
      if (fileInput.files) {
        const [file] = fileInput.files

        const isOk = await isImageOrVideo(file)

        if (!isOk) {
          notify(
            <ErrorMessage>
              {t("txt_wrong_file_uploaded")}
            </ErrorMessage>,
          )
        } else {
          setFile(file)
          setFileName(file.name)
        }
      }
    }
    fileInput.click()
  }, [notify, t, setFile])

  async function onDrop(files: File[]) {
    const [file] = files

    if (!file) {
      return
    }

    const isOk = await isImageOrVideo(file)

    if (!isOk) {
      notify(
        <ErrorMessage>
          {t("txt_wrong_file_uploaded")}
        </ErrorMessage>,
      )
    } else {
      setFile(file)
      setFileName(file.name)
    }
  }

  const dropzoneState = useDropzone({
    accept: getFormatsFromMimeTypes([
      ...mimeTypes["images"],
    ]),
    onDrop,
    maxFiles: 1,
    maxSize: 10_000_000,
    noClick: true,
  })

  const initialConfig = createPromptStateFrom(params.params)

  const sectionsConfig = params.sections
    ? params.sections.map((section) =>
        createPromptStateFrom(section.components),
      )
    : null

  const config = sectionsConfig
    ? sectionsConfig.reduce((acc, section) => {
        return section ? { ...acc, ...section } : acc
      }, initialConfig || {})
    : initialConfig || {}

  const [promptState, setPromptState] = useState(config)

  async function submitData() {
    if (actionLoading) {
      return
    }

    try {
      setActionLoading(true)

      let url = null

      if (file) {
        const { upload, download, contentType } =
          await axios
            .post<DeforumImageUrlResponse>(
              "/api/deforum-image-url",
              { content: file.type },
            )
            .then((res) => res.data)

        await axios.put(upload, file, {
          headers: {
            "Content-Type": contentType,
            "web-version": HEADER_WEB_VERSION,
          },
        })
        url = download
      }

      let res = null
      if (promptState && promptState["resolution"])
        res = Number(promptState["resolution"])

      const resolution = res ?? MIN_RESOLUTION
      const calculatedDimensions = calculateDimensions(
        resolution,
        aspectRatio,
      )

      const submitData: AISubmitRequest = {
        prompt: inputRef.current?.textContent ?? "",
        style: promptType,
        tool: "text_to_image",
        params: promptState ?? { resolution: 720 },
        face_object_url: url,
        compositions:
          selectedModel &&
          imagePosition &&
          selectedComposition
            ? [
                {
                  url: selectedComposition,
                  rotation: rotation,
                  bg_aspect: aspectRatio,
                  c_x: centerX,
                  c_y: centerY,
                  width_ratio: imagePosition?.widthRatio,
                },
              ]
            : undefined,
        prompt_model_tags: selectedModel
          ? [
              {
                id: selectedModel.id,
                tag: selectedModel.name,
              },
            ]
          : undefined,
        ...calculatedDimensions,
      }

      await axios
        .post<AISubmitResponse>(
          "/api/deforum-submit",
          submitData,
        )
        .then((res) => res.data)

      setActionLoading(false)
      window.removeEventListener(
        "beforeunload",
        handleLeave,
      )

      queryClient.invalidateQueries("coins")

      await router.push("/profile/generations/all")
      editor.closePage()
    } catch (error) {
      const notifier = withNotify((t) =>
        notify(<ErrorMessage>{t}</ErrorMessage>),
      )

      notifier(error)

      console.error(error)

      setSubscriptionPopupOpen(false)
      setActionLoading(false)
    }
  }

  async function getMagic() {
    setLoading(true)
    try {
      const magicText = await axios
        .post<string>("/api/deforum-magic-prompt", {
          tool: "text_to_image",
          style_id: promptType,
          model_ids: selectedModel?.id,
        })
        .then((res) => res.data)
      setLoading(false)

      handleDivInput(selectedModel?.name, magicText)
      const additionalLenght = magicText.length ?? 0
      const foundRange = setSelectionIndex(additionalLenght)
      if (foundRange) {
        const range = document.createRange()
        range.setStart(foundRange.node, foundRange.index)
        range.setEnd(foundRange.node, foundRange.index)

        const selection = window.getSelection()!
        selection.removeAllRanges()
        selection.addRange(range)
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      notify(
        <ErrorMessage>
          {t("txt_could_not_get_magic")}
        </ErrorMessage>,
      )
    }
  }

  const scrollToImage = useCallback(function (
    index: number,
  ) {
    const nextImage = document
      .getElementsByClassName(`style-${index}`)
      .item(0)
    if (!nextImage) {
      return
    }
    const parent = nextImage.parentElement
    if (!parent) {
      return
    }

    const childX = nextImage.getBoundingClientRect().x
    const parentX = parent.getBoundingClientRect().x
    const childOffset = childX - parentX
    parent.scrollBy({
      behavior: "smooth",
      left: childOffset,
    })
  },
  [])

  useEffect(() => {
    scrollToImage(0)
  }, [scrollToImage])

  function getSelectionIndex() {
    const area = inputRef.current

    if (!area) {
      throw new Error("You forgot the ref")
    }
    const selection = window.getSelection()
    if (!selection) {
      throw new Error("Call this function inside onInput")
    }

    let index = 0
    for (let node of area.childNodes) {
      if (node.nodeType !== Node.TEXT_NODE) {
        node = node.childNodes[0]
      }
      if (node === selection.focusNode) {
        return index + selection.focusOffset
      }
      if (node) {
        index += node.textContent?.length ?? 0
      }
    }

    return 0
  }

  function setSelectionIndex(index: number) {
    const area = inputRef.current

    if (!area) {
      return
    }
    let scanned = 0
    for (let node of area.childNodes) {
      scanned += node.textContent!.length
      if (scanned >= index) {
        const beforeNode =
          scanned - node.textContent!.length
        const delta = index - beforeNode
        if (node.nodeType !== Node.TEXT_NODE) {
          node = node.childNodes[0]
        }
        return {
          node,
          index: delta,
        }
      }
    }

    return null
  }

  const handleDivInput = useCallback(
    (modelName?: string, content?: string) => {
      const selected = modelName ?? selectedModel?.name
      const area = inputRef.current

      if (!area) {
        return
      }
      if (content !== undefined) {
        area.textContent = content
      }
      setTextLength(area.textContent?.length ?? 0)

      if (textLength === 0) {
        setSelectedModel(null)
      }

      if (!selected) {
        if (content !== undefined) {
          area.textContent = content
        }
        return
      }

      const foundIndex = getSelectionIndex()

      if (content) {
        area.textContent = content
      }
      area.innerHTML = area.textContent!.replace(
        "@" + selected,
        `<span style="color: #2F91FD;">@${selected}</span>`,
      )

      const foundRange = setSelectionIndex(foundIndex)
      if (foundRange) {
        const range = document.createRange()
        range.setStart(foundRange.node, foundRange.index)
        range.setEnd(foundRange.node, foundRange.index)

        const selection = window.getSelection()!
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
    [selectedModel, textLength],
  )

  const canSubmit = promptType !== null

  useEffect(() => {
    const area = inputRef.current
    if (!area) {
      return
    }
    if (firstRender) {
      if (selectedModel) {
        handleDivInput(selectedModel.name, content.text)
        const additionalLenght =
          selectedModel.name.length ?? 0
        const foundRange = setSelectionIndex(
          additionalLenght + 1,
        )
        if (foundRange) {
          const range = document.createRange()
          range.setStart(foundRange.node, foundRange.index)
          range.setEnd(foundRange.node, foundRange.index)

          const selection = window.getSelection()!
          selection.removeAllRanges()
          selection.addRange(range)
        }
      } else {
        handleDivInput(undefined, content.text)
      }
      setFirstRender(false)
    }
  }, [
    firstRender,
    selectedModel,
    handleDivInput,
    content.text,
  ])

  return (
    <ParamsContext.Provider value={params}>
      <div className="no-scrollbar flex w-full justify-center overflow-y-scroll">
        <div className="relative mx-auto my-[32px] flex w-[1175] gap-[25px]">
          <button
            onClick={async () => {
              window.removeEventListener(
                "beforeunload",
                handleLeave,
              )

              if (
                router.asPath === "/editor/text-to-image"
              ) {
                router.back()
              }
              editor.closePage()
            }}
            className="group absolute -left-[200px] hidden flex-row items-center gap-3 desktop:top-0 desktop:flex">
            <div
              className={clsx(
                "flex h-9 w-9 items-center justify-center rounded-full bg-color-cell inner-border",
                "inner-border-[transparent] group-hover:inner-border-color-separator",
              )}>
              <CustomThemedResource
                format="svg"
                source="/general/arrow"
                className="rotate-180"
              />
            </div>
            <p className=" text-lg font-500 text-blue-700 group-hover:text-blue-900">
              {t("lbl_back")}
            </p>
          </button>

          <div className="flex w-[575px] flex-col gap-3">
            <div
              className={clsx(
                "relative h-[145px] w-full shrink-0 rounded-[14px] border border-color-separator bg-color-cell transition-all",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              <div
                contentEditable
                ref={inputRef}
                data-placeholder={t(
                  "txt_describe_imagination",
                )}
                className={clsx(
                  "text-body-5 no-scrollbar h-[98px] w-full resize-none rounded-[14px] bg-color-cell py-2 pl-3 pr-8",
                  "overflow-hidden overflow-y-scroll placeholder:text-color-placeholder focus-visible:outline-none",
                  loading
                    ? "text-color-placeholder"
                    : "text-blue-800",
                )}
                onInput={(e) => {
                  handleDivInput()

                  const text =
                    e.currentTarget.textContent?.split(" ")
                  const includesModelName = text?.includes(
                    `@${selectedModel?.name}`,
                  )

                  if (
                    text?.includes("@") &&
                    !includesModelName
                  ) {
                    setIsWriteModel(true)
                  } else {
                    setIsWriteModel(false)
                  }
                }}
              />
              <div className="absolute bottom-[6px] left-[12px] z-10 flex items-start gap-0">
                <button
                  disabled={loading}
                  onClick={() => getMagic()}
                  className={clsx(
                    "flex items-center gap-1 disabled:pointer-events-none",
                    "px-[13px] py-[3px] hover:rounded-[40px] hover:bg-primary-100",
                  )}>
                  {loading ? (
                    <img
                      alt="loading"
                      src={assetUrl(
                        "/ai-tools/text-to-image/loading.webp",
                      )}
                      className={clsx(
                        "pointer-events-none h-3 w-3 animate-spin [animation-timing-function:linear]",
                      )}
                    />
                  ) : (
                    <img
                      src={assetUrl(
                        "/ai-tools/text-to-image/magic.svg",
                      )}
                      alt="magic"
                    />
                  )}
                  <p className="text-body-5 font-500 text-primary-500">
                    {t("lbl_surprise_me")}
                  </p>
                </button>
                <DropDown
                  hover
                  isOpen={isWriteModel}
                  className={clsx(
                    selectedModel &&
                      !isWriteModel &&
                      textLength !== 0 &&
                      "pointer-events-none opacity-50",
                    (result?.length === 0 ||
                      result === undefined) &&
                      "pointer-events-none opacity-50",
                    "relative",
                  )}
                  trigger={
                    <div
                      className={clsx(
                        "-mb-[30px] pb-[30px]",
                      )}>
                      <div className="flex gap-[4px] px-[13px] py-[3px] hover:rounded-[40px] hover:bg-[#2F91FD]/30">
                        <img
                          src={assetUrl(
                            "/general/model-blue-icon.svg",
                          )}
                          alt="model icon"
                        />
                        <span className="text-body-5 font-500 text-primary-500">
                          {t("lbl_model")}
                        </span>
                      </div>
                    </div>
                  }>
                  <div
                    className={clsx(
                      "no-scrollbar absolute -left-[144px] top-[45px] max-h-[236px] w-[343px]",
                      "overflow-y-scroll rounded-[10px] bg-color-cell px-[8px]",
                      "py-[5px] shadow-xl",
                    )}>
                    {result?.map((model) => {
                      return (
                        <button
                          key={model.id}
                          className="flex w-full gap-[10px] rounded-[7px] p-[10px] hover:bg-blue-100"
                          onClick={() => {
                            const area = inputRef.current
                            if (
                              area &&
                              selectedModel &&
                              area.textContent?.includes(
                                "@" + selectedModel.name,
                              )
                            ) {
                              area.innerHTML =
                                area.textContent!.replace(
                                  "@" + selectedModel.name,
                                  "@" + model.name,
                                )
                              handleDivInput(model.name)
                            } else if (area) {
                              const foundIndex =
                                getSelectionIndex()
                              const content =
                                area.textContent ?? ""
                              const newText = isWriteModel
                                ? content.slice(
                                    0,
                                    foundIndex,
                                  ) +
                                  model.name +
                                  content.slice(foundIndex)
                                : content.slice(
                                    0,
                                    foundIndex,
                                  ) +
                                  "@" +
                                  model.name +
                                  content.slice(foundIndex)
                              area.innerHTML = newText
                              handleDivInput(model.name)
                              setIsWriteModel(false)

                              const additionalLenght =
                                model.name.length ?? 0
                              const foundRange =
                                setSelectionIndex(
                                  additionalLenght + 1,
                                )
                              if (foundRange) {
                                const range =
                                  document.createRange()
                                range.setStart(
                                  foundRange.node,
                                  foundRange.index,
                                )
                                range.setEnd(
                                  foundRange.node,
                                  foundRange.index,
                                )

                                const selection =
                                  window.getSelection()!
                                selection.removeAllRanges()
                                selection.addRange(range)
                              }
                            }
                            setSelectedModel({
                              name: model.name,
                              id: model.id,
                            })
                          }}>
                          <img
                            src={model.thumb_url}
                            alt="model image"
                            className="h-[32px] w-[32px] rounded-[6px] object-cover"
                          />
                          <span className="text-[16px] font-500 text-blue-700">
                            {model.name}
                          </span>
                        </button>
                      )
                    })}
                  </div>
                </DropDown>
              </div>
              <button
                onClick={() => {
                  handleDivInput(undefined, "")
                }}
                className={clsx(
                  "absolute right-3 top-2 flex h-[17px] w-[17px] items-center justify-center rounded-full",
                  "bg-blue-200 transition-colors [--icon-color:var(--color-blue-700)] hover:bg-blue-400",
                )}>
                <ClearIcon />
              </button>
              <div className="absolute bottom-2 right-3 flex flex-row items-center gap-2">
                <p className="text-[14px] font-500 text-color-placeholder">
                  {textLength + "/1000"}
                </p>
              </div>
            </div>

            <div
              className={clsx(
                "flex w-full flex-row items-center justify-between rounded-[14px] bg-color-cell px-5 py-[10px] transition-all",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              {aspects.map((aspect, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setAspectRation(
                      aspect.aspectWidth /
                        aspect.aspectHeight,
                    )
                  }}
                  className="flex cursor-pointer flex-col items-center justify-center gap-2">
                  <div
                    style={{
                      width: aspect.width,
                      height: aspect.height,
                    }}
                    className={clsx(
                      "rounded-[2px] transition-colors",
                      aspect.aspectWidth /
                        aspect.aspectHeight ===
                        aspectRatio
                        ? "bg-primary-500"
                        : "bg-blue-300 hover:bg-blue-400 dark:bg-[#34353D]",
                    )}></div>
                  <p
                    className={clsx(
                      "text-[14px] font-500 transition-colors",
                      aspect.aspectWidth /
                        aspect.aspectHeight ===
                        aspectRatio
                        ? "text-primary-500"
                        : "text-color-placeholder",
                    )}>
                    {aspect.aspectWidth +
                      ":" +
                      aspect.aspectHeight}
                  </p>
                </div>
              ))}
            </div>

            <div
              className={clsx(
                "flex flex-col items-stretch gap-2 rounded-[15px] bg-color-cell px-4 py-[10px] transition-all",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              <div className="flex flex-row items-start justify-between">
                <span className="text-[16px] font-700 text-blue-600">
                  {t("lbl_styles")}
                </span>
                <div
                  className={clsx(
                    "flex flex-row items-start gap-[6px]",
                    styles.length <= 4 &&
                      "pointer-events-none opacity-0",
                  )}>
                  <button
                    disabled={offset === 0}
                    onClick={() =>
                      scrollToImage(offset - 1)
                    }
                    style={{
                      filter:
                        "drop-shadow(0px 2.5px 15px rgba(123, 128, 158, 0.15))",
                    }}
                    className={clsx(
                      "flex h-5 w-5 items-center justify-center rounded-full",
                      "bg-color-cell transition-all inner-border inner-border-color-separator",
                      "[--icon-color:var(--color-blue-700)] hover:inner-border-blue-500",
                      "disabled:hover:inner-border-color-separator",
                    )}>
                    <ButtonArrowIcon />
                  </button>
                  <button
                    disabled={offset === styles.length - 4}
                    onClick={() =>
                      scrollToImage(offset + 1)
                    }
                    style={{
                      filter:
                        "drop-shadow(0px 2.5px 15px rgba(123, 128, 158, 0.15))",
                    }}
                    className={clsx(
                      "flex h-5 w-5 items-center justify-center rounded-full",
                      "bg-color-cell transition-all inner-border inner-border-color-separator",
                      "hover:inner-border-blue-500 disabled:hover:inner-border-color-separator",
                    )}>
                    <div className=" rotate-180 [--icon-color:var(--color-blue-700)]">
                      <ButtonArrowIcon />
                    </div>
                  </button>
                </div>
              </div>
              <div
                onScroll={(event) => {
                  const container = event.target
                  if (container instanceof HTMLElement) {
                    const snapIndex = Math.floor(
                      container.scrollLeft /
                        (container.offsetWidth - 424),
                    )

                    setOffset(snapIndex)
                  }
                }}
                className="no-scrollbar flex snap-x snap-mandatory flex-row items-center gap-2 overflow-x-scroll">
                {styles.map(
                  ({ name, id, image }, index) => (
                    <div
                      key={id}
                      className={clsx(
                        "relative h-[126px] w-[126px] shrink-0 snap-start overflow-hidden rounded-[8px]",
                        promptType === id &&
                          "border-primary-500",
                        "select-none border-2 border-[transparent]",
                        "cursor-pointer transition-colors",
                        `style-${index}`,
                      )}
                      onClick={() => {
                        if (promptType !== id) {
                          const style = styles.find(
                            (style) => style.id === id,
                          )
                          if (
                            style &&
                            style.ignoreFace &&
                            file
                          ) {
                            notify(
                              <WarningMessage>
                                {t(
                                  "txt_not_supported_face",
                                )}
                              </WarningMessage>,
                            )
                          }
                          setPromptType(id)
                        }
                      }}>
                      <img
                        src={image}
                        alt={`cover of ${name} style`}
                        className={clsx(
                          "pointer-events-none aspect-1 h-full object-cover",
                        )}
                      />
                      <div className="absolute bottom-0 left-0 h-8 w-full bg-gradient-to-t from-color-black/60 to-[transparent]"></div>
                      <span className="absolute bottom-1 left-2 text-[13px] font-600 text-color-white">
                        {name}
                      </span>
                    </div>
                  ),
                )}
              </div>
            </div>

            {selectedModel !== null ? (
              <ModelComposition
                aspectRatio={aspectRatio}
                compInfo={
                  result?.find(
                    (elem) =>
                      elem.name === selectedModel.name,
                  )?.comp_info
                }
                selectedComposition={selectedComposition}
                setSelectedComposition={
                  setSelectedComposition
                }
                imagePosition={imagePosition}
                setImagePosition={setImagePosition}
                setRotation={setRotation}
                setCenterX={setCenterX}
                setCenterY={setCenterY}
              />
            ) : (
              <div
                className={clsx(
                  "relative flex w-full flex-col items-start rounded-[14px] bg-color-cell px-[14px] py-[10px]",
                  styles.find(
                    (style) => style.id === promptType,
                  )?.ignoreFace &&
                    "pointer-events-none opacity-30",
                  actionLoading &&
                    "pointer-events-none opacity-30",
                )}>
                <DropDown
                  hover
                  className="!absolute right-3 top-3 h-[18px]"
                  zIndex="z-10"
                  contentClassName="-translate-y-3"
                  trigger={
                    <div className=" z-10 [--icon-color:var(--color-blue-600)]">
                      <InfoIcon />
                    </div>
                  }>
                  <div className="absolute z-50 w-[575px] -translate-x-1/2 pt-3">
                    <div
                      style={{
                        boxShadow:
                          "0px 0px 30px 0px rgba(0, 0, 0, 0.13)",
                      }}
                      className="w-full rounded-[16px] bg-color-cell px-[14px] py-[16px]">
                      <div className="flex w-full flex-col gap-[10px]">
                        <p className="text-[18px] font-500 leading-normal text-blue-700">
                          {t(
                            "txt_face_replace_guide_title",
                          )}
                        </p>
                        <div className="flex w-full flex-row gap-3 rounded-[15px] border border-blue-300 p-2">
                          {goodPhotos.map((photo) => (
                            <div
                              key={photo}
                              className="relative h-[86px] w-[86px] rounded-lg">
                              <img
                                src={assetUrl(photo)}
                                className="pointer-events-none h-full w-full select-none rounded-lg"></img>
                              <div className="absolute left-1 top-1">
                                <CheckboxIcon />
                              </div>
                            </div>
                          ))}
                          <div className="flex flex-1 flex-col justify-start gap-2">
                            <p className="text-[16px] font-600 leading-normal text-blue-800">
                              {t("txt_good_photos")}
                            </p>
                            <p className="text-[14px] font-400 leading-[18px] text-blue-600">
                              {t("txt_make_sure_visible")}
                            </p>
                          </div>
                        </div>
                        <div className="flex w-full flex-row gap-3 rounded-[15px] border border-blue-300 p-2">
                          {badPhotos.map((photo) => (
                            <div
                              key={photo}
                              className="relative h-[86px] w-[86px] rounded-lg">
                              <img
                                src={assetUrl(photo)}
                                className="pointer-events-none h-full w-full select-none rounded-lg"></img>
                              <div className="absolute left-1 top-1">
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                          <div className="flex flex-1 flex-col justify-start gap-2">
                            <p className="text-[16px] font-600 leading-normal text-blue-800">
                              {t("txt_bad_photos")}
                            </p>
                            <p className="text-[14px] font-400 leading-[18px] text-blue-600">
                              {t("txt_group_pics")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropDown>

                <div className="flex flex-row items-center gap-[10px] [--icon-color:var(--color-blue-600)]">
                  <ImageIcon />
                  <div className="flex flex-col items-start">
                    <div className="flex items-start gap-[5px]">
                      <p className=" text-[16px] font-600 leading-normal text-blue-800">
                        {t("common:txt_reference_face")}
                      </p>
                      <p className="bg-primary-500/10 rounded px-1 py-[2px] text-[12px] font-700 text-primary-500">
                        {t("common:lbl_new")}
                      </p>
                    </div>
                    <p className="text-[14px] font-400 leading-normal text-blue-600">
                      {t(
                        "common:txt_change_face_into_photo",
                      )}
                    </p>
                  </div>
                </div>

                {file ? (
                  <div className="mt-4 flex w-full flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-3">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="uploaded file"
                        className="aspect-1 w-[82px] rounded-lg object-cover"
                      />
                      <p className="text-[16px] font-500 text-color-placeholder">
                        {fileName}
                      </p>
                    </div>
                    <button
                      onClick={() => setFile(null)}
                      className="flex h-12 w-12 items-center justify-center rounded-full bg-[#FD2E2E]/10 transition-colors hover:bg-[#FD2E2E]/20">
                      <TrashIcon />
                    </button>
                  </div>
                ) : (
                  <div className="mt-4 w-[547px]">
                    <DropAreaSmall
                      border
                      borderImage="/general/border-dropzone-2"
                      className="h-[93px] w-full bg-[#E7EAF7] dark:bg-[#313649]"
                      state={dropzoneState}
                      formats={["image/png", "image/jpeg"]}
                      openExplorer={openExplorer}
                    />
                  </div>
                )}
              </div>
            )}

            {promptState && (
              <ParamtersTrigger
                actionLoading={actionLoading}
                promptState={promptState}
                setPromptState={setPromptState}
                className="!py-[18px]"
              />
            )}

            {isPro ? (
              <button
                disabled={textLength === 0 || !canSubmit}
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "disabled:pointer-events-none",
                  "bg-primary-500 hover:bg-primary-600",
                  "relative py-3 transition-colors",
                  "flex items-center justify-center gap-4",
                  actionLoading && "pointer-events-none",
                )}
                onClick={() => {
                  if (actionLoading) return
                  submitData()
                }}>
                <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2">
                  <img
                    className={clsx(
                      "h-6 w-6 animate-[spin_1s_infinite_linear]",
                      actionLoading
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                    src={assetUrl(
                      "/general/loading-dark.webp",
                    )}
                    alt="loading"
                  />
                </div>

                {t("lbl_generate")}
                {promptState && params.params && (
                  <CostsCoins
                    coins={getPromptPrice(
                      promptState,
                      params.params,
                      params.price,
                    )}
                  />
                )}
              </button>
            ) : (
              <button
                disabled={textLength === 0}
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "disabled:pointer-events-none",
                  "bg-[#2F91FD] hover:bg-[#0264CF]",
                  "py-3 transition-colors",
                )}
                onClick={() =>
                  setSubscriptionPopupOpen(true)
                }>
                {t("txt_subscribe_to_continue")}
              </button>
            )}
            <div className="h-[70px] w-full shrink-0"></div>
          </div>
        </div>
        <SubscriptionPopup
          isOpen={subscriptionPopupOpen}
          close={() => setSubscriptionPopupOpen(false)}
          location="/editor/text-to-image"
          addToStorage={addToStorage}
        />
      </div>
    </ParamsContext.Provider>
  )
}

export function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

export interface PromptState {
  [id: string]: number | string | boolean
}

function getPromptPrice(
  prompt: PromptState,
  params: PromptParams[],
  non_free_price: number,
) {
  for (const param of params) {
    const promptID = prompt[param.id]
    if (typeof promptID !== "number") {
      continue
    }

    if (param.type !== "slider") {
      continue
    }

    if (promptID > param.maxFreeValue) {
      return non_free_price
    }
  }

  return 0
}

function CostsCoins(props: { coins: number }) {
  const { coins } = props
  const { userInfo } = useAuth()
  const { isCoinFree } = getUserEntitlements(
    userInfo.entitlements,
  )
  if (isCoinFree || !coins) {
    return <></>
  }

  return (
    <div className="flex gap-1 rounded-full bg-[#C6113F] px-1 text-[14px] font-700 text-color-white">
      +{coins}
      <img
        src={assetUrl("/general/coin.svg")}
        alt="coins"
      />
    </div>
  )
}

const actionMimes = {
  deform: ["images", "videos"],
  restyle: ["videos"],
  photo_ai: ["images"],
} as const

function getAcceptedMimes(): string {
  const mimes = [...actionMimes["photo_ai"]]
  const empty: string[] = []
  const allMimes = mimes.reduce(
    (all, single) => [...all, ...mimeTypes[single]],
    empty,
  )

  return allMimes.join(", ")
}

const aspects = [
  {
    width: 23,
    height: 29,
    aspectWidth: 3,
    aspectHeight: 4,
  },
  {
    width: 27,
    height: 27,
    aspectWidth: 1,
    aspectHeight: 1,
  },
  {
    width: 24,
    height: 38,
    aspectWidth: 9,
    aspectHeight: 16,
  },
  {
    width: 37,
    height: 24,
    aspectWidth: 16,
    aspectHeight: 9,
  },
  {
    width: 29,
    height: 22,
    aspectWidth: 4,
    aspectHeight: 3,
  },
  {
    width: 24,
    height: 26,
    aspectWidth: 4,
    aspectHeight: 5,
  },
  {
    width: 26,
    height: 24,
    aspectWidth: 5,
    aspectHeight: 4,
  },
]

const goodPhotos = [
  "/ai-tools/text-to-image/good-photo-1.webp",
  "/ai-tools/text-to-image/good-photo-2.webp",
  "/ai-tools/text-to-image/good-photo-3.webp",
]

const badPhotos = [
  "/ai-tools/text-to-image/bad-photo-1.webp",
  "/ai-tools/text-to-image/bad-photo-2.webp",
  "/ai-tools/text-to-image/bad-photo-3.webp",
]
