import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"

interface SaveMediaPopupProps {
  imageOrVideo: string | string[]
  showMediaDialog: boolean
  setShowMediaDialog: (v: boolean) => void
  offset: number
  showFor?: "gallery" | "history"
  setAsShared?: () => void
  setThanksPopup?: (v: boolean) => void
  setShowDropdown: (v: boolean) => void
  showDropdown: boolean
}

export function SaveMediaPopup(props: SaveMediaPopupProps) {
  const {
    imageOrVideo,
    setShowMediaDialog,
    showMediaDialog,
    offset,
    showFor,
    setAsShared,
    setShowDropdown,
    setThanksPopup,
    showDropdown,
  } = props

  const { t } = useTranslation()
  const logAdjust = useAdjustLogger()

  async function handleSaveAllMedia() {
    setShowMediaDialog(!showMediaDialog)

    if (showFor === "gallery") {
      setAsShared?.()
    }

    if (typeof imageOrVideo === "string") {
      return
    }

    for (const url of imageOrVideo) {
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.rel = "noopener noreferrer"
      anchor.download = url.substring(
        url.lastIndexOf("/") + 1,
      )
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      await new Promise((resolve) =>
        setTimeout(resolve, 500),
      )
    }
    setThanksPopup?.(true)
  }

  function handleSaveMedia() {
    logAdjust?.logEvent("deform_ai_video_save")
    setShowDropdown(!showDropdown)
    setThanksPopup?.(true)
    if (showFor === "gallery") {
      setAsShared?.()
    }
  }

  switch (typeof imageOrVideo) {
    case "object":
      return (
        <div
          onClick={() => {
            setShowMediaDialog(!showMediaDialog)
          }}
          className={clsx(
            "group relative flex items-center justify-center",
            "cursor-pointer transition-colors hover:bg-blue-300",
            "disabled:hover:bg-transparent disabled:opacity-50",
            "h-[52px] w-[52px] shrink-0 rounded-full bg-color-surface",
          )}
          id="media-box">
          <img
            src={assetUrl(
              "/ai-tools/history/save-primary.svg",
            )}
            alt="save icon"
            className="h-[20px] w-[20px]"
            id="media-box"
          />
          <div
            className={clsx(
              "opacity-0 hover:opacity-0 group-hover:opacity-100",
              "pointer-events-none absolute -bottom-[30px]",
              "rounded-[4px] px-3 py-1",
              "bg-color-tooltip transition-all",
              "text-xs font-500 text-blue-100",
            )}>
            {t("txt_save")}
          </div>
          {showMediaDialog && (
            <div
              className={clsx(
                "fixed top-[150px] z-[103] w-[200px] translate-x-[780px] shadow-xl",
                "shadow-color-black/30 tablet:left-0",
                "flex flex-col gap-[4px] rounded-xl bg-color-cell px-[10px] py-[14px]",
              )}
              id="media-box">
              <a
                onClick={handleSaveMedia}
                href={imageOrVideo[offset]}
                download={true}
                className="rounded-lg px-[16px] py-[12px] text-start text-blue-800 hover:bg-blue-100">
                {t("txt_save_media")}
              </a>
              <button
                className="rounded-lg px-[16px] py-[12px] text-start text-blue-800 hover:bg-blue-100"
                onClick={handleSaveAllMedia}>
                {t("txt_save_all_media")}
              </button>
            </div>
          )}
        </div>
      )
    case "string":
      return (
        <a
          onClick={handleSaveMedia}
          href={imageOrVideo}
          download={true}
          className={clsx(
            "relative flex items-center justify-center",
            "group transition-colors hover:bg-blue-300",
            "disabled:hover:bg-transparent disabled:opacity-50",
            "h-[52px] w-[52px] shrink-0 rounded-full bg-color-background",
          )}>
          <img
            src={assetUrl(
              "/ai-tools/history/save-primary.svg",
            )}
            alt="save icon"
            className="h-[20px] w-[20px]"
          />
          <div
            className={clsx(
              "opacity-0 hover:opacity-0 group-hover:opacity-100",
              "pointer-events-none absolute -bottom-[30px]",
              "rounded-[4px] px-3 py-1",
              "bg-color-tooltip transition-all",
              "text-xs font-500 text-blue-100",
            )}>
            {t("txt_save")}
          </div>
        </a>
      )
  }
}
