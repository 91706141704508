import clsx from "clsx"
import { ReactNode } from "react"

interface PrimaryButtonProps {
  className?: string
  href?: string
  onClick?: () => void
  children: ReactNode
  inactive?: boolean
  center?: boolean
}

export default function PrimaryButton(
  props: PrimaryButtonProps,
) {
  const {
    href,
    onClick,
    children,
    inactive,
    center,
    className,
  } = props

  return (
    <a
      href={href}
      className={clsx(
        className,
        inactive
          ? "border-1 border-color-separator text-blue-300"
          : "bg-primary-500 text-color-white",
        center && "self-center",
        "px-24px box-border min-w-[232px] py-3 font-600",
        "rounded-[10px] text-center hover:bg-primary-600",
        "text-color-white transition-colors",
        "cursor-pointer select-none",
      )}
      onClick={(event) => {
        if (!href) {
          event.preventDefault()
          if (onClick) onClick()
        }
      }}>
      {children}
    </a>
  )
}
