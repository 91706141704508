import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import {
  useAuthUpdater,
  useClientAuth,
} from "@/utils/client-auth"
import { useCoins, useCoinsPopup } from "@/utils/coins"
import { redirectToLogin } from "@/utils/login-redirect"
import { scrollToSection } from "@/utils/scrollto"
import axios from "axios"
import clsx from "clsx"
import { PageName } from "controllers/dashboard"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { TrainingShortStatusResponse } from "pages/api/training-short-status"
import { useQuery } from "react-query"
import { DropDown } from "../dropdown"
import { getCurrentPlan } from "../subscription-popup"
import Tooltip from "../tooltip"
import { ThemeSwitch } from "./icon"

interface AvatarWithMenuProps {
  onFocus?: () => void
  setSubscriptionPopupOpen: (v: boolean) => void
  changeSelectedPage?: (name: PageName) => Promise<void>
}

const PROFILE_SHORT_CACHE_HOURS = 5

export function AvatarWithMenu(props: AvatarWithMenuProps) {
  const {
    onFocus,
    setSubscriptionPopupOpen,
    changeSelectedPage,
  } = props

  const { userInfo } = useClientAuth()
  const router = useRouter()
  const logAdjust = useAdjustLogger()
  const userId = userInfo?.userId ?? null

  async function getModelStatus() {
    if (!userId) {
      return undefined
    }

    return await axios
      .get<TrainingShortStatusResponse>(
        `/api/training-short-status?id=${userId}`,
      )
      .then((res) => res.data)
  }

  const queryModel = useQuery({
    queryKey: ["model-status"],
    queryFn: getModelStatus,
    cacheTime: PROFILE_SHORT_CACHE_HOURS * 60 * 60 * 1000,
    staleTime: PROFILE_SHORT_CACHE_HOURS * 60 * 60 * 1000,
    enabled: userInfo !== null && !userInfo.isAnonymous,
  })

  const { t } = useTranslation()
  const { logOut } = useAuthUpdater()

  function showSection() {
    if (userInfo === null || userInfo.isAnonymous) {
      return "sign_in" as const
    }

    return "user" as const
  }

  const show = showSection()

  const wallet = useCoins()
  const openPopup = useCoinsPopup()

  const currentPlan = userInfo
    ? getCurrentPlan(userInfo.entitlements)
    : { id: "free", title: "Free", weight: -1 }

  return (
    <>
      {show === "user" && (
        <DropDown
          hover
          onFocus={onFocus}
          contentClassName={clsx(
            "top-0 left-0 w-screen",
            "translate-y-[48px]",
            "tablet:translate-y-full",
            "tablet:-translate-x-[calc(100%-40px)]",
            "tablet:bottom-4 tablet:w-auto",
            "tablet:drop-shadow-[0px_0px_30px_rgba(0,0,0,0.13)]",
            "fixed tablet:absolute shrink-0",
          )}
          className="flex items-center"
          trigger={
            <div className="flex shrink-0 items-center tablet:h-[90px]">
              <img
                src={
                  userInfo?.details.profilePic ??
                  assetUrl("/general/avatar.svg")
                }
                alt="avatar"
                className={clsx(
                  "h-8 w-8 rounded-full border border-color-separator",
                  "pointer-events-none animate-appear",
                )}
              />
            </div>
          }>
          <div
            className={clsx(
              "bg-color-popup-cell tablet:w-[312px] tablet:rounded-[10px]",
              "w-full border-b-color-separator",
              "border-b-2 tablet:border-none",
              "flex flex-col",
              "gap-1 px-[10px] pb-[10px] text-[14px] font-500",
            )}>
            <a
              href={`/${userInfo?.details.username}`}
              className="flex flex-col items-center gap-3 px-3 py-3 tablet:flex-row">
              <img
                src={
                  userInfo?.details.profilePic ??
                  assetUrl("/general/avatar.svg")
                }
                alt="avatar"
                className={clsx(
                  "h-16 w-16 rounded-full border border-color-separator tablet:h-8 tablet:w-8",
                  "pointer-events-none animate-appear",
                )}
              />
              <div className="flex flex-col items-center tablet:items-start">
                <span className="font-600">
                  {userInfo?.details.fullName}
                </span>
                <p className="truncate text-blue-600">
                  @{userInfo?.details.username}
                </p>
              </div>
            </a>

            {userInfo && (
              <div className="h-px w-full bg-color-separator" />
            )}

            {wallet && (
              <button
                onClick={() =>
                  setSubscriptionPopupOpen(true)
                }
                className="flex h-[43px] w-full flex-row items-center justify-between rounded-[6px] px-2 transition-colors hover:bg-blue-100">
                <div className="flex flex-row items-center gap-2">
                  <p className=" text-[14px] font-500 text-blue-600">
                    {t("lbl_tokens_remaining")}
                  </p>

                  <Tooltip
                    showAlways
                    className=" whitespace-pre-line"
                    title={
                      wallet.renewInfo
                        ? t("fs_tokens_renew", {
                            tokens:
                              wallet.permanentTokens +
                              wallet.temporaryTokens,
                            renew: wallet.renewInfo.tokens,
                          })
                        : wallet.permanentTokens +
                            wallet.temporaryTokens >
                          0
                        ? t("fs_tokens_available", {
                            tokens:
                              wallet.permanentTokens +
                              wallet.temporaryTokens,
                          })
                        : t("txt_dont_have_tokens")
                    }
                    margin={5}
                    width={200}>
                    <div className="mt-1 flex h-[14px] w-[14px] items-center justify-center rounded-full border border-blue-500 text-[10px] font-700 text-blue-500">
                      !
                    </div>
                  </Tooltip>
                </div>

                <div className="flex flex-row items-center gap-[6px]">
                  <img
                    src={assetUrl("/general/token.svg")}
                    alt="coin-icon-new"
                  />

                  <p
                    className={clsx(
                      "text-[18px] font-600 uppercase",
                      "text-blue-700",
                    )}>
                    {(
                      wallet.temporaryTokens +
                      wallet.permanentTokens
                    ).toLocaleString()}
                  </p>
                </div>
              </button>
            )}

            {userInfo && (
              <button
                onClick={() =>
                  setSubscriptionPopupOpen(true)
                }
                className="flex h-[43px] w-full flex-row items-center justify-between rounded-[6px] px-2 transition-colors hover:bg-blue-100">
                <p className=" text-[14px] font-500 text-blue-600">
                  {t("lbl_subscription")}
                </p>

                <p
                  className={clsx(
                    "text-[16px] font-600 uppercase",
                    currentPlan.id === "free"
                      ? "text-color-success"
                      : "text-blue-700",
                  )}>
                  {currentPlan.title}
                </p>
              </button>
            )}

            <div className="h-px w-full bg-color-separator" />

            <div className="flex h-[43px] w-full flex-row items-center justify-between px-2">
              <p className=" text-[14px] font-500 text-blue-600">
                {t("txt_coins")}
              </p>

              <div className="flex flex-row items-center gap-[6px]">
                <img
                  src={assetUrl("/general/coin-new.svg")}
                  alt="coin-icon-new"
                />
                <p className="text-[16px] font-600 text-blue-700">
                  {wallet?.coins.toLocaleString() ?? 0}
                </p>
                <button
                  onClick={() => openPopup()}
                  className="relative h-[24px] w-[24px] rounded-[6px] bg-blue-100 transition-colors hover:bg-blue-200">
                  <div className=" absolute left-1/2 top-1/2 h-[1.5px] w-[7.5px] -translate-x-1/2 -translate-y-1/2 bg-blue-500"></div>
                  <div className=" absolute left-1/2 top-1/2 h-[1.5px] w-[7.5px] -translate-x-1/2 -translate-y-1/2 rotate-90 bg-blue-500"></div>
                </button>
              </div>
            </div>

            <a
              href="/ai-studio"
              onClick={async (e) => {
                if (changeSelectedPage) {
                  e.preventDefault()
                  await changeSelectedPage("aiStudio")
                  scrollToSection("history", 70)()
                }
              }}
              className="flex h-[43px] w-full flex-row items-center justify-between rounded-[6px] px-2 transition-colors hover:bg-blue-100">
              <p className=" text-[14px] font-500 text-blue-600">
                {t("lbl_history")}
              </p>
            </a>

            <a
              href="/models"
              className="flex h-[43px] w-full flex-row items-center justify-between rounded-[6px] px-2 transition-colors hover:bg-blue-100">
              <div className="flex flex-row items-center gap-1">
                <p className="text-[14px] font-500 text-blue-600">
                  {t("lbl_my_models")}
                </p>
                <span className="text-[14px] font-500 text-blue-600">
                  ({queryModel.data?.model_count ?? "-"})
                </span>
              </div>

              {queryModel.data?.is_training === true && (
                <span className="rounded-[12px] bg-[#2F91FD] px-[11px] text-[11px] font-600 text-color-white">
                  {t("txt_training")}
                </span>
              )}
            </a>

            <div className="flex h-[43px] w-full flex-row items-center justify-between px-2">
              <p className="text-[14px] font-500 text-blue-600">
                {t("lbl_theme")}
              </p>
              <ThemeSwitch />
            </div>

            <div className="h-px w-full bg-color-separator" />

            <button
              onClick={async () => {
                logAdjust?.logEvent("w_did_log_out")
                await logOut()
              }}
              className="flex h-[43px] w-full flex-row items-center justify-between rounded-[6px] px-2 transition-colors hover:bg-blue-100">
              <p className=" text-[14px] font-500 text-blue-600">
                {t("lbl_log_out")}
              </p>
            </button>
          </div>
        </DropDown>
      )}
      {show === "sign_in" && (
        <div className="flex flex-row items-center gap-3">
          <a
            id="signin-button"
            onClick={(e) => {
              logAdjust?.logEventWithPayload(
                "w_do_sign_in",
                { from: router.asPath, type: "header" },
              )

              if (!router.asPath.startsWith("/pricing")) {
                e.preventDefault()
                redirectToLogin()
              }
            }}
            href="/login"
            className={clsx(
              "bg-primary-500 px-4 py-[6px] text-[16px] font-600 hover:bg-primary-600 desktop:bg-blue-100",
              "rounded-[6px] text-color-white desktop:text-blue-800 desktop:hover:bg-blue-200",
              "animate-appear transition-colors",
            )}>
            {t("lbl_signin")}
          </a>
          <a
            id="signup-button"
            onClick={(e) => {
              logAdjust?.logEventWithPayload(
                "w_do_sign_in",
                { from: router.asPath, type: "header" },
              )
              if (!router.asPath.startsWith("/pricing")) {
                e.preventDefault()
                redirectToLogin("signup")
              }
            }}
            href="/login?type=signup"
            className={clsx(
              "hidden bg-primary-500 px-6 py-[6px] text-[16px] font-600 desktop:block",
              "rounded-[6px] text-color-white hover:bg-primary-600",
              "animate-appear transition-colors",
            )}>
            {t("lbl_signup")}
          </a>
        </div>
      )}
    </>
  )
}
