import { useEffect } from "react"
import { clarity } from "react-microsoft-clarity"

const useClarity = (userId: string | null) => {
  useEffect(() => {
    try {
      clarity.init(
        process.env.NEXT_PUBLIC_CLARITY_KEY ?? ""
      )
      if (userId) {
        clarity.identify(userId, {})
      }
    } catch (e) {
      console.error("Clarity counld not be loaded", e)
    }
  }, [userId])
}

export default useClarity
