import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { Box } from "react-styled-flex"

import styles from "./styles.module.css"

type AppearBottomProps = {
	children: React.ReactNode
	delay?: number
	onView?: boolean
	playNow?: boolean
	short?: boolean
	opaque?: boolean
	long?: boolean
}

const AppearBottom = (props: AppearBottomProps) => {
	const { children, onView, playNow, delay, short, opaque, long } = props

	const { inView, ref } = useInView()
	const [play, setPlay] = useState(playNow || false)

	if (
		!play &&
		// if it uses `playNow`, already initialized correctly
		((onView === undefined && playNow) ||
			// render only when user sees, and is already `inView`
			(onView && inView) ||
			// no trigger specified, render immediately
			(onView === undefined && playNow === undefined))
	)
		setPlay(true)

	const getAnimateClass = () => {
		if (short && opaque) return styles["appear-bottom-short-opaque"]
		if (short) return styles["appear-bottom-short"]
		if (opaque) return styles["appear-bottom-opaque"]
		if (long) return styles["appear-bottom-long"]
		return styles["appear-bottom"]
	}

	const getDelayMaybe = (delayInSeconds?: number) =>
		delayInSeconds === undefined ? undefined : delayInSeconds + "s"

	const animateIf = (playIfTrue: boolean) =>
		playIfTrue ? getAnimateClass() : "invisible"

	return (
		<Box ref={ref}>
			{
				<Box
					className={animateIf(play)}
					style={{
						animationDelay: getDelayMaybe(delay),
					}}>
					{children}
				</Box>
			}
		</Box>
	)
}

export default AppearBottom
