import {
  Color,
  FontSize,
  FontWeight,
} from "@/utils/globals"
import getLetterSpacing from "@/utils/spacing"
import { useTranslation } from "next-i18next"
import { CSSProperties } from "styled-components"

type TextProps = {
  content: string
  size: FontSize
  weight: FontWeight
  color?: Color
  line?: FontSize
  letterSpacing?: string
  capitalization?: CSSProperties["textTransform"]
  center?: boolean
}

const RawText = (props: TextProps) => {
  const {
    content,
    line,
    size,
    weight,
    color,
    capitalization,
    center,
    letterSpacing,
  } = props

  return (
    <div
      style={{
        display: "inline",
        color: color ? `var(--color-${color})` : undefined,
        fontWeight: Number.parseInt(weight),
        fontSize: size + "px",
        lineHeight: line ? line + "px" : undefined,
        letterSpacing: letterSpacing
          ? letterSpacing + "px"
          : getLetterSpacing(size),
        textTransform: capitalization,
        textAlign: center ? "center" : undefined,
      }}>
      {content}
    </div>
  )
}

const Text = (props: TextProps) => {
  const { t } = useTranslation()

  return <RawText {...props} content={t(props.content)} />
}

export { RawText }

export default Text
