import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useRouter } from "next/router"
import { CustomThemedResource } from "../image"
import PrimaryButton from "../primary-button"

interface UserCoverProps {
  username: string
  nickname: string
  source: string
  desktop?: boolean
}

function UserCover(props: UserCoverProps) {
  const { username, nickname, source, desktop } = props

  const balls = [
    {
      colors: "bg-[#C5C5FF] dark:bg-[#3B3B99]",
      left: -100,
      top: -130,
      size: 18,
    },
    {
      colors: "bg-[#BCF5C5] dark:bg-[#509B5C]",
      left: 90,
      top: 30,
      size: 16,
    },
    {
      colors: "bg-[#FFD4AC] dark:bg-[#996638]",
      left: -110,
      top: 20,
      size: 20,
    },
  ]

  const router = useRouter()

  return (
    <div
      onClick={() => {
        router.push(`/${username}`)
      }}
      className={clsx(
        desktop && "hidden desktop:flex",
        "desktop:pointer-events-none",
        "relative flex flex-row bg-color-background px-5 py-4 tablet:flex-col",
        "overflow-hidden pr-0 tablet:pr-[unset]",
        "items-center gap-[40px] tablet:justify-center tablet:gap-5",
        "rounded-[12px] tablet:rounded-[22px] desktop:rounded-[18px]",
        "w-full tablet:h-[356px] tablet:w-[356px] desktop:h-[422px] desktop:w-[475px]"
      )}>
      <div className="absolute top-0 right-0 h-full w-5 bg-gradient-to-r from-[transparent] to-color-background" />
      {balls.map(({ colors, left, top, size }) => (
        <div
          className={clsx(
            colors,
            "hidden rounded-full tablet:block"
          )}
          key={colors}
          style={{
            position: "absolute",
            left: `calc(50% + ${left}px)`,
            top: `calc(50% + ${top}px)`,
            width: size,
            height: size,
          }}
        />
      ))}
      <img
        className="aspect-square w-[91px] rounded-full tablet:w-[139px] desktop:w-[162px]"
        src={assetUrl(`/success/${source}-avatar.webp`)}
        alt={`Profile picture of ${nickname}`}
      />
      <div
        className={clsx(
          "desktop:text-h6 tablet:text-h5 text-[18px] flex flex-col gap-1 tablet:gap-2 tablet:text-center",
          "overflow-hidden"
        )}>
        <span className="break-words">{nickname}</span>
        <a
          className={clsx(
            "pointer-events-auto border-b-2 border-[transparent] transition-all hover:border-blue-700",
            "mr-4 font-700 text-blue-700"
          )}
          href={`/${username}`}>
          @{username}
        </a>
      </div>
    </div>
  )
}

export interface SuccessCoverProps {
  username: string
  nickname: string
  short: string
  title: string
  paragraphs: string[]
  source: string
}

export default function SuccessCover(
  props: SuccessCoverProps
) {
  const { username, short, source, title, paragraphs } =
    props

  return (
    <div className="bg-color-cell">
      <h1 className="text-h1 py-[50px] text-center">
        Success Story of {short}
      </h1>
      <div className="wrapped">
        <div
          className={clsx(
            "pb-[30px] tablet:pb-[36px] desktop:pb-[42px]",
            "flex flex-col desktop:flex-row desktop:items-stretch",
            "items-center gap-[15px] desktop:gap-[25px]"
          )}>
          <div className="flex max-w-[620px] flex-col items-center gap-9 desktop:w-[475px] desktop:items-start desktop:self-stretch">
            <div className="flex flex-col items-center gap-4 desktop:items-start">
              <h6 className="text-h5 tablet:text-h6 text-center desktop:text-left">
                {title}
              </h6>
            </div>
            <div className="hidden max-h-[700px] w-full max-w-[500px] flex-1 overflow-hidden rounded-[18px] desktop:block desktop:max-h-[1000px]">
              <CustomThemedResource
                alt={`Templates of ${short}`}
                className="h-full w-full object-cover"
                source={`/success/${source}-cover`}
              />
            </div>
          </div>

          <div className="flex w-full flex-col items-center gap-[15px] tablet:flex-row desktop:hidden">
            <UserCover {...props} />
            <div className="h-[356px] w-full overflow-hidden rounded-[18px] tablet:w-[356px]">
              <CustomThemedResource
                className="h-full w-full object-cover"
                source={`/success/${source}-cover`}
                alt={`Templates of ${short}`}
              />
            </div>
          </div>
          <div className="flex max-w-[604px] flex-col gap-9 desktop:w-[475px]">
            <UserCover {...props} desktop />
            <div className="flex flex-col gap-4 text-base font-500">
              {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
            <PrimaryButton href={`/${username}`}>
              Check My Templates
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
