import styles from "./styles.module.css"

type AppearInPlaceProps = {
	children: React.ReactNode
}

const AppearInPlace = (props: AppearInPlaceProps) => {
	return <div className={styles["appear-in-place"]}>{props.children}</div>
}

export default AppearInPlace
