import clsx from "clsx"

interface CheckboxProps {
  checked: boolean
  onClick: () => void
  id?: string
  className?: string
}

export default function Checkbox(props: CheckboxProps) {
  const { checked, onClick, className, id } = props
  return (
    <>
      <div className="relative h-6 w-6 cursor-pointer rounded-[6px] bg-[transparent] inner-border-[1.8px] inner-border-color-separator">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onClick}
          className={clsx(
            className,
            "peer h-full w-full cursor-pointer",
            "opacity-0"
          )}
        />
        <div
          className={clsx(
            "absolute inset-0 h-full w-full cursor-pointer transition-opacity",
            "pointer-events-none opacity-0 peer-checked:opacity-100"
          )}>
          <Checked />
        </div>
      </div>
    </>
  )
}

const Checked = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="24"
          height="24"
          rx="6"
          fill="var(--color-primary-500)"
        />
        <path
          d="M17.6676 7.95154L9.10782 16.0487L6.33167 12.8098"
          stroke="white"
          strokeWidth="2.16"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
