import { useEffect } from "react"

const useScrollListener = (handler: () => void, parent: HTMLElement | null = null) => {
	useEffect(() => {
		const element = parent ?? document
		element.addEventListener("scroll", handler)
		handler()
		return () => element.removeEventListener("scroll", handler)
	}, [handler, parent])
}

export default useScrollListener
