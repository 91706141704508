import Button from "@/comps/button"
import { assetUrl } from "@/utils/cdn"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useState } from "react"
import { useQueryClient } from "react-query"
import ModelMobileCard from "sections/train-model/model-mobile-card"

export interface SelectModelProps {
  samples:
    | {
        thumbs: string[]
        epoch: number
        model_file_url: string
      }[]
    | null
  id: number
}

export function SelectModelPopup(props: SelectModelProps) {
  const { t } = useTranslation()
  const { samples, id } = props
  const [selected, setSelected] = useState<number>(0)
  const router = useRouter()
  const queryClient = useQueryClient()

  if (samples === null) {
    return <></>
  }

  return (
    <>
      <div className="absolute left-0 top-[95px] hidden h-full w-full bg-color-background desktop:block">
        <div className="flex flex-col items-center gap-[13px] pt-[10px]">
          <span className="text-[24px] font-500 text-blue-800">
            {t("txt_select_model")}
          </span>
          <p className="text-[14px] font-400 text-color-placeholder">
            {t("txt_select_model_desc")}
          </p>

          <div className="flex items-center gap-[14px] pt-[5px]">
            {samples.map((sample, index) => (
              <SelectGroupCard
                key={sample.epoch}
                images={sample.thumbs}
                epoch={sample.epoch}
                index={index}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </div>

          <div className="flex flex-col items-center gap-[20px] pt-[68px]">
            <Button
              text={t("lbl_continue")}
              disabled={selected === 0}
              onClick={async () => {
                await axios.post(
                  "/api/training-select",

                  {
                    model_id: id,
                    epoch: selected,
                  },
                )
                router.push(`/models/single?id=${id}`)
                queryClient.invalidateQueries("all-models")
              }}
              className="w-[376px]"
            />

            <p className="w-[290px] text-center text-[14px] font-400 text-color-placeholder">
              {t("txt_select_desc")}
            </p>
          </div>
        </div>
      </div>

      <ModelMobileCard />
    </>
  )
}

interface SelectgroupCardProps {
  images: string[]
  epoch: number
  index: number
  selected: number
  setSelected: (v: number) => void
}

function SelectGroupCard(props: SelectgroupCardProps) {
  const { epoch, images, index, selected, setSelected } =
    props

  const { t } = useTranslation()

  return (
    <button
      className="rounded-[14px] border border-color-separator p-[8px] pb-[32px]"
      onClick={async () => {
        setSelected(epoch)
      }}>
      <div className="flex flex-col gap-[4px]">
        <img
          src={images[0]}
          alt="first image"
          className="h-[270px] w-[270px] rounded-t-[14px]"
        />
        <div className="flex items-center gap-[4px]">
          <img
            src={images[1]}
            alt="first image"
            className="h-[131px] w-[133px] rounded-bl-[14px]"
          />
          <img
            src={images[2]}
            alt="first image"
            className="h-[131px] w-[133px] rounded-br-[14px]"
          />
        </div>
      </div>

      <div className="flex flex-col items-center gap-[13px] pt-[21px]">
        {selected === epoch ? (
          <img
            src={assetUrl("/general/check-primary.svg")}
            alt="check primary"
            className="h-[32px] w-[32px]"
          />
        ) : (
          <div className="h-[32px] w-[32px] rounded-full border border-color-separator" />
        )}

        <span
          className={clsx(
            selected === epoch
              ? "text-primary-500"
              : "text-color-placeholder",
            "text-[15px] font-500",
          )}>
          {t("lbl_checkpoint")} {index + 1}
        </span>
      </div>
    </button>
  )
}
