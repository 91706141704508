import { assetUrl } from "@/utils/cdn"
import Head from "next/head"
import { useRouter } from "next/router"
import { Thing, WithContext } from "schema-dts"

export function JsonLd<T extends Thing>(
  json: WithContext<T>,
) {
  return (
    <script type="application/ld+json">
      {JSON.stringify(json)}
    </script>
  )
}

export interface GalleryMetaProps {
  "@context": "https://schema.org"
  "@type": "WebSite"
  url: string
  potentialAction: {
    "@type": "SearchAction"
    target: string
    "query-input": string
  }
}

interface MetaProps {
  title: string
  description: string
  cover?: string
  width?: string | null
  height?: string | null
  alt?: string
  tags?: string
  prevAndNextLinks?: {
    next: string | null
    prev: string | null
  }
  currentPage?: string
  jsonLdData?: WithContext<Thing>[] | GalleryMetaProps[]
}

const defaultCover = "default-cover"
const Meta = (props: MetaProps) => {
  const {
    title,
    description,
    cover,
    alt,
    width = "1200",
    height = "630",
    tags,
    prevAndNextLinks = {
      next: null,
      prev: null,
    },
    currentPage = null,
    jsonLdData = [],
  } = props

  const { next, prev } = prevAndNextLinks

  const coverUrl =
    cover ?? assetUrl("/covers/" + defaultCover + ".jpg")

  const router = useRouter()
  const [canonical] = router.asPath.split("?")

  return (
    <Head>
      <title>{title}</title>

      <link
        rel="canonical"
        href={
          "https://zoomerang.app" +
          (currentPage ?? canonical)
        }
      />
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, viewport-fit=cover"
      />

      {/* iOS Safari SmartBanner */}
      <meta
        name="apple-itunes-app"
        content="app-id=1361030006, app-argument=https://link.zoomerang.app/QnOr3F3HXvb"
      />

      <link
        rel="app-argument"
        href="https://link.zoomerang.app/QnOr3F3HXvb"
      />

      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black"
      />

      <meta name="description" content={description} />
      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={
          "https://zoomerang.app" +
          (currentPage ?? canonical)
        }
      />

      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description}
      />
      <meta property="og:image" content={coverUrl} />
      <meta
        property="og:image:secure_url"
        content={coverUrl}
      />
      <meta property="og:image:secure" content={coverUrl} />

      <meta property="og:image:type" content="image/jpeg" />
      {width && height && (
        <>
          <meta property="og:image:width" content={width} />
          <meta
            property="og:image:height"
            content={height}
          />
        </>
      )}
      <meta
        property="og:image:alt"
        content={alt ?? "Zoomerang app cover"}
      />
      <meta property="og:site_name" content="Zoomerang" />

      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta name="twitter:image" content={coverUrl} />
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:image:alt"
        content={alt ?? "Zoomerang app cover"}
      />

      <meta
        name="keywords"
        content={
          "video maker, video editor, video creator, Video editing, video editor app, best video editing app, iphone video editor, the best video editing software, video maker app, best video maker app, video collage maker, youtube video maker, video creator app, video editing app for android, best video editor for iphone, instagram video editor, tiktok editing app, instagram video maker, insta video editor, slideshow maker, video template app, best mobile apps, video templates, video editing apps, best video editing app for android, best video editing app for ios, best video templates for TikTok, video editing templates, canva video template, best video templates 2022, editable video templates, high-quality video templates, popular video templates, creative video templates, video preset templates, best premiere pro templates" +
          (tags ? ", " + tags : "")
        }
      />

      {next && (
        <link
          rel="next"
          href={"https://zoomerang.app" + next}
        />
      )}

      {prev && (
        <link
          rel="prev"
          href={"https://zoomerang.app" + prev}
        />
      )}

      {jsonLdData.map((data, index) => (
        <script
          key={index}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(data),
          }}
        />
      ))}
    </Head>
  )
}

export default Meta
