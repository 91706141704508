function prettyMillions(n: number): string {
  const separator = "."
  const thousands = "K"
  const millions = "M"

  if (n < 1_000) {
	  return n.toString()
  }

  if (n < 100_000) {
    const [first, last] = getNumberAfterDot(n, 3)
    if (last === 0) {
      return first + thousands
    }
    return first + separator + last + thousands
  }

  if (n < 1_000_000) {
    const [first] = getNumberAfterDot(n, 3)
    return first + thousands
  }

  const [first, last] = getNumberAfterDot(n, 6)
  if (last === 0) {
    return first + millions
  }

  return first + separator + last + millions
}

function getNumberAfterDot(n: number, power: number) {
  const fraction = n / Math.pow(10, power)
  const remainder = fraction % 1
  const first = Math.floor(fraction)
  const last = Math.floor(remainder * 10)

  return [first, last] as const
}

export default prettyMillions

export type TimeLocale =
  | {
      locale: string
      text: number
    }
  | {
      locale: null
      text: string
    }

export const contextAwareTime = (
  time: number,
): TimeLocale => {
  const now = new Date().getTime()
  const passed = now - time

  const millisecondsInMinutes = 1000 * 60

  const minutes = passed / millisecondsInMinutes
  const hours = minutes / 60
  const days = hours / 24
  const months = days / 30
  const years = days / 365

  const timestamps = [
    { count: years, locale: "fs_years_ago" },
    { count: months, locale: "fs_months_ago" },
    { count: days, locale: "fs_days_ago" },
    { count: hours, locale: "fs_hours_ago" },
    { count: minutes, locale: "fs_minutes_ago" },
  ]

  const closest = timestamps.find(({ count }) => count >= 1)

  if (closest) {
    return {
      locale: closest.locale,
      text: Math.floor(closest.count),
    }
  }

  return { locale: null, text: "txt_just_now" }
}
