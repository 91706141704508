import { appStoreConstant } from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { backgroundGradient } from "pages/models"
import { useEffect, useState } from "react"

export default function ModelMobileCard() {
  const [mobileState, setMobileState] = useState<
    "person" | "object" | "perfume"
  >("object")
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (mobileState === "object") {
        setMobileState("perfume")
      } else if (mobileState === "perfume") {
        setMobileState("person")
      } else {
        setMobileState("object")
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [mobileState])

  return (
    <div className="flex h-full flex-col items-center justify-center desktop:hidden">
      <div
        className={clsx(
          backgroundGradient,
          "flex h-[70px] w-full justify-center pt-[19px] tablet:h-[80px] tablet:pt-[22px]",
        )}>
        <span className="text-[24px] font-700 text-blue-800">
          {t("txt_models")}
        </span>
      </div>

      <div
        className={clsx(
          "flex w-full flex-col items-center gap-[13px] pb-[60px] pt-[67px] tablet:gap-[23px] tablet:pt-[113px]",
        )}>
        <div className="relative flex w-full items-center justify-center gap-[8px] py-[8px] tablet:gap-[13px]">
          <img
            src={assetUrl("/general/model-icon-overt.svg")}
            alt="model icon"
            className={clsx(
              "absolute -top-[17px] left-1/2 -translate-x-1/2 tablet:-top-[30px] tablet:h-[34px] tablet:w-[34px]",
              "h-[20px] w-[20px]",
            )}
          />

          <img
            src={assetUrl("/general/cut-border.svg")}
            alt="border"
            className=" absolute left-1/2 top-0 h-[56px] w-[204px] -translate-x-1/2 tablet:h-[97px] tablet:w-[353px]"
          />

          {new Array(4).fill(null).map((_, index) => {
            switch (mobileState) {
              case "object":
                return (
                  <img
                    src={assetUrl(
                      `/general/shoose-model-${index}.svg`,
                    )}
                    alt="shoose model"
                    className="h-[40px] w-[40px] tablet:h-[70px] tablet:w-[70px]"
                    key={index}
                  />
                )
              case "person":
                return (
                  <img
                    src={assetUrl(
                      `/general/model-person-${index}.svg`,
                    )}
                    alt="shoose model"
                    className="h-[40px] w-[40px] tablet:h-[70px] tablet:w-[70px]"
                    key={index}
                  />
                )
              case "perfume":
                return (
                  <img
                    src={assetUrl(
                      `/general/model-object-${index}.svg`,
                    )}
                    alt="shoose model"
                    className="h-[40px] w-[40px] tablet:h-[70px] tablet:w-[70px]"
                    key={index}
                  />
                )
            }
          })}
        </div>
        <img
          src={
            mobileState === "object"
              ? assetUrl("/general/model-object.svg?aaa")
              : mobileState === "person"
              ? assetUrl("/general/model-person.svg?aaa")
              : assetUrl("/general/model-perfume.svg?aaa")
          }
          alt="model object"
          className="h-[174px] w-[184px] rounded-[6.64px] transition-all tablet:h-[301px] tablet:w-[318px] tablet:rounded-[11.5px]"
        />

        <div className="flex items-start gap-[24px] pt-[33px]">
          <div className="flex flex-col items-center gap-[8px] transition-all">
            {mobileState === "person" ? (
              <>
                <img
                  src={assetUrl(
                    "/general/person-icon-solid.svg",
                  )}
                  alt="person icon"
                  className="h-[20px] w-[20px]"
                />
                <div className="h-1 w-1 rounded-full bg-blue-600" />
              </>
            ) : (
              <img
                src={assetUrl("/general/person-icon.svg")}
                alt="person icon"
                className="h-[20px] w-[20px]"
              />
            )}
          </div>
          <div className="flex flex-col items-center gap-[8px]">
            {mobileState === "object" ? (
              <>
                <img
                  src={assetUrl(
                    "/general/object-icon-solid.svg",
                  )}
                  alt="object icon"
                  className="h-[20px] w-[20px]"
                />
                <div className="h-1 w-1 rounded-full bg-blue-600" />
              </>
            ) : (
              <img
                src={assetUrl("/general/object-icon.svg")}
                alt="object icon"
                className="h-[20px] w-[20px]"
              />
            )}
          </div>
          <div className="flex flex-col items-center gap-[8px]">
            {mobileState === "perfume" ? (
              <>
                <img
                  src={assetUrl(
                    "/general/perfume-icon-solid.svg",
                  )}
                  alt="person icon"
                  className="h-[20px] w-[20px]"
                />
                <div className="h-1 w-1 rounded-full bg-blue-600" />
              </>
            ) : (
              <img
                src={assetUrl("/general/perfume-icon.svg")}
                alt="person icon"
                className="h-[20px] w-[20px]"
              />
            )}
          </div>
        </div>

        <div className="flex flex-col items-center gap-[16px] px-[32px] pt-[60px]">
          <p className="max-w-[470px] text-center text-[13px] font-400 text-color-placeholder tablet:max-w-[525px] tablet:text-[18px]">
            {t("txt_model_mobile_dec")}
          </p>
          <a
            href={appStoreConstant}
            className="flex w-full max-w-[413px] items-center justify-center rounded-[24px] bg-primary-500 py-[14px]">
            <div className="flex items-center gap-[4px]">
              <img
                src={assetUrl(
                  "/general/model-plus-white.svg",
                )}
                alt="model pluse icon"
              />
              <span className="text-[16px] font-600 text-color-white">
                {t("lbl_train_model")}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
