import {
  DesktopOrTablet,
  devices,
  Mobile,
  Tablet,
} from "@/utils/breakpoints"

import { useTranslation } from "next-i18next"
import styled from "styled-components"
import Socials from "./socials"

const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 20px 0px;

  width: 100%;

  @media ${devices.tablet} {
    width: 728px;
    flex-direction: row;
  }

  @media ${devices.desktop} {
    width: 1175px;
  }
`

const CopyrightText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: var(--color-blue-800);

  @media ${devices.tablet} {
    font-size: 14px;
  }

  @media ${devices.desktop} {
    font-size: 16px;
    width: unset;
  }
`

const CopyrightOld = () => {
  const { t } = useTranslation()

  return (
    <CopyrightContainer>
      <CopyrightText>
        {t("fs_copyright", {
          year: new Date().getFullYear(),
        })}
      </CopyrightText>
      <Tablet>
        <Socials />
      </Tablet>
      <CopyrightText>
        {t("txt_rights_reserved")}
      </CopyrightText>
      <Mobile>
        <Socials />
      </Mobile>
    </CopyrightContainer>
  )
}

const Copyright = () => {
  const { t } = useTranslation()

  return (
    <CopyrightContainer>
      <CopyrightText>
        {t("fs_copyright_rights", {
          year: new Date().getFullYear(),
        })}
      </CopyrightText>
      <DesktopOrTablet>
        <Socials />
      </DesktopOrTablet>
      <Mobile>
        <Socials />
      </Mobile>
    </CopyrightContainer>
  )
}

export default Copyright
