export const base = process.env.BACKEND_ENDPOINT_BASE
export const code = process.env.BACKEND_ENDPOINT_CODE

const empty: string[] = []
const queryfy = (params: object) =>
	"?" +
	Object.entries(params)
	.reduce(
		(array, [key, value]) => [
			...array,
			encodeURIComponent(key) + "=" + encodeURIComponent(value),
		],
		empty,
	)
	.join("&")

export default queryfy
