import {
  devices,
  onDesktop,
  onTablet,
} from "@/utils/breakpoints"
import { WithClass } from "@/utils/types"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import { CustomThemedResource } from "./image"

const loadingAnimation = (width: number) => keyframes`
	from {
		margin-left: -${width}px;
		margin-right: ${width}px;
	}

	to {
		margin-left: ${width}px;
		margin-right: -${width}px;
	}
`

const loadingAnimationPercent = keyframes`
	from {
		margin-left: -100%;
		margin-right: 100%;
	}

	to {
		margin-left: 100%;
		margin-right: -100%;
	}
`

const CustomAnimatedImageSlow = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;

  opacity: 1;
  animation-name: ${loadingAnimationPercent};

  @media ${devices.tablet} {
    animation-name: ${loadingAnimationPercent};
  }

  @media ${devices.desktop} {
    animation-name: ${loadingAnimationPercent};
  }

  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: normal;
`

const CustomAnimatedImageMasonry = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;

  opacity: 1;
  animation-name: ${loadingAnimationPercent};

  @media ${devices.tablet} {
    animation-name: ${loadingAnimationPercent};
  }

  @media ${devices.desktop} {
    animation-name: ${loadingAnimationPercent};
  }

  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: normal;
`

const CustomAnimatedImage = styled.div`
  position: absolute;
  top: 0;

  opacity: 1;
  animation-name: ${() => loadingAnimation(164)};

  @media ${devices.tablet} {
    animation-name: ${() => loadingAnimation(233)};
  }

  @media ${devices.desktop} {
    animation-name: ${() => loadingAnimation(275)};
  }

  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: normal;
`

const SkeletonRectAnimatedImage = styled(
  CustomAnimatedImage
)`
  height: 100%;

  img {
    height: 100%;
  }
`

const AnimatedImage = styled(CustomAnimatedImage)`
  width: 164px;
  height: 203px;

  ${onTablet(css`
    width: 233px;
    height: 289px;
  `)}

  ${onDesktop(css`
    width: 275px;
    height: 342px;
  `)}
`

const CustomSkeletonContainer = styled.div`
  position: relative;
  overflow: hidden;

  background-color: var(--color-skeleton);
  border-radius: 12px;
`

const Container = styled(CustomSkeletonContainer)`
  width: 164px;
  height: 203px;

  ${onTablet(css`
    width: 233px;
    height: 289px;
  `)}

  ${onDesktop(css`
    width: 275px;
    height: 342px;
  `)}
`

interface SkeletonProps {
  className?: string
}

const Skeleton = (props: SkeletonProps) => {
  const { className } = props

  return (
    <Container className={className}>
      <AnimatedImage className={className}>
        <CustomThemedResource
          source="/general/skeleton"
          format="webp"
        />
      </AnimatedImage>
    </Container>
  )
}

export function SizedSkeleton(props: WithClass) {
  const { className,style } = props

  return (
    <div
		style={style}
      className={clsx(
        "relative z-20 overflow-hidden",
        "bg-color-skeleton",
        className
      )}>
      <CustomAnimatedImageSlow className="h-full">
        <CustomThemedResource
          source="/general/skeleton"
          className="aspect-[1/2] h-full"
        />
      </CustomAnimatedImageSlow>
    </div>
  )
}

export function MasonrySkeleton(props: WithClass) {
  const { className } = props

  return (
    <div
      className={clsx(
        "relative z-20 overflow-hidden",
        "bg-color-skeleton",
        className
      )}>
      <CustomAnimatedImageMasonry className="h-full">
        <CustomThemedResource
          source="/general/skeleton"
          className="h-full w-[300px] object-cover"
        />
      </CustomAnimatedImageMasonry>
    </div>
  )
}

interface SkeletonRectangleProps {
  className?: string
}

export const SkeletonRectangle = (
  props: SkeletonRectangleProps
) => {
  return (
    <Container {...props}>
      <SkeletonRectAnimatedImage>
        <CustomThemedResource
          source="/general/skeleton"
          format="webp"
        />
      </SkeletonRectAnimatedImage>
    </Container>
  )
}

type VideoProps = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>

interface SkeletonVideoProps extends VideoProps {
  src: string
  className?: string
}

type SkeletonWithProps = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
> & {
  srcset: string[]
}

export const SkeletonVideoWith = React.forwardRef<
  HTMLVideoElement,
  SkeletonWithProps
>((props, ref) => {
  const { srcset, children, ...others } = props

  const [sources, setSources] = useState<string[]>([])

  useEffect(() => {
    const load = async (src: string) => {
      await fetch(src)
      return src
    }

    Promise.all(srcset.map((source) => load(source)))
      .then((sources) => setSources(sources))
      .catch(console.error)
  }, [srcset])

  if (sources.length === 0) {
    return <>{children}</>
  }

  return (
    <video {...others} ref={ref}>
      {sources.map((source) => (
        <source src={source} key={source} />
      ))}
    </video>
  )
})

SkeletonVideoWith.displayName = "SkeletonVideoWith"

export const SkeletonVideo = React.forwardRef<
  HTMLVideoElement,
  SkeletonVideoProps
>((props, ref) => {
  const { src, className, ...otherProps } = props

  const [url, setUrl] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const load = async () => {
      await fetch(src)
      setUrl(src)
    }

    load().catch()
  }, [src])

  if (url === undefined)
    return <SkeletonRectangle className={className} />

  return (
    <video
      {...otherProps}
      className={className}
      src={url}
      ref={ref}
    />
  )
})

SkeletonVideo.displayName = "SkeletonVideo"

const spin = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const SmallLoadingContainer = styled(CustomThemedResource)`
  animation-name: ${spin};
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  width: 20px;
  height: 20px;
`

export const SmallLoading = () => (
  <SmallLoadingContainer source="/general/loading" />
)

export const CustomSkeleton = (props: WithClass) => (
  <div
    className={clsx(
      props.className,
      "relative",
      "bg-color-skeleton",
      "overflow-hidden"
    )}>
    <video src=""></video>
    <CustomThemedResource
      source="/general/skeleton"
      className="aspect-square absolute left-0 top-0 h-full animate-slide object-cover"
    />
  </div>
)

export default Skeleton
