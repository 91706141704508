import { devices } from "@/utils/breakpoints"

import { useTranslation } from "next-i18next"
import styled, { css } from "styled-components"

type BubbleColors =
  | "tan"
  | "purple"
  | "pink"
  | "pinkPurple"
  | "purplePink"
  | "primaryPink"
  | "pinkPurpleLight"
  | "purplePinkSelected"
  | "purplePinkWithHover"

interface BubbleProps {
  text: string
  color: BubbleColors
  control?: boolean
  className?: string
}

const textColors = {
  tan: css`
    background: #1f7b8f;
  `,
  purple: css`
    background: #7d4fde;
  `,
  pink: css`
    background: #ce32b9;
  `,
  pinkPurple: css`
    background: var(--color-primary-500);
  `,
  primaryPink: css`
    background: var(--color-primary-500);
  `,
  purplePink: css`
    background: linear-gradient(
      90deg,
      #d13cf6 0%,
      #a64dfe 100%
    );
  `,
  pinkPurpleLight: css`
    background: #d33af9;
  `,
  purplePinkSelected: css`
    background: #eff1fb;
  `,
  purplePinkWithHover: css`
    background: linear-gradient(
      90deg,
      #d13cf6 0%,
      #a64dfe 100%
    );
  `,
}

const backgroundColors = {
  tan: css`
    background: rgba(31, 123, 143, 0.2);
  `,
  purple: css`
    background: rgba(125, 79, 222, 0.2);
  `,
  pink: css`
    background: rgba(206, 50, 185, 0.2);
  `,
  pinkPurple: css`
    background: linear-gradient(
      90deg,
      rgba(209, 60, 246, 0.15) 0%,
      rgba(166, 77, 254, 0.15) 100%
    );
  `,
  primaryPink: css`
    background: rgba(255, 49, 102, 0.15);
  `,
  purplePink: css`
    background: linear-gradient(
      90deg,
      rgba(209, 60, 246, 0.15) 0%,
      rgba(166, 77, 254, 0.15) 100%
    );
  `,
  pinkPurpleLight: css`
    background: #d13cf614;
  `,
  purplePinkSelected: css`
    background: linear-gradient(
      90deg,
      rgba(209, 60, 246, 1) 0%,
      rgba(166, 77, 254, 1) 100%
    );
  `,
  purplePinkWithHover: css`
    background: linear-gradient(
      90deg,
      rgba(209, 60, 246, 0.15) 0%,
      rgba(166, 77, 254, 0.15) 100%
    );
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(209, 60, 246, 0.22) 0%,
        rgba(166, 77, 254, 0.22) 100%
      );
    }
  `,
}

const StyledBubble = styled.div<{
  color: BubbleColors
  control?: boolean
  light?: boolean
}>`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15em;

  @media ${devices.desktop} {
    font-size: 14px;
    line-height: 17px;
  }

  font-weight: 700;
  flex-shrink: 0;

  text-transform: uppercase;
  user-select: none;

  border-radius: 38px;
  padding: 7px 15px;

  ${(props) =>
    !props.control &&
    css`
      align-self: start;
    `};

  ${(props) =>
    props.light
      ? backgroundColors[props.color]
      : props.color !== "purplePinkWithHover" &&
        props.color !== "purplePinkSelected"
      ? css`
          background: rgba(255, 255, 255, 0.2);
        `
      : props.color === "purplePinkWithHover"
      ? css`
          background: rgba(255, 255, 255, 0.2);
          &:hover {
            background: rgba(255, 255, 255, 0.4);
          }
        `
      : css`
          color: rgba(0, 0, 0, 0.8);
          background: var(--color-white);
        `}
`

// noinspection CssInvalidPropertyValue
const StyledBubbleInside = styled.div<{
  color: BubbleColors
  light?: boolean
}>`
  ${(props) =>
    props.light
      ? textColors[props.color]
      : css`
          background: var(--color-white);
        `}

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`

const Bubble = (props: BubbleProps) => {
  const { t } = useTranslation()

  return <RawBubble {...props} text={t(props.text)} />
}

export const RawBubble = (props: BubbleProps) => {
  const { text, color, className, control } = props

  return (
    <>
      <StyledBubble
        className={className}
        light
        control={control}
        color={color}
        data-hide-on-theme="dark">
        <StyledBubbleInside
          light
          color={color}
          data-hide-on-theme="dark">
          {text}
        </StyledBubbleInside>
      </StyledBubble>
      <StyledBubble
        control={control}
        className={className}
        color={color}
        data-hide-on-theme="light">
        {text}
      </StyledBubble>
    </>
  )
}

type StaticBubbleProps = {
  text: string
  color: string
}

const StyledStaticBubble = styled.div<{ color: string }>`
  color: var(--color-white);
  background-color: ${(props) => props.color};
  display: inline;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  font-size: 12px;
  padding: 5px 10px;

  @media ${devices.tablet} {
    font-size: 16px;
    padding: 7px 30px;
  }

  @media ${devices.desktop} {
    padding: 5px 30px;
  }

  border-radius: 30px;
`

const StaticBubble = (props: StaticBubbleProps) => {
  const { text, color } = props

  const { t } = useTranslation()

  return (
    <StyledStaticBubble color={color}>
      {t(text)}
    </StyledStaticBubble>
  )
}

export { StaticBubble }

export default Bubble
