import Button from "@/comps/button"
import clsx from "clsx"
import { SizedSkeleton } from "comps/skeleton"
import { useTranslation } from "next-i18next"

export default function BlockSkelethon() {
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col items-center gap-[36px]">
      <div
        className={clsx(
          "flex w-full gap-[10px] px-[70px] pt-[22px]",
          "justify-center tablet:items-center tablet:gap-0",
        )}>
        {/*DEFAULT */}
        <div
          className={clsx(
            "flex flex-col gap-[12px] rounded-[10px] bg-color-cell",
            "tablet:h-[383px] tablet:w-[240px] tablet:rounded-r-none tablet:px-[20px] tablet:py-[24px]",
            "w-full overflow-hidden border-2 border-color-separator tablet:gap-[18px]",
            "max-w-[150px] tablet:max-w-none tablet:border-none",
          )}>
          <div
            className={clsx(
              "flex h-full flex-col tablet:gap-[18px] desktop:gap-[20px]",
              "tablet:items-start",
            )}>
            <span
              className={clsx(
                "border-b-2 border-color-separator text-[14px] font-700 text-blue-600",
                "pb-[20px] pt-[16px] text-center tablet:text-[22px] desktop:text-[28px]",
                "tablet:border-none tablet:p-0 tablet:text-start tablet:text-blue-900",
              )}>
              {t("txt_default")}
            </span>
            <div
              className={clsx(
                "flex-1 bg-color-background px-[10px] pt-[40px]",
                "tablet:flex tablet:flex-col tablet:gap-[20px] tablet:bg-color-cell tablet:p-0",
              )}>
              <SizedSkeleton
                className={clsx(
                  "h-[14px] rounded-[50px] inner-border tablet:h-[20px] tablet:w-[165px]",
                  "tablet:rounded-[12px]",
                  " bg-color-cell inner-border-blue-200",
                )}
              />
              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[163px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[112px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[135px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />
              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[163px] inner-border",
                  "hidden rounded-[12px] tablet:flex",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[112px] inner-border",
                  "hidden rounded-[12px] tablet:flex",
                  "bg-color-cell inner-border-blue-200",
                )}
              />
            </div>
          </div>
        </div>

        {/*ADVANCED */}
        <div
          className={clsx(
            "boredr-2 w-full max-w-[150px] border-primary-500 tablet:max-w-none",
            "flex flex-col gap-[12px] rounded-[10px] border-2 bg-color-cell",
            "relative items-center overflow-hidden tablet:gap-[18px]",
            "tablet:h-[460px] tablet:w-[248px] tablet:px-[20px] tablet:py-[24px]",
            "tablet:boredr-2 tablet:border-primary-500 tablet:shadow-xl tablet:shadow-primary-100",
          )}>
          <img
            src="/dashed-bg.svg"
            alt="bg image"
            className=" absolute right-0 top-0"
          />
          <div className="flex flex-col gap-[17px] pt-[16px] tablet:gap-[10px] desktop:gap-[20px]">
            <div
              className={clsx(
                "flex w-full flex-col items-center gap-[9px]",
                "tablet:items-start tablet:pt-[23px]",
              )}>
              <span
                className={clsx(
                  "text-center text-[14px] font-700 text-blue-700 ",
                  "tablet:text-[22px] tablet:text-blue-900 desktop:text-[28px]",
                  "tablet:text-start",
                )}>
                {t("txt_prime")}
              </span>
              <span
                className={clsx(
                  "rounded-[80px] bg-primary-100 px-[10px] text-primary-500",
                  "py-[6px] text-[8px] font-700 tracking-[0.833px]",
                  "top-[15px] tablet:absolute tablet:left-[17px] tablet:text-[10px] tablet:font-800",
                  "tablet:px-[26px] tablet:py-[7px]",
                )}>
                {t("txt_best_for_ai")}
              </span>
            </div>
            <div
              className={clsx(
                "flex-1 pb-[52px] pt-[13px]",
                "tablet:flex tablet:flex-col tablet:gap-[20px] tablet:bg-color-cell tablet:p-0",
              )}>
              <SizedSkeleton
                className={clsx(
                  "h-[14px] rounded-[50px] inner-border tablet:h-[20px] tablet:w-[165px]",
                  "tablet:rounded-[12px]",
                  " bg-color-cell inner-border-blue-200",
                )}
              />
              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[163px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[112px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[135px] inner-border",
                  "hidden rounded-[12px] tablet:block",
                  " bg-color-cell inner-border-blue-200",
                )}
              />
              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[163px] inner-border",
                  "hidden rounded-[12px] tablet:flex",
                  " bg-color-cell inner-border-blue-200",
                )}
              />

              <SizedSkeleton
                className={clsx(
                  "h-[16px] w-[112px] inner-border",
                  "hidden rounded-[12px] tablet:flex",
                  "bg-color-cell inner-border-blue-200",
                )}
              />
            </div>
          </div>
          <Button
            text={t("txt_upgrade")}
            primary
            disabled
            className="mt-auto hidden tablet:mb-[35px] tablet:block"
          />
        </div>
      </div>

      <div className="flex w-full justify-center px-[20px] tablet:hidden">
        <Button
          text={t("txt_upgrade")}
          primary
          disabled
          className="mt-auto w-full max-w-[470px] tablet:hidden"
        />
      </div>
    </div>
  )
}
