import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { Models, ModelType } from "pages/models/create"

interface RenderModelProps {
  models: Models
  selectedModel: ModelType
  setSelectedModel: (v: ModelType) => void
}

export default function RenderModels(
  props: RenderModelProps,
) {
  const { models, selectedModel, setSelectedModel } = props
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-[14px] pt-[20px]">
      <p className="text-[15px] font-600 leading-[22px] tracking-[0.3px] text-blue-700">
        {t("txt_model_type")}
      </p>

      <div className="flex gap-[14px]">
        {models.map((model) => {
          return (
            <button
              key={model.id}
              className={clsx(
                selectedModel === model.id &&
                  "border-2 border-primary-500",
                "relative overflow-hidden rounded-[8px] border-2 border-color-background",
              )}
              onClick={() =>
                setSelectedModel(model.id as ModelType)
              }>
              <img
                src={model.thumb_url}
                alt="model image"
                className=" h-[152px] w-[138px] object-cover"
              />
              <div className="absolute bottom-0 h-[27px] w-full bg-color-black/20 [backdrop-filter:blur(8px)]">
                <span className="text-[13px] font-600 text-color-white">
                  {model.name}
                </span>
              </div>

              <div className="absolute right-[6px] top-[6px] overflow-hidden rounded-full pb-[1.5px] pr-[1px]">
                <div
                  className={clsx(
                    selectedModel === model.id
                      ? "flex items-center justify-center border-2 border-primary-500"
                      : " border-2 border-[#E8E6F0] shadow-color-black/20 drop-shadow-md",
                    "h-[20px] w-[20px] rounded-full transition-colors",
                    "bg-color-white ",
                  )}>
                  {selectedModel === model.id && (
                    <div className="h-[10px] w-[10px] rounded-full bg-primary-500 transition-colors"></div>
                  )}
                </div>
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}
