import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import { getAnalytics, logEvent } from "firebase/analytics"
import { RawBubble } from "../bubble"
import { RawButton } from "../button"
import { CustomThemedResource } from "../image"

interface SuccessSupportProps {
  support: string
  success: string
  used?: string
  followers: string
  revenue?: string
  coins?: string
  shoots?: string
  engagement?: string
}

export default function SuccessSupport(
  props: SuccessSupportProps
) {
  const {
    support,
    success,
    used,
    followers,
    revenue,
    coins,
    engagement,
    shoots,
  } = props

  const moneyValue: string = coins || revenue || ""
  const moneyText = coins
    ? "earned coins through selling video templates"
    : "earned revenue through selling video templates."

  const activityValue = engagement || shoots || used || ""
  const activityText = engagement
    ? "increase in engagement rates across all my social media platforms."
    : shoots
    ? "video shoots using my created templates"
    : "times used the created template"

  return (
    <div
      className={clsx(
        "py-4 tablet:py-8 desktop:py-[50px]",
        "bg-color-cell"
      )}>
      <div className="wrapped mx-auto flex flex-col items-center gap-4 desktop:items-start">
        <SupportCard support={support} />
        <h4 className="text-h4">Their Success</h4>
        <span className="tablet:w-[604px] desktop:w-[850px]">
          {success}
        </span>
        <div className="flex flex-col gap-4 tablet:w-[604px]">
          <SuccessPoint
            value={activityValue}
            text={activityText}
          />
          <SuccessPoint
            value={followers}
            text="followers gained with high engagement rate"
          />
          <SuccessPoint
            value={moneyValue}
            text={moneyText}
          />
        </div>
      </div>
    </div>
  )
}

interface SupportCardProps {
  support: string
}

function SupportCard(props: SupportCardProps) {
  const { support } = props
  const { userInfo } = useAuth()

  return (
    <div
      className={clsx(
        "bg-[linear-gradient(309.95deg,#8EC5FC_-9.92%,rgba(252,195,222,0.67)_108.56%)]",
        "dark:bg-[linear-gradient(309.95deg,#1F4770_-9.92%,#652A46_108.56%)]",
        "relative flex flex-col gap-4 rounded-[18px]",
        "tablet:w-[481px] desktop:mb-[20px] desktop:w-[869px]",
        "mb-[170px] tablet:mb-0 tablet:mr-[123px] desktop:mr-[106px]",
        "p-[24px_16px_170px_16px] tablet:p-[42px_107px_42px_26px] desktop:p-[55px_200px_55px_34px]"
      )}>
      <RawBubble
        color="pink"
        text="Dedicated support"
        control
        className="gap-4 self-center tablet:self-start"
      />
      <h4 className="text-h4 text-center tablet:text-left">
        Received Support
      </h4>
      <p className="text-base">{support}</p>
      <RawButton
        text="Get your guide"
        primary
        onClick={() => {
          $crisp.push(["do", "chat:toggle"])
          logEvent(getAnalytics(), "web_crisp_support", {
            page: "success_story",
            uid: userInfo.userId,
          })
        }}
        className="absolute -bottom-[330px] left-1/2 w-full -translate-x-1/2 tablet:bottom-0 tablet:left-0 tablet:block tablet:w-[212px] tablet:translate-x-0"
      />
      <CustomThemedResource
        source="/success/support-card"
        className={clsx(
          "absolute bottom-[50px] right-1/2 aspect-[486/365] max-h-[365px] tablet:bottom-1/2 tablet:right-0",
          "[transform:translate(70%,50%)]"
        )}
      />
    </div>
  )
}

interface SuccessPointProps {
  value: string
  text: string
}

function SuccessPoint(props: SuccessPointProps) {
  const { value, text } = props

  return (
    <div className="flex items-start gap-2">
      <img
        className="mt-[3px] h-[22px] w-[22px]"
        src={assetUrl("/success/tick.webp")}
        alt="Tick"
      />
      <span className="text-[18px]">
        <span className="font-700">{value}</span> {text}
      </span>
    </div>
  )
}
