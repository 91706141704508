import { Device, useDeviceSize } from "@/utils/breakpoints"
import clsx from "clsx"
import { AITool } from "comps/editors/deform"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { SingleProduct } from "pages/tools"
import { ReactNode } from "react"
import { ResponsiveImage } from "../image"
import {
  deforumIcon,
  deforumSmallIcon,
  photoAiIcon,
  photoAiSmallIcon,
  restyleIcon,
  restyleSmallIcon,
  textToImageIcon,
  textToImageSmallIcon,
  textToVideoIcon,
  textToVideoSmallIcon,
} from "./icons"

export const allProducts: OtherProduct[] = [
  {
    tool: "ai_restyle",
    label: "txt_restyle_ai",
    location: "restyle",
    color: "#a42ef3",
    url: "/tools/ai-animation-generator",
    icon: restyleIcon(),
    smallIcon: restyleSmallIcon(),
  },
  {
    tool: "deform",
    label: "txt_deforum_ai",
    location: "deforum",
    color: "#d94f91",
    url: "/tools/ai-video-transformation",
    icon: deforumIcon(),
    smallIcon: deforumSmallIcon(),
  },
  {
    tool: "ai_photo",
    label: "txt_photo_ai",
    location: "photo-ai",
    color: "#227df5",
    url: "/tools/ai-photo-editor",
    icon: photoAiIcon(),
    smallIcon: photoAiSmallIcon(),
  },
  {
    tool: "text_to_image",
    label: "txt_text_to_image",
    location: "text-to-image",
    color: "#227df5",
    url: "/tools/text-to-image",
    icon: textToImageIcon(),
    smallIcon: textToImageSmallIcon(),
  },
  {
    tool: "text_to_video",
    label: "txt_text_to_video",
    location: "text-to-video",
    color: "#227df5",
    url: "/tools/text-to-video",
    icon: textToVideoIcon(),
    smallIcon: textToVideoSmallIcon(),
  },
]

export interface OtherProduct {
  tool: AITool
  label: string
  location: string
  color: string
  url: string
  icon: ReactNode
  smallIcon: ReactNode
}

export interface OtherProductsSectionProps {
  products?: OtherProduct[]
}

export function OtherProductsSectionOld(
  props: OtherProductsSectionProps
) {
  const { products } = props
  const selectedProducts = products ?? allProducts

  const { t } = useTranslation()

  return (
    <section className="relative flex w-full flex-col items-center gap-12 pb-28 pt-20 tablet:gap-16 tablet:pb-44 tablet:pt-11 desktop:pb-24 desktop:pt-20">
      <h2 className="text-h2 text-center">
        {t("txt_try_other")}
      </h2>
      <div
        className={clsx(
          "z-20 flex flex-row gap-3 tablet:gap-4 desktop:items-center desktop:gap-6",
          "no-scrollbar w-full overflow-x-scroll px-4 tablet:w-[731px] desktop:w-[867px] desktop:px-0"
        )}>
        {selectedProducts.map((product) => (
          <a
            href={product.url}
            key={product.label}
            className={clsx(
              "relative z-20 flex flex-col gap-1 rounded-[14px] tablet:gap-4 tablet:rounded-[22px] desktop:gap-8",
              "flex-1 bg-color-cell text-[12px] font-700 tablet:text-[20px] desktop:text-[28px]",
              "group h-[103px] items-center justify-center tablet:h-[182px] desktop:h-[242px]",
              "transition-all duration-300 desktop:hover:gap-6"
            )}
            style={
              {
                "--icon-color": product.color,
              } as React.CSSProperties
            }>
            <div
              className={clsx(
                "tablet:scale-[2] tablet:group-hover:scale-[2.5] desktop:scale-[2.5]",
                "transition-transform desktop:group-hover:scale-[3]",
                "duration-300"
              )}>
              {product.icon}
            </div>
            <span className="text-center">
              {t(product.label)}
            </span>
          </a>
        ))}
      </div>

      <ResponsiveImage
        src="/tools/other"
        className="pointer-events-none absolute left-1/2 top-0 z-10 h-full -translate-x-1/2 object-cover"
      />
    </section>
  )
}

export function OtherProductsSection(
  props: OtherProductsSectionProps
) {
  const { products } = props
  const selectedProducts = products ?? allProducts

  const { t } = useTranslation()

  const router = useRouter()
  const device = useDeviceSize()

  return (
    <>
      <section className="relative flex w-full flex-col items-center gap-6 py-[35px] tablet:gap-8 tablet:py-[40px] desktop:py-[55px]">
        <h2 className="text-h2 z-10 text-center">
          {t("txt_try_other")}
        </h2>
        <div
          className={clsx(
            "no-scrollbar z-10 flex w-full flex-col gap-3 overflow-x-scroll px-4",
            "tablet:grid tablet:w-auto tablet:grid-cols-3 tablet:gap-4 tablet:overflow-auto",
            "desktop:grid-cols-4 desktop:gap-6 desktop:px-0"
          )}>
          {selectedProducts
            .filter(
              (product) =>
                !router.pathname.startsWith(product.url)
            )
            .map((product) => (
              <SingleProduct
                key={product.url}
                product={product}
                isMobile={device === Device.Mobile}
              />
            ))}
        </div>

        <div className="pointer-events-none absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-x-clip desktop:translate-y-1/4">
          <ResponsiveImage
            src="/tools/other"
            className="h-full w-full scale-x-150 scale-y-125 object-cover tablet:scale-150 desktop:w-[1728px] desktop:scale-100"
          />
        </div>
      </section>
    </>
  )
}
