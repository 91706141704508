import { WarningMessage } from "@/comps/message"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { NotificationContext } from "./notification"
import { AxiosError } from "axios"

export function withNotify(notify: (message: string) => void) {
  return function(error: unknown) {
    if (error instanceof AxiosError) {
      notify(error.response?.data?.message ?? error.message)
      return
    }

    if (error instanceof Error) {
      notify(error.message)
      return
    }

    notify("Unknown error")
  }
}
export const useOnce = (callback: () => void) => {
  const { ready, trigger } = useTrigger()

  useEffect(() => {
    if (ready) return

    callback()
    trigger()
  }, [callback, ready, trigger])
}

interface UseTriggerResult {
  ready: boolean
  trigger: () => void
}

const useTrigger = (): UseTriggerResult => {
  const [ready, setReady] = useState(false)

  return { ready, trigger: () => setReady(true) }
}

export function useErrorNotification() {
  const router = useRouter()
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation("common")

  const [notified, setNotified] = useState(false)

  useEffect(() => {
    const { error } = router.query
    if (error) {
      if (notified) return
      setNotified(true)
      notify(
        <WarningMessage>
          {t("txt_no_pdfs")}
        </WarningMessage>
      )
    }
  }, [notified, notify, router.query, t])
}


export default useTrigger
