import {
  createContext,
  ReactNode,
  useEffect,
  useState,
} from "react"

export const LastViewedContext = createContext<
  string | null
>(null)

export const LastViewedProvider = (props: {
  children: ReactNode
}) => {
  const [last, setLast] = useState<string | null>(null)

  useEffect(() => {
    const local = sessionStorage.getItem("last")

    if (typeof local === "string") {
      setLast(local)
    }
  }, [])

  return (
    <LastViewedContext.Provider value={last}>
      {props.children}
    </LastViewedContext.Provider>
  )
}
