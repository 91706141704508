import { getAdExamplesSSR } from "@/ssr/ad-examples"
import {
  getCategoriesForFilter,
  getInitialFilters,
} from "@/ssr/gallery"
import {
  SSRTemporaryRedirect,
  wrapSSRAuth,
} from "@/utils/ssr-auth"
import {
  AdExamplesType,
  AvailableFilters,
  AvailableFiltersTemplates,
  FilterStateAdExamples,
  MetaLinks,
} from "pages/ai-gallery"
import GalleryAll from "sections/gallery/all-gallery"
import { z } from "zod"

export const adExampleEntrySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  script: z.string().nullable(),
  aspect: z.number(),
  share_url: z.string().nullable(),
  preview_thumbnail_url: z.string(),
  preview_video_url: z.string(),
  created_at: z.string(),
  owner: z
    .object({
      username: z.string(),
      profile_pic: z
        .object({
          original: z.string().nullish(),
        })
        .nullish(),
      followers: z.number(),
      uid: z.string(),
      follow_status: z.number().optional(),
    })
    .nullable(),
})

export const homeResponseSchema = z.object({
  sections: z
    .object({
      title: z.string(),
      query: z.string(),
      results: adExampleEntrySchema.array(),
    })
    .array(),
})

export const searchResponseSchema = z.object({
  results: adExampleEntrySchema.array(),
  total: z.number().nullable().default(null),
})

export type AdExamplesSingle = z.infer<
  typeof adExampleEntrySchema
>

const indexFiltersWithoutApplied = {
  searchQuery: null,
  aspect: null,
} as const

export const indexFiltersAdExamples: FilterStateAdExamples =
  {
    ...indexFiltersWithoutApplied,
    applyFilters: indexFiltersWithoutApplied,
  }

export type AdExamplesPageProps = {
  jobs: AdExamplesType
  initial: FilterStateAdExamples
  availableFiltersTemplates: AvailableFiltersTemplates
  availableFiltersGallery: AvailableFilters
  pageTitle: string | null
  metaLinks: MetaLinks
  pageNumber: number
}

export default function Templates(
  props: AdExamplesPageProps,
) {
  return (
    <GalleryAll
      galleryJobs={null}
      templatesJobs={null}
      adExamplesJobs={props.jobs}
      initialFiltersTemplates={null}
      initialFiltersGallery={null}
      availableFiltersTemplates={
        props.availableFiltersTemplates
      }
      availableFiltersGallery={
        props.availableFiltersGallery
      }
      initialFiltersAdExamples={props.initial}
      pageTitle={props.pageTitle}
      metaLinks={props.metaLinks}
      pageNumber={props.pageNumber}
      initialPage="ad-examples"
    />
  )
}

export const getServerSideProps = wrapSSRAuth(
  async (context, userInfo) => {
    if (!userInfo.isAnonymous) {
      throw new SSRTemporaryRedirect(
        "/discover?filter=ad-examples",
      )
    }

    let page = Number(context.query.page)

    if (isNaN(page) || page < 1) {
      page = 1
    }

    const prevNumber = page > 1 ? page - 1 : null

    const metaLinks: MetaLinks = {
      current: "/ad-examples?page=" + page,
      next: "/ad-examples?page=" + (page + 1),
      prev: prevNumber
        ? "/ad-examples?page=" + prevNumber
        : null,
    }

    const token = userInfo.accessToken

    const [
      jobs,
      availableFiltersTemplates,
      availableFiltersGallery,
    ] = await Promise.all([
      getAdExamplesSSR(token, indexFiltersAdExamples, page),
      getCategoriesForFilter(token),
      getInitialFilters(),
    ])

    const props: AdExamplesPageProps = {
      jobs,
      initial: indexFiltersAdExamples,
      availableFiltersTemplates,
      availableFiltersGallery,
      pageTitle: null,
      metaLinks,
      pageNumber: page,
    }

    return props
  },
  [],
)
