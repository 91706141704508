import {
  getSingleGalleryInfo,
  SingleGalleryInfo,
} from "@/ssr/gallery"
import { SSRNotFound, wrapSSRAuth } from "@/utils/ssr-auth"
import { GallerySingle } from "sections/gallery/single-gallery"

interface SinglePage {
  job: SingleGalleryInfo
}

export default function SingleGeneratedPage(
  props: SinglePage,
) {
  return <GallerySingle job={props.job} />
}

export function getFirstFiveWords(inputString: string) {
  const words = inputString.split(/\s+/)

  if (words && words.length >= 5) {
    const firstFiveWords = words.slice(0, 5).join(" ")
    return firstFiveWords
  } else {
    return inputString
  }
}

export const getServerSideProps = wrapSSRAuth(
  async (context, userInfo) => {
    const id = context.params?.id

    if (typeof id !== "string") {
      throw new SSRNotFound()
    }

    const props: SinglePage = {
      job: await getSingleGalleryInfo(
        id,
        userInfo.accessToken,
      ),
    }

    return props
  },
  [],
)
