import queryfy from "@/utils/queryfy"
import axios from "axios"
import { getAuth } from "firebase/auth"
import { useEffect, useMemo, useState } from "react"
import {
  QueryFunction,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "react-query"
import * as FeaturedTutorials from "types/endpoints/tutorial"
import {
  TutorialComment,
  TutorialGeneralComment,
} from "types/endpoints/tutorial-comments"

import { PaginatedPage } from "@/comps/advanced-profile"
import { HEADER_WEB_VERSION } from "@/utils/cdn"
import {
  Template,
  TutorialTemplate,
  TutorialTemplateCreator,
} from "../types/endpoints/tutorial"
import * as UserCreatedTutorials from "../types/endpoints/user-created-tutorials"

interface SinglePage {
  data: TutorialTemplate[]
  next: number | undefined
}

export type TemplateFetcher = () => Promise<SinglePage>

export type ScrollContextSerialized =
  | {
      page: "created"
      templates: TutorialTemplate[]
      uid: string
      creator: TutorialTemplateCreator
    }
  | {
      page: "liked"
      templates: TutorialTemplate[]
      uid: string
      creator: TutorialTemplateCreator
    }
  | { page: "featured"; templates: TutorialTemplate[] }

interface ScrollContext {
  id: string
  templates: TutorialTemplate[]

  fetcher(): QueryFunction<SinglePage>

  serialize(): ScrollContextSerialized
}

export const getGeneralNextPage = (
  length: number,
  page?: number
) => {
  const next = (page ?? 0) + length

  if (page === undefined) return length
  if (length) return next

  return undefined
}

const getNextPage = (
  templates: TutorialTemplate[],
  page?: number
) => {
  const next = (page ?? 0) + templates.length

  if (templates.length === templatesInPage) return next

  return undefined
}

export const getTemplates =
  (
    uid: string,
    field: "created" | "liked",
    creator: TutorialTemplateCreator
  ): QueryFunction<SinglePage> =>
  async ({ pageParam }) => {
    return axios
      .get<UserCreatedTutorials.Response>(
        `/api/user-${field}-tutorials` +
          queryfy({
            uid,
            offset: pageParam || 0,
            limit: templatesInPage,
          }),
        {
          headers: {
            Authorization:
              "Bearer " +
              (await getAuth().currentUser?.getIdToken()),
            "web-version": HEADER_WEB_VERSION,
          },
        }
      )
      .then((res) => {
        const withCreator: TutorialTemplate[] =
          res.data.templates.map((template) => ({
            ...template,
            creator,
          }))

        const context = getScrollContext()
        if (context) {
          context.templates.push(...withCreator)
          setScrollContext(context)
        }

        return {
          data: withCreator,
          next: getNextPage(withCreator, pageParam),
        }
      })
  }

export const getFeatured: QueryFunction<
  SinglePage
> = async ({ pageParam }) =>
  await axios
    .get<FeaturedTutorials.Response>(
      "/api/tutorial" +
        queryfy({
          region: "US",
          limit: templatesInPage,
          offset: pageParam ?? 0,
        }),
      {
        headers: {
          Authorization:
            "Bearer " +
            (await getAuth().currentUser?.getIdToken()),
        },
      }
    )
    .then((res) => {
      const context = getScrollContext()
      if (context) {
        context.templates.push(...res.data.templates)
        setScrollContext(context)
      }

      return {
        data: res.data.templates,
        next: getNextPage(res.data.templates, pageParam),
      }
    })

export class CreatedScrollContext implements ScrollContext {
  readonly id: string
  readonly templates: TutorialTemplate[]
  private readonly uid: string
  private readonly creator: TutorialTemplateCreator

  constructor(
    uid: string,
    templates: Template[],
    creator: TutorialTemplateCreator
  ) {
    this.id = "created/" + uid
    this.uid = uid
    this.templates = templates.map((template) => ({
      ...template,
      creator,
    }))
    this.creator = creator
  }

  fetcher() {
    return getTemplates(this.uid, "created", this.creator)
  }

  serialize() {
    return {
      page: "created",
      templates: this.templates,
      uid: this.uid,
      creator: this.creator,
    } as const
  }
}

export class LikedScrollContext implements ScrollContext {
  readonly id: string
  readonly templates: TutorialTemplate[]
  private readonly uid: string
  private readonly creator: TutorialTemplateCreator

  constructor(
    uid: string,
    templates: Template[],
    creator: TutorialTemplateCreator
  ) {
    this.id = "liked/" + uid
    this.uid = uid
    this.templates = templates.map((templates) => ({
      ...templates,
      creator,
    }))
    this.creator = creator
  }

  fetcher() {
    return getTemplates(this.uid, "liked", this.creator)
  }

  serialize() {
    return {
      page: "liked",
      templates: this.templates,
      uid: this.uid,
      creator: this.creator,
    } as const
  }
}

export class FeaturedScrollContext
  implements ScrollContext
{
  id: string
  templates: TutorialTemplate[]

  constructor(templates: TutorialTemplate[]) {
    this.id = "featured"
    this.templates = templates
  }

  fetcher() {
    return getFeatured
  }

  serialize() {
    return {
      page: "featured",
      templates: this.templates,
    } as const
  }
}

export type ScrollContextKey =
  | "created"
  | "liked"
  | "featured"

export const setScrollContext = (
  context: ScrollContext
) => {
  const serialized = JSON.stringify(context.serialize())
  sessionStorage.setItem("scroll", serialized)
}

export const getScrollContext =
  (): ScrollContext | null => {
    const context = sessionStorage.getItem("scroll")

    if (!context) return null

    let scrollContext = null
    try {
      scrollContext = JSON.parse(
        context
      ) as ScrollContextSerialized
    } catch (_) {
      return null
    }

    switch (scrollContext.page) {
      case "created":
        return new CreatedScrollContext(
          scrollContext.uid,
          scrollContext.templates,
          scrollContext.creator
        )
      case "liked":
        return new LikedScrollContext(
          scrollContext.uid,
          scrollContext.templates,
          scrollContext.creator
        )
      case "featured":
        return new FeaturedScrollContext(
          scrollContext.templates
        )
      default:
        return null
    }
  }

export const flattenAndFilter = <
  T extends TutorialTemplate
>(
  pages: PaginatedPage<T>[]
) =>
  flattenAndFilterArrays(
    pages,
    (page) => page.data,
    (data) => data.id
  )

export interface TutorialGeneralCommentPage {
  next?: number
  data: TutorialGeneralComment[]
}

export interface TutorialCommentPage {
  next?: number
  data: TutorialComment[]
}

export const flattenAndFilterComments = (
  pages: TutorialCommentPage[]
) =>
  flattenAndFilterArrays(
    pages,
    (page) => page.data,
    (data) => data.id
  )

export const flattenAndFilterGeneralComments = (
  pages: TutorialGeneralCommentPage[]
) =>
  flattenAndFilterArrays(
    pages,
    (page) => page.data,
    (data) => data.id
  )

export const flattenAndFilterArrays = <T, K, L>(
  pages: T[],
  extractor: (data: T) => K[],
  identity: (data: K) => L
): K[] => {
  const dataSet = new Set()
  const filteredArray = new Array<K>()

  for (const page of pages) {
    for (const data of extractor(page)) {
      if (!dataSet.has(identity(data))) {
        dataSet.add(identity(data))
        filteredArray.push(data)
      }
    }
  }

  return filteredArray
}

export interface PaginatedTemplates {
  data: TutorialTemplate[]
  next?: number
}

const templatesInPage = 12

export type ScrollDataResult =
  | { ready: false; data: undefined }
  | { ready: true; data: ScrollContext | null }

export const useScrollData = (): ScrollDataResult => {
  const [result, setResult] = useState<ScrollDataResult>({
    ready: false,
    data: undefined,
  })

  useEffect(() => {
    if (result.ready) return

    const context = getScrollContext()
    const contextState: ScrollDataResult = {
      ready: true,
      data: context,
    }
    setResult(contextState)
  }, [result.ready])

  return result
}

interface UseScrollContextResult {
  query: UseInfiniteQueryResult<SinglePage>
  templates: FeaturedTutorials.TutorialTemplate[]
}

export const useScrollContext = (
  props: ScrollDataResult
): UseScrollContextResult => {
  const { ready, data } = props || {}

  const enabled = ready && Boolean(data)

  const initialTemplates = data?.templates ?? []

  const query = useInfiniteQuery({
    queryFn: data?.fetcher(),
    queryKey: data?.id,
    enabled,
    initialData: ready
      ? {
          pages: [
            {
              data: initialTemplates,
              next: initialTemplates.length,
            },
          ],
          pageParams: [initialTemplates.length],
        }
      : undefined,
    staleTime: Infinity,
    getNextPageParam: (page) => page.next,
  })

  const templates = useMemo(() => {
    const pages = query.data?.pages ?? []
    return flattenAndFilter(pages)
  }, [query.data?.pages])

  return { query, templates }
}

export type ScrollReducerState =
  | {
      status: "single"
      template: TutorialTemplate
    }
  | {
      status: "multiple"
      page: TutorialTemplate[]
      active: TutorialTemplate
      scrolled: boolean
    }
  | {
      status: "loading"
    }

export type ScrollReducerAction =
  | {
      name: "context"
      context: ScrollContext
      template: TutorialTemplate
    }
  | {
      name: "single"
      template: TutorialTemplate
    }
  | {
      name: "view"
      active: TutorialTemplate
    }
  | {
      name: "scrolled"
    }
  | {
      name: "load"
      page: TutorialTemplate[]
    }

export const scrollReducer = (
  state: ScrollReducerState,
  action: ScrollReducerAction
): ScrollReducerState => {
  switch (action.name) {
    case "single":
      return {
        status: "single",
        template: action.template,
      }

    case "context":
      return {
        status: "multiple",
        page: action.context.templates,
        scrolled: false,
        active: action.template,
      }

    case "view":
      if (state.status !== "multiple") {
        console.error("Cant view")
        return state
      }

      return {
        ...state,
        active: action.active,
      }

    case "scrolled":
      if (state.status !== "multiple") {
        console.error("How did you scroll single template?")
        return state
      }
      return { ...state, scrolled: true }

    case "load":
      if (state.status !== "multiple") {
        console.error("Loaded with status = single")
        return state
      }

      return {
        ...state,
        page: action.page,
      }
  }
}
