import { devices } from "@/utils/breakpoints"
import getLetterSpacing from "@/utils/spacing"
import { twMerge } from "tailwind-merge"

import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { ReactNode } from "react"
import styled, { css, keyframes } from "styled-components"

type ButtonProps = {
  text: string
  title?: string
  href?: string
  small?: boolean
  primary?: boolean
  secondary?: boolean
  outlineSecondary?: boolean
  outline?: boolean
  disabled?: boolean
  full?: boolean
  loading?: boolean
  onClick?: () => void
  className?: string
  type?: "button" | "submit" | "reset"
}

type StyledButtonProps = {
  primary?: boolean
  secondary?: boolean
  outline?: boolean
  outlineSecondary?: boolean
  small?: boolean
  full?: boolean
  disabled?: boolean
}

const Styled = styled.button<StyledButtonProps>`
  ${(props) =>
    props.small
      ? css`
          padding: 6px 16px;
          border-radius: 6px;

          font-size: 15px;
          line-height: 20px;
          letter-spacing: ${getLetterSpacing("16")};
        `
      : css`
          min-width: 200px;

          padding: 14px 34px;
          border-radius: 6px;

          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.03em;
        `}

  ${(props) =>
    props.full &&
    css`
      max-width: 400px;
      width: calc(var(--w-screen) - 32px);

      @media ${devices.tablet} {
        width: unset;
      }
    `}

  user-select: none;
  flex-shrink: 0;
  font-weight: 700;
  transition: color 300ms, background-color 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 6;
  text-align: center;

  ${(props) =>
    props.disabled
      ? css`
          background-color: var(
            --color-primary-100
          ) !important;
          cursor: not-allowed;
          color: var(--color-inactive-text) !important;

          &:hover {
            background-color: var(
              --color-primary-100
            ) !important;
          }
        `
      : css`
          cursor: pointer;
        `}

  ${(props) =>
    props.primary
      ? css`
          color: var(--color-white);
          background-color: var(--color-primary-500);

          &:hover {
            background-color: var(--color-primary-600);
          }
        `
      : props.outline
      ? css`
          color: var(--color-primary-700);
          border-width: 1px;
          border-color: var(--color-primary-400);

          &:hover {
            background-color: var(--color-primary-50);
          }
        `
      : props.secondary
      ? css`
          color: var(--color-blue-50);
          background-color: var(--color-green-600);

          &:hover {
            background-color: var(--color-green-700);
          }
        `
      : props.outlineSecondary
      ? css`
          color: var(--color-green-600);
          border-width: 1px;
          border-color: var(--color-green-400);

          &:hover {
            background-color: var(--color-green-50);
          }
        `
      : css`
          color: var(--color-primary-800);
          background-color: var(--color-primary-100);

          &:hover {
            background-color: var(--color-primary-200);
          }
        `};
`

const Loading = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const Loader = styled.img<{ show: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0 12px;
  animation: ${rotate} 800ms infinite linear;

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `};
`

export function RawButton(props: ButtonProps) {
  const {
    text,
    small,
    primary,
    full,
    href,
    onClick,
    className,
    disabled,
    loading,
    ...other
  } = props

  return (
    <Styled
      {...other}
      as={href ? "a" : "button"}
      href={href}
      disabled={disabled}
      primary={primary}
      small={small}
      full={full}
      onClick={onClick}
      className={className}>
      {text}
      <Loading>
        <Loader
          src={assetUrl("/comps/button-loader.webp")}
          show={loading ?? false}
        />
      </Loading>
    </Styled>
  )
}

function Button(props: ButtonProps) {
  const { t } = useTranslation()
  return <RawButton {...props} text={t(props.text)} />
}

const allButtonStyles = {
  primary: clsx(
    "bg-primary-500 text-color-white disabled:opacity-40 disabled:pointer-events-none",
    "hover:bg-primary-600 select-none",
    "transition-colors",
  ),
  empty: clsx(
    "bg-color-cell text-blue-700 hover:text-blue-900",
    "transition-colors",
  ),
}

const allButtonSizes = {
  regular:
    "text-base font-600 min-w-[232px] py-3 rounded-[10px] text-center",
}

const defaultButtonSize: ButtonSizes = "regular"
const defaultButtonStyle: ButtonStyle = "primary"

export type ButtonStyle = keyof typeof allButtonStyles
export type ButtonSizes = keyof typeof allButtonSizes
export type ConditionalButtonPropsState<T> =
  | {
      disabled?: undefined
      onClick?: () => void
    }
  | {
      /**
       * If this function returns null then the button is disabled
       */
      disabled: () => T | null
      /**
       * Whatever returns `disabled` function is passed to onClick
       */
      onClick?: (context: T) => void
    }

export type ConditionalButtonProps<T> =
  ConditionalButtonPropsState<T> & {
    className?: string
    children?: ReactNode
    style?: ButtonStyle
    size?: ButtonSizes
  }

export type ConditionalAnchorProps<T> =
  ConditionalButtonProps<T> & {
    href: string
    download?: boolean
  }

export function ConditionalAnchor<T extends object>(
  props: ConditionalAnchorProps<T>,
) {
  const {
    disabled,
    onClick,
    className,
    children,
    style,
    size,
    href,
    download,
  } = props

  const value = disabled && disabled()

  return (
    <a
      className={twMerge(
        allButtonSizes[size ?? defaultButtonSize],
        allButtonStyles[style ?? defaultButtonStyle],
        value === null && "pointer-events-none",
        className,
      )}
      onClick={() => {
        if (value) {
          onClick?.(value)
          return
        }

        if (!disabled) {
          onClick?.()
        }
      }}
      download={download}
      href={href}>
      {children}
    </a>
  )
}

export function ConditionalButton<T extends object>(
  props: ConditionalButtonProps<T>,
) {
  const {
    disabled,
    onClick,
    className,
    children,
    style,
    size,
  } = props

  const value = disabled && disabled()

  return (
    <button
      className={twMerge(
        allButtonSizes[size ?? defaultButtonSize],
        allButtonStyles[style ?? defaultButtonStyle],
        className,
      )}
      disabled={value === null}
      onClick={() => {
        if (value) {
          onClick?.(value)
          return
        }

        if (!disabled) {
          onClick?.()
        }
      }}>
      {children}
    </button>
  )
}

interface NewButtonProps {
  text?: string
  variant?: VariantTypes
  onClick: () => void
  disabled?: boolean
  className?: string
  children?: ReactNode
}

type VariantTypes =
  | "default"
  | "primary"
  | "secondary"
  | "secondaryBlue"
  | "outline"

const variantsMap: Record<VariantTypes, string> = {
  default: "",
  secondary: clsx(
    "bg-primary-100 text-primary-800 disabled:opacity-70",
    "hover:bg-primary-200 select-none transition-colors disabled:text-primary-800",
  ),
  secondaryBlue: clsx(
    "bg-green-600 text-color-white disabled:opacity-70",
    "hover:bg-green-700 select-none transition-colors disabled:text-color-white",
    "hover:text-color-white",
  ),
  primary: clsx(
    "bg-primary-500 text-color-white disabled:opacity-40",
    "hover:bg-primary-600 select-none transition-colors",
  ),
  outline: clsx(
    "bg-color-cell text-blue-600 disabled:opacity-40 border",
    "hover:bg-blue-100 select-none transition-colors border-color-separator",
    "flex-row-reverse",
  ),
}

export function NewButton(props: NewButtonProps) {
  const {
    children,
    text,
    className,
    disabled,
    variant,
    onClick,
  } = props

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        "h-[44px] w-full rounded-[6px] text-[16px] font-600 disabled:pointer-events-none",
        "flex items-center justify-center gap-[8px]",
        variantsMap[variant ?? "default"],
        className,
      )}>
      {children} {text}
    </button>
  )
}

export default Button
