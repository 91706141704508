export const getFormattedDate = (time: number) => {
  const deadline = new Date(time)
  const year = deadline.toLocaleString(undefined, {
    year: "numeric",
  })
  const month = deadline.toLocaleString(undefined, {
    month: "long",
  })
  const day = deadline.toLocaleString(undefined, {
    day: "numeric",
  })
  return `${day} ${month}, ${year}`
}

export function dateToSortableString(date: Date): string {
	return JSON.stringify(date).slice(1, -1)
}

export function formatDate(dateStr: string, difference?: boolean): string {
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const date = new Date(dateStr)

  const day: number = date.getDate()
  const month: string = months[date.getMonth()]
  const year: number = date.getFullYear()

  const currentDate: Date = new Date()
  const timeDiff: number = Math.abs(date.getTime() - currentDate.getTime())
  const diffDays: number = Math.ceil(timeDiff / (1000 * 3600 * 24))

  let dateString = `${day} ${month} ${year}`

  if (date.getTime() > currentDate.getTime() && difference) {
    dateString += ` (${diffDays} days)`
  }

  return dateString
}
