import { getAnalytics, logEvent } from "firebase/analytics"
import { useRouter } from "next/router"
import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useQueryClient } from "react-query"
import { v4 } from "uuid"
import { z } from "zod"
import { appStoreConstant } from "./branch"
import useAuth, { forceRefreshToken } from "./client-auth"
import "./firebase"
import type { WithChildren } from "./types"

const FACEBOOK_PIXEL_TOKEN = "303692382241884"

type EventId =
	| "appstore_icons"
	| "become_creator"
	| "complete_registration"
	| "contact"
	| "deform_ai"
	| "deform_ai_generate"
	| "deform_ai_prompt_writing"
	| "deform_ai_select_style"
	| "deform_ai_subscribe_to_continue"
	| "deform_ai_upload_media"
	| "deform_ai_video_save"
	| "initiate_checkout"
	| "page_view"
	| "pricing_page_view"
	| "dp_start_free"
	| "view_content"
	| "monthly_subscribe"
	| "yearly_subscribe"
	| "non_subscription_purchase"
	| "ai_art_view"
	| "ai_art_like"
	| "w_dp_start_now"
	| "w_do_sign_in"
	| "w_dp_sign_in"
	| "w_did_sign_in"
	| "w_dp_sign_up"
	| "w_did_sign_up"
	| "w_dp_forgot_password"
	| "w_dp_send_password_reset_link"
	| "w_did_finish_quiz"
	| "w_dp_onboarding_skip"
	| "w_onboarding_success"
	| "w_did_log_out"
	| "w_dp_dashboard"
	| "w_dp_cs"
	| "w_dp_upgrade"
	| "w_upgrade_success"
	| "w_upgrade_canceled"

export const paymentSessionDataSchema = z.object({
	session: z.string(),
	currency: z.string(),
	value: z.number(),
})

export const PAYMENT_DATA_KEY = "payment_data"

export type PaymentSessionData = z.infer<
	typeof paymentSessionDataSchema
>

export function UsePageViewEvent() {
	const logAdjust = useAdjustLogger()

	const id = useMemo(() => v4(), [])

	const router = useRouter()

	const queryClient = useQueryClient()

	const { userInfo, setUserInfo } = useAuth()

	// useEffect(() => {
	//   if (logAdjust === null) return

	//   logAdjust.logEventWithPayload(
	//     "page_view",
	//     { from: router.asPath },
	//     id,
	//   )
	// }, [logAdjust, id, router.asPath])

	useEffect(() => {
		if (!router.isReady) return

		if (!logAdjust) return

		if (!userInfo) return

		const handleRouteChange = () => {
			logAdjust.logEvent("view_content")
		}

		if (
			router.query.monthly === "success" ||
			router.query.yearly === "success"
		) {
			const isMonthly = router.query.monthly === "success"

			try {
				const purchaseData = localStorage.getItem(
					PAYMENT_DATA_KEY,
				)

				if (!purchaseData) {
					return
				}

				const { session, currency, value } =
					paymentSessionDataSchema.parse(
						JSON.parse(purchaseData),
					)

				if (session !== router.query.session) {
					return
				}

				forceRefreshToken()
					.then((userInfo) => setUserInfo(userInfo))
					.catch((error) =>
						console.error("Could not get new token", error),
					)

				logAdjust.logEventWithPayload("w_upgrade_success", {
					from: router.asPath,
				})
				logAdjust.registerSubscription(
					value,
					currency,
					isMonthly ? "monthly" : "yearly",
				)

				localStorage.removeItem(PAYMENT_DATA_KEY)
			} catch (e) {
				console.error(e)
			}
		} else if (
			router.query.monthly === "cancel" ||
			router.query.yearly === "cancel"
		) {
			logAdjust.logEventWithPayload("w_upgrade_canceled", {
				from: router.asPath,
			})
		}

		if (router.query.coins === "success") {
			queryClient.invalidateQueries("coins")

			try {
				const purchaseData = localStorage.getItem(
					PAYMENT_DATA_KEY,
				)

				if (!purchaseData) {
					return
				}

				const { session, currency, value } =
					paymentSessionDataSchema.parse(
						JSON.parse(purchaseData),
					)

				if (session !== router.query.session) {
					return
				}

				logAdjust.logEvent("non_subscription_purchase")
				logAdjust.registerPurchase(value, currency)
				logAdjust.logEvent("non_subscription_purchase")

				localStorage.removeItem(PAYMENT_DATA_KEY)
			} catch (e) {
				console.error(e)
			}
		}

		router.events.on(
			"routeChangeComplete",
			handleRouteChange,
		)
		return () =>
			router.events.off(
				"routeChangeComplete",
				handleRouteChange,
			)
	}, [
		id,
		logAdjust,
		queryClient,
		router,
		setUserInfo,
		userInfo,
	])

	useEffect(() => {
		if (!logAdjust) return

		const elements = [
			...document.querySelectorAll(
				`a[href^="${appStoreConstant}"]`,
			),
		]

		const handleAppStoreClick = () => {
			logAdjust?.logEvent("appstore_icons")
		}

		elements.forEach((element) =>
			element.addEventListener(
				"click",
				handleAppStoreClick,
			),
		)

		return () =>
			elements.forEach((element) =>
				element.removeEventListener(
					"click",
					handleAppStoreClick,
				),
			)
	}, [id, logAdjust])

	return <></>
}

interface AdjustWrapper {
	logEvent: (logEvent: EventId) => Promise<void>
	logEventWithPayload: (
		logEvent: EventId,
		payload: Record<string, string | null>,
	) => Promise<void>
	registerPurchase: (
		price: number,
		currenct: string,
	) => void
	registerSubscription: (
		price: number,
		currenct: string,
		subscription: keyof typeof subscriptionMap,
	) => void
}

const subscriptionMap = {
	monthly: {
		ltv: 1.8,
		event: "StartTrial",
	},
	yearly: {
		ltv: 1.3,
		event: "Subscribe",
	},
}

const AdjustContext = createContext<AdjustWrapper | null>(
	null,
)

export function AdjustProvider({ children }: WithChildren) {
	const [adjust, setAdjust] =
		useState<AdjustWrapper | null>(null)

	useEffect(() => {
		const firebaseAnalytics = getAnalytics()
		Promise.all([
			import("react-facebook-pixel"),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			import("@analytics/google-tag-manager"),
			import("analytics"),
		]).then(
			([importedPixel, googleTagManager, Analytics]) => {
				const googleTagManagerWrapper = Analytics.default({
					app: "Zoomerang",
					plugins: [
						googleTagManager.default({
							containerId: "GTM-T34Q5RX3",
						}),
					],
				})

				const isDebug =
					process.env.NEXT_PUBLIC_DEBUG === "true"
				importedPixel.default.init(
					FACEBOOK_PIXEL_TOKEN,
					undefined,
					{ debug: isDebug, autoConfig: true },
				)

				async function logEventWithPayload(
					event: EventId,
					payload?: Record<string, string | null>,
				) {
					try {
						importedPixel.default.trackCustom(
							event,
							payload,
						)
						googleTagManagerWrapper.track(event, payload)
						logEvent(
							firebaseAnalytics,
							String(event),
							payload,
						)
					} catch (error) {
						console.error("Analytics Error", error)
					}
				}

				setAdjust({
					registerSubscription: function (
						value,
						currency,
						subscription,
					) {
						const { ltv, event } =
							subscriptionMap[subscription]

						try {
							importedPixel.default.track(event, {
								value,
								currency,
								predicted_ltv: ltv,
							})
							googleTagManagerWrapper.track(
								subscription + "_subscribe",
								{ value, currency, predicted_ltv: ltv },
							)
						} catch (e) {
							console.error(e)
						}
					},
					registerPurchase: function (value, currency) {
						try {
							importedPixel.default.track("Purchase", {
								value,
								currency,
							})
							googleTagManagerWrapper.track(
								"non_subscription_purchase",
								{ value, currency },
							)
						} catch (e) {
							console.error(e)
						}
					},
					logEvent: async function (event) {
						logEventWithPayload(event, undefined)
					},
					logEventWithPayload,
				})
			},
		)
	}, [])

	return (
		<AdjustContext.Provider value={adjust}>
			{children}
		</AdjustContext.Provider>
	)
}

export function useAdjustLogger(): AdjustWrapper | null {
	return useContext(AdjustContext)
}
