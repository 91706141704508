type VerticalPosition = { top: number } | { bottom: number }
type HorizontalPosition = { left: number } | { right: number }

type FloatingProps = {
	children: React.ReactNode
	position: VerticalPosition & HorizontalPosition
	className?: string
	z?: number
	none?: boolean
}

const Floating = (props: FloatingProps) => {
	const { position, className, children, z, none } = props
	return (
		<div
			style={{
				position: "absolute",
				width: "100%",
				zIndex: z,
				display: none ? "none" : "block",
				...position,
			}}
			className={className}>
			{children}
		</div>
	)
}

type RelativeProps = {
	children: React.ReactNode
}

const Relative = (props: RelativeProps) => {
	return (
		<div
			style={{
				position: "relative",
				overflow: "visible",
			}}>
			{props.children}
		</div>
	)
}

export { Relative }

export default Floating
