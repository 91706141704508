import { PlayWithProvider, Timeline } from "@/comps/player"
import { useProgressive } from "@/comps/progressive"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useRouter } from "next/router"
import { getFirstFiveWords } from "pages/ai-gallery/single/[id]"
import { useRef, useState } from "react"
import { VideoProvider } from "video-provider"
import { HistoryPopupProps } from "./history-popup"

interface SingleVideoProps {
  previewImage: string
  video: string
  thumb?: string
  aspectRatio?: number
  prompt: string
  style: string
}

interface SingleImageProps {
  image: string
  thumb?: string
  aspectRatio?: number
  previewImage: string
  prompt: string
  style: string
}

interface MultipleImagesProps {
  images: string[]
  aspectRatio?: number
  previewImage: string
  offset: number
  setOffset: (v: number) => void
  customPrompt: string
}

export function RenderContent(
  props: HistoryPopupProps & {
    offset: number
    setOffset: (v: number) => void
    viewFor: "gallery" | "history"
  },
): JSX.Element {
  const {
    imageOrVideo,
    notShallow,
    onClose,
    previewImage,
    closeLink,
    promptName,
    offset,
    setOffset,
    thumb,
    customPrompt,
    aspectRatio,
    viewFor,
  } = props

  const isVideo = imageOrVideo.includes(".mp4")
  const router = useRouter()

  return (
    <div className="relative">
      <div
        className={clsx(
          viewFor === "history" ? "h-[100svh]" : "",
          "w-screen",
          "overflow-hidden bg-color-popup tablet:h-[667px] tablet:w-full tablet:shrink-0 tablet:rounded-xl",
          "desktop:h-[857px] desktop:w-[775px] desktop:rounded-r-none desktop:px-[113px] desktop:py-[110px]",
          "group relative flex items-center justify-center",
        )}>
        <div
          className={clsx(
            "absolute left-0 top-0 z-10 h-full w-full overflow-hidden bg-color-black filter",
            "overflow-hidden tablet:rounded-xl desktop:h-[857px] desktop:w-[775px] desktop:rounded-r-none",
          )}>
          <img
            src={previewImage ?? undefined}
            alt="preview image"
            className="absolute h-full w-full scale-110 object-cover opacity-30 blur-lg"
          />
        </div>
        {typeof imageOrVideo === "string" ? (
          isVideo ? (
            <SingleVideo
              previewImage={previewImage ?? ""}
              video={imageOrVideo}
              thumb={thumb}
              aspectRatio={aspectRatio}
              prompt={customPrompt}
              style={promptName}
            />
          ) : (
            <SingleImage
              image={imageOrVideo}
              thumb={thumb}
              aspectRatio={aspectRatio}
              previewImage={previewImage}
              prompt={customPrompt}
              style={promptName}
            />
          )
        ) : (
          <MultipleImages
            images={imageOrVideo}
            aspectRatio={aspectRatio}
            offset={offset}
            setOffset={setOffset}
            previewImage={previewImage}
            customPrompt={customPrompt}
          />
        )}
        <button
          className={clsx(
            "rounded-full bg-color-white/25 p-2",
            "fixed left-[17px] top-[17px] z-[101] desktop:hidden",
          )}
          onClick={() => {
            if (!notShallow) {
              router.push(
                closeLink
                  ? `${closeLink}`
                  : `/profile/generations/all`,
                undefined,
                {
                  shallow: true,
                },
              )
            }
            onClose()
          }}>
          <img
            src={assetUrl("/comps/close-icon.svg")}
            alt="close icon"
          />
        </button>
      </div>

      <button
        className={clsx(
          "absolute hidden desktop:flex",
          "right-[-480px] top-0 rounded-full bg-color-cell hover:bg-blue-100",
          "h-[32px] w-[32px] items-center justify-center",
        )}
        onClick={() => {
          if (!notShallow) {
            router.push(
              closeLink
                ? `${closeLink}`
                : `/profile/generations/all`,
              undefined,
              {
                shallow: true,
              },
            )
          }
          onClose()
        }}>
        <img
          src={assetUrl("/comps/close-dark.svg")}
          alt="close icon"
        />
      </button>
    </div>
  )
}

function SingleImage(props: SingleImageProps) {
  const { image, aspectRatio } = props

  return (
    <div
      style={{
        aspectRatio: aspectRatio,
      }}
      className={clsx(
        "relative z-40",
        "overflow-hidden tablet:rounded-[19px]",
        "tablet:max-h-[533px] tablet:max-w-[500px]",
        "desktop:max-h-[700px] desktop:max-w-[525px]",
      )}>
      <img
        src={image}
        alt={props.style}
        className={clsx(
          "tablet:max-h-[533px] tablet:rounded-[19px] desktop:max-h-[700px]",
        )}
      />
    </div>
  )
}

function SingleVideo(props: SingleVideoProps) {
  const { video, aspectRatio } = props

  const videoRef = useRef<HTMLVideoElement>(null)

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  })

  const handleVideoLoadedMetadata = () => {
    if (videoRef.current) {
      const { videoWidth, videoHeight } = videoRef.current
      setVideoDimensions({
        width: videoWidth,
        height: videoHeight,
      })
    }
  }

  return (
    <div
      style={{
        aspectRatio:
          aspectRatio ??
          `${videoDimensions.width}/${videoDimensions.height}`,
      }}
      className={clsx(
        "relative z-40",
        "max-h-full overflow-hidden tablet:max-h-[533px] tablet:max-w-[322px] tablet:rounded-[19px] desktop:max-h-[700px] desktop:max-w-[525px]",
      )}>
      <video
        loop
        autoPlay
        playsInline
        preload="metadata"
        src={video}
        ref={videoRef}
        onLoadedMetadata={handleVideoLoadedMetadata}
        className={clsx("h-full tablet:rounded-[19px]")}
      />
      <VideoProvider remember={[]} element={videoRef}>
        <div className="absolute bottom-0 left-0 z-50 w-full text-color-white">
          <Timeline />
        </div>
        <PlayWithProvider />
      </VideoProvider>
    </div>
  )
}

function MultipleImages(props: MultipleImagesProps) {
  const {
    images,
    offset,
    setOffset,
    aspectRatio,
    previewImage,
    customPrompt,
  } = props
  const desktopRef = useRef<HTMLDivElement>(null)
  const isImageReady = useProgressive(images[0])

  function scrollToImage(index: number) {
    const nextImage = desktopRef.current
      ?.getElementsByClassName(
        `ai-art-generation-${images}-${index}`,
      )
      .item(0)

    if (!nextImage) {
      return
    }
    const parent = nextImage.parentElement
    if (!parent) {
      return
    }
    const childX = nextImage.getBoundingClientRect().x
    const parentX = parent.getBoundingClientRect().x
    const childOffset = childX - parentX
    parent.scrollBy({
      behavior: "smooth",
      left: childOffset,
    })
  }

  return (
    <div
      style={{ aspectRatio }}
      className={clsx(
        "relative z-50 flex items-center justify-center tablet:max-h-[500px] desktop:max-h-[700px]",
      )}>
      <div
        onScroll={(event) => {
          const container = event.target
          if (container instanceof HTMLElement) {
            const snapIndex = Math.floor(
              container.scrollLeft / container.offsetWidth,
            )

            setOffset(snapIndex)
          }
        }}
        ref={desktopRef}
        className={clsx(
          "no-scrollbar flex h-full snap-x snap-mandatory gap-12 overflow-x-scroll transition-all",
          "tablet:rounded-xl",
        )}>
        {images.map((thumb, index) => (
          <img
            key={thumb}
            src={isImageReady ? thumb : previewImage}
            alt={getFirstFiveWords(customPrompt)}
            className={clsx(
              "w-full shrink-0 snap-center tablet:rounded-xl desktop:w-[525px]",
              `ai-art-generation-${images}-${index}`,
            )}
          />
        ))}
      </div>
      {/*BOOKMARK*/}
      {images.length > 1 && (
        <div
          className={clsx(
            "flex flex-row gap-[5px] rounded-[40px] bg-[#00000066] px-[10px] py-1 opacity-100 transition-all",
            "absolute bottom-2 left-1/2 -translate-x-1/2",
          )}>
          {images.map((_, index) => (
            <div
              key={index}
              onClick={() => scrollToImage(index)}
              className={clsx(
                "h-[6px] w-[6px] cursor-pointer rounded-full transition-all",
                offset === index
                  ? "scale-[1.4] bg-primary-500"
                  : "scale-100 bg-color-white",
              )}></div>
          ))}
        </div>
      )}

      {/*LEFT_RIGHT_BUTTONS */}
      {images.length > 1 && (
        <>
          <button
            disabled={offset === 0}
            onClick={() => {
              scrollToImage(offset - 1)
            }}
            className={clsx(
              "absolute left-2 top-[calc(50%-12px)] flex h-6 w-6 items-center justify-center rounded-full",
              "border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all hover:border-color-white/60 disabled:border-[transparent]",
              "hover:opacity-0 group-hover:opacity-100",
              "z-[999] desktop:-left-[73px]",
            )}>
            <img
              src={assetUrl(
                "/ai-tools/history/arrow-white.svg",
              )}
              alt="arrow"
            />
          </button>
          <button
            disabled={offset === images.length - 1}
            onClick={() => {
              scrollToImage(offset + 1)
            }}
            className={clsx(
              "absolute right-2 top-[calc(50%-12px)] flex h-6 w-6 items-center justify-center rounded-full",
              " border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all",
              " hover:border-color-white/60 disabled:border-[transparent]",
              "hover:opacity-0 group-hover:opacity-100",
              "z-[999] desktop:-right-[73px]",
            )}>
            <img
              src={assetUrl(
                "/ai-tools/history/arrow-white.svg",
              )}
              alt="arrow"
              className="rotate-180"
            />
          </button>
        </>
      )}
    </div>
  )
}
