import { Device, useDeviceSize } from "@/utils/breakpoints"
import {
	ReactNode,
	RefObject,
	useEffect,
	useState,
} from "react"
import { animated, config, useSpring } from "react-spring"
import styled, { css } from "styled-components"

interface HoverPopupProps {
	children: ReactNode
	sibling: RefObject<HTMLDivElement>
	floating: boolean
	base: boolean
	setBase: (value: boolean) => void
}

const Optional = styled.div<{ hidden: boolean }>`
	${(props) =>
		props.hidden &&
		css`
			display: none;
		`};
	
	width: 0;
	height: 0;
`

const HoverPopup = (props: HoverPopupProps) => {
	const { sibling, children, floating, base, setBase } = props

	const [hidden, setHidden] = useState(true)

	if (hidden && (base || floating)) setHidden(false)

	const device = useDeviceSize()

	useEffect(() => {
		const desktop = device === Device.Desktop
		const onMouseEnter = () => desktop && setBase(true)
		const onMouseLeave = () => setBase(false)

		const elem = sibling.current
		if (elem === null) return
		elem.addEventListener("mouseenter", onMouseEnter)
		elem.addEventListener("mouseleave", onMouseLeave)

		return () => {
			elem.removeEventListener("mouseenter", onMouseEnter)
			elem.removeEventListener("mouseleave", onMouseLeave)
		}
	}, [sibling, device, setBase])

	const trigger = base || floating

	const style = useSpring({
		display: "inline-block",
		transform: trigger ? "scale(1)" : "scale(0.8)",
		opacity: trigger ? 1 : 0,
		onRest: () => setHidden(true),
		config: config.gentle,
	})

	return (
		<Optional hidden={hidden}>
			<animated.div
				style={style}>
				{children}
			</animated.div>
		</Optional>
	)
}

export default  HoverPopup
