import { useEffect } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

interface MarkdownProps {
  markdown: string
}

export function Markdown(props: MarkdownProps) {
  const { markdown } = props

  useEffect(() => {
    const markdownElements =
      document.getElementsByClassName("markdown-wizard")

    const links: HTMLAnchorElement[] = []
    for (let j = 0; j < markdownElements.length; j++) {
      const anchorElements =
        markdownElements[j].getElementsByTagName("a")
      for (let k = 0; k < anchorElements.length; k++) {
        links.push(anchorElements[k])
      }
    }

    links.forEach((link) => {
      link.setAttribute("target", "_blank")
    })
  }, [])

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={"markdown-wizard"}>
      {markdown}
    </ReactMarkdown>
  )
}
