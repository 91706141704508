import styled, { keyframes } from "styled-components"

const rotateAnimation = keyframes`
	0% {
		transform: rotate(180deg);
	}
	50% {
		transform: rotate(540deg);
	}
	100% {
		transform: rotate(540deg);
	}
`

const fillAnimation = keyframes`
	0% {
		height: 70px;
	}

	50% {
		height: 70px;
	}
	
	51% {
		height: 0;
	}

	100% {
		height: 70px;
	}
`

const disappearAnimation = keyframes`
	0% {
		stop-opacity: 1;
	}
	
	51% {
		stop-opacity: 0;
	}
	
	52% {
		stop-opacity: 1;
	}
`


const StyledSvg = styled.svg`
	--color-fill: var(--color-blue-100);
	
	aspect-ratio: 1;
	
	animation-name: ${rotateAnimation};
	animation-duration: 2000ms;
	animation-direction: normal;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;

	#clip-animated {
		animation-name: ${fillAnimation};
		animation-duration: 2000ms;
		animation-direction: normal;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
	}

	#gradient-animated {
		stop {
			animation-name: ${disappearAnimation};
			animation-duration: 2000ms;
			animation-direction: normal;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}
	}
`

export const Loader = () => {
	return (
		<StyledSvg
			width="70"
			height="70"
			viewBox="0 0 70 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4091_172398)">
				<g clipPath="url(#clip1_4091_172398)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M53.7645 20.3923C53.5484 25.0029 47.6114 28.9815 41.871 32.8336C40.7908 33.5514 39.4945 34.4255 38.1766 35.3768C36.4029 36.6622 34.5989 38.0656 33.2983 39.4218C31.0319 41.7785 28.8217 44.463 28.8217 49.0329C28.8217 53.7464 30.9822 57.0779 35.2492 61.9478C36.2451 63.0811 37.1677 64.0902 37.9779 64.9644C39.8834 67.034 41.6831 68.9922 41.3093 69.5428C41.3026 69.5556 41.2939 69.5672 41.2834 69.5771C41.1646 69.6178 40.067 69.8578 35.2686 67.0404C32.3066 65.3029 29.1609 63.1368 27.2748 61.8406L26.6267 61.4121L26.0109 60.9836C22.6168 58.6719 16.3288 54.0613 16.3288 49.6699C16.3288 45.2785 20.2705 42.3527 25.7819 38.5091C26.5208 37.9949 27.2943 37.4635 28.0656 36.9043L28.4977 36.5937C29.7529 35.6981 31.0902 34.7554 32.3865 33.7506C36.9387 30.2455 41.1862 25.9884 41.1862 20.4051C41.1862 14.0977 35.5387 8.23371 31.4186 3.9509C30.7705 3.2803 30.1612 2.64399 29.6146 2.05266C29.3073 1.73242 29.0383 1.37798 28.8131 0.99642C28.6338 0.694331 28.5668 0.409381 28.6986 0.218701C28.7374 0.191487 28.783 0.175201 28.8304 0.171566C28.9152 0.156544 29.0013 0.149371 29.0875 0.150141C31.8831 0.150141 40.7541 6.26477 43.7204 8.31512L43.8997 8.43939C48.7867 11.8095 53.9784 15.7881 53.7645 20.3923Z"
						fill="var(--color-fill)"
					/>
				</g>
				<g clipPath="url(#clip2_4091_172398)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M53.7645 20.3923C53.5484 25.0029 47.6114 28.9815 41.871 32.8336C40.7908 33.5514 39.4945 34.4255 38.1766 35.3768C36.4029 36.6622 34.5989 38.0656 33.2983 39.4218C31.0319 41.7785 28.8217 44.463 28.8217 49.0329C28.8217 53.7464 30.9822 57.0779 35.2492 61.9478C36.2451 63.0811 37.1677 64.0902 37.9779 64.9644C39.8834 67.034 41.6831 68.9922 41.3093 69.5428C41.3026 69.5556 41.2939 69.5672 41.2834 69.5771C41.1646 69.6178 40.067 69.8578 35.2686 67.0404C32.3066 65.3029 29.1609 63.1368 27.2748 61.8406L26.6267 61.4121L26.0109 60.9836C22.6168 58.6719 16.3288 54.0613 16.3288 49.6699C16.3288 45.2785 20.2705 42.3527 25.7819 38.5091C26.5208 37.9949 27.2943 37.4635 28.0656 36.9043L28.4977 36.5937C29.7529 35.6981 31.0902 34.7554 32.3865 33.7506C36.9387 30.2455 41.1862 25.9884 41.1862 20.4051C41.1862 14.0977 35.5387 8.23371 31.4186 3.9509C30.7705 3.2803 30.1612 2.64399 29.6146 2.05266C29.3073 1.73242 29.0383 1.37798 28.8131 0.99642C28.6338 0.694331 28.5668 0.409381 28.6986 0.218701C28.7374 0.191487 28.783 0.175201 28.8304 0.171566C28.9152 0.156544 29.0013 0.149371 29.0875 0.150141C31.8831 0.150141 40.7541 6.26477 43.7204 8.31512L43.8997 8.43939C48.7867 11.8095 53.9784 15.7881 53.7645 20.3923Z"
						fill="url(#gradient-animated)"
					/>
				</g>
				<path
					d="M35.062 1.00806C37.7768 1.91369 41.3755 3.56922 46.4868 6.30373C46.7768 6.45887 47.0462 6.59429 47.2958 6.71088C47.7608 6.96439 48.1963 7.20561 48.5987 7.43253C52.2545 9.48395 58.9442 13.8074 58.5598 18.7025C58.2705 22.3578 55.289 25.1448 50.5427 28.2733C53.0281 25.9521 55.0898 23.495 55.2215 20.6684C55.57 15.1412 49.8835 10.7474 44.5521 7.07064L44.3707 6.94644C41.1 4.69223 37.8601 2.57587 35.062 1.00806Z"
					fill="#FF3166"
				/>
				<path
					d="M19.4573 41.2601C14.7111 44.3886 11.7295 47.1756 11.4402 50.8309C11.0558 55.726 17.7455 60.0495 21.4013 62.1009C24.2646 63.7155 28.8036 66.0538 33.7096 68.3965C34.9269 68.9887 36.1905 69.4284 37.478 69.8499C34.213 68.2996 29.948 65.5635 25.6293 62.587L25.4479 62.4628C20.1165 58.7861 14.43 54.3922 14.7785 48.865C14.9102 46.0384 16.9719 43.5813 19.4573 41.2601Z"
					fill="#FF3166"
				/>
			</g>
			<defs>
				<linearGradient
					id="gradient-animated"
					x1="35.0499"
					y1="0.150085"
					x2="35.0499"
					y2="69.5996"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#F81579" />
					<stop offset="1" stopColor="#06D3FF" />
				</linearGradient>
				<clipPath id="clip0_4091_172398">
					<rect width="70" height="70" fill="white" />
				</clipPath>
				<clipPath id="clip1_4091_172398">
					<rect
						width="37.442"
						height="69.4495"
						fill="white"
						transform="translate(16.3289 0.150085)"
					/>
				</clipPath>
				<clipPath id="clip2_4091_172398">
					<rect
						id="clip-animated"
						width="38"
						height="70"
						fill="white"
						transform="translate(16 -6.10352e-05)"
					/>
				</clipPath>
			</defs>
		</StyledSvg>
	)
}

export default Loader
