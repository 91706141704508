import { WithOptionalToken, WithRegion } from "../general"

export interface Request
  extends WithOptionalToken,
    WithRegion {
  /**
   * Start with 0, then give `offset + limit` every time
   */
  offset: string

  /**
   * Number of templates that should be fetched
   */
  limit: string
}

export enum TemplateReviewStatus {
  None = 0,
  UnderReview = 1,
  PassedAutomatically = 2,
  PassedManually = 3,
  DidNotPass = -1,
}

export interface Template {
  /**
   * Tutorial ID
   */
  id: string

  /**
   * Template name/title
   */
  name: string

  /**
   * Template description with tags
   */
  description: TaggedTextSlice[]

  /**
   * Possible preview media
   *
   * Fallback trial sequence: webp, gif, jpg is always set
   */
  preview: {
    webp: string | null
    gif: string | null
    jpg: string
  }

  /**
   * First frame of the template
   */
  first: string

  /**
   * Streamable .mp4 video
   */
  video: string

  /**
   * URL used to share the template
   */
  share: string | null

  /**
   * Date of creation
   */
  created: number

  /**
   * Number of users that have seen this template
   */
  views: number

  /**
   * Number of users that have used this template
   */
  setups: number

  /**
   * Number of users that have liked this template
   */
  likes: number

  /**
   * Number of comments
   */
  comments: number

  /**
   * Whether comments are allowed
   */
  allowComments: boolean

  /**
   * Status of template (if it was reported)
   */
  reviewStatus: TemplateReviewStatus

  /**
   * Whether should show Featured on template
   */
  isFeatured: boolean

  /**
   * Access level of template
   */
  privacy: "public" | "private" | "friends"

  /**
   * Translated category name
   * @nullable
   */
  category: string | null

  /**
   * @nullable
   */
  categoryId: number | null

  /**
   * Only if pricing is `"coins"`, price is specified
   */
  price?: number

  /**
   * Free: template is free for everyone
   * Pro: template is available for Premium users
   * Coins: template can be purchased with coins
   */
  pricing: "free" | "pro" | "coins"
}

export interface TutorialTemplate extends Template {
  /**
   * Creators information
   */
  creator: TutorialTemplateCreator
}

export interface TutorialCategory {
  id: number
  name: string
}

export interface TutorialTemplateCreator {
  /**
   * Profile picture URL, null if default
   *
   * @nullable
   */
  picture: string | null
  uid: string
  follow_status?: string
  username: string
  followers: number
}

/**
 * See more at {@link TutorialTemplate}
 */
export interface Response {
  templates: TutorialTemplate[]
}

export type TaggedTextSlice =
  | {
      format: "text" | "hashtag"
      /**
       * Hashtag is without #
       */
      content: string
    }
  | {
      format: "username"
      /**
       * Without @
       */
      label: string
      uid: string
    }

export interface BackEndTemplate {
  id: string
  name: string
  /**
   * @nullable
   */
  description: string | null
  views: number
  likes: number
  comments: number
  setups: number
  price: number
  created_at: string

  /*
   * 0 - public
   * 1 - private
   * 2 - friends
   */
  privacy: number

  /**
   * @nullable
   */
  share_url: string | null
  /**
   * @nullable
   */
  preview_gif_url: string | null
  /**
   * @nullable
   */
  preview_webp_url: string | null
  preview_image_url: string

  /**
   * First frame of the template
   */
  preview_thumbnail_url: string

  /**
   * Video file URL with fallback
   *
   * @nullable
   */
  video_stream_small_url: string | null
  /**
   * @nullable
   */
  preview_video_stream_url: string | null

  /**
   * Whether this template is accessible only
   * to Prime members or not
   */
  is_pro: boolean

  /**
   * Whether comments are shown
   */
  allow_comments: boolean

  /**
   * Numeric representation of status
   */
  review_status: TemplateReviewStatus

  /**
   * 1 - featured
   * 0 - not featured
   */
  type: number

  // * Indices of usernames mapped to UIDs

  /**
   * @nullable
   */
  desc_tags: null | BackEndDescriptionTagArray

  categories?: TutorialCategory[]
}

/**
 * See more at {@link TutorialTemplate}
 */
export interface BackEndTutorialTemplate
  extends BackEndTemplate {
  /**
   * Creators short details
   */
  created_by_user: {
    uid: string
    follow_status?: number
    /**
     * @nullable
     */
    profile_pic: {
      /**
       * @nullable
       */
      original?: string | null
    } | null
    username: string
    followers: number
  }
}

export interface BackEndDescriptionTag {
  /**
   * Start character of a handle
   */
  s: number
  /**
   * End character of a handle
   */
  e: number
  /**
   * Universal UID of handler
   */
  uid: string
}

export interface BackEndRequest {
  /**
   * Which fields we care about
   *
   * Pass comma separated dense (no whitespace)
   * field names. Objects' properties
   * are described inside parenthesis
   *
   * e.g
   * {
   *   name: string,
   *   age: number
   *   friends: {
   *     name: string,
   *     age: number
   *   }[]
   * }
   *
   * To get only names, you need:
   * name,friends(name)
   *
   * this will only give you:
   *
   * {
   *   name: string,
   *   friends: {
   *     name: string,
   *   }[]
   * }
   */
  fields: string

  /**
   * See more at {@link Request}
   */
  offset: string
  limit: string
  segment: "web_landing"
}

export interface BackEndResponse {
  /**
   * Must be true for any user
   */
  status: boolean
  result: {
    tutorials: BackEndTutorialTemplate[]
  }
}

export type BackEndDescriptionTagArray =
  BackEndDescriptionTag[]
