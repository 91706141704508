import { CustomThemedResource } from "@/comps/image"
import {
  DropAreaForModel,
  getFormatsFromMimeTypes,
  mimeTypes,
} from "@/comps/simple-drop-area"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useTheme } from "next-themes"
import { TrainModelUploadFileType } from "pages/models/create"
import { useRef } from "react"
import { useDropzone } from "react-dropzone"

interface RenderModelContentProps {
  title: string
  subtitle: string
  directoryName: string
  minCount: number
  maxCount: number
  iframeUrl: string | null
  onDrop: (files: File[]) => void
  haveFiles: boolean
  uploadedFiles: TrainModelUploadFileType[]
  removeFile: (v: TrainModelUploadFileType) => void
}

export default function RenderModelContent(
  props: RenderModelContentProps
) {
  const {
    title,
    subtitle,
    minCount,
    maxCount,
    iframeUrl,
    onDrop,
    haveFiles,
    uploadedFiles,
    directoryName,
    removeFile,
  } = props
  const { t } = useTranslation()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const selectedTheme = useTheme()

  const dropzoneState = useDropzone({
    accept: getFormatsFromMimeTypes([
      ...mimeTypes["images"],
    ]),
    onDrop,
    maxFiles: 100,
    multiple: true,
    maxSize: 10_000_000,
    noClick: false,
  })

  return (
    <div className="flex flex-col gap-[14px] pt-[34px]">
      <div className="flex w-[557px] flex-col gap-[10px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[6px]">
            {uploadedFiles.length >= minCount ? (
              <img
                src={assetUrl("/general/check-success.svg")}
                alt="check icon green"
              />
            ) : (
              <img
                src={assetUrl("/general/check.svg")}
                alt="check icon"
              />
            )}
            <span className="text-[18px] font-600 text-blue-800">
              {title}
            </span>

            <div
              className={clsx(
                iframeUrl === null
                  ? "pointer-events-none opacity-40"
                  : "group peer relative"
              )}>
              <img
                src={assetUrl("/general/info.svg")}
                alt="info icon"
                className={clsx(
                  iframeUrl
                    ? "cursor-pointer"
                    : "pointer-events-none cursor-default"
                )}
              />
              {iframeUrl && (
                <div
                  className={clsx(
                    "absolute z-50 hidden",
                    "-top-[200px] left-[30px] -ml-[30px] pl-[30px] group-hover:flex"
                  )}>
                  <iframe
                    src={
                      iframeUrl +
                      `?platform=ios&theme=${
                        selectedTheme.theme ?? "light"
                      }`
                    }
                    ref={iframeRef}
                    className="z-50 h-[468px] w-[470px] rounded-[8px]"
                  />
                </div>
              )}
            </div>
            <div className="fixed inset-0 z-30 hidden h-full w-full animate-appear bg-color-popup peer-hover:block"></div>
          </div>

          {minCount === maxCount ? (
            <span className="text-[15px] font-500 leading-[16px] tracking-[0.3px] text-blue-600">
              <b>{uploadedFiles.length}</b> / {minCount}{" "}
              {t("txt_photos")}
            </span>
          ) : (
            <span className="text-[15px] font-500 leading-[16px] tracking-[0.3px] text-blue-600">
              <b>{uploadedFiles.length}</b> / {maxCount}{" "}
              {t("txt_photos")}
            </span>
          )}
        </div>
        <p className="w-[320px] text-[14px] font-400 leading-[18px] tracking-[0.28px] text-color-placeholder">
          {subtitle}
        </p>
      </div>

      {haveFiles ? (
        <div className="flex w-[545px] animate-scale flex-wrap gap-[11px]">
          {uploadedFiles.map((file) => {
            if (file.directory === directoryName) {
              return (
                <div
                  className={clsx("group relative")}
                  key={file.url}>
                  <img
                    src={file.url}
                    alt="user uploaded file"
                    className="h-[128px] w-[128px] overflow-hidden rounded-[8px] object-cover"
                  />

                  <button
                    onClick={() => removeFile(file)}
                    className={clsx(
                      "absolute -right-[6px] -top-[6px] h-[28px] w-[28px] rounded-full bg-color-cell hover:bg-blue-300",
                      "cursor-pointer items-center justify-center border border-color-background",
                      "hidden group-hover:flex"
                    )}>
                    <CustomThemedResource
                      source="/general/minus-gray"
                      format="svg"
                      alt="minus icon"
                    />
                  </button>
                </div>
              )
            }
          })}
          <DropAreaForModel
            state={dropzoneState}
            openExplorer={dropzoneState.open}
            formats={["image/jpeg", "image/png"]}
            border={true}
            className={clsx(
              uploadedFiles.length >= maxCount && "hidden",
              "h-[128px] w-[128px] rounded-[8px]"
            )}
            noText={true}
            backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%236498EEFF' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e")`}
          />
        </div>
      ) : (
        <DropAreaForModel
          state={dropzoneState}
          openExplorer={dropzoneState.open}
          formats={["image/jpeg", "image/png"]}
          border={true}
          className="h-[148px] w-[557px] animate-fadeInUp rounded-[14px]"
          backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%236498EE80' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='round'/%3e%3c/svg%3e")`}
        />
      )}
    </div>
  )
}
