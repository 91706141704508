import {
  aiPhotoshootIcon,
  deforumIcon,
  lookAiIcon,
  photoAiIcon,
  restlyeIcon,
  textToImageIcon,
  textToVideoIcon,
} from "@/comps/header/icon"
import {
  getCategoriesForFilter,
  getInitialFilters,
  getInitialGallery,
} from "@/ssr/gallery"
import {
  SSRTemporaryRedirect,
  wrapSSRAuth,
} from "@/utils/ssr-auth"
import { AdExamplesSingle } from "pages/ad-examples"
import {
  galleryEntrySchema,
  GalleryResponse,
  galleryToolSchema,
} from "pages/api/gallery-all"
import { Template } from "pages/video-templates"
import { ReactNode } from "react"
import GalleryAll from "sections/gallery/all-gallery"
import { z } from "zod"

export type GalleryEntryType = z.infer<
  typeof galleryEntrySchema
>

export type GalleryTools = z.infer<typeof galleryToolSchema>

export type MediaType = "all" | "image" | "video"
export type SortBy = "best" | "newest" | "oldest"
export type SortByTemplates =
  | "price_low"
  | "price_high"
  | "rec"
  | "most_recent"
  | "most_used"

export type PriceTypeTemplates = "prime" | "coin" | "all"
export type FilterStyle = {
  tool: GalleryTools
  name: string
  id: string
}

export interface AdExamplesHomeSingle {
  title: string
  query: string
  results: AdExamplesSingle[]
}

export interface AdExamplesHome {
  type: "home"
  content: AdExamplesHomeSingle[]
}

export interface AdExamplesSearched {
  type: "searched"
  content: AdExamplesSingle[]
  total: number | null
}

export type AdExamplesType =
  | AdExamplesHome
  | AdExamplesSearched

export interface TemplatesWithSections {
  id: number | null
  section: string | "featured" | "rec" | "used" | "searched"
  data: Template[]
}

export type FilterCategory = {
  id: string
  name: string
}

export type FilterCategoryTemplates = {
  id: number
  name: string
}

export type SelectedStyle = {
  name: string
  ids: string[]
}

export type AvailableFilters = {
  styles: FilterStyle[]
  categories: FilterCategory[]
}

export type AvailableFiltersTemplates = {
  categories: FilterCategoryTemplates[]
}

export type ApplyFiltersGallery = Omit<
  FilterStateGallery,
  "applyFilters"
>

export type ApplyFiltersTemplates = Omit<
  FilterStateTemplates,
  "applyFilters"
>

export type ApplyFiltersAdExamples = Omit<
  FilterStateAdExamples,
  "applyFilters"
>

export type FilterStateGallery = {
  mediaType: MediaType
  sortBy: SortBy
  selectedStyles: SelectedStyle[]
  selectedCategories: FilterCategory[]
  ownedBy: string | null
  includeCustoms: boolean
  searchQuery: string | null
  selectedTools: GalleryTools[]
  applyFilters: ApplyFiltersGallery | null
}

export type FilterStateTemplates = {
  selectedCategory: number
  price: PriceTypeTemplates
  searchQuery: string | null
  sortBy: SortByTemplates
  applyFilters: ApplyFiltersTemplates
}

export type AspectAdExamples =
  | "1:1"
  | "9:16"
  | "16:9"
  | "4:5"
  | "5:4"
  | null

export type FilterStateAdExamples = {
  searchQuery: string | null
  aspect: AspectAdExamples
  applyFilters: ApplyFiltersAdExamples
}

export const indexFiltersGallery: FilterStateGallery = {
  ownedBy: null,
  searchQuery: null,
  selectedCategories: [],
  selectedStyles: [],
  mediaType: "all",
  includeCustoms: false,
  sortBy: "best",
  selectedTools: [],
  applyFilters: null,
}

export const galleryProducts: OtherProduct[] = [
  {
    tool: "ai_restyle",
    label: "txt_restyle_ai",
    icon: restlyeIcon(),
  },
  {
    tool: "deform",
    label: "txt_deforum_ai",
    icon: deforumIcon(),
  },
  {
    tool: "ai_photo",
    label: "txt_photo_ai",
    icon: photoAiIcon(),
  },
  {
    tool: "text_to_image",
    label: "txt_text_to_image",
    icon: textToImageIcon(),
  },
  {
    tool: "text_to_video",
    label: "txt_text_to_video",
    icon: textToVideoIcon(),
  },
  {
    tool: "ai_suggest",
    label: "txt_ai_photoshoot",
    icon: aiPhotoshootIcon(),
  },
  {
    tool: "ai_look",
    label: "txt_look_ai",
    icon: lookAiIcon(),
  },
]

export interface OtherProduct {
  tool: GalleryTools
  label: string
  icon: ReactNode
}

export type MetaLinks = {
  current: string | null
  next: string | null
  prev: string | null
}

export type GalleryPageProps = {
  jobs: GalleryResponse | null
  initial: FilterStateGallery
  availableFiltersGallery: AvailableFilters
  availableFiltersTemplates: AvailableFiltersTemplates
  page: string | null
  metaLinks: MetaLinks
  pageNumber: number
}

export default function AllGallery(
  props: GalleryPageProps,
) {
  return (
    <GalleryAll
      galleryJobs={props.jobs}
      templatesJobs={null}
      adExamplesJobs={null}
      initialFiltersGallery={props.initial}
      initialFiltersTemplates={null}
      availableFiltersGallery={
        props.availableFiltersGallery
      }
      availableFiltersTemplates={
        props.availableFiltersTemplates
      }
      pageTitle={props.page}
      initialFiltersAdExamples={null}
      metaLinks={props.metaLinks}
      pageNumber={props.pageNumber}
      initialPage="ai-gallery"
    />
  )
}

export const getServerSideProps = wrapSSRAuth(
  async (context, userInfo) => {
    if (!userInfo.isAnonymous) {
      throw new SSRTemporaryRedirect(
        "/discover?filter=ai-gallery",
      )
    }
    let page = Number(context.query.page)

    if (isNaN(page) || page < 1) {
      page = 1
    }

    const prevNumber = page > 1 ? page - 1 : null

    const metaLinks: MetaLinks = {
      current: "/ai-gallery?page=" + page,
      next: "/ai-gallery?page=" + (page + 1),
      prev: prevNumber
        ? "/ai-gallery?page=" + prevNumber
        : null,
    }

    const payload: FilterStateGallery = {
      ownedBy: null,
      searchQuery: null,
      selectedCategories: [],
      selectedStyles: [],
      mediaType: "all",
      includeCustoms: false,
      sortBy: "best",
      selectedTools: [],
      applyFilters: null,
    }

    const [
      jobs,
      availableFiltersGallery,
      availableFiltersTemplates,
    ] = await Promise.all([
      getInitialGallery(payload, page),
      getInitialFilters(),
      getCategoriesForFilter(userInfo.accessToken),
    ])

    const props: GalleryPageProps = {
      jobs,
      initial: payload,
      availableFiltersGallery,
      availableFiltersTemplates,
      metaLinks,
      pageNumber: page,
      page: null,
    }

    return props
  },
  [],
)
