import { RawBubble } from "@/comps/bubble"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"

interface BlogRateProps {
  used?: string
  shoots?: string
  engagement?: string
  followers: string
  revenue?: string
  coins?: string
  title: string
  advice: string
}

export default function BlogRate(props: BlogRateProps) {
  const {
    used,
    shoots,
    engagement,
    coins,
    followers,
    revenue,
    title,
    advice,
  } = props

  const moneyValue = coins || revenue
  const moneyText = coins
    ? "Earned Coins"
    : "Revenue Earned"

  const activityValue = engagement || shoots || used
  const activityText = engagement
    ? "Engagement Rate"
    : shoots
    ? "Video Shoots"
    : "Templates Used"

  const benefits = [
    {
      label: activityText,
      value: activityValue,
      colors: "bg-[#C5C5FF] dark:bg-[#3B3B99]",
      left: -10,
      top: 110,
      size: 18,
    },
    {
      label: "Followers Gained",
      value: followers,
      colors: "bg-[#BCF5C5] dark:bg-[#3B3B99]",
      left: -5,
      top: -15,
      size: 16,
    },
    {
      label: moneyText,
      value: moneyValue,
      colors: "bg-[#FFD4AC] dark:bg-[#996638]",
      left: 50,
      top: 120,
      size: 20,
    },
  ]

  return (
    <>
      <div className="relative overflow-hidden py-[26px] tablet:py-[36px] desktop:py-[41px]">
        <img
          className="absolute inset-0 h-full w-full scale-[3] object-cover"
          src={assetUrl("/tools/other-products.webp")}
          alt="background image"
        />
        <div className="wrapped flex flex-col items-center">
          <RawBubble
            control
            text={"Success Metric"}
            color="purple"
          />
          <div
            className={clsx(
              "my-[30px] tablet:mt-[41px] tablet:mb-[78px] desktop:mt-[38px] desktop:mb-[45px]",
              "flex w-full flex-col gap-[10px] tablet:flex-row tablet:gap-[12px] desktop:gap-[14px]"
            )}>
            {benefits.map(({ label, value, ...ball }) => (
              <div
                key={label}
                className={clsx(
                  "relative flex flex-row items-center justify-between py-5 px-5",
                  "tablet:flex-col-reverse tablet:justify-center tablet:gap-1",
                  "tablet:h-[139px] tablet:w-[232px] desktop:h-[130px] desktop:w-[315px]",
                  "rounded-[12px] bg-[#ffffffa0] dark:bg-[#1c1c1ca0] tablet:rounded-[18px] desktop:rounded-[22px]"
                )}>
                <span className="text-base font-700 uppercase tablet:text-[18px] tablet:font-500 desktop:text-[20px]">
                  {label}
                </span>
                <span className="text-[28px] font-800 tablet:text-[26px] desktop:text-[36px]">
                  {value}
                </span>
                <FloatingBall {...ball} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative mt-3 flex w-full flex-col items-center gap-3 bg-color-background px-4 pt-5 pb-7 tablet:gap-4 desktop:gap-10">
        <RawBubble
          control
          className="self-center"
          text="Goal"
          color="purple"
        />
        <div
          className={clsx(
            "relative bg-color-cell p-3 desktop:gap-2 desktop:p-8",
            "flex w-full flex-col items-center gap-3",
            "rounded-[12px] tablet:rounded-[18px] desktop:rounded-[22px]",
            "max-w-[725px] desktop:max-w-[975px]"
          )}>
          <h5 className="text-h5 text-center">{title}</h5>
          <p className="max-w-[604px] text-center text-base desktop:text-[18px]">
            {advice}
          </p>
        </div>
      </div>
    </>
  )
}

interface FloatingBallProps {
  left: number
  top: number
  colors: string
  size: number
}

function FloatingBall(props: FloatingBallProps) {
  const { size, left, top, colors } = props

  return (
    <div
      className={clsx(
        "absolute hidden rounded-full tablet:block",
        colors
      )}
      style={{
        left: left + "%",
        top: top + "%",
        width: size + "px",
        height: size + "px",
      }}
    />
  )
}
