import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { assetUrl } from "@/utils/cdn"
import { AllTools } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useContext, useState } from "react"
import { HistoryPopupProps } from "./history-popup"

interface WithTextProps {
  customPrompt: string
}

export function RenderTextContent(
  props: HistoryPopupProps,
): JSX.Element {
  const { tool } = props

  switch (tool as AllTools) {
    case "text_to_image":
    case "text_to_video":
      return <WithText {...props} />

    case "deform":
    case "ai_restyle":
    case "ai_photo":
    case "model_drafts":
      return <WithoutText {...props} />
  }
}

function WithText(props: WithTextProps) {
  const { customPrompt } = props

  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [isHovered, setIsHovered] = useState(false)

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    text: string,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    try {
      await navigator.clipboard.writeText(text)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  return (
    <>
      <div
        className={clsx(
          "w-full pr-3 tablet:hidden",
          "transition-all group-hover:opacity-100",
          "relative mt-[16px] flex cursor-auto items-start gap-[30px]",
        )}>
        <div className="no-scrollbar flex justify-between overflow-hidden overflow-y-scroll pr-3">
          <p
            className={clsx(
              "cursor-text font-mono text-sm font-400 text-color-white transition-[max-height]",
              isHovered
                ? "max-h-[80px] min-h-[50px] max-w-[280px] whitespace-normal"
                : "max-h-[20px] w-[260px] truncate",
            )}>
            {customPrompt}
          </p>

          <button
            className={clsx(
              isHovered
                ? " absolute right-0 top-0"
                : "hidden",
              "h-8 w-8 shrink-0 cursor-pointer transition-colors hover:bg-blue-100",
              "flex items-center justify-center rounded-[4px]",
            )}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(
                  customPrompt,
                )
                notify(
                  <SuccessMessage>
                    {t("common:txt_successfully_copied")}
                  </SuccessMessage>,
                )
              } catch (e) {
                console.error(e)
                notify(
                  <ErrorMessage>
                    {t("common:txt_couldnt_copy")}
                  </ErrorMessage>,
                )
              }
            }}>
            <img
              src={assetUrl("/ai-tools/history/copy.svg")}
              alt="copy"
              className="h-[18px] w-[18px]"
            />
          </button>
        </div>
        <button
          className={clsx(
            isHovered ? "-bottom-[2px]" : "-top-[2px]",
            "absolute right-0 w-[70px]",
            "flex items-center justify-center rounded-[12px]",
          )}
          onClick={() => setIsHovered(!isHovered)}>
          {isHovered ? (
            <span className="text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-color-white">
              {t("txt_less")}
            </span>
          ) : (
            <span className="text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-color-white">
              {t("txt_more")}
            </span>
          )}
        </button>
      </div>

      <div className="flex w-full flex-row pl-4 pr-6 pt-3">
        <div className="popup-markdown-container no-scrollbar max-h-[200px] flex-1 overflow-y-scroll pr-3 font-mono text-[12px] font-400 text-blue-700">
          {customPrompt}
        </div>
        <button
          onClick={(e) => handleCopyText(e, customPrompt)}
          className="h-[20px] w-[20px]">
          <img
            src={assetUrl("/ai-tools/history/copy.svg")}
            alt="copy"
            className="h-full"
          />
        </button>
      </div>
    </>
  )
}

function WithoutText(props: { promptName: string }) {
  const { promptName } = props
  const { t } = useTranslation()

  return (
    <div className="flex tablet:flex-col tablet:gap-[8px] tablet:px-[16px]">
      <span className="hidden text-[16px] font-600 leading-[26px] tracking-[0.32px] text-blue-500 tablet:block">
        {t("txt_style")}
      </span>
      <div className="flex">
        <span
          className={clsx(
            "rounded-[100px] bg-color-success-15 px-[22px] text-[14px] font-[500] capitalize",
            " leading-[26px] tracking-[0.28px] text-color-success",
          )}>
          {promptName}
        </span>
      </div>
    </div>
  )
}
