import {
	createContext,
	ReactNode,
	useMemo,
	useState,
} from "react"
import styled, { css } from "styled-components"
import { v4 } from "uuid"

const closeAnimationDuration = 500

export interface PopupLayer {
	component: ReactNode
	id: string
}

export interface PopupContextValue {
	add: (id: string, layer: ReactNode) => void
	pop: (id: string) => void
}

const initialState: PopupContextValue = {
	add() {
		throw new Error("Provider not used")
	},
	pop() {
		throw new Error("Provider not used")
	},
}

export const PopupContext = createContext(initialState)

type PopupBuilder = (close: () => void) => ReactNode

const showPopup = async (
	context: PopupContextValue,
	builder: PopupBuilder
) => {
	const id = v4()
	const close = () => {
		context.pop(id)
	}
	context.add(id, builder(close))
}

const PopupBackground = styled.div<{ show: boolean }>`
	background-color: transparent;

	position: fixed;
	z-index: 101;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	pointer-events: none;

	transition: background-color ${closeAnimationDuration}ms
		ease-in-out;

	${(props) =>
		props.show &&
		css`
			pointer-events: unset;
			background-color: var(--color-popup-background);
		`};
`

const PopupElement = styled.div<{ show: boolean }>`
	position: fixed;
	z-index: 102;
	top: 100vh;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	justify-content: flex-end;

	transition: top ${closeAnimationDuration}ms ease-in;

	${(props) =>
		props.show &&
		css`
			top: 0;
			transition: top ${closeAnimationDuration}ms ease-out;
		`};
`

interface SingleLayer {
	component: ReactNode
	visibility: boolean
}

interface PopupProviderProps {
	children: ReactNode
}

export const PopupProvider = (
	props: PopupProviderProps
) => {
	const [layers, setLayers] = useState<
		Map<string, SingleLayer>
	>(new Map())

	const add = (id: string, component: ReactNode) => {
		setLayers((layers) => {
			layers.set(id, { component, visibility: false })
			return new Map(layers)
		})

		setTimeout(() => {
			setLayers((layers) => {
				const layer = layers.get(id)
				if (layer) {
					layer.visibility = true
					layers.set(id, layer)
				}

				return new Map(layers)
			})
		}, 0)
	}

	const pop = (id: string) => {
		setLayers((layers) => {
			const layer = layers.get(id)
			if (layer) {
				layer.visibility = false
				layers.set(id, layer)
			}

			return new Map(layers)
		})

		setTimeout(() => {
			setLayers((layers) => {
				layers.delete(id)
				return new Map(layers)
			})
		}, closeAnimationDuration)
	}

	const flatLayers = useMemo(
		() => Array.from(layers),
		[layers]
	)

	return (
		<PopupContext.Provider value={{ add, pop }}>
			{flatLayers.length > 0 && (
				<PopupBackground
					show={
						flatLayers.find(
							([_, { visibility }]) => visibility
						) !== undefined
					}
				/>
			)}
			{flatLayers.map(([id, { component, visibility }]) => (
				<PopupElement
					id={id}
					show={visibility}
					key={id}
					onClick={(e) => {
						const element = e.target as HTMLDivElement

						if (element.id !== id) return
						flatLayers.forEach(([id]) => pop(id))
					}}>
					{component}
				</PopupElement>
			))}
			{props.children}
		</PopupContext.Provider>
	)
}

export default showPopup
