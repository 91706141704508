import { useEffect, useState } from "react"
import { z, ZodSchema, ZodTypeDef } from "zod"

const KEY_NAME = "id"
interface WithID {
  [KEY_NAME]: string
}

export type IndexedDBData<T extends WithID> =
  | {
      status: "loading"
      data?: undefined
    }
  | {
      status: "not_available"
      data?: undefined
    }
  | {
      status: "error"
      error: unknown
      data?: undefined
    }
  | {
      status: "ready"
      setData: (data: T) => Promise<void>
      getData: (id: string) => Promise<T | null>
      getAll: () => Promise<T[] | null>
      clearData: (id: string) => Promise<void>
    }

const indexedDBStores = [
  "deform" as const,
  "ai_restyle" as const,
  "ai_photo" as const,
  "text_to_image" as const,
  "text_to_video" as const,
  "model_drafts" as const,
  "ai_music_generator" as const,
]

const otherStores = [
  "deform" as const,
  "ai_restyle" as const,
  "ai_photo" as const,
  "text_to_image" as const,
  "text_to_video" as const,
  "model_drafts" as const,
]

export type AllTools = (typeof otherStores)[number]

export type IndexedDBStores =
  (typeof indexedDBStores)[number]

export function useIndexedDB<T extends WithID>(
  databaseName: "ai_art",
  storeName: IndexedDBStores,
  schema: ZodSchema<T, ZodTypeDef, unknown>
): IndexedDBData<T> {
  const [content, setContent] = useState<IndexedDBData<T>>({
    status: "loading",
  })

  useEffect(() => {
    if (!window.indexedDB) {
      setContent({ status: "not_available" })
      return
    }

    async function connectToDB() {
      const db = await getDBWithStore(
        databaseName,
        indexedDBStores
      )

      async function setData(data: T): Promise<void> {
        return new Promise((resolve, reject) => {
          const transaction = db.transaction(
            storeName,
            "readwrite"
          )
          const store = transaction.objectStore(storeName)
          store.put(data)

          transaction.oncomplete = () => resolve()
          transaction.onerror = reject
        })
      }

      async function clearData(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
          const transaction = db.transaction(
            storeName,
            "readwrite"
          )
          const store = transaction.objectStore(storeName)
          store.delete(id)

          transaction.oncomplete = () => resolve()
          transaction.onerror = reject
        })
      }

      async function getData(
        id: string
      ): Promise<T | null> {
        return new Promise((resolve, reject) => {
          const transaction = db.transaction(
            storeName,
            "readwrite"
          )
          const store = transaction.objectStore(storeName)
          const request = store.get(id)
          transaction.oncomplete = () => {
            const parseResult = schema.safeParse(
              request.result
            )
            if (!parseResult.success) {
              resolve(null)
              return
            }

            resolve(parseResult.data)
          }

          transaction.onerror = reject
        })
      }

      async function getAll(): Promise<T[] | null> {
        return new Promise((resolve, reject) => {
          const transaction = db.transaction(
            storeName,
            "readwrite"
          )
          const store = transaction.objectStore(storeName)
          const request = store.getAll()
          transaction.oncomplete = () => {
            const parseResult = z
              .array(schema)
              .safeParse(request.result)
            if (!parseResult.success) {
              resolve(null)
              return
            }

            resolve(parseResult.data)
          }

          transaction.onerror = reject
        })
      }

      setContent({
        status: "ready",
        setData,
        getData,
        clearData,
        getAll,
      })
    }

    connectToDB().catch((error) =>
      setContent({ status: "error", error })
    )
  }, [databaseName, schema, storeName])

  return content
}

async function getDBWithStore(
  databaseName: "ai_art",
  stores: string[]
) {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const openRequest = indexedDB.open(
      databaseName,
      stores.length
    )

    openRequest.onupgradeneeded = (
      e: IDBVersionChangeEvent
    ) => {
      if (!e.target) {
        reject(e)
        return
      }

      const db = openRequest.result
      stores.forEach((store) => {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store, { keyPath: KEY_NAME })
        }
      })
    }

    openRequest.onsuccess = (e) => {
      if (!e.target) {
        reject(e)
        return
      }

      const db = openRequest.result
      resolve(db)
    }

    openRequest.onerror = reject
  })
}
