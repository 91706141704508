import { useAdjustLogger } from "@/utils/adjust"
import { appStoreFallback } from "@/utils/branch"
import { useClientAuth } from "@/utils/client-auth"
import { scrollToSection } from "@/utils/scrollto"
import { getAnalytics, logEvent } from "firebase/analytics"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import styled from "styled-components"

const TabContainer = styled.a<{ active?: boolean }>`
  font-size: 16px;
  font-weight: 500;

  flex-shrink: 0;

  cursor: pointer;
  padding: 0px 12px;
  border-radius: 10px;

  transition: color 300ms ease-in-out;
  color: ${(props) =>
    props.active
      ? "var(--color-primary-500)"
      : "var(--color-blue-700)"};

  &:hover {
    color: var(--color-primary-500);
  }

  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`

export interface HeaderTabProps {
  name: string
  link?: string
  section?: string
  newTab?: boolean
  active?: boolean
  className?: string
  showNew?: boolean
}

const HeaderTab = (props: HeaderTabProps) => {
  const {
    name,
    link,
    newTab,
    active,
    section,
    className,
    showNew,
  } = props

  const logAdjust = useAdjustLogger()
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo } = useClientAuth()

  return section ? (
    <TabContainer
      className={className}
      active={active}
      onClick={(e) => {
        e.preventDefault()
        const scroller = scrollToSection(section)
        scroller()
      }}>
      {t(name)}
    </TabContainer>
  ) : (
    <TabContainer
      className={className}
      href={link}
      active={active}
      rel="noopener noreferrer"
      target={newTab ? "_blank" : undefined}
      onClick={() => {
        if (link === "/pricing") {
          logAdjust?.logEvent("pricing_page_view")
        }
        if (link === appStoreFallback) {
          logEvent(getAnalytics(), "web_redirect", {
            page: router.asPath,
            section: "header",
            destination: "app",
            uid: userInfo ? userInfo.userId : null,
          })
        }
      }}>
      {t(name)}
      {showNew && (
        <div className="bg-primary-500/20 flex h-[18px] w-[41px] items-center justify-center rounded-[4px] text-[12px] font-700 text-primary-500">
          {t("lbl_new")}
        </div>
      )}
    </TabContainer>
  )
}

export default HeaderTab
