import { FollowButton } from "@/comps/advanced-profile"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { useProgressive } from "@/comps/progressive"
import { SizedSkeleton } from "@/comps/skeleton"
import Tooltip, { TextTooltip } from "@/comps/tooltip"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import {
  GalleryEntryType,
  GalleryTools,
} from "pages/ai-gallery"
import { getFirstFiveWords } from "pages/ai-gallery/single/[id]"
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useQuery } from "react-query"
import Masonry, {
  ResponsiveMasonry,
} from "react-responsive-masonry"
import { skeletonColors } from "sections/dashboard/generated-video"
import { SuggestionsSkeleton } from "sections/gallery/all-gallery"
import {
  getKeywords,
  getRelatedArt,
} from "sections/gallery/gallery-popup"
import { skeletonList } from "sections/gallery/single-gallery"
import { FollowStatus } from "types/endpoints/user-profile"
import { RELATED_ART_LIMIT } from "./ai-single-page"
import {
  formatTimes,
  transformTool,
  transformToolToIcon,
} from "./history-popup"

interface SingleGeneratedProps {
  entity: GalleryEntryType
}

export function SingleGenerated(
  props: SingleGeneratedProps,
) {
  const {
    id,
    object_urls,
    media_type,
    prompt,
    style_name,
    style,
  } = props.entity

  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)
  const [offset, setOffset] = useState(0)
  const [videoPaused, setVideoPaused] = useState(true)

  const scrollToImage = useCallback(
    function (index: number) {
      const nextImage = document
        .getElementsByClassName(
          `single-gallery-${id}-${index}`,
        )
        .item(0)
      if (!nextImage) {
        return
      }
      const parent = nextImage.parentElement
      if (!parent) {
        return
      }

      const childX = nextImage.getBoundingClientRect().x
      const parentX = parent.getBoundingClientRect().x
      const childOffset = childX - parentX
      parent.scrollBy({
        behavior: "smooth",
        left: childOffset,
      })
    },
    [id],
  )

  useEffect(() => {
    scrollToImage(0)
  }, [scrollToImage, id])

  const { notify } = useContext(NotificationContext)

  const thumbReady = useProgressive(object_urls[0].thumb)

  async function handleCopyPrompt(text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  if (!thumbReady)
    return (
      <div
        style={{
          backgroundColor:
            skeletonColors[id % skeletonColors.length],
        }}
        className={clsx(
          `min-h-[130px] w-full rounded-[12px] desktop:min-h-[180px] desktop:rounded-[14px]`,
        )}>
        <SizedSkeleton
          className={`h-full w-full !bg-[transparent]`}
        />
      </div>
    )

  return (
    <div className="group relative min-h-[130px] w-full shrink-0 rounded-[12px] desktop:min-h-[180px] desktop:rounded-[14px]">
      <div className="absolute left-2 top-2">
        {media_type === "video" && (
          <img
            src={assetUrl(
              "/ai-tools/history/video-icon.svg",
            )}
            alt="video-icon"
            className={clsx(
              "h-4 w-4 transition-opacity",
              videoPaused ? "opacity-100" : "opacity-0",
            )}
          />
        )}
      </div>

      <div
        className={clsx(
          "absolute bottom-0 left-0 z-10 hidden w-full pt-3 desktop:block",
          "pointer-events-none opacity-0 transition-all group-hover:pointer-events-auto group-hover:opacity-100",
          "rounded-b-[12px] desktop:rounded-b-[14px]",
          "bg-gradient-to-t from-color-black/80 to-[transparent]",
          "flex cursor-auto flex-col items-center",
        )}>
        {prompt && prompt.trim().length > 0 && (
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={clsx(
              "peer relative flex w-full flex-row items-end justify-between p-3 pt-0",
            )}>
            <TextTooltip
              showAlways={prompt.length > 144}
              className={clsx("max-w-full pr-6")}
              title={prompt}>
              <p
                className={clsx(
                  "text-sm font-400 text-color-white transition-[max-height]",
                  isHovered
                    ? "line-clamp-4 max-h-[80px] whitespace-normal"
                    : "max-h-[20px] truncate",
                )}>
                {prompt}
              </p>
            </TextTooltip>
          </div>
        )}

        <div className="flex w-full flex-row items-center justify-between px-3 pb-3">
          <div className="rounded-[100px] bg-[#212127A3] px-[13px] py-1 text-[13px] font-500 text-color-white">
            {style_name}
          </div>
          {prompt && (
            <Tooltip
              className="flex items-center justify-center"
              title={t("common:txt_copy")}
              showAlways>
              <div
                onClick={() => handleCopyPrompt(prompt)}
                className={clsx(
                  "flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/copy.svg",
                  )}
                  alt="copy"
                  className="h-full"
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      {object_urls.length > 1 && (
        <>
          <button
            disabled={offset === 0}
            onClick={() => scrollToImage(offset - 1)}
            className={clsx(
              "absolute left-2 top-[calc(50%-12px)] z-20 flex h-6 w-6 items-center justify-center rounded-full border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all hover:border-color-white/60 disabled:border-[transparent]",
              isHovered
                ? "opacity-0"
                : "group-hover:opacity-100",
            )}>
            <img
              src={assetUrl(
                "/ai-tools/history/arrow-white.svg",
              )}
              alt="arrow"
            />
          </button>
          <button
            disabled={offset === object_urls.length - 1}
            onClick={() => scrollToImage(offset + 1)}
            className={clsx(
              "absolute right-2 top-[calc(50%-12px)] z-20 flex h-6 w-6 items-center justify-center rounded-full border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all hover:border-color-white/60 disabled:border-[transparent]",
              isHovered
                ? "opacity-0"
                : "group-hover:opacity-100",
            )}>
            <img
              src={assetUrl(
                "/ai-tools/history/arrow-white.svg",
              )}
              alt="arrow"
              className="rotate-180"
            />
          </button>
        </>
      )}

      <a
        href={`/ai-gallery/single/${id}`}
        className="cursor-pointer">
        <div
          className={clsx(
            "w-full overflow-hidden object-cover",
            "rounded-[12px] desktop:rounded-[14px]",
          )}>
          <div
            onScroll={(event) => {
              const container = event.target
              if (container instanceof HTMLElement) {
                const snapIndex = Math.floor(
                  container.scrollLeft /
                    container.offsetWidth,
                )

                setOffset(snapIndex)
              }
            }}
            className="no-scrollbar top-0 flex h-full snap-x snap-mandatory flex-row gap-12 overflow-x-scroll transition-all ">
            {object_urls.map((single, index) =>
              media_type === "image" ? (
                <img
                  key={index}
                  src={single.thumb}
                  alt={
                    prompt
                      ? getFirstFiveWords(prompt)
                      : style ?? "Custom"
                  }
                  className={clsx(
                    " h-full min-h-[130px] w-full shrink-0 snap-center rounded-[12px] object-cover desktop:min-h-[180px] desktop:rounded-[14px]",
                    `single-gallery-${id}-${index}`,
                  )}
                />
              ) : (
                <video
                  key={index}
                  src={single.object + "#t=0.001"}
                  poster={single.thumb}
                  playsInline
                  loop
                  preload="none"
                  muted
                  onPointerEnter={(e) => {
                    setVideoPaused(false)
                    e.currentTarget
                      .play()
                      .catch(console.error)
                  }}
                  onPointerLeave={(e) => {
                    setVideoPaused(true)
                    e.currentTarget.pause()
                    e.currentTarget.currentTime = 0.001
                  }}
                  className={clsx(
                    "h-full w-full object-cover",
                    "min-h-[130px] rounded-[12px] desktop:min-h-[180px] desktop:rounded-[14px]",
                  )}
                />
              ),
            )}
          </div>
        </div>
      </a>
    </div>
  )
}

interface RelatedSectionProps {
  showRelated: boolean
  id: number
  username: string
  profilePic: string | null
  tool: string
  createdAt: number | string
  followStatus: FollowStatus
  ownerId: string
  setFollowStatus: (v: FollowStatus) => void
  followers: number
  customPrompt: string | null
  likes?: number
  views?: number
  singlePageUse: boolean
  liked: boolean
  handleLike: () => void
}

export function RelatedSection(props: RelatedSectionProps) {
  const {
    showRelated,
    createdAt,
    tool,
    id,
    followStatus,
    setFollowStatus,
    ownerId,
    username,
    profilePic,
    followers,
    customPrompt,
    likes,
    views,
    singlePageUse,
    liked,
    handleLike,
  } = props

  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    try {
      await navigator.clipboard.writeText(
        customPrompt ?? "",
      )
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  const keywords = useQuery({
    queryKey: ["keywords", `id-${id}`],
    queryFn: getKeywords(id),
    staleTime: Infinity,
    enabled: showRelated,
  })

  const relatedArt = useQuery({
    queryKey: ["related-single", `related-art-${id}`],
    queryFn: getRelatedArt(id, RELATED_ART_LIMIT),
    staleTime: Infinity,
    enabled: showRelated,
  })

  return (
    <div
      className={clsx(
        "ap-[16px] flex w-full flex-col items-center gap-[16px] px-4 pb-[60px] pt-4 tablet:pb-4",
      )}>
      <div
        className={clsx(
          singlePageUse ? "hidden" : "flex",
          "w-full items-center justify-between border-b border-color-separator pb-[12px]",
          "desktop:hidden",
        )}>
        <a
          className="flex items-center gap-[6px]"
          href={`/${username}`}>
          <img
            src={
              profilePic ?? assetUrl("/general/avatar.svg")
            }
            onError={(e) =>
              e.currentTarget.setAttribute(
                "src",
                assetUrl("/icons/avatar-default.svg"),
              )
            }
            alt="profile picture"
            className="h-11 w-11 rounded-full border border-color-separator object-cover"
          />
          <div className="flex flex-col items-start">
            <span className="max-w-[113px] truncate text-[16px] font-700 text-blue-800">
              {username}
            </span>
            <span className="text-[14px] font-500 text-blue-600">
              {followers} followers
            </span>
          </div>
        </a>
        <div className="w-[169px]">
          <FollowButton
            userPage={false}
            setFollowStatus={setFollowStatus}
            followStatus={followStatus}
            uid={ownerId}
          />
        </div>
      </div>
      <div
        className={clsx(
          singlePageUse
            ? "hidden"
            : "flex w-full flex-col gap-[8px]",
        )}>
        <div
          className={clsx(
            "flex w-full items-center justify-between",
            "tablet:absolute tablet:-top-[360px] tablet:flex-col tablet:items-start",
          )}>
          <div className="flex items-center gap-[12px] [--icon-color:var(--color-blue-700)]">
            {transformToolToIcon(tool as GalleryTools)}
            <span
              className={clsx(
                " text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-blue-700",
                "tablet:text-blue-700",
              )}>
              {transformTool(tool as GalleryTools)}
            </span>
          </div>
          <span
            className={clsx(
              "text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-blue-400",
            )}>
            {formatTimes(createdAt)}
          </span>
        </div>

        <div className="flex flex-col">
          {customPrompt !== null &&
            customPrompt.trim().length !== 0 && (
              <div
                className={clsx(
                  "flex justify-between gap-[8px] desktop:hidden",
                  "relative rounded-[5px] bg-color-background pl-[12px] pr-[5px]",
                  "max-h-[177px] overflow-hidden",
                )}>
                <div
                  className={clsx(
                    "text-[13px] font-400 leading-[22px] tracking-[0.32px] text-blue-800",
                    "cursor-text -tracking-[0.5px] tablet:max-h-[130px]",
                    "no-scrollbar overflow-y-scroll desktop:max-h-[175px]",
                  )}>
                  <p
                    className={clsx(
                      "py-[9px] pr-[45px] font-mono",
                    )}>
                    {customPrompt}
                  </p>
                </div>
                <div className=" absolute right-4 top-2">
                  <button
                    className={clsx(
                      "relative h-8 w-8 shrink-0 cursor-pointer transition-colors",
                      "group flex items-center justify-center rounded-[4px] hover:bg-blue-200",
                    )}
                    onClick={handleCopyText}>
                    <img
                      src={assetUrl(
                        "/ai-tools/history/copy.svg",
                      )}
                      alt="copy"
                      className="h-[20px] w-[20px]"
                    />
                    <div
                      className={clsx(
                        "absolute -bottom-7 opacity-0 hover:opacity-0 group-hover:opacity-100",
                        "rounded-[4px] px-3 py-1",
                        "bg-color-tooltip transition-all",
                        "text-xs font-500 text-blue-900",
                      )}>
                      {t("txt_copy")}
                    </div>
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
      {(keywords.isFetching ||
        (keywords.isSuccess &&
          keywords.data?.length !== 0)) && (
        <div className="flex w-full flex-col gap-3 desktop:w-[1175px]">
          <span className="text-[22px] font-500 tracking-normal text-blue-700">
            {t("txt_related_keywords")}
          </span>

          <div className="no-scrollbar flex h-[36px] w-full shrink-0 flex-row gap-[10px] overflow-x-scroll">
            {keywords.isFetching && <SuggestionsSkeleton />}
            {keywords.isSuccess &&
              keywords.data.map((keyword) => (
                <a
                  key={keyword}
                  href={`/ai-gallery/${keyword}`}
                  className={clsx(
                    "flex h-full shrink-0 flex-row items-center justify-center gap-[6px] rounded-[6px]",
                    "bg-color-background p-3 tablet:bg-color-background desktop:bg-color-cell",
                    "text-[13px] font-400 tracking-wider text-blue-700 [--icon-color:var(--color-blue-600)]",
                  )}>
                  {keyword}
                </a>
              ))}
          </div>
        </div>
      )}

      <div className="flex w-full flex-col gap-[14px] pb-[40px] desktop:w-[1175px]">
        {relatedArt.isSuccess &&
          relatedArt.data.length !== 0 &&
          !relatedArt.isFetching && (
            <span className="text-[20px] font-500 tracking-normal text-blue-700">
              {t("txt_relevant_art")}
            </span>
          )}

        <ResponsiveMasonry
          columnsCountBreakPoints={{
            1: 3,
            760: 4,
            1280: 5,
          }}>
          <Masonry gutter="10px">
            {relatedArt.isFetching &&
              skeletonList.map((height, index) => (
                <div
                  key={index}
                  style={{ height }}
                  className="w-[full] overflow-hidden rounded-[12px] desktop:rounded-[14px]">
                  <SizedSkeleton className="h-full w-full" />
                </div>
              ))}
            {relatedArt.isSuccess &&
              relatedArt.data.map((single) => (
                <SingleGenerated
                  key={single.id}
                  entity={single}
                />
              ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  )
}
