import { components } from "@/utils/mdx"
import { MDXProvider } from "@mdx-js/react"
import { ReactNode } from "react"
import CrispButton from "../crisp"
import { WrappedFooter } from "../footer"
import Header from "../header"

export interface SuccessLayoutProps {
  children: ReactNode
}

export default function SuccessLayout(
  props: SuccessLayoutProps
) {
  return (
    <MDXProvider components={components}>
      <Header />
      {props.children}
      <WrappedFooter />
      <CrispButton page="success" />
    </MDXProvider>
  )
}
