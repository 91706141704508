import { assetUrl } from "@/utils/cdn"
import Head from "next/head"
import { useEffect, useState } from "react"

const Favicon = () => {
	const [dark, setDark] = useState(false)

	useEffect(() => {
		setDark(
			window.matchMedia("(prefers-color-scheme: dark)")
				.matches
		)

		window
			.matchMedia("(prefers-color-scheme: dark)")
			.addEventListener("change", (media) =>
				setDark(media.matches)
			)
	}, [setDark])

	return (
		<Head>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicon-16x16.png"
			/>
			<link rel="manifest" href="/site.webmanifest" />
			<meta name="theme-color" content="#121212" />
			<meta
				name="msapplication-TileColor"
				content="#ecedef"
			/>
			{dark ? (
				<link href={assetUrl("/icons/favicon-dark.svg")} rel="icon" />
			) : (
				<link href={assetUrl("/icons/favicon-light.svg")} rel="icon" />
			)}
		</Head>
	)
}

export default Favicon
