import { FollowButton } from "@/comps/advanced-profile"
import Button from "@/comps/button"
import { Markdown } from "@/comps/markdown"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { PlayWithProvider, Timeline } from "@/comps/player"
import { SizedSkeleton } from "@/comps/skeleton"
import { SubscriptionPopup } from "@/comps/subscription-popup"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import prettyMillions from "@/utils/millions"
import { NotificationContext } from "@/utils/notification"
import { followStatusMap } from "@/utils/tutorial"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { AdExamplesSingle } from "pages/ad-examples"
import { AdExamplesRelatedResponse } from "pages/api/ad-examples-related"
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useQuery } from "react-query"
import Masonry from "react-responsive-masonry"
import { socials } from "sections/history/generations"
import { formatTimes } from "sections/history/history-popup"
import { FollowStatus } from "types/endpoints/user-profile"
import { VideoProvider } from "video-provider"
import { SuggestionsSkeleton } from "./all-gallery"
import { skeletonList } from "./single-gallery"

const RELATED_LIMIT = 9
export function getRelated(id: string, limit: number) {
  return async function () {
    return await axios
      .post<AdExamplesRelatedResponse>(
        "/api/ad-examples-related",
        { id, limit },
      )
      .then((response) => response.data)
  }
}

export interface AdExamplesPopupProps {
  entity: AdExamplesSingle
  onClose: () => void
  startNewSessionWithMessage?: (
    message: string,
  ) => Promise<void>
}

export function AdExamplesPopup(
  init: AdExamplesPopupProps,
) {
  const [props, setProps] = useState(init)
  const { entity, onClose } = props
  const {
    preview_thumbnail_url,
    aspect,
    preview_video_url,
  } = entity
  const videoRef = useRef<HTMLVideoElement>(null)

  const thumbnail = preview_thumbnail_url.startsWith(
    "https://",
  )
    ? preview_thumbnail_url
    : undefined

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener(
        "keydown",
        handleKeyPress,
      )
    }
  }, [onClose])

  return (
    <div
      id="ad-examples-popup"
      onClick={(e) => {
        const target = e.target
        if (target instanceof HTMLElement) {
          if (target.id === "ad-examples-popup") {
            onClose()
          }
        }
      }}
      className={clsx(
        "fixed left-0 top-0 z-[100] flex h-screen w-screen bg-color-popup tablet:h-screen",
        "no-scrollbar overflow-y-scroll tablet:items-start tablet:justify-center tablet:pb-[50px] tablet:pt-[100px] desktop:items-center desktop:p-0",
      )}>
      <div
        className={clsx(
          "w-full tablet:h-auto tablet:w-[564px] desktop:h-[660px] desktop:w-[1070px]",
          "relative flex flex-col bg-color-popup-cell tablet:rounded-[10px] desktop:flex-row",
        )}>
        <button
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
          className={clsx(
            "absolute left-4 top-4 z-20 h-10 w-10 rounded-full bg-color-white/20 tablet:h-8 tablet:w-8 tablet:bg-color-popup-cell",
            "flex items-center justify-center tablet:left-auto tablet:right-[-40px] tablet:top-0",
          )}>
          <div className="relative h-4 w-4 rotate-45">
            <div className="absolute left-1/2 top-0 h-4 w-[2px] -translate-x-[1px] rounded-sm bg-color-white tablet:bg-blue-700" />
            <div className="absolute left-0 top-1/2 h-[2px] w-4 -translate-y-[1px] rounded-sm bg-color-white tablet:bg-blue-700" />
          </div>
        </button>
        {/* Video Content */}
        <div
          className={clsx(
            "relative flex w-full shrink-0 overflow-hidden",
            "tablet:h-[667px] tablet:items-center tablet:justify-center tablet:rounded-t-[10px]",
            "desktop:h-full desktop:w-auto desktop:flex-1 desktop:rounded-l-[10px] desktop:rounded-tr-none",
          )}>
          <div
            className={clsx(
              "absolute inset-0 h-full w-full overflow-hidden bg-color-black filter",
              "hidden overflow-hidden tablet:block desktop:rounded-r-none",
            )}>
            <img
              src={thumbnail}
              alt="preview image"
              className="h-full w-full scale-110 object-cover opacity-30 blur-lg"
            />
          </div>

          {preview_video_url && (
            <div
              style={{ aspectRatio: aspect }}
              className="relative z-10 w-full tablet:max-h-[600px] tablet:w-auto tablet:max-w-[500px] tablet:rounded-t-[10px] desktop:max-h-[600px] desktop:max-w-[525px] desktop:rounded-[20px]">
              <video
                style={{ aspectRatio: aspect }}
                src={preview_video_url}
                poster={thumbnail}
                ref={videoRef}
                autoPlay
                loop
                playsInline
                className="relative z-10 w-full tablet:max-h-[600px] tablet:w-auto tablet:max-w-[500px] tablet:rounded-t-[10px] desktop:max-h-[600px] desktop:max-w-[525px] desktop:rounded-[10px]"
              />
              <VideoProvider
                remember={[]}
                element={videoRef}>
                <div className="absolute bottom-0 left-0 z-50 w-full text-color-white">
                  <Timeline radius="0px 0px 10px 10px" />
                </div>
                <PlayWithProvider />
              </VideoProvider>
            </div>
          )}
        </div>

        {/* Info Content */}
        <InfoSection props={props} setProps={setProps} />
      </div>
    </div>
  )
}

interface InfoSectionProps {
  props: AdExamplesPopupProps
  setProps: (a: AdExamplesPopupProps) => void
}

function InfoSection(init: InfoSectionProps) {
  const { entity, startNewSessionWithMessage, onClose } =
    init.props
  const {
    owner,
    title,
    created_at,
    share_url,
    script,
    description,
    preview_video_url,
    preview_thumbnail_url,
    id,
  } = entity

  const thumbnail = preview_thumbnail_url.startsWith(
    "https://",
  )
    ? preview_thumbnail_url
    : undefined

  return (
    <div className="no-scrollbar w-full bg-color-popup-cell pb-[90px] pt-4 tablet:overflow-y-scroll tablet:rounded-b-[10px] tablet:py-4 desktop:h-full desktop:w-[408px] desktop:rounded-r-[10px] desktop:rounded-bl-none">
      {/* Username and Follow Section */}
      {owner && (
        <UsernameFollowSection
          uid={owner.uid}
          username={owner.username}
          follow_status={owner.follow_status}
          profile_pic={owner.profile_pic?.original}
          followers={owner.followers}
        />
      )}

      {/* Separator */}
      {owner && (
        <div className="mx-4 mt-3 h-[1px] flex-1 shrink-0 bg-color-separator" />
      )}
      {/* Session Name */}
      <SessionName
        title={title}
        created_at={created_at}
        hasOwner={owner ? true : false}
      />

      {/* Share Section */}
      <ShareSection
        share_url={
          share_url ??
          `${APP_HOST}/ad-examples/single/${id}`
        }
        videoUrl={preview_video_url}
        previewImage={thumbnail ?? ""}
        id={id}
        title={title}
      />

      {/* Separator */}
      <div className="mx-4 mt-3 h-[1px] flex-1 shrink-0 bg-color-separator" />

      {/* Script Section */}
      <ScriptSection
        script={script}
        description={description}
      />

      {/* Try Now Button */}
      <TryNowButton
        description={description}
        startNewSessionWithMessage={
          startNewSessionWithMessage
        }
        onClose={onClose}
      />

      {/* Related Section */}
      <RelatedSection
        props={init.props}
        setProps={init.setProps}
      />
    </div>
  )
}

interface UsernameFollowSectionProps {
  uid: string
  username: string
  follow_status?: number
  profile_pic?: string | null
  followers: number
}

export function UsernameFollowSection(
  props: UsernameFollowSectionProps,
) {
  const {
    uid,
    username,
    followers,
    follow_status,
    profile_pic,
  } = props

  const { userInfo } = useAuth()

  const isSelf = uid === userInfo.userId
  const follow =
    follow_status !== undefined
      ? followStatusMap[follow_status]
      : null

  const [followStatus, setFollowStatus] =
    useState<FollowStatus | null>(follow)

  return (
    <div className="flex w-full flex-row justify-between px-4">
      {/* Username Section */}
      <a
        href={`/${username}`}
        className="flex flex-row items-center justify-center gap-2">
        <img
          src={
            profile_pic ?? assetUrl("/general/avatar.svg")
          }
          onError={(e) =>
            (e.currentTarget.src = assetUrl(
              "/general/avatar.svg",
            ))
          }
          alt={`${username} avatar`}
          className="h-10 w-10 rounded-full border border-color-separator bg-color-background object-cover"
        />

        <div className="flex flex-col">
          <p className="max-w-[150px] truncate text-[16px] font-700 text-blue-800">
            {username}
          </p>
          <p className="text-[12px] font-400 text-blue-600">
            {prettyMillions(followers) + " followers"}
          </p>
        </div>
      </a>

      {/* Follow Section */}
      <div className="flex flex-row gap-4">
        {!isSelf && followStatus !== null && (
          <FollowButton
            followStatus={followStatus}
            uid={uid}
            setFollowStatus={setFollowStatus}
            userPage={false}
            className="!w-[106px] !min-w-0 !items-center !justify-center !rounded-[6px] !p-0 !text-center"
          />
        )}
      </div>
    </div>
  )
}

interface TryNowButtonProps {
  description: string
  startNewSessionWithMessage?: (
    message: string,
  ) => Promise<void>
  onClose?: () => void
}

export function TryNowButton(props: TryNowButtonProps) {
  const {
    description,
    startNewSessionWithMessage,
    onClose,
  } = props
  const { userInfo } = useAuth()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  async function handleTryNow() {
    setIsLoading(true)
    if (userInfo.isAnonymous) {
      router.push(
        `/login?redirect_url=/chat?initial_message=${description}`,
      )
      setIsLoading(false)
      return
    }

    if (startNewSessionWithMessage && onClose) {
      startNewSessionWithMessage(description)
      onClose()
    } else {
      router.push("/chat?initial_message=" + description)
    }

    setIsLoading(false)
  }
  return (
    <>
      <div className="hidden w-full px-4 pt-3 tablet:block">
        <Button
          text="txt_try_now"
          primary
          full
          disabled={isLoading}
          loading={isLoading}
          onClick={handleTryNow}
          className="!w-full !max-w-none"
        />
      </div>
      <div className="fixed bottom-0 left-0 z-30 w-full border-t border-color-separator bg-color-cell p-4 tablet:hidden">
        <Button
          text="txt_try_now"
          primary
          full
          disabled={isLoading}
          loading={isLoading}
          className="!w-full !max-w-none"
        />
      </div>

      <SubscriptionPopup
        isOpen={subscriptionPopupOpen}
        close={() => setSubscriptionPopupOpen(false)}
        location={router.asPath}
      />
    </>
  )
}

interface ScriptSectionProps {
  script: string | null
  description: string | null
}

export function ScriptSection(props: ScriptSectionProps) {
  const { script, description } = props
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  const textRef = useRef<HTMLDivElement | null>(null)

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    text: string,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    try {
      await navigator.clipboard.writeText(text)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  if (!script && !description) return <></>

  return (
    <div className="flex w-full flex-row pl-4 pr-6 pt-3">
      <div
        ref={textRef}
        className="popup-markdown-container no-scrollbar max-h-[200px] flex-1 overflow-y-scroll pr-3 font-mono text-[12px] font-400 text-blue-700">
        <Markdown markdown={script ?? description ?? ""} />
      </div>
      <button
        onClick={(e) =>
          handleCopyText(
            e,
            textRef.current?.innerText ?? description ?? "",
          )
        }
        className="h-[20px] w-[20px]">
        <img
          src={assetUrl("/ai-tools/history/copy.svg")}
          alt="copy"
          className="h-full"
        />
      </button>
    </div>
  )
}

interface ShareSectionProps {
  share_url: string
  videoUrl?: string
  previewImage: string
  title: string
  id: string
}

export function ShareSection(props: ShareSectionProps) {
  const { share_url, videoUrl, previewImage, title, id } =
    props
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  const [downloadApi, setDownloadApi] = useState(false)

  useEffect(() => {
    if (downloadApi) return
    const isIOS = /iPad|iPhone|iPod/.test(
      navigator.userAgent,
    )
    const isSafari = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent,
    )

    if (isIOS || isSafari) {
      setDownloadApi(true)
      return
    }
  }, [downloadApi])

  async function handleCopyText(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    const target = e.currentTarget
    target.disabled = true
    setTimeout(() => {
      target.disabled = false
    }, 3000)

    try {
      await navigator.clipboard.writeText(share_url)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  return (
    <div
      className={clsx(
        "relative flex w-full shrink-0 items-start gap-[8px] px-[16px]",
        "no-scrollbar overflow-x-auto pt-4 desktop:justify-between",
      )}>
      <a
        href={
          !videoUrl
            ? `/api/download-music?link=${encodeURIComponent(
                previewImage,
              )}`
            : downloadApi
            ? `/api/download-video?link=${encodeURIComponent(
                videoUrl,
              )}&name=${encodeURIComponent(title)}`
            : videoUrl
        }
        onClick={() =>
          axios.post("/api/wizard-save-result-event", {
            result_id: id,
          })
        }
        datatype="mp4"
        download={title + ".mp4"}
        target="_blank"
        rel="noreferrer noopener"
        className={clsx(
          "flex items-center justify-center",
          "transition-colors hover:bg-blue-300",
          "h-[44px] w-[44px] shrink-0 rounded-full bg-color-surface",
        )}>
        <img
          src={assetUrl(
            "/ai-tools/history/save-primary.svg",
          )}
          alt="copy icon"
          className="h-[20px] w-[20px]"
        />
      </a>
      <button
        onClick={handleCopyText}
        className={clsx(
          "flex items-center justify-center",
          "transition-colors hover:bg-blue-300",
          "h-[44px] w-[44px] shrink-0 rounded-full bg-color-surface",
        )}>
        <img
          src={assetUrl(
            "/ai-tools/history/copy-primary.svg",
          )}
          alt="copy icon"
          className="h-[20px] w-[20px]"
        />
      </button>
      {socials.map((props) => (
        <SocialButton
          {...props}
          slug={share_url}
          key={props.icon ?? props.light}
        />
      ))}
    </div>
  )
}
interface SessionNameProps {
  title: string | null
  created_at: string
  hasOwner: boolean
}

export function SessionName(props: SessionNameProps) {
  const { title, created_at, hasOwner } = props
  return (
    <div
      className={clsx(
        "flex flex-col px-4",
        hasOwner && "pt-3",
      )}>
      <p className="text-[16px] font-500 text-blue-700">
        {title}
      </p>
      <p className="text-[14px] font-400 text-blue-500">
        {formatTimes(created_at)}
      </p>
    </div>
  )
}

function RelatedSection(init: InfoSectionProps) {
  const { props, setProps } = init
  const { id } = props.entity
  const { t } = useTranslation()

  const related = useQuery({
    queryKey: ["related-ad-examples", `ad-example-${id}`],
    queryFn: getRelated(id, RELATED_LIMIT),
    staleTime: Infinity,
  })

  return (
    <>
      <div className="mt-4">
        {!related.isError &&
          related.data?.searches.length !== 0 && (
            <span className="px-4 text-[14px] font-500 tracking-normal text-blue-600">
              {t("txt_related_keywords")}
            </span>
          )}
        {(related.isFetching || related.isSuccess) &&
          related.data?.searches.length !== 0 && (
            <div className="no-scrollbar flex h-[36px] w-full shrink-0 flex-row gap-[10px] overflow-x-scroll px-4 pt-[6px]">
              {related.isFetching && (
                <SuggestionsSkeleton />
              )}
              {related.isSuccess &&
                related.data.searches.map((keyword) => (
                  <a
                    key={keyword}
                    href={`/ad-examples/${keyword}`}
                    className={clsx(
                      "flex h-full shrink-0 flex-row items-center",
                      "justify-center rounded-[6px] bg-color-surface p-3",
                      "text-[13px] font-400 tracking-wider text-blue-700",
                    )}>
                    {keyword}
                  </a>
                ))}
            </div>
          )}
      </div>

      <div className="mt-4">
        {!related.isError &&
          related.data?.related.length !== 0 && (
            <span className="mt-3 px-4 text-[14px] font-500 tracking-normal text-blue-600">
              {t("txt_relevant_ads")}
            </span>
          )}

        <Masonry
          columnsCount={2}
          gutter="8px"
          className="px-4 pt-[6px]">
          {related.isFetching &&
            skeletonList.map((height, index) => (
              <div
                key={index}
                style={{ height }}
                className="w-full overflow-hidden rounded-[12px] desktop:rounded-[14px]">
                <SizedSkeleton className="h-full w-full" />
              </div>
            ))}
          {related.isSuccess &&
            related.data.related.map((single) => (
              <a
                style={{
                  aspectRatio: single.aspect,
                }}
                key={single.id}
                href={`/ad-examples/single/${single.id}`}
                onClick={(e) => {
                  e.preventDefault()
                  const newUrl = `/ad-examples/single/${single.id}`
                  window.history.pushState(
                    { path: newUrl },
                    "",
                    newUrl,
                  )
                  setProps({
                    entity: single,
                    onClose: props.onClose,
                  })
                }}
                className="cursor-pointer rounded-[10px] bg-color-skeleton">
                <div
                  className={clsx(
                    "w-full overflow-hidden object-cover",
                    "rounded-[10px]",
                  )}>
                  <video
                    src={
                      single.preview_video_url + "#t=0.001"
                    }
                    poster={
                      single.preview_thumbnail_url.startsWith(
                        "https://",
                      )
                        ? single.preview_thumbnail_url
                        : undefined
                    }
                    playsInline
                    loop
                    preload="none"
                    muted
                    onPointerEnter={(e) => {
                      e.currentTarget
                        .play()
                        .catch(console.error)
                    }}
                    onPointerLeave={(e) => {
                      e.currentTarget.pause()
                      e.currentTarget.currentTime = 0.001
                    }}
                    className={clsx(
                      "h-full w-full object-cover",
                      "min-h-[130px] rounded-[10px] desktop:min-h-[180px]",
                    )}
                  />
                </div>
              </a>
            ))}
        </Masonry>
      </div>
    </>
  )
}

type SocialButtonIconProps =
  | {
      dark?: undefined
      light?: undefined
      icon: string
    }
  | {
      dark: string
      light: string
      icon?: undefined
    }

type SocialButtonProps = SocialsData & {
  slug: string
}

type UrlOrOnClick =
  | {
      url: (link: string) => string
      onClick?: undefined
    }
  | {
      onClick: (link: string) => unknown
      url?: undefined
    }

type SocialsData = SocialButtonIconProps &
  UrlOrOnClick & {
    desktop?: (link: string) => string
    className?: string
  }

export function SocialButton(props: SocialButtonProps) {
  const { onClick, url, slug, desktop, className } = props

  const imageClasses =
    "hover:bg-blue-300 bg-color-surface transition-colors p-2 desktop:w-full h-full " +
    className

  const anchorClasses =
    "flex overflow-hidden rounded-full shrink-0 pointer-events-auto w-11 h-11"
  const desktopUrl = onClick
    ? undefined
    : desktop
    ? desktop(slug)
    : url && url(slug)

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={desktopUrl}
        onClick={() => onClick && onClick(slug)}
        className={clsx(
          "hidden desktop:block",
          onClick && "cursor-pointer",
          anchorClasses,
        )}>
        {props.icon ? (
          <img src={props.icon} className={imageClasses} />
        ) : (
          <>
            <img
              src={props.dark}
              data-hide-on-theme="light"
              className={imageClasses}
            />
            <img
              src={props.light}
              data-hide-on-theme="dark"
              className={imageClasses}
            />
          </>
        )}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "desktop:hidden",
          anchorClasses,
          onClick && "cursor-pointer",
        )}
        href={url && url(slug)}>
        {props.icon ? (
          <img src={props.icon} className={imageClasses} />
        ) : (
          <>
            <img
              src={props.dark}
              data-hide-on-theme="light"
              className={imageClasses}
            />
            <img
              src={props.light}
              data-hide-on-theme="dark"
              className={imageClasses}
            />
          </>
        )}
      </a>
    </>
  )
}
