import { deforumStorageSchema } from "@/comps/editors/deform"
import { photoAiStorageSchema } from "@/comps/editors/photo-ai"
import { restyleStorageSchema } from "@/comps/editors/restyle"
import { textToImageStorageSchema } from "@/comps/editors/text-to-image"
import { textToVideoStorageSchema } from "@/comps/editors/text-to-video"
import { z } from "zod"
import { useIndexedDB } from "./indexed"

export function useRegenerate() {
  const photoAiIndexed = useIndexedDB(
    "ai_art",
    "ai_photo",
    photoAiStorageSchema
  )
  const restyleIndexed = useIndexedDB(
    "ai_art",
    "ai_restyle",
    restyleStorageSchema
  )
  const deforumIndexed = useIndexedDB(
    "ai_art",
    "deform",
    deforumStorageSchema
  )
  const textToImageIndexed = useIndexedDB(
    "ai_art",
    "text_to_image",
    textToImageStorageSchema
  )

  const textToVideoIndexed = useIndexedDB(
    "ai_art",
    "text_to_video",
    textToVideoStorageSchema
  )

  async function setContentDeforum(
    content: z.infer<typeof deforumStorageSchema>
  ): Promise<boolean> {
    if (deforumIndexed.status !== "ready") {
      return false
    }

    await deforumIndexed.setData(content)
    return true
  }

  async function setContentRestyle(
    content: z.infer<typeof restyleStorageSchema>
  ): Promise<boolean> {
    if (restyleIndexed.status !== "ready") {
      return false
    }

    await restyleIndexed.setData(content)
    return true
  }

  async function setContentPhotoAi(
    content: z.infer<typeof photoAiStorageSchema>
  ): Promise<boolean> {
    if (photoAiIndexed.status !== "ready") {
      return false
    }

    await photoAiIndexed.setData(content)
    return true
  }

  async function setContentTextToImage(
    content: z.infer<typeof textToImageStorageSchema>
  ): Promise<boolean> {
    if (textToImageIndexed.status !== "ready") {
      return false
    }

    await textToImageIndexed.setData(content)
    return true
  }

  async function setContentTextToVideo(
    content: z.infer<typeof textToVideoStorageSchema>
  ): Promise<boolean> {
    if (textToVideoIndexed.status !== "ready") {
      return false
    }

    await textToVideoIndexed.setData(content)
    return true
  }

  return {
    setContentTextToImage,
    setContentTextToVideo,
    setContentPhotoAi,
    setContentRestyle,
    setContentDeforum,
  }
}
