import { useEffect } from "react"
import useAuth, { useAuthUpdater } from "./client-auth"

export function GoogleOneTap() {
  const { userInfo } = useAuth()
  const { logInWithCredential } = useAuthUpdater()

  useEffect(() => {
    if (userInfo && !userInfo.isAnonymous) {
      return
    }

    try {
      if (!window.google || !window.google.accounts) {
        console.error("Google accounts library not loaded")
        return
      }

      window.google.accounts.id.initialize({
        client_id:
          "478172087871-vv0ov4m902flthd5c1pnl13i8o5l7t7e.apps.googleusercontent.com",
        callback: async (response) => {
          try {
            await logInWithCredential(response.credential)
          } catch (error) {
            console.error(
              "Error logging in with credential",
              error,
            )
          }
        },
      })

      window.google.accounts.id.prompt()
    } catch (e) {
      console.error("Google One Tap Error", e)
    }
  }, [logInWithCredential, userInfo])

  return (
    <script
      src="https://accounts.google.com/gsi/client"
      async
    />
  )
}
