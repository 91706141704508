import { BorderInput } from "@/comps/border-input"
import Header from "@/comps/header"
import { CustomThemedResource } from "@/comps/image"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import Meta from "@/comps/meta"
import { assetUrl } from "@/utils/cdn"
import { useCoins, useCoinsPopup } from "@/utils/coins"
import { errorExtractor } from "@/utils/error-extractor"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { base } from "@/utils/queryfy"
import { wrapSSRAuth } from "@/utils/ssr-auth"
import axios from "axios"
import clsx from "clsx"
import JSZip from "jszip"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { TrainingCheckNameResponse } from "pages/api/training-check-name"
import { TrainingSubmitRequest } from "pages/api/training-submit"
import { TrainingUploadObjectResponse } from "pages/api/training-upload-object"
import { UsageInfoResponse } from "pages/api/training-usage-info"
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useQuery } from "react-query"
import ModelMobileCard from "sections/train-model/model-mobile-card"
import RenderModelContent from "sections/train-model/render-model-content"
import RenderModels from "sections/train-model/render-models"
import { z } from "zod"

export const useWindowSize = () => {
  const [isDesktop, setIsDesktop] = useState<
    "tablet" | "desktop"
  >()

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window
      if (width > 1223) {
        setIsDesktop("desktop")
      } else if (width < 1223 && width > 768) {
        setIsDesktop("tablet")
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () =>
      window.removeEventListener("resize", handleResize)
  }, [])

  return isDesktop
}

function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

export type ModelType =
  | "model_male"
  | "model_female"
  | "model_perfume"
  | "model_shoes"
  | "model_sunglasses"

type SubmitStatus =
  | {
      type: "idle"
    }
  | {
      type: "zipping"
    }
  | {
      type: "uploading"
      progress: number
    }
  | {
      type: "submitting"
    }

export const trainModelDraftSchema = z.object({
  id: z.string(),
  modelName: z.string(),
  date: z.string(),
  modelType: z.enum([
    "model_male",
    "model_female",
    "model_perfume",
    "model_shoes",
    "model_sunglasses",
  ]),
  uploadedFiles: z
    .object({
      directory: z.string(),
      file: z.instanceof(File),
    })
    .array(),
})

type TrainModelDraft = z.infer<typeof trainModelDraftSchema>

interface TrainModelEditProps {
  result: Models
  draft: TrainModelDraft | null
}

interface TrainModelPageProps {
  result: Models
  draftId: string | null
}

export type TrainModelUploadFileType = {
  directory: string
  file: File
  url?: string
}

function getModelPrice() {
  return async function () {
    return await axios
      .get<UsageInfoResponse>(`/api/training-usage-info`)
      .then((res) => res.data)
  }
}

export default function TrainModelCreate(
  props: TrainModelPageProps,
) {
  const { result, draftId } = props
  const [draft, setDraft] =
    useState<TrainModelDraft | null>(null)

  const db = useIndexedDB(
    "ai_art",
    "model_drafts",
    trainModelDraftSchema,
  )

  useEffect(() => {
    if (db.status !== "ready") {
      return
    }

    if (draftId === null) {
      return
    }

    db.getData(draftId).then((result) => {
      if (result === null) {
        console.error("Draft not found")
      }

      setDraft(result)
    })
  }, [db, draftId])

  if (draftId === null) {
    return <TrainModelEdit result={result} draft={null} />
  }

  if (draft === null) {
    return <></>
  }

  return <TrainModelEdit result={result} draft={draft} />
}

function TrainModelEdit(props: TrainModelEditProps) {
  const { result } = props

  const draft = useMemo(() => {
    if (props.draft === null) {
      return null
    }

    return {
      ...props.draft,
      uploadedFiles: props.draft.uploadedFiles.map(
        (uploaded) => {
          return {
            ...uploaded,
            url: URL.createObjectURL(uploaded.file),
          }
        },
      ),
    }
  }, [props.draft])

  const { t } = useTranslation("common")
  const [modelName, setModelName] = useState(
    draft?.modelName ?? "",
  )
  const [selectedModel, setSelectedModel] =
    useState<ModelType>(draft?.modelType ?? "model_male")
  const [modelContent, setModelContent] =
    useState<ContentType>(result[0].instructions)
  const [uploadedFiles, setUploadedFiles] = useState<
    TrainModelUploadFileType[]
  >(draft?.uploadedFiles ?? [])

  const [submitStatus, setSubmitStatus] =
    useState<SubmitStatus>({ type: "idle" })
  const router = useRouter()

  useEffect(() => {
    const index = result.findIndex(
      (model) => model.id === selectedModel,
    )

    setModelContent(result[index].instructions)
  }, [result, selectedModel])

  const query = useQuery({
    queryKey: ["model-price"],
    queryFn: getModelPrice(),
  })

  const canUseQuery = useQuery({
    queryKey: ["can-use", modelName],
    queryFn: async ({ signal }) =>
      await axios
        .post<TrainingCheckNameResponse>(
          "/api/training-check-name",
          { name: modelName },
          { signal },
        )
        .then((res) => res.data),
  })

  const canUse = canUseQuery.data?.can_use !== false

  function appendFileCreator(
    directory: string,
    maxCount: number,
  ) {
    return function (files: File[]) {
      if (process.env.NEXT_PUBLIC_DEBUG !== "true") {
        window.addEventListener("beforeunload", handleLeave)
      }

      const uploadedForDirLength = uploadedFiles.filter(
        (file) => file.directory === directory,
      ).length

      let newFiles: File[] = []
      if (files.length > maxCount - uploadedForDirLength) {
        for (
          let i = 0;
          i < maxCount - uploadedForDirLength;
          i++
        ) {
          newFiles[i] = files[files.length - 1 - i]
        }
      } else {
        newFiles = files
      }

      setUploadedFiles((oldFiles) => [
        ...oldFiles,
        ...newFiles.map((file) => {
          return {
            file,
            directory,
            url: URL.createObjectURL(file),
          }
        }),
      ])
    }
  }

  async function getThumbnailUrl(file: File) {
    const { uploadUrl, objectUrl } = await axios
      .get<TrainingUploadObjectResponse>(
        `/api/training-upload-object?contentType=${file.type}`,
      )
      .then((res) => res.data)

    await axios.put(uploadUrl, file)

    return objectUrl
  }

  function addFilesToZip() {
    const zip = new JSZip()
    const initialFolders: Record<
      string,
      JSZip | undefined
    > = {}
    uploadedFiles
      .filter((file) =>
        modelContent.upload.sections.find(
          (section) => section.dir_name === file.directory,
        ),
      )
      .reduce((acc, uploaded) => {
        let folder = acc[uploaded.directory]
        if (!folder) {
          folder =
            zip.folder(uploaded.directory) ?? undefined
          acc[uploaded.directory] = folder
        }

        folder?.file(
          Math.random() + uploaded.file.name,
          uploaded.file,
        )

        return acc
      }, initialFolders)

    return zip
  }

  const { notify } = useContext(NotificationContext)
  const openCoinsPopup = useCoinsPopup()
  const coins = useCoins()

  async function handleZipCreation() {
    if (!uploadedFiles) {
      return
    }

    const thumbnailUrl = getThumbnailUrl(
      uploadedFiles[0].file,
    )

    const zip = addFilesToZip()

    setSubmitStatus({ type: "zipping" })

    const [zipFile, { uploadUrl, objectUrl }] =
      await Promise.all([
        zip.generateAsync({ type: "blob" }),
        axios
          .get<TrainingUploadObjectResponse>(
            "/api/training-upload-object?contentType=application/zip",
          )
          .then((res) => res.data),
      ])

    setSubmitStatus({ type: "uploading", progress: 0 })

    await axios.put(uploadUrl, zipFile, {
      onUploadProgress: ({ progress }) =>
        setSubmitStatus({
          type: "uploading",
          progress: progress ?? 0,
        }),
    })

    const payload: TrainingSubmitRequest = {
      zip_url: objectUrl,
      name: modelName,
      thumb_url: await thumbnailUrl,
      model_type: selectedModel,
      should_buy: Boolean(query.data?.price),
    }

    const notifySuccess = () => {
      if (
        result.findIndex(
          (model) => model.id === selectedModel,
        )
      ) {
        db.status === "ready" && db.clearData(selectedModel)
      }
      notify(
        <SuccessMessage>
          {t("fs_successfully_submited", {
            model: modelName,
          })}
        </SuccessMessage>,
      )
    }

    const error = await axios
      .post("/api/training-submit", payload)
      .then(notifySuccess)
      .catch(errorExtractor)

    if (!error) {
      await router.push(`/models?page=training`)
      return
    }

    if (
      error.type === "special" &&
      error.explanation === "insufficient_funds"
    ) {
      const userCoins = coins ? coins.coins : 0
      const price = query.data?.price ?? 0

      let neededCoins: number | undefined
      if (query.data?.price !== undefined) {
        neededCoins = price - userCoins
      }
      openCoinsPopup(neededCoins)
      return
    }

    notify(<ErrorMessage>{error.message}</ErrorMessage>)
  }

  function removeFile(
    removedFile: TrainModelUploadFileType,
  ) {
    const newList = uploadedFiles.filter(
      ({ url }) => url !== removedFile.url,
    )
    setUploadedFiles(newList)
  }

  const db = useIndexedDB(
    "ai_art",
    "model_drafts",
    trainModelDraftSchema,
  )

  const enableButton =
    hasRequiredData(
      uploadedFiles,
      modelContent.upload.sections,
    ) &&
    query.data !== undefined &&
    modelName !== ""

  return (
    <>
      <Meta
        title={t("txt_model_title")}
        description={t("txt_home_description")}
        cover={assetUrl("/covers/model.jpg")}
      />
      <Header />
      <div
        className={clsx(
          "hidden flex-col items-start pl-[300px] pt-[44px] desktop:flex",
          "pb-[66px]",
        )}>
        <p className="text-[20px] font-700 tracking-[0.4px] text-blue-800">
          {t("lbl_train_model")}
        </p>

        <div className="flex flex-col gap-[11px] pt-[30px]">
          <label
            htmlFor="model-name"
            className="text-[15px] font-600 leading-[22px] tracking-[0.3px] text-blue-700">
            {t("txt_model_name")}
          </label>
          <BorderInput
            hasErrors={!canUse}
            id="model-name"
            value={modelName}
            onChange={(e) => {
              const name = e.currentTarget.value
              setModelName(name)
            }}
            className="w-[300px]"
            placeholder={t("txt_enter_model_name")}
          />
        </div>

        <RenderModels
          models={result}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />

        <div className="flex flex-col gap-[24px]">
          {modelContent.upload.sections.map(
            (section, index) => {
              return (
                <RenderModelContent
                  key={index}
                  onDrop={appendFileCreator(
                    section.dir_name,
                    section.max_media,
                  )}
                  title={section.title}
                  haveFiles={
                    uploadedFiles.length !== 0 &&
                    include(uploadedFiles, section.dir_name)
                  }
                  subtitle={section.subtitle}
                  maxCount={section.max_media}
                  minCount={section.min_media}
                  iframeUrl={section.tip_url}
                  uploadedFiles={uploadedFiles.filter(
                    (file) =>
                      file.directory === section.dir_name,
                  )}
                  directoryName={section.dir_name}
                  removeFile={removeFile}
                />
              )
            },
          )}
        </div>
        <div className="flex w-[557px] gap-[12px] pt-[20px]">
          <button
            disabled={
              db.status !== "ready" ||
              uploadedFiles.length === 0
            }
            onClick={() => {
              router.push("/models?page=drafts")
              if (db.status !== "ready") {
                console.error("Cannot save")
                return
              }
              let indexedId = getRandomID()
              if (draft !== null) {
                indexedId = draft.id
              }

              db.setData({
                id: indexedId,
                modelName,
                uploadedFiles: uploadedFiles,
                modelType: selectedModel,
                date: String(new Date()),
              })
            }}
            className={clsx(
              "rounded-[10px] bg-blue-200 py-[12px] text-blue-800",
              "w-[141px] text-[16px] font-600 leading-[20px] disabled:text-blue-400",
            )}>
            {t("txt_draft")}
          </button>
          <button
            onClick={async () => {
              await handleZipCreation()
              setSubmitStatus({
                ...submitStatus,
                type: "idle",
              })
            }}
            disabled={!enableButton}
            className={clsx(
              "rounded-[10px] disabled:bg-blue-200 disabled:text-blue-400",
              "flex-1 bg-primary-500 text-[16px] font-600 leading-[20px] transition-all",
              "text-color-white transition-colors duration-300 hover:bg-primary-600",
              "flex items-center justify-center gap-[8px]",
            )}>
            <span>{t("txt_submit")}</span>
            {enableButton && query.data?.price && (
              <div className="flex items-center gap-[2px] rounded-[48px] bg-color-black/20 px-[7px] py-[5px]">
                <span className="min-w-[30px] text-[13px] font-600 text-color-white">
                  {query.data?.price}
                </span>
                <img
                  src={assetUrl("/general/coin.svg")}
                  alt="coin icon"
                />
              </div>
            )}
            {submitStatus.type !== "idle" && (
              <div className="animate-spin">
                <CustomThemedResource
                  format="webp"
                  source="/general/loading"
                  className="h-[18px] w-[18px]"
                />
              </div>
            )}
          </button>
        </div>
      </div>

      <ModelMobileCard />
    </>
  )
}

const instructionUploadSectionSchema = z.object({
  title: z.string(),
  subtitle: z.string(),
  dir_name: z.string(),
  min_media: z.number(),
  max_media: z.number(),
  tip_url: z.string().nullable(),
})

const instructionsSchema = z.object({
  upload: z.object({
    title: z.string(),
    subtitle: z.string(),
    sections: z.array(instructionUploadSectionSchema),
  }),
})

const modelsSchema = z.object({
  options: z
    .object({
      id: z.string(),
      name: z.string(),
      thumb_url: z.string(),
      instructions: instructionsSchema,
    })
    .array(),
})

export type ContentType = z.infer<typeof instructionsSchema>
export type Models = z.infer<typeof modelsSchema>["options"]

const querySchema = z.object({
  draftId: z.string().nullable().catch(null),
})

export const getServerSideProps =
  wrapSSRAuth<TrainModelPageProps>(async (context) => {
    const response = await axios.get<unknown>(
      `${base}/admin/training/model-types`,
      {
        headers: {
          Authorization: "kJf5_ukuc%Cu2X",
          "web-version": HEADER_WEB_VERSION,
        },
      },
    )
    const { data } = response
    const { options } = modelsSchema.parse(data)
    const { draftId } = querySchema.parse(context.query)

    const props = { result: options, draftId }

    return props
  }, [])

function include(
  files: TrainModelUploadFileType[],
  dirName: string,
): boolean {
  for (let i = 0; i < files.length; i++) {
    if (files[i].directory === dirName) {
      return true
    }
  }

  return false
}

type RequiredData = {
  title: string
  subtitle: string
  dir_name: string
  min_media: number
  max_media: number
  tip_url: string | null
}

function hasRequiredData(
  uploadedFiles: TrainModelUploadFileType[],
  requiredData: RequiredData[],
): boolean {
  for (const data of requiredData) {
    const uploadedFolder = uploadedFiles.filter(
      (folder) => folder.directory === data.dir_name,
    )

    if (uploadedFolder.length < data.min_media) {
      return false
    }
  }

  return true
}

export function getRandomID() {
  const first = Math.floor(Math.random() * 10_000)
  const second = Math.floor(Math.random() * 10_000)

  return `${first}-${second}`
}
