import { appStoreConstant } from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import PrimaryButton from "../primary-button"

interface SuccessQuoteProps {
  name: string
  source: string
  role: string
  quote: string
}

export default function SuccessQuote(
  props: SuccessQuoteProps
) {
  const { name, source, role, quote } = props

  return (
    <div className="bg-color-background">
      <div className="wrapped flex flex-col items-center py-6 desktop:pb-[83px] desktop:pt-7">
        <img
          className={clsx(
            "relative z-10 box-content border-4 border-color-background tablet:border-8",
            "aspect-square mb-[-20px] w-[100px] rounded-full tablet:mb-[-60px] tablet:w-[126px]"
          )}
          src={assetUrl(`/success/${source}-avatar.webp`)}
          alt={`Avatar of ${name}`}
        />
        <div
          className={clsx(
            "mb-6 flex flex-col items-center gap-2",
            "rounded-[18px] bg-[#D2EFEB] pt-[20px] dark:bg-[#18443E] tablet:w-[604px] tablet:pt-[70px] desktop:w-full"
          )}>
          <span className="text-h5">{name}</span>
          <span className="text-base tablet:text-[18px]">
            {role}
          </span>
          <p
            className={clsx(
              "mb-[70px] mt-8",
              "text-[18px] font-600 text-blue-700 tablet:text-[20px] desktop:text-[24px]",
              "relative max-w-[80%] text-center tablet:max-w-[479px] desktop:max-w-[822px]"
            )}>
            <QuoteIcon top />
            {quote}
            <QuoteIcon />
          </p>
        </div>
        <PrimaryButton
          href={appStoreConstant}
          className="w-full max-w-[500px] tablet:w-auto">
          Become a Creator
        </PrimaryButton>
      </div>
    </div>
  )
}

function QuoteIcon(props: { top?: boolean }) {
  return (
    <svg
      className={clsx(
        "absolute desktop:scale-150",
        props.top
          ? "-right-6 top-0 tablet:-top-4 tablet:right-0 desktop:-top-6"
          : "-left-6 bottom-0 rotate-180 tablet:-bottom-4 tablet:left-0"
      )}
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3164 0.199219H4.85744L0.950195 15.7992H9.61525L11.3164 0.199219ZM22.4002 0.199219H15.9413L12.034 15.7992H20.6991L22.4002 0.199219Z"
        className={clsx(
          "[fill:#afceca] dark:[fill:#9AD0C8]"
        )}
      />
    </svg>
  )
}
