import {
	PaymentSessionData,
	PAYMENT_DATA_KEY,
	useAdjustLogger,
} from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import { useClientAuth } from "@/utils/client-auth"
import { redirectToLogin } from "@/utils/login-redirect"
import { NotificationContext } from "@/utils/notification"
import { useShortCut } from "@kartyom/shared-components"
import axios from "axios"
import clsx from "clsx"
import { createStore, values } from "idb-keyval"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
	PaymentStripeUrlRequest,
	PaymentStripeUrlResponse,
} from "pages/api/payment-stripe-url"
import { useContext, useEffect, useState } from "react"
import { SliderComponent } from "sections/pricing/render-block"
import plansJson from "statics/offering-info.json"
import priceJson from "statics/offering-prices.json"
import { v4 } from "uuid"
import { z } from "zod"
import Button from "./button"
import { CustomThemedResource } from "./image"
import { ErrorMessage } from "./message"
import { SquareSwitch } from "./toggle"

export const TOKENS_TO_VIDEOS_DIVISOR = 2.5

const PackageSchema = z.object({
	id: z.string(),
	title: z.string(),
	best_for_ai: z.boolean().default(false),
})

const FeatureSchema = z.object({
	title: z.string(),
	package_info: z.object({
		free: z.string().default("x"),
		basic: z.string().default("x"),
		advanced: z.string().default("x"),
		enterprise: z.string().default("x"),
	}),
})

const SectionSchema = z.object({
	title: z.string(),
	features: z.array(FeatureSchema),
})

const PackageCompareSchema = z.object({
	packages: z.array(PackageSchema),
	sections: z.array(SectionSchema),
})

const AvailablePlansSchema = z.array(
	z.object({
		id: z.string(),
		title: z.string(),
		weight: z.number(),
	}),
)

export type AvailablePlans = z.infer<
	typeof AvailablePlansSchema
>

export const plansSchema = z.object({
	packages: z.array(PackageSchema),
	package_compare: PackageCompareSchema,
	available_plans: AvailablePlansSchema,
})

export type Plans = z.infer<typeof plansSchema>

// Prices Schema
const variantSchema = z.object({
	pack_id: z.string(),
	monthly_price: z.number(),
	yearly_price: z.number(),
	monthly_tokens: z.number(),
	yearly_tokens: z.number(),
	monthly_entitlement: z.string(),
	yearly_entitlement: z.string(),
})

const planSchema = z.object({
	free: z.object({
		id: z.string(),
		points: z.array(z.string()),
	}),
	advanced: z.object({
		id: z.string(),
		points: z.array(z.string()),
		variants: z.array(variantSchema).optional(),
	}),
	enterprise: z.object({
		id: z.string(),
		contact_url: z.string(),
		points: z.array(z.string()),
	}),
})

export const pricesSchema = z.object({
	save_percent: z.number(),
	plans: planSchema,
	faq: z.array(
		z.object({
			q: z.string(),
			a: z.string(),
		}),
	),
	reviews: z
		.object({
			image: z.string(),
			name: z.string(),
			stars: z.number(),
			text: z.string(),
		})
		.array(),
})

export type Prices = z.infer<typeof pricesSchema>

const prices = pricesSchema.parse(priceJson)
const plans = plansSchema.parse(plansJson)

export const getCurrentPlan = (
	userEntitlements: string[],
) => {
	const matchingWeights = userEntitlements
		.filter((entitlement) =>
			plans.available_plans.some(
				(plan) => plan.id === entitlement,
			),
		)
		.map(
			(entitlement) =>
				plans.available_plans.find(
					(plan) => plan.id === entitlement,
				)!.weight,
		)

	if (matchingWeights.length === 0) {
		return { id: "free", title: "Free", weight: -1 }
	}

	const maxWeight = Math.max(...matchingWeights)
	const truePlan = plans.available_plans.find(
		(plan) => plan.weight === maxWeight,
	)!

	return truePlan
}

export const getCurrentPackId = (
	userEntitlements: string[],
) => {
	let packId = "free"
	let isYearly = false

	userEntitlements.forEach((a) => {
		const canFindMonthly =
			prices.plans.advanced.variants?.find(
				(b) => b.monthly_entitlement === a,
			)

		if (canFindMonthly) {
			packId = canFindMonthly.pack_id
			return
		}

		const canFindYearly =
			prices.plans.advanced.variants?.find(
				(b) => b.yearly_entitlement === a,
			)

		if (canFindYearly) {
			packId = canFindYearly.pack_id
			isYearly = true
			return
		}
	})

	return { packId, isYearly }
}

export const getCurrentPlanText = (
	userEntitlements: string[],
	packId: string,
	isYearly: boolean,
) => {
	const currentPack = prices.plans.advanced.variants?.find(
		(a) => a.pack_id === packId,
	)
	const tireId = isYearly
		? currentPack?.yearly_entitlement
		: currentPack?.monthly_entitlement
	const isCurrent = userEntitlements.some(
		(a) => a === tireId,
	)
	if (isCurrent) return "txt_current_plan"

	const yearlyTireNames =
		prices.plans.advanced.variants?.map(
			(a) => a.yearly_entitlement,
		)
	const monthlyTireName =
		prices.plans.advanced.variants?.map(
			(a) => a.monthly_entitlement,
		)

	let shouldUpgrade = true

	userEntitlements.forEach((a) => {
		const canFindMonthly = monthlyTireName?.find(
			(b) => a === b,
		)

		if (canFindMonthly && isYearly) {
			shouldUpgrade = true
			return
		}

		if (canFindMonthly && !isYearly) {
			const userPackTokens =
				prices.plans.advanced.variants?.find(
					(c) => c.monthly_entitlement === a,
				)?.monthly_tokens
			const currentPackTokens =
				prices.plans.advanced.variants?.find(
					(c) => c.pack_id === packId,
				)?.monthly_tokens

			if (userPackTokens && currentPackTokens) {
				if (userPackTokens > currentPackTokens) {
					shouldUpgrade = false
					return
				}
			}
		}

		const canFindYearly = yearlyTireNames?.find(
			(b) => a === b,
		)

		if (canFindYearly && !isYearly) {
			shouldUpgrade = false
			return
		}

		if (canFindYearly && isYearly) {
			const userPackTokens =
				prices.plans.advanced.variants?.find(
					(c) => c.yearly_entitlement === a,
				)?.yearly_tokens
			const currentPackTokens =
				prices.plans.advanced.variants?.find(
					(c) => c.pack_id === packId,
				)?.yearly_tokens

			if (userPackTokens && currentPackTokens) {
				if (userPackTokens > currentPackTokens) {
					shouldUpgrade = false
					return
				}
			}
		}
	})

	if (shouldUpgrade) return "txt_upgrade"
	else return "txt_downgrade"
}

export interface SubscriptionPopupProps {
	isOpen: boolean
	close: () => void
	location?: string
	addToStorage?: () => Promise<void>
}
export function SubscriptionPopup(
	props: SubscriptionPopupProps,
) {
	const { isOpen, close, location, addToStorage } = props

	const { plans, save_percent } = prices

	const { userInfo } = useClientAuth()

	const { packId: currentPackId, isYearly: isPackYearly } =
		userInfo
			? getCurrentPackId(userInfo.entitlements)
			: { packId: "free", isYearly: false }

	const [isYearly, setIsYearly] = useState(isPackYearly)

	const currentValue =
		prices.plans.advanced.variants?.findIndex(
			(a) => a.pack_id === currentPackId,
		) ?? -1

	const [value, setValue] = useState<number>(
		currentValue !== -1 ? currentValue : 0,
	)

	const [packId, setPackId] = useState(
		prices.plans.advanced.variants?.[value].pack_id ?? "",
	)

	const currentPlanText = userInfo
		? getCurrentPlanText(
				userInfo.entitlements,
				packId,
				isYearly,
		  )
		: "txt_upgrade"

	const { t } = useTranslation("common")
	const { notify } = useContext(NotificationContext)

	const router = useRouter()

	async function handlePurchaseClick() {
		if (userInfo && !userInfo.isAnonymous) {
			fetchPaymentLink()
		} else {
			redirectToLogin()
		}
	}

	async function fetchPaymentLink() {
		try {
			const sessionId = v4()
			const path = window.location.pathname
			const search = window.location.search
			const payload: PaymentStripeUrlRequest = {
				location:
					location === undefined ? path + search : location,
				period: isYearly ? "yearly" : "monthly",
				adId: await getAdId(),
				sessionId,
				offering: packId,
			}

			const { url, currency, value } = await axios
				.post<PaymentStripeUrlResponse>(
					"/api/payment-stripe-url",
					payload,
				)
				.then((res) => res.data)

			logAdjust?.logEvent("initiate_checkout")

			addToStorage && (await addToStorage())

			const paymentData: PaymentSessionData = {
				currency,
				value,
				session: sessionId,
			}

			localStorage.setItem(
				PAYMENT_DATA_KEY,
				JSON.stringify(paymentData),
			)

			router.push(url)
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_could_not_purchase_now")}
				</ErrorMessage>,
			)
		}
		window.blur()
	}

	const logAdjust = useAdjustLogger()
	useEffect(() => {
		if (!logAdjust) return
		if (!isOpen) return

		logAdjust.logEvent("pricing_page_view")
	}, [isOpen, logAdjust])

	const yearlyPrice =
		plans.advanced.variants?.[value].yearly_price || 0
	const yearlyPriceDivided = yearlyPrice / 12
	const yearlyPriceFormatted = Math.floor(
		yearlyPriceDivided,
	)

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [isOpen])

	useShortCut({
		shortCut: "Escape",
		handler: () => {
			close()
		},
	})

	return (
		<div
			onClick={(event) => {
				if (event.target === event.currentTarget) {
					close()
				}
			}}
			className={clsx(
				isOpen ? "block" : "hidden",
				"fixed left-0 top-0 h-full w-full",
				"flex items-center justify-center",
				"z-[999] bg-color-popup",
			)}>
			<div
				className={clsx(
					"relative h-[100svh] w-full overflow-y-scroll bg-color-background tablet:h-auto tablet:w-[658px] tablet:overflow-visible tablet:py-4 desktop:w-[703px]",
					"flex flex-col items-center gap-4 tablet:rounded-[10px]",
				)}>
				<button
					className={clsx(
						"absolute -right-12 top-0",
						"h-[34px] w-[34px] rounded-full",
						"hidden items-center justify-center tablet:flex",
						"bg-color-cell hover:bg-blue-100",
						"transition-colors",
					)}
					onClick={close}>
					<img
						src={assetUrl("/general/close.svg")}
						alt="close popup"
					/>
				</button>
				<div
					className={clsx(
						"flex w-full flex-col items-center",
					)}>
					<div className="mt-4 flex w-full flex-row justify-between px-4 tablet:mt-0 tablet:justify-center">
						<p className="text-[20px] font-700 text-blue-900 tablet:text-[24px]">
							{t("txt_pricing_title")}
						</p>
						<button
							className={clsx(
								"h-[34px] w-[34px] rounded-full",
								"flex items-center justify-center",
								"bg-color-cell hover:bg-blue-100",
								"transition-colors tablet:hidden",
							)}
							onClick={close}>
							<img
								src={assetUrl("/general/close.svg")}
								alt="close popup"
							/>
						</button>
					</div>
					<div className="mt-2 flex w-full justify-start gap-[10px] px-4 tablet:justify-center tablet:px-0">
						<span
							className={clsx(
								isYearly
									? "text-blue-500"
									: "text-blue-900",
								"text-[18px] font-500 transition-colors",
							)}>
							{t("txt_monthly")}
						</span>
						<SquareSwitch
							onChange={setIsYearly}
							enabled={isYearly}
							activeColor="var(--color-green-600)"
						/>
						<span
							className={clsx(
								isYearly
									? "text-blue-900"
									: "text-blue-500",
								"text-[18px] font-500 transition-colors",
							)}>
							{t("txt_yearly")}
						</span>
						<span
							className={clsx(
								"text-[18px] font-500 transition-colors",
								isYearly
									? "text-blue-900"
									: "text-blue-500",
							)}>
							{t("fs_save_percent", {
								percent: String(save_percent),
							})}
						</span>
					</div>

					<div className="mb-4 mt-4 flex w-full flex-col items-center justify-center gap-6 px-4 tablet:mb-0 tablet:px-5 desktop:flex-row desktop:gap-[27px]">
						{/*PRIME */}

						<div
							className={clsx(
								"flex w-full flex-col overflow-hidden rounded-[8px] border border-green-400",
								"tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
								"[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
							)}>
							<div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between p-[18px]">
								<div className="flex flex-col">
									<p className="text-[24px] font-600 text-blue-900">
										{t("txt_pro_title")}
									</p>
									<p className="font-300 text-[14px] text-blue-600">
										{t("txt_pro_subtitle")}
									</p>
									<div className="relative mt-3 h-[50px] w-full overflow-hidden">
										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												isYearly && "-top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(
													plans.advanced.variants?.[value]
														.monthly_price,
												).slice(0, -2)}
												.
												<small>
													{String(
														plans.advanced.variants?.[value]
															.monthly_price,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}
											</span>
										</div>

										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												!isYearly && "top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(yearlyPriceFormatted).slice(
													0,
													-2,
												).length !== 0
													? String(
															yearlyPriceFormatted,
													  ).slice(0, -2)
													: 0}
												.
												<small>
													{String(
														yearlyPriceFormatted,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}*
											</span>
										</div>
									</div>
								</div>

								{plans.advanced.variants && (
									<SliderComponent
										variants={plans.advanced.variants}
										isYearly={isYearly}
										setPackId={setPackId}
										value={value}
										setValue={setValue}
									/>
								)}

								<div className="flex w-full">
									<Button
										secondary
										disabled={
											currentPlanText === "txt_current_plan"
										}
										onClick={handlePurchaseClick}
										className="w-full !font-500 disabled:!bg-green-100 disabled:!text-color-inactive-text disabled:hover:!bg-green-100"
										text={currentPlanText}></Button>
								</div>
							</div>
							<div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
							<div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
								<div className="flex items-center gap-[10px] ">
									<CustomThemedResource
										format="svg"
										source="/pricing/check"
									/>
									<span className="text-[14px] font-400 text-blue-800">
										{isYearly
											? t("fs_tokens_to_videos_year", {
													tokens:
														plans.advanced.variants?.[value]
															.yearly_tokens,
													videos: Math.ceil(
														(plans.advanced.variants?.[
															value
														].yearly_tokens ?? 0) /
															TOKENS_TO_VIDEOS_DIVISOR,
													),
											  })
											: t("fs_tokens_to_videos_month", {
													tokens:
														plans.advanced.variants?.[value]
															.monthly_tokens,
													videos: Math.ceil(
														(plans.advanced.variants?.[
															value
														].monthly_tokens ?? 0) /
															TOKENS_TO_VIDEOS_DIVISOR,
													),
											  })}
									</span>
								</div>

								{plans.advanced.points.map((point) => {
									return (
										<div
											className="flex items-center gap-[10px] "
											key={point}>
											<CustomThemedResource
												format="svg"
												source="/pricing/check"
											/>
											<span className="text-[14px] font-400 text-blue-800">
												{point}
											</span>
										</div>
									)
								})}
							</div>
						</div>

						{/* Enterprise */}

						<div
							className={clsx(
								"flex w-full flex-col overflow-hidden rounded-[8px]",
								"tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
								"[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
							)}>
							<div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between p-[18px]">
								<div className="flex flex-col">
									<p className="text-[24px] font-600 text-blue-900">
										{t("txt_enterprise_title")}
									</p>
									<p className="font-300 text-[14px] text-blue-600">
										{t("txt_enterprise_subtitle")}
									</p>
									<p className="mt-3 text-[38px] font-800 text-blue-800">
										{t("txt_custom")}
									</p>
								</div>
								<div className="mt-8 flex w-full tablet:mt-12 desktop:mt-0">
									<Button
										outlineSecondary
										href={plans.enterprise.contact_url}
										onClick={() =>
											logAdjust?.logEventWithPayload(
												"w_dp_cs",
												{ from: router.asPath },
											)
										}
										className="w-full"
										text="txt_contact_sales"></Button>
								</div>
							</div>
							<div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
							<div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
								{plans.enterprise.points.map((point) => {
									return (
										<div
											className="flex items-center gap-[10px] "
											key={point}>
											<CustomThemedResource
												format="svg"
												source="/pricing/check"
											/>
											<span className="text-[14px] font-400 text-blue-800">
												{point}
											</span>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
				<p
					className={clsx(
						" text-[16px] font-400 text-blue-500 transition-opacity",
						isYearly ? "opacity-100" : "opacity-0",
					)}>
					*{t("txt_billed_yearly")}
				</p>
			</div>
		</div>
	)
}

const adjustStoreSchema = z.object({
	at: z.object({
		a: z.string(),
	}),
})

export async function getAdId(): Promise<string | null> {
	const adjustStore = createStore("adjust-sdk", "as")
	const [storeValue] = await values<unknown>(adjustStore)
	if (!storeValue) {
		// For IOS devices

		const localStorageValue =
			localStorage.getItem("adjust-sdk.as")

		if (!localStorageValue) {
			return null
		}

		try {
			const parsedLocalStorageValue = JSON.parse(
				localStorageValue,
			)
			if (
				Array.isArray(parsedLocalStorageValue) &&
				parsedLocalStorageValue.length > 0
			) {
				const localParsed = adjustStoreSchema.parse(
					parsedLocalStorageValue[0],
				)
				return localParsed.at.a
			} else {
				return null
			}
		} catch (e) {
			console.error("Error parsing local storage value", e)
			return null
		}
	}

	try {
		const parsedStoreValue =
			adjustStoreSchema.parse(storeValue)
		return parsedStoreValue.at.a
	} catch (e) {
		console.error("Error parsing store value", e)
		return null
	}
}
