import styled, { css } from "styled-components"

const Container = styled.button<{
  active: boolean
  activeColor?: string
  inactiveColor?: string
  available?: boolean
}>`
  height: 28px;
  width: 52px;

  padding: 3px;

  border-radius: 28px;

  transition: all 200ms ease-in-out;
  cursor: pointer;

  background-color: ${(props) =>
    props.active
      ? props.activeColor ?? "var(--color-primary-500)"
      : props.inactiveColor ?? "var(--color-blue-300)"};

  ${(props) =>
    props.available === false &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`

const Circle = styled.div<{ active: boolean }>`
  width: 22px;
  height: 22px;

  background-color: var(--color-background-cell);
  border-radius: 22px;

  margin-left: ${(props) => (props.active ? "24px" : "0")};

  transition: margin-left 300ms ease-in-out;
`
const ContainerRectangle = styled.button<{
  active: boolean
  activeColor?: string
  inactiveColor?: string
  available?: boolean
}>`
  height: 24px;
  width: 46px;

  padding: 2px;

  border-radius: 6px;

  transition: all 200ms ease-in-out;
  cursor: pointer;

  background-color: ${(props) =>
    props.active
      ? props.activeColor ?? "var(--color-primary-500)"
      : props.inactiveColor ?? "var(--color-blue-200)"};

  ${(props) =>
    props.available === false &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`

const Square = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;

  background-color: var(--color-white);
  border-radius: 4px;

  margin-left: ${(props) => (props.active ? "22px" : "0")};

  transition: margin-left 300ms ease-in-out;
`

interface ToggleProps {
  onChange: (status: boolean) => void
  className?: string
  theme: boolean
}

const Toggle = (props: ToggleProps) => {
  const { className, theme } = props

  return (
    <Switch
      {...props}
      enabled={theme}
      className={className}
    />
  )
}

export interface SwitchProps {
  onChange: (status: boolean) => void
  className?: string
  enabled: boolean
  activeColor?: string
  inactiveColor?: string
  available?: boolean
}

export function Switch(props: SwitchProps) {
  const {
    onChange,
    className,
    enabled,
    activeColor,
    inactiveColor,
    available,
  } = props

  return (
    <Container
      available={available}
      className={className}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      active={enabled}
      onClick={() => {
        onChange(!enabled)
      }}>
      <Circle active={enabled} />
    </Container>
  )
}

export function ControlledSwitch(props: SwitchProps) {
  const {
    onChange,
    className,
    enabled,
    activeColor,
    inactiveColor,
    available,
  } = props

  return (
    <Container
      available={available}
      className={className}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      active={enabled}
      onClick={() => {
        onChange(!enabled)
      }}>
      <Circle active={enabled} />
    </Container>
  )
}

export function SquareSwitch(props: SwitchProps) {
  const {
    onChange,
    className,
    enabled,
    activeColor,
    inactiveColor,
    available,
  } = props

  return (
    <ContainerRectangle
      available={available}
      className={className}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      active={enabled}
      onClick={(e) => {
        e.preventDefault()
        onChange(!enabled)
      }}>
      <Square active={enabled} />
    </ContainerRectangle>
  )
}

export default Toggle
