import { assetUrl } from "@/utils/cdn"
import resize from "@/utils/resize"
import { StaticImageData } from "next/image"
import { twMerge } from "tailwind-merge"

type ImageProps = StaticImageData & {
  isIcon?: boolean
  theme?: string
  className?: string
  alt?: string
}

const StaticImage = (props: ImageProps) => {
  const {
    src,
    width,
    height,
    isIcon,
    theme,
    className,
    alt,
  } = props

  return (
    <img
      data-hide-on-theme={theme}
      {...(isIcon
        ? { src, width, height }
        : resize({ src, width, height }))}
      className={className}
      alt={alt}
    />
  )
}

type ThemedImageProps = {
  light: string
  dark: string
  isIcon?: boolean
  className?: string
  alt?: string
}

export const ThemedImage = (props: ThemedImageProps) => {
  const { light, dark, className, alt } = props

  return (
    <>
      <img
        data-hide-on-theme="dark"
        src={light}
        className={className}
        alt={alt}
      />
      <img
        data-hide-on-theme="light"
        src={dark}
        className={className}
        alt={alt}
      />
    </>
  )
}

interface CustomThemedResourceProps {
  className?: string
  source: string
  alt?: string
  format?: string
}

export const CustomThemedResource = (
  props: CustomThemedResourceProps
) => {
  const { className, source, alt, format } = props

  const extension = format ?? "webp"
  return (
    <>
      <img
        data-hide-on-theme="dark"
        src={assetUrl(source + "-light." + extension + "?aaa")}
        className={className}
        alt={alt}
      />
      <img
        data-hide-on-theme="light"
        src={assetUrl(source + "-dark." + extension + "?aaa")}
        className={className}
        alt={alt}
      />
    </>
  )
}

export interface ResponsiveImageProps {
  src: string
  className?: string
  alt?: string
}

export function ResponsiveImage(
  props: ResponsiveImageProps
) {
  const { src, className, ...otherProps } = props

  const mobile = `${src}-mobile.webp`
  const tablet = `${src}-tablet.webp`
  const desktop = `${src}-desktop.webp`

  return (
    <>
      <img
        {...otherProps}
        src={assetUrl(mobile)}
        className={twMerge("tablet:hidden", className)}
      />
      <img
        {...otherProps}
        src={assetUrl(tablet)}
        className={twMerge(
          "hidden tablet:block desktop:hidden",
          className
        )}
      />
      <img
        {...otherProps}
        src={assetUrl(desktop)}
        className={twMerge(
          "hidden desktop:block",
          className
        )}
      />
    </>
  )
}

export default StaticImage
