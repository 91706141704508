import {
  PromptOption,
  PromptParams,
} from "@/ssr/ai-tools"
import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { withNotify } from "@/utils/trigger"
import {
  extractFramesFromVideo,
  extractUniformFrames,
  getImageDimensions,
  getVideoDurationMillis,
  KeyframeWithSource,
} from "@/utils/video"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { DeforumImageUrlResponse } from "pages/api/deforum-image-url"
import {
  AISubmitRequest,
  AISubmitResponse,
} from "pages/api/deforum-submit"
import { aiToolSchema } from "pages/api/gallery-all"
import {
  createContext,
  MouseEvent,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useQueryClient } from "react-query"
import {
  ChannelsParams,
  DynamicEditorProps,
  useEditorParamsContext,
} from "sections/editor/editor"
import { z } from "zod"
import {
  createPromptStateFrom,
  ParamtersTrigger,
} from "../ai-config"
import { BorderTextareaResizable } from "../border-input"
import { CustomThemedResource } from "../image"
import { ErrorMessage } from "../message"
import { SubscriptionPopup } from "../subscription-popup"
import { AllParams } from "pages/api/deforum-params"

export const DEFORUM_STORAGE_KEY = "last"
export const ParamsContext =
  createContext<AllParams | null>(null)

export function getAcceptedMimes(): string {
  return [
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/quicktime",
  ].join(",")
}

const remoteStorageSchema = z.object({
  location: z.literal("remote"),
  url: z.string(),
})

const localStorageSchema = z.object({
  location: z.literal("local"),
  blob: z.unknown(),
})

export const deforumStorageSchema = z
  .object({
    tool: z.literal("deform").default("deform"),
    id: z.literal(DEFORUM_STORAGE_KEY),
    promptType: z.string(),
    inputs: z.array(
      z.object({
        id: z.string(),
        text: z.string(),
      }),
    ),
  })
  .and(z.union([remoteStorageSchema, localStorageSchema]))

export type AITool = z.infer<typeof aiToolSchema>

type DeforumStorageType = z.infer<
  typeof deforumStorageSchema
>

interface InputWithId {
  id: string
  text: string
}

const MAX_INPUTS = 4

export const promptSchema = z.union([
  z.object({
    type: z.literal("custom"),
    content: z.array(z.string()),
  }),
  z.object({
    type: z.literal("predefined"),
    id: z.string(),
  }),
])

export type Prompt = z.infer<typeof promptSchema>

function uuid(): string {
  return Math.random().toString()
}

function createInput(text = ""): InputWithId {
  return {
    text,
    id: uuid(),
  }
}

export function createInputs(
  initial: Prompt,
): InputWithId[] {
  if (initial.type === "predefined") {
    return [createInput()]
  }

  return initial.content.map(createInput)
}

type SubmitImageGetter = {
  getter: () => Promise<Blob>
}

function updateInput(
  input: InputWithId,
  text: string,
): InputWithId {
  return {
    id: input.id,
    text,
  }
}

export const MIN_INPUT_CHARACTERS = 3
export const MIN_RESOLUTION = 450
export default function DeformEditor(props: {
  content: DynamicEditorProps
  params: {
    styles: PromptOption[]
    params: AllParams
  } | null
  channels: ChannelsParams | null
  blob: Blob | null
}) {
  if (props.content.tool !== "deform") {
    throw new Error("You are not in deform editor.")
  }

  if (props.blob === null) {
    throw new Error("No file uploaded.")
  }

  const { content, params: parameters, blob: file } = props

  if (parameters === null) {
    throw new Error("Parameters for filters are null.")
  }
  const [uploadedUrl, setUploadedUrl] = useState<string>()

  const editor = useEditorParamsContext()
  const { styles, params } = parameters

  const [inputs, setInputs] = useState<InputWithId[]>(
    content.inputs,
  )

  const [promptType, setPromptType] = useState(
    content.promptType,
  )

  const fileType = useMemo(() => getFileType(file), [file])

  const [getData, setDataGetter] =
    useState<null | SubmitImageGetter>(null)

  const { t } = useTranslation()

  const { notify } = useContext(NotificationContext)

  const { userInfo } = useAuth()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )

  const router = useRouter()

  const initialConfig = createPromptStateFrom(params.params)

  const sectionsConfig = params.sections
    ? params.sections.map((section) =>
        createPromptStateFrom(section.components),
      )
    : null

  const config = sectionsConfig
    ? sectionsConfig.reduce((acc, section) => {
        return section ? { ...acc, ...section } : acc
      }, initialConfig || {})
    : initialConfig || {}

  const [promptState, setPromptState] = useState(config)

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const [actionLoading, setActionLoading] = useState(false)

  const logAdjust = useAdjustLogger()
  const queryClient = useQueryClient()

  const indexed = useIndexedDB(
    "ai_art",
    "deform",
    deforumStorageSchema,
  )

  async function submitData() {
    if (actionLoading) {
      return
    }

    try {
      if (!getData) {
        throw new Error("Should have set getData")
      }

      setActionLoading(true)

      const image = await getData.getter()

      const prompts: Prompt =
        promptType === "custom"
          ? {
              type: "custom",
              content: inputs.map((input) => input.text),
            }
          : { type: "predefined", id: promptType }

      let res = null
      if (promptState && promptState["resolution"])
        res = Number(promptState["resolution"])

      const resolution = res ?? MIN_RESOLUTION

      const dimensions = await getImageDimensions(image)
      const changedDimensions = resizeDimensions(
        dimensions,
        resolution,
      )

      let url = uploadedUrl

      if (!url) {
        const { upload, download, contentType } =
          await axios
            .post<DeforumImageUrlResponse>(
              "/api/deforum-image-url",
              { content: image.type },
            )
            .then((res) => res.data)

        await axios.put(upload, image, {
          headers: {
            "Content-Type": contentType,
            "web-version": HEADER_WEB_VERSION,
          },
        })
        url = download
        setUploadedUrl(url)
      }

      const submitData: AISubmitRequest = {
        url,
        prompts,
        tool: "deform",
        params: promptState ?? { resolution: 720 },
        ...changedDimensions,
      }

      await axios
        .post<AISubmitResponse>(
          "/api/deforum-submit",
          submitData,
        )
        .then((res) => res.data)

      if (promptType === "custom") {
        logAdjust?.logEvent("deform_ai_prompt_writing")
      } else {
        logAdjust?.logEvent("deform_ai_select_style")
      }

      logAdjust?.logEvent("deform_ai_generate")

      window.removeEventListener(
        "beforeunload",
        handleLeave,
      )

      queryClient.invalidateQueries("coins")
      await router.push("/profile/generations/all")
      editor.closePage()
      setActionLoading(false)
    } catch (error) {
      const notifier = withNotify((t) =>
        notify(<ErrorMessage>{t}</ErrorMessage>),
      )

      notifier(error)

      console.error(error)

      setSubscriptionPopupOpen(false)
      setActionLoading(false)
    }
  }

  async function addToStorage() {
    if (indexed.status !== "ready") {
      console.error("Indexed is not ready")
      return
    }

    const payload: DeforumStorageType = {
      tool: "deform",
      id: DEFORUM_STORAGE_KEY,
      inputs,
      promptType,
      location: "local",
      blob: file,
    }

    await indexed.setData(payload)
  }

  const canEditPrompts = promptType === "custom"

  const firstInputLength = inputs.at(0)?.text.length ?? 0
  const isFirstInputCorrect =
    firstInputLength >= MIN_INPUT_CHARACTERS
  const canSubmit =
    promptType !== "custom" || isFirstInputCorrect

  return (
    <ParamsContext.Provider value={params}>
      <div className="flex w-full justify-center overflow-y-scroll">
        <div className="mx-auto my-[24px] flex w-[1175] gap-[25px]">
          <div
            className="w-[calc(70vh/16*9)] min-w-[300px] max-w-[575px]"
            id="media-preview">
            {fileType === "image" && (
              <ImagePreview
                file={file}
                setDataGetter={setDataGetter}
                closePage={async () => {
                  window.removeEventListener(
                    "beforeunload",
                    handleLeave,
                  )

                  if (router.asPath === "/editor/deform") {
                    router.back()
                  }
                  editor.closePage()
                }}
              />
            )}
            {fileType === "video" && (
              <VideoPreviewWithFrames
                file={file}
                setDataGetter={setDataGetter}
                closePage={async () => {
                  window.removeEventListener(
                    "beforeunload",
                    handleLeave,
                  )
                  editor.closePage()
                }}
              />
            )}
          </div>

          <div className="flex w-[575px] flex-col gap-3">
            <div
              className={clsx(
                "flex flex-col items-stretch gap-[22px] rounded-[15px] bg-color-cell px-6 pb-[22px] pt-[16px] transition-all",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              <div className="relative flex flex-col gap-2">
                <span className="text-[16px] font-700 text-blue-600">
                  {t("lbl_styles")}
                </span>
                <div className="absolute bottom-0 right-0 z-10 h-3 w-full bg-gradient-to-t from-color-cell to-color-white/0"></div>
                <div className="no-scrollbar grid max-h-[340px] grid-cols-3 gap-2 overflow-y-scroll pb-3">
                  <div
                    className={clsx(
                      "relative h-[122px] overflow-hidden rounded-[8px]",
                      promptType === "custom"
                        ? "border-primary-500 bg-[#FF31661F]"
                        : "bg-blue-100",
                      "select-none border-2 border-[transparent]",
                      "cursor-pointer transition-colors",
                      "flex flex-col items-center justify-center gap-1",
                    )}
                    onClick={() => setPromptType("custom")}>
                    <CustomThemedResource
                      format="svg"
                      source="/general/custom-prompt"
                      alt="custom prompt"
                    />
                    <span className="font-600 text-blue-800">
                      {t("lbl_custom")}
                    </span>
                  </div>
                  {styles.map(({ name, id, image }) => (
                    <div
                      key={id}
                      className={clsx(
                        "relative h-[122px] overflow-hidden rounded-[8px]",
                        promptType === id &&
                          "border-primary-500",
                        "select-none border-2 border-[transparent]",
                        "cursor-pointer transition-colors",
                      )}
                      onClick={() => setPromptType(id)}>
                      <img
                        src={image}
                        alt={`cover of ${name} style`}
                        className={clsx(
                          "pointer-events-none h-full w-full object-cover",
                        )}
                      />
                      <div className="absolute bottom-0 left-0 h-8 w-full bg-gradient-to-t from-color-black/60 to-[transparent]"></div>
                      <span className="absolute bottom-1 left-2 text-[13px] font-600 text-color-white">
                        {name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {canEditPrompts && (
                <div className="flex flex-col gap-2">
                  <span className="text-[16px] font-700 text-blue-600 transition-opacity">
                    {t("lbl_prompts")}
                  </span>
                  <div
                    className={clsx(
                      "no-scrollbar flex max-h-[312px] shrink-0 flex-col items-stretch gap-3 overflow-y-scroll",
                      "transition-opacity",
                      actionLoading && "opacity-30",
                    )}>
                    <div className="flex flex-col items-stretch gap-3">
                      {inputs.map((input, index) => {
                        const isLast =
                          index === inputs.length - 1
                        const isNotFull =
                          inputs.length < MAX_INPUTS

                        const canAddPrompts =
                          canEditPrompts &&
                          isLast &&
                          isNotFull
                        const canDeletePrompts =
                          canEditPrompts &&
                          inputs.length > 1

                        return (
                          <div
                            key={input.id}
                            className={clsx("relative")}>
                            <BorderTextareaResizable
                              placeholder={t(
                                "txt_prompt_placeholder",
                              )}
                              setValue={(content) => {
                                const newInput =
                                  createInput()
                                if (canAddPrompts) {
                                  setInputs((oldInputs) => [
                                    ...oldInputs.map(
                                      (old, i) =>
                                        i === index
                                          ? updateInput(
                                              old,
                                              content,
                                            )
                                          : old,
                                    ),
                                    newInput,
                                  ])
                                } else if (
                                  canDeletePrompts &&
                                  content === ""
                                ) {
                                  setInputs((oldInputs) =>
                                    oldInputs.filter(
                                      (_, i) => i !== index,
                                    ),
                                  )
                                } else {
                                  setInputs((oldInputs) =>
                                    oldInputs.map(
                                      (old, i) =>
                                        i === index
                                          ? updateInput(
                                              old,
                                              content,
                                            )
                                          : old,
                                    ),
                                  )
                                }
                              }}
                              disabled={!canEditPrompts}
                              className="group inline-block w-full resize-none !pr-10"
                              autoCorrect="off"
                            />

                            <button
                              disabled={!canDeletePrompts}
                              onClick={() => {
                                const newInputs =
                                  inputs.filter(
                                    (_, i) => i !== index,
                                  )
                                setInputs(newInputs)
                              }}
                              className={clsx(
                                "absolute right-2 top-[9px] h-8 w-8 rounded-full bg-color-cell transition-opacity",
                                "flex flex-col items-center justify-center outline-none focus:bg-[#ff316630]",
                                canDeletePrompts
                                  ? "opacity-100 disabled:opacity-50"
                                  : "pointer-events-none opacity-0",
                              )}>
                              <img
                                src={assetUrl(
                                  "/general/trash-icon.svg",
                                )}
                                alt="remove this prompt"
                              />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {promptState && (
              <ParamtersTrigger
                actionLoading={actionLoading}
                promptState={promptState}
                setPromptState={setPromptState}
              />
            )}

            {isPro ? (
              <button
                disabled={
                  fileType === "unrecognized" || !canSubmit
                }
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "disabled:pointer-events-none",
                  "hover:bg-primary-600 bg-primary-500",
                  "relative py-3 transition-colors",
                  "flex items-center justify-center gap-4",
                  actionLoading && "pointer-events-none",
                )}
                onClick={() => {
                  if (actionLoading) return
                  submitData()
                }}>
                <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2">
                  <img
                    className={clsx(
                      "h-6 w-6 animate-[spin_1s_infinite_linear]",
                      actionLoading
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                    src={assetUrl(
                      "/general/loading-dark.webp",
                    )}
                    alt="loading"
                  />
                </div>

                {t("lbl_generate")}
                {promptState && params.params && (
                  <CostsCoins
                    coins={getPromptPrice(
                      promptState,
                      params.params,
                      params.price,
                    )}
                  />
                )}
              </button>
            ) : (
              <button
                disabled={
                  fileType === "unrecognized" ||
                  actionLoading
                }
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "bg-[#2F91FD] hover:bg-[#0264CF]",
                  "py-3 transition-colors",
                )}
                onClick={() => {
                  setSubscriptionPopupOpen(true)
                  logAdjust?.logEvent(
                    "deform_ai_subscribe_to_continue",
                  )
                }}>
                {t("txt_subscribe_to_continue")}
              </button>
            )}
            <div className="h-[70px] w-full shrink-0"></div>
          </div>
        </div>
        <SubscriptionPopup
          isOpen={subscriptionPopupOpen}
          close={() => setSubscriptionPopupOpen(false)}
          location="/editor/deform"
          addToStorage={addToStorage}
        />
      </div>
    </ParamsContext.Provider>
  )
}

type FileType = "image" | "video" | "unrecognized"
function getFileType(file: Blob): FileType {
  try {
    if (file.type.startsWith("video")) {
      return "video"
    }

    if (file.type.startsWith("image")) {
      return "image"
    }
  } catch {}

  return "unrecognized"
}

interface MediaDimensions {
  width: number
  height: number
}

function resizeDimensions(
  dimensions: MediaDimensions,
  max: number,
): MediaDimensions {
  if (Math.max(dimensions.width, dimensions.height) < max) {
    return dimensions
  }

  if (dimensions.width > dimensions.height) {
    return {
      width: Math.floor(
        (max / dimensions.height) * dimensions.width,
      ),
      height: max,
    }
  }

  return {
    width: max,
    height: Math.floor(
      (max / dimensions.width) * dimensions.height,
    ),
  }
}

function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

interface MediaPreviewProps {
  file: Blob
  setDataGetter: (callback: SubmitImageGetter) => void
  closePage: () => void
}

function ImagePreview(props: MediaPreviewProps) {
  const { file, setDataGetter, closePage } = props

  useEffect(() => {
    setDataGetter({
      getter: async (): Promise<Blob> => {
        return file
      },
    })
  }, [file, setDataGetter])

  return (
    <div className="relative w-full">
      <button
        className="group absolute left-2 top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-blue-300 hover:bg-blue-400"
        onClick={closePage}>
        <img
          className="group-hover:hidden"
          src={assetUrl("/general/close-in-circle.svg")}
        />
        <img
          className="hidden group-hover:block dark:hidden dark:group-hover:hidden"
          src={assetUrl(
            "/general/close-in-circle-hover.svg",
          )}
        />
        <img
          className="hidden dark:hidden dark:group-hover:block"
          src={assetUrl(
            "/general/close-in-circle-hover-dark.svg",
          )}
        />
      </button>
      <img
        src={URL.createObjectURL(props.file)}
        className="w-full rounded-[16px]"
      />
    </div>
  )
}

async function canvasToBlob(
  canvas: HTMLCanvasElement,
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Cannot get blob"))
        return
      }

      resolve(blob)
    })
  })
}

async function videoToBlob(
  ref: RefObject<HTMLVideoElement>,
): Promise<Blob> {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) throw new Error("Cannot get 2d context")

  if (!ref.current)
    throw new Error("Cannot get video element")
  const { videoWidth, videoHeight } = ref.current

  canvas.width = videoWidth
  canvas.height = videoHeight

  context.drawImage(
    ref.current,
    0,
    0,
    videoWidth,
    videoHeight,
  )
  const image = await canvasToBlob(canvas)

  return image
}

interface Progress {
  time: number
  duration: number
}

interface PreviewConstants {
  PREVIEW_SLIDER_WIDTH: number
  PREVIEW_FRAMES_COUNT: number
  PREVIEW_SINGLE_WIDTH: number
  PREVIEW_SINGLE_HEIGHT: number
  PREVIEW_FRAME_BORDER: number
}

function mapRanges(
  startIn: number,
  endIn: number,
  startOut: number,
  endOut: number,
  value: number,
): number {
  const rangeIn = endIn - startIn
  const rangeOut = endOut - startOut
  const percent = (value - startIn) / rangeIn
  const result = percent * rangeOut + startOut
  return result
}

function calculateFramePosition(
  x: number,
  constants: PreviewConstants,
) {
  const start = x - constants.PREVIEW_SINGLE_WIDTH
  const max =
    constants.PREVIEW_SLIDER_WIDTH -
    2 * constants.PREVIEW_SINGLE_WIDTH

  const percent = mapRanges(0, max, 0, 1, start)
  const clampedDown = Math.max(0, percent)
  const clampedUp = Math.min(1, clampedDown)

  return clampedUp
}

function VideoPreviewWithFrames(props: MediaPreviewProps) {
  const { file, setDataGetter, closePage } = props

  const [frames, setFrames] = useState<
    KeyframeWithSource[]
  >([])
  const [progress, setProgress] = useState<Progress | null>(
    null,
  )
  const [holding, setHolding] = useState(false)
  const video = useRef<HTMLVideoElement>(null)
  const [constants, setConstants] =
    useState<PreviewConstants>({
      PREVIEW_SLIDER_WIDTH: 1,
      PREVIEW_FRAMES_COUNT: 1,
      PREVIEW_SINGLE_WIDTH: 1,
      PREVIEW_SINGLE_HEIGHT: 1,
      PREVIEW_FRAME_BORDER: 0,
    })

  useEffect(() => {
    function updateSizes() {
      const PREVIEW_SLIDER_WIDTH =
        document.getElementById("media-preview")
          ?.clientWidth ?? 575
      const PREVIEW_FRAMES_COUNT = 18
      const PREVIEW_SINGLE_WIDTH =
        PREVIEW_SLIDER_WIDTH / PREVIEW_FRAMES_COUNT
      const PREVIEW_SINGLE_HEIGHT =
        (PREVIEW_SINGLE_WIDTH / 9) * 16
      const PREVIEW_FRAME_BORDER = 2
      setConstants({
        PREVIEW_SLIDER_WIDTH,
        PREVIEW_FRAMES_COUNT,
        PREVIEW_SINGLE_WIDTH,
        PREVIEW_SINGLE_HEIGHT,
        PREVIEW_FRAME_BORDER,
      })
    }
    updateSizes()

    window.addEventListener("resize", updateSizes)
    return () =>
      window.removeEventListener("resize", updateSizes)
  }, [])

  const previewFrames = useMemo(() => {
    return extractUniformFrames(
      frames,
      constants.PREVIEW_FRAMES_COUNT,
    )
  }, [frames, constants])

  useEffect(() => {
    setDataGetter({
      getter: async (): Promise<Blob> => {
        return await videoToBlob(video)
      },
    })
  }, [setDataGetter])

  function handleMouseMove(
    event: MouseEvent<HTMLDivElement>,
    overrideHolding?: boolean,
  ) {
    if (!progress) {
      return
    }

    if (!holding && !overrideHolding) {
      return
    }

    const element = video.current
    if (!element) {
      return
    }

    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left

    const percent = calculateFramePosition(x, constants)

    setProgress((progress) => {
      const duration = progress?.duration ?? 0
      const time = duration * percent
      element.currentTime = time
      return { duration, time }
    })
  }

  const videoUrl = useMemo(
    () => URL.createObjectURL(file),
    [file],
  )

  useEffect(() => {
    if (constants.PREVIEW_FRAMES_COUNT === 1) return
    extractFramesFromVideo(
      file,
      constants.PREVIEW_FRAMES_COUNT,
    ).then(({ keyframes }) => setFrames(keyframes))
    getVideoDurationMillis(file).then((durationMillis) =>
      setProgress({
        time: 0,
        duration: durationMillis / 1000,
      }),
    )
  }, [file, constants])

  useEffect(() => {
    function handleMouseDown() {
      setHolding(true)
    }

    function handleMouseUp() {
      setHolding(false)
    }

    window.addEventListener("mousedown", handleMouseDown)
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
      window.removeEventListener(
        "mousedown",
        handleMouseDown,
      )
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])

  const { t } = useTranslation()

  const preview =
    progress && frames.length
      ? frames[
          Math.floor(
            (progress.time / progress.duration) *
              (frames.length - 1),
          )
        ].source
      : ""

  return (
    <div className="flex flex-col gap-3">
      <div className="relative w-full overflow-hidden rounded-[16px] bg-blue-100">
        <button
          className="group absolute left-2 top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-blue-300 hover:bg-blue-400"
          onClick={closePage}>
          <img
            className="group-hover:hidden"
            src={assetUrl("/general/close-in-circle.svg")}
          />
          <img
            className="hidden group-hover:block dark:hidden dark:group-hover:hidden"
            src={assetUrl(
              "/general/close-in-circle-hover.svg",
            )}
          />
          <img
            className="hidden dark:hidden dark:group-hover:block"
            src={assetUrl(
              "/general/close-in-circle-hover-dark.svg",
            )}
          />
        </button>
        <video
          src={videoUrl}
          className={clsx(
            "w-full object-cover",
            "opacity-0 transition-opacity",
            progress && "opacity-100",
          )}
          ref={video}
        />
      </div>
      <div
        className="relative flex"
        style={{ height: constants.PREVIEW_SINGLE_HEIGHT }}
        onMouseMove={handleMouseMove}
        onClick={(event) => handleMouseMove(event, true)}>
        {previewFrames.map(({ source, time }, index) => (
          <img
            key={time}
            src={source}
            alt={`Frame of time ${time}`}
            style={{
              width: constants.PREVIEW_SINGLE_WIDTH,
              height: constants.PREVIEW_SINGLE_HEIGHT,
            }}
            className={clsx(
              "pointer-events-none aspect-[9/16] object-cover first:rounded-l-[12px]",
              "brightness-[0.3]",
              index === previewFrames.length - 1 &&
                "rounded-r-[12px]",
            )}
          />
        ))}
        {progress && (
          <div
            className={clsx(
              "absolute top-0 aspect-[9/16] border-primary-500",
              holding
                ? "hover:cursor-grabbing hover:border-[#FFDB20]"
                : "cursor-grab",
              "transition-colors duration-150",
              "group box-border rounded-sm",
              "overflow-visible",
            )}
            style={{
              borderWidth: constants.PREVIEW_FRAME_BORDER,
              left: calculateBorderPosition(
                progress.time,
                progress.duration,
                constants,
              ),
              width:
                constants.PREVIEW_SINGLE_WIDTH +
                constants.PREVIEW_FRAME_BORDER * 2,
              height: constants.PREVIEW_SINGLE_HEIGHT,
            }}>
            <div className="relative h-full w-full">
              <div
                className={clsx(
                  "absolute -top-14 left-1/2 -translate-x-1/2 px-4 py-2",
                  "pointer-events-none select-none opacity-0",
                  holding && "group-hover:opacity-100",
                  "rounded-lg bg-color-cell transition-opacity duration-150",
                )}>
                <div
                  className={clsx(
                    "relative",
                    "w-[120px] text-center",
                    "font-500 text-blue-600",
                  )}>
                  <div
                    className={clsx(
                      "absolute h-3 w-3 rotate-45 rounded-[3px] bg-color-cell",
                      "-bottom-3 left-1/2 -translate-x-1/2",
                    )}
                  />
                  {t("txt_make_keyframe")}
                </div>
              </div>
              {preview ? (
                <img
                  src={preview}
                  alt="current frame"
                  className="pointer-events-none h-full w-full object-cover"
                />
              ) : (
                <div className="pointer-events-none h-full w-full object-cover" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function calculateBorderPosition(
  time: number,
  duration: number,
  constants: PreviewConstants,
) {
  const progress = time / duration
  const max =
    constants.PREVIEW_SLIDER_WIDTH -
    constants.PREVIEW_SINGLE_WIDTH
  const maxWithoutBorders =
    max - constants.PREVIEW_FRAME_BORDER

  const pixels = progress * maxWithoutBorders
  const style = `${pixels}px`
  return style
}

function CostsCoins(props: { coins: number }) {
  const { coins } = props
  const { userInfo } = useAuth()
  const { isCoinFree } = getUserEntitlements(
    userInfo.entitlements,
  )

  if (isCoinFree || !coins) {
    return <></>
  }

  return (
    <div className="flex gap-1 rounded-full bg-[#C6113F] px-1 text-[14px] font-700 text-color-white">
      +{coins}
      <img
        src={assetUrl("/general/coin.svg")}
        alt="coins"
      />
    </div>
  )
}

interface PromptState {
  [id: string]: number | string | boolean
}

function getPromptPrice(
  prompt: PromptState,
  params: PromptParams[],
  non_free_price: number,
) {
  for (const param of params) {
    const promptID = prompt[param.id]
    if (typeof promptID !== "number") {
      continue
    }

    if (param.type !== "slider") {
      continue
    }

    if (promptID > param.maxFreeValue) {
      return non_free_price
    }
  }

  return 0
}
