import { Header } from "@/comps/index"
import { ErrorMessage } from "@/comps/message"
import { MimeTypes } from "@/comps/simple-drop-area"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"
import { useIndexedDB } from "@/utils/indexed"
import { redirectToLogin } from "@/utils/login-redirect"
import { changeMimeType } from "@/utils/mime-types"
import { NotificationContext } from "@/utils/notification"
import { WithChildren } from "@/utils/types"
import { loadUrlToBlob } from "@/utils/video"
import axios from "axios"
import clsx from "clsx"
import { getStorage } from "controllers/editors"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { ParamsResponse } from "pages/api/deforum-params"
import { FeatureTools } from "pages/feature/[feature]"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { z, ZodSchema, ZodTypeDef } from "zod"
import { AiMusicGeneratorStorageSchema } from "../../comps/editors/ai-music-generator"
import {
  createInputs,
  deforumStorageSchema,
  DEFORUM_STORAGE_KEY,
  getAcceptedMimes as deformMimes,
} from "../../comps/editors/deform"
import {
  getAcceptedMimes as photoMimes,
  photoAiStorageSchema,
} from "../../comps/editors/photo-ai"
import {
  getAcceptedMimes as restyleMimes,
  restyleStorageSchema,
} from "../../comps/editors/restyle"
import { textToImageStorageSchema } from "../../comps/editors/text-to-image"
import { textToVideoStorageSchema } from "../../comps/editors/text-to-video"

const channelsSchema = z.object({
  available_bitrates: z.array(
    z.object({
      bitrate: z.number(),
    }),
  ),
  available_intensities: z.array(
    z.object({
      intensity: z.union([
        z.literal("low"),
        z.literal("medium"),
        z.literal("high"),
      ]),
    }),
  ),
  categories: z.array(
    z.object({
      category_id: z.number(),
      name: z.string(),
      groups: z.array(
        z.object({
          name: z.string(),
          playlist: z.string(),
        }),
      ),
    }),
  ),
})

const channelsResponseSchema = z.object({
  data: channelsSchema,
})

export type ChannelsParams = z.infer<typeof channelsSchema>

type DeformEditorProps = z.infer<
  typeof deforumStorageSchema
>

type RestyleEditorProps = z.infer<
  typeof restyleStorageSchema
>

type PhotoAiEditorProps = z.infer<
  typeof photoAiStorageSchema
>

type TextToImageEditorProps = z.infer<
  typeof textToImageStorageSchema
>

type TextToVideoEditorProps = z.infer<
  typeof textToVideoStorageSchema
>
type AiMusicGeneratorEditorProps = z.infer<
  typeof AiMusicGeneratorStorageSchema
>

export type DynamicEditorProps =
  | DeformEditorProps
  | RestyleEditorProps
  | PhotoAiEditorProps
  | (TextToImageEditorProps & {
      isModelUse?: {
        name: string
        id: number
      }
    })
  | TextToVideoEditorProps
  | AiMusicGeneratorEditorProps

type EditorPopupState =
  | {
      status: "idle"
    }
  | {
      status: "loading"
    }
  | {
      status: "done"
      comp: JSX.Element
    }

interface EditorParamsResult {
  openPage(params: DynamicEditorProps): void
  closePage(): void
  loading: boolean
  isOpen: boolean
}

export const EditorParamsContext =
  createContext<EditorParamsResult | null>(null)

export function useEditorParamsContext(): EditorParamsResult {
  const context = useContext(EditorParamsContext)
  return context!
}

const pageMaps = {
  deform: () => import("../../comps/editors/deform"),
  ai_restyle: () => import("../../comps/editors/restyle"),
  ai_photo: () => import("../../comps/editors/photo-ai"),
  text_to_image: () =>
    import("../../comps/editors/text-to-image"),
  text_to_video: () =>
    import("../../comps/editors/text-to-video"),
  ai_music_generator: () =>
    import("../../comps/editors/ai-music-generator"),
} as const

async function getParams(tool: FeatureTools) {
  if (tool !== "ai_music_generator") {
    const result = await axios
      .post<ParamsResponse>("/api/deforum-params", { tool })
      .then((res) => res.data)

    return { ...result, channels: null }
  } else {
    return null
  }
}

async function getChannels(
  tool: FeatureTools,
  isPro: boolean,
  pat: string | null,
) {
  if (tool === "ai_music_generator" && isPro && pat) {
    try {
      const submitData = {
        method: "GetPlayMusic",
        params: {
          pat,
        },
      }

      const response = await axios
        .post<unknown>(
          "https://api-b2b.mubert.com/v2/GetPlayMusic",
          submitData,
        )
        .then((res) => res.data)

      const result = channelsResponseSchema.parse(response)

      const {
        available_bitrates,
        available_intensities,
        categories,
      } = result.data

      return {
        available_bitrates,
        available_intensities,
        categories,
      }
    } catch {
      const result = channelsResponseSchema.parse(
        defaultChannelsList,
      )

      const {
        available_bitrates,
        available_intensities,
        categories,
      } = result.data

      return {
        available_bitrates,
        available_intensities,
        categories,
      }
    }
  } else {
    const result = channelsResponseSchema.parse(
      defaultChannelsList,
    )

    const {
      available_bitrates,
      available_intensities,
      categories,
    } = result.data

    return {
      available_bitrates,
      available_intensities,
      categories,
    }
  }
}

async function loadPageComponent(
  props: DynamicEditorProps,
  setPage: (page: JSX.Element) => void,
  isPro: boolean,
) {
  const pat =
    props.tool === "ai_music_generator" ? props.pat : null
  const loader = pageMaps[props.tool]
  const [Comp, params, channels] = await Promise.all([
    loader().then((imported) => imported.default),
    getParams(props.tool),
    getChannels(props.tool, isPro, pat),
  ])

  let blob: Blob | null
  if (props.tool !== "ai_music_generator") {
    if (props.location === "remote" && props.url) {
      blob = await loadUrlToBlob(props.url)
    } else {
      if (
        props.location === "local" &&
        props.blob instanceof Blob
      ) {
        blob = props.blob
      } else {
        blob = null
      }
    }
  } else {
    blob = null
  }

  setPage(
    <Comp
      params={params}
      content={props}
      blob={blob}
      channels={channels}
    />,
  )
}

export function EditorParamsProviders(props: WithChildren) {
  const [state, setState] = useState<EditorPopupState>({
    status: "idle",
  })
  const { userInfo } = useAuth()

  const openPage = useCallback(
    async (props: DynamicEditorProps) => {
      setState({ status: "loading" })
      const { isPro } = getUserEntitlements(
        userInfo.entitlements,
      )
      loadPageComponent(
        props,
        (comp) => setState({ status: "done", comp }),
        isPro,
      )
    },
    [userInfo],
  )

  const closePage = useCallback(() => {
    setState({ status: "idle" })
  }, [])

  useEffect(() => {
    if (state.status === "done") {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [state.status])

  return (
    <EditorParamsContext.Provider
      value={{
        openPage,
        closePage,
        loading: state.status === "loading",
        isOpen: state.status === "done",
      }}>
      {props.children}
      {state.status === "done" && (
        <div
          className={clsx(
            "fixed inset-0 z-[100] flex overflow-y-scroll bg-color-background pt-[50px] tablet:pt-[70px]",
          )}>
          <Header isSolid isStatic />
          {state.comp}
        </div>
      )}
    </EditorParamsContext.Provider>
  )
}

export function useEditorFromIndexedDB(
  tool: FeatureTools,
  schema: ZodSchema<
    DynamicEditorProps,
    ZodTypeDef,
    unknown
  >,
) {
  const editorParams = useEditorParamsContext()
  const indexed = useIndexedDB("ai_art", tool, schema)
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation("common")

  useEffect(() => {
    async function load() {
      if (editorParams.isOpen) {
        return
      }
      if (indexed.status === "ready") {
        const props = await indexed.getData(
          DEFORUM_STORAGE_KEY,
        )

        if (!props) {
          return
        }

        editorParams.openPage(props)
      }
    }
    load()
  }, [editorParams, indexed, indexed.status, notify, t])

  const clearData = useCallback(async () => {
    if (indexed.status !== "ready") {
      console.error("Not ready")
      return
    }

    await indexed.clearData(DEFORUM_STORAGE_KEY)
  }, [indexed])

  return {
    clearData,
    editorParams,
  }
}

interface UploadOptions {
  onDrop?: (files: File[]) => Promise<void>
  formats?: MimeTypes[]
  onClick?: (prompt?: string) => void
}

export function useUploadOption(
  upload?: boolean,
  tool?: FeatureTools,
): UploadOptions {
  const { notify } = useContext(NotificationContext)
  const editor = useEditorParamsContext()
  const { t } = useTranslation("common")

  const { userInfo } = useAuth()
  const router = useRouter()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )

  const data = getStorage(tool ?? "deform")
  const indexed = useIndexedDB(
    "ai_art",
    data.tool,
    data.storage,
  )

  if (!upload || !tool) return {}

  switch (tool) {
    case "deform": {
      const onClick = () => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const fileInput = document.createElement("input")

        fileInput.type = "file"
        fileInput.accept = deformMimes()

        fileInput.onchange = async (_) => {
          if (fileInput.files) {
            const [file] = fileInput.files

            const isOk = await isImageOrVideo(file)

            if (isOk && indexed.status === "ready") {
              indexed.setData({
                tool: "deform",
                id: DEFORUM_STORAGE_KEY,
                inputs: createInputs({
                  type: "custom",
                  content: [""],
                }),
                promptType: "custom",
                location: "local",
                blob: file,
              })
              router.push("/ai-studio?tool=deform")
            } else {
              notify(
                <ErrorMessage>
                  {t("txt_wrong_file_uploaded")}
                </ErrorMessage>,
              )
            }
          }
        }
        fileInput.click()
      }

      const onDrop = async (files: File[]) => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const [file] = files

        if (!file) {
          return
        }

        const isOk = await isImageOrVideo(file)

        if (isOk && indexed.status === "ready") {
          indexed.setData({
            tool: "deform",
            id: DEFORUM_STORAGE_KEY,
            inputs: createInputs({
              type: "custom",
              content: [""],
            }),
            promptType: "custom",
            location: "local",
            blob: file,
          })
          router.push("/ai-studio?tool=deform")
        } else {
          notify(
            <ErrorMessage>
              {t("txt_wrong_file_uploaded")}
            </ErrorMessage>,
          )
        }
      }

      return {
        onDrop,
        onClick,
        formats: [
          "image/png",
          "image/jpeg",
          "video/mp4",
          "video/quicktime",
        ],
      }
    }

    case "ai_restyle": {
      const onClick = () => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const fileInput = document.createElement("input")

        fileInput.type = "file"
        fileInput.accept = restyleMimes()

        fileInput.onchange = async (_) => {
          if (fileInput.files) {
            const [file] = fileInput.files

            const isOk = await isImageOrVideo(file)

            if (isOk && indexed.status === "ready") {
              indexed.setData({
                tool: "ai_restyle",
                id: DEFORUM_STORAGE_KEY,
                input: "",
                promptType: "custom",
                location: "local",
                blob: file,
              })
              router.push("/ai-studio?tool=ai_restyle")
            } else {
              notify(
                <ErrorMessage>
                  {t("txt_wrong_file_uploaded")}
                </ErrorMessage>,
              )
            }
          }
        }
        fileInput.click()
      }

      const onDrop = async (files: File[]) => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const [file] = files

        if (!file) {
          return
        }

        const isOk = await isImageOrVideo(file)

        if (isOk && indexed.status === "ready") {
          indexed.setData({
            tool: "ai_restyle",
            id: DEFORUM_STORAGE_KEY,
            input: "",
            promptType: "custom",
            location: "local",
            blob: file,
          })
          router.push("/ai-studio?tool=ai_restyle")
        } else {
          notify(
            <ErrorMessage>
              {t("txt_wrong_file_uploaded")}
            </ErrorMessage>,
          )
        }
      }

      return {
        onDrop,
        onClick,
        formats: ["video/mp4", "video/quicktime"],
      }
    }
    case "ai_photo": {
      const onClick = () => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const fileInput = document.createElement("input")

        fileInput.type = "file"
        fileInput.accept = photoMimes()

        fileInput.onchange = async (_) => {
          if (fileInput.files) {
            const [file] = fileInput.files

            const isOk = await isImageOrVideo(file)

            if (isOk && indexed.status === "ready") {
              indexed.setData({
                tool: "ai_photo",
                id: DEFORUM_STORAGE_KEY,
                input: "",
                promptType: "custom",
                location: "local",
                blob: file,
              })
              router.push("/ai-studio?tool=ai_photo")
            } else {
              notify(
                <ErrorMessage>
                  {t("txt_wrong_file_uploaded")}
                </ErrorMessage>,
              )
            }
          }
        }
        fileInput.click()
      }

      const onDrop = async (files: File[]) => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        const [file] = files

        if (!file) {
          return
        }

        const isOk = await isImageOrVideo(file)

        if (isOk && indexed.status === "ready") {
          indexed.setData({
            tool: "ai_photo",
            id: DEFORUM_STORAGE_KEY,
            input: "",
            promptType: "custom",
            location: "local",
            blob: file,
          })
          router.push("/ai-studio?tool=ai_photo")
        } else {
          notify(
            <ErrorMessage>
              {t("txt_wrong_file_uploaded")}
            </ErrorMessage>,
          )
        }
      }

      return {
        onDrop,
        onClick,
        formats: ["image/png", "image/jpeg"],
      }
    }

    case "text_to_image": {
      const onClick = async (prompt?: string) => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        if (indexed.status === "ready") {
          indexed.setData({
            tool: "text_to_image",
            id: DEFORUM_STORAGE_KEY,
            promptType: null,
            text: prompt ?? "",
            location: "local",
            blob: null,
          })
          router.push("/ai-studio?tool=text_to_image")
        }
      }

      return {
        onClick,
      }
    }

    case "text_to_video": {
      const onClick = async (prompt?: string) => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        editor.openPage({
          tool: "text_to_video",
          id: DEFORUM_STORAGE_KEY,
          promptType: null,
          text: prompt ?? "",
          location: "local",
          blob: null,
        })
      }

      return {
        onClick,
      }
    }

    case "ai_music_generator": {
      const onClick = async () => {
        if (userInfo.isAnonymous) {
          redirectToLogin()
          return
        }

        let pat = ""

        if (isPro) {
          try {
            pat = await axios
              .get<string>("/api/user-pat")
              .then((res) => res.data)
          } catch (e) {
            notify(
              <ErrorMessage>
                {t("txt_something_wrong")}
              </ErrorMessage>,
            )
          }
        }

        editor.openPage({
          tool: "ai_music_generator",
          id: DEFORUM_STORAGE_KEY,
          pat,
        })
      }

      return {
        onClick,
      }
    }
  }
}

async function isImageFile(file: File) {
  return new Promise<boolean>((resolve) => {
    if (!file.type.startsWith("image/")) {
      resolve(false)
      return
    }
    const reader = new FileReader()
    reader.onload = function () {
      if (!reader.result) {
        resolve(false)
        return
      }

      const blob = new Blob([reader.result])

      createImageBitmap(blob).then(
        (_ok) => resolve(true),
        (_err) => resolve(false),
      )
    }

    reader.onerror = function () {
      resolve(false)
    }

    reader.readAsArrayBuffer(file)
  })
}

export async function isVideoFile(file: Blob) {
  return new Promise<boolean>((resolve) => {
    if (file.type === "video/mp4") {
      file = changeMimeType(file, "video/mp4")
    }

    if (!file.type.startsWith("video/")) {
      resolve(false)
      return
    }

    const element = document.createElement("video")
    element.preload = "metadata"
    element.muted = true
    element.onloadedmetadata = () => resolve(true)
    element.onerror = () => {
      resolve(false)
    }

    const url = URL.createObjectURL(file)
    element.src = url
  })
}

export async function isImageOrVideo(file: File) {
  const isImage = await isImageFile(file)

  return isImage || (await isVideoFile(file))
}

const defaultChannelsList = {
  data: {
    available_bitrates: [
      {
        bitrate: 32,
      },
      {
        bitrate: 96,
      },
      {
        bitrate: 128,
      },
      {
        bitrate: 192,
      },
      {
        bitrate: 256,
      },
      {
        bitrate: 320,
      },
    ],
    available_intensities: [
      {
        intensity: "low",
      },
      {
        intensity: "medium",
      },
      {
        intensity: "high",
      },
    ],
    categories: [
      {
        category_id: 0,
        name: "Moods",
        groups: [
          {
            group_id: 0,
            name: "Calm",
            playlist: "0.0",
          },
          {
            group_id: 1,
            name: "Energizing",
            playlist: "0.1",
          },
          {
            group_id: 2,
            name: "Joyful",
            playlist: "0.2",
          },
          {
            group_id: 3,
            name: "Sad",
            playlist: "0.3",
          },
          {
            group_id: 4,
            name: "Tense",
            playlist: "0.4",
          },
          {
            group_id: 5,
            name: "Beautiful",
            playlist: "0.5",
          },
          {
            group_id: 6,
            name: "Erotic",
            playlist: "0.6",
          },
          {
            group_id: 7,
            name: "Dreamy",
            playlist: "0.7",
          },
          {
            group_id: 8,
            name: "Heroic",
            playlist: "0.8",
          },
          {
            group_id: 10,
            name: "Scary",
            playlist: "0.10",
          },
        ],
      },
      {
        category_id: 1,
        name: "Focus",
        groups: [
          {
            group_id: 0,
            name: "Minimal",
            playlist: "1.0",
          },
        ],
      },
      {
        category_id: 2,
        name: "Sleep",
        groups: [
          {
            group_id: 0,
            name: "Ambient",
            playlist: "2.0",
          },
          {
            group_id: 1,
            name: "Classical",
            playlist: "2.1",
          },
          {
            group_id: 2,
            name: "Lullaby",
            playlist: "2.2",
          },
          {
            group_id: 3,
            name: "Noise",
            playlist: "2.3",
          },
          {
            group_id: 4,
            name: "Sounds",
            playlist: "2.4",
          },
        ],
      },
      {
        category_id: 3,
        name: "Calm",
        groups: [
          {
            group_id: 0,
            name: "Ambient",
            playlist: "3.0",
          },
          {
            group_id: 1,
            name: "Chill",
            playlist: "3.1",
          },
        ],
      },
      {
        category_id: 4,
        name: "Chill",
        groups: [
          {
            group_id: 0,
            name: "Chillout",
            playlist: "4.0",
          },
          {
            group_id: 1,
            name: "Ethnic",
            playlist: "4.1",
          },
          {
            group_id: 2,
            name: "Summer",
            playlist: "4.2",
          },
          {
            group_id: 3,
            name: "Travel",
            playlist: "4.3",
          },
        ],
      },
      {
        category_id: 5,
        name: "Sport",
        groups: [
          {
            group_id: 0,
            name: "Fitness",
            playlist: "5.0",
          },
          {
            group_id: 1,
            name: "Cardio",
            playlist: "5.1",
          },
          {
            group_id: 2,
            name: "Running",
            playlist: "5.2",
          },
          {
            group_id: 3,
            name: "Yoga",
            playlist: "5.3",
          },
        ],
      },
      {
        category_id: 6,
        name: "Genres",
        groups: [
          {
            group_id: 0,
            name: "EDM",
            playlist: "6.0",
          },
          {
            group_id: 1,
            name: "House",
            playlist: "6.1",
          },
          {
            group_id: 2,
            name: "Techno",
            playlist: "6.2",
          },
          {
            group_id: 3,
            name: "Chill",
            playlist: "6.3",
          },
          {
            group_id: 4,
            name: "Hiphop",
            playlist: "6.4",
          },
          {
            group_id: 5,
            name: "Ambient",
            playlist: "6.5",
          },
          {
            group_id: 6,
            name: "Electronica",
            playlist: "6.6",
          },
          {
            group_id: 7,
            name: "Trance",
            playlist: "6.7",
          },
          {
            group_id: 8,
            name: "Drumnbass",
            playlist: "6.8",
          },
          {
            group_id: 9,
            name: "Dub",
            playlist: "6.9",
          },
          {
            group_id: 10,
            name: "Bass",
            playlist: "6.10",
          },
          {
            group_id: 11,
            name: "Breakbeat",
            playlist: "6.11",
          },
          {
            group_id: 12,
            name: "Jazz & Funk",
            playlist: "6.12",
          },
          {
            group_id: 13,
            name: "Disco",
            playlist: "6.13",
          },
          {
            group_id: 15,
            name: "Pop",
            playlist: "6.15",
          },
          {
            group_id: 16,
            name: "Rock & Metal",
            playlist: "6.16",
          },
          {
            group_id: 17,
            name: "Classical",
            playlist: "6.17",
          },
          {
            group_id: 18,
            name: "Folk",
            playlist: "6.18",
          },
        ],
      },
      {
        category_id: 10,
        name: "Countries",
        groups: [
          {
            group_id: 0,
            name: "India",
            playlist: "10.0",
          },
        ],
      },
    ],
  },
}
