import { devices } from "@/utils/breakpoints"
import React, {
  createContext,
  useCallback,
  useState,
} from "react"
import styled, { css } from "styled-components"

export type NotificationFunction = (
  message: React.ReactNode,
  full?: boolean
) => void
/**
 * User gets this from context
 */
export interface NotificationSystem {
  notify: NotificationFunction
  timeout: number
}

/**
 * User initializes the provider with this options
 */
export interface NotificationOptions {
  timeout: number
}

/**
 * Props for each notification system stores
 */
export interface NotificationMessage {
  message: React.ReactNode
  counter: number
}

export const NotificationContext =
  createContext<NotificationSystem>({
    notify: (_: React.ReactNode): void => {
      throw new Error("Provider not used")
    },
    timeout: 3000,
  })

const NotificationAligner = styled.div`
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: var(--w-screen);

  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  @media ${devices.desktop} {
    right: -436px;
  }
`

const NotificationContainer = styled.div<{
  maxTop?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  align-self: stretch;

  margin-top: 64px;

  ${(props) =>
    props.maxTop &&
    css`
      margin-top: 0;
    `};

  @media ${devices.tablet} {
    margin-top: 106px;
    align-self: center;
    width: 523px;
  }

  @media ${devices.desktop} {
    align-self: flex-end;
    width: 872px;
  }
`

export interface NotificationProviderProps
  extends NotificationOptions {
  children: React.ReactNode
}

export const NotificationProvider = (
  props: NotificationProviderProps
) => {
  const { timeout } = props

  const [messages, setMessages] = useState<
    NotificationMessage[]
  >([])

  const [maxTop, setMaxTop] = useState(false)

  const notify = useCallback(
    (message: React.ReactNode, top = false) => {
      setMaxTop(top)
      const currentMessage = {
        message,
        counter: Math.random(),
      }
      setMessages([currentMessage, ...messages])
    },
    [messages]
  )

  return (
    <NotificationContext.Provider
      value={{ notify, timeout }}>
      <NotificationAligner>
        <NotificationContainer maxTop={maxTop}>
          {messages.map((query) => (
            <div key={query.counter}>{query.message}</div>
          ))}
        </NotificationContainer>
      </NotificationAligner>
      {props.children}
    </NotificationContext.Provider>
  )
}
