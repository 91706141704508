import useScrollListener from "@/utils/listener"
import { RefObject, useCallback } from "react"
import { UseInfiniteQueryResult } from "react-query"

const useInfiniteScroll = (
	query: UseInfiniteQueryResult,
	container: RefObject<HTMLDivElement>,
	offset = 0,
	enabled = true,
	parent : HTMLElement | null = null
) => {
	const scrollHandler = useCallback(() => {
		if (!enabled) return

		const elem = container.current
		if (!elem) {
			return
		}

		const { y, height } = elem.getBoundingClientRect()
		const bottom = y + height

		if (bottom > window.innerHeight + offset) {
			return
		}
		if (!query.hasNextPage) {
			return
		}
		if (query.isFetchingNextPage) {
			return
		}

		query.fetchNextPage().catch(console.error)
	}, [offset, container, query, enabled])

	useScrollListener(scrollHandler, parent)
}

export default useInfiniteScroll
