import { devices } from "@/utils/breakpoints"
import { NotificationContext } from "@/utils/notification"
import clsx from "clsx"
import React, { useContext } from "react"
import styled, { keyframes } from "styled-components"

const messageAppearMobile = keyframes`
	from {
		margin-top: -67px;
		opacity: 0;
	}
	to {
		margin-top: 0;
		opacity: 1;
	}
`

const MessageWrapper = styled.div`
  word-wrap: break-word;
  width: 360px;
`

const messageAppearTablet = keyframes`
	from {
		margin-top: -67px;
		opacity: 0;
	}
	to {
		margin-top: 0;
		opacity: 1;
	}
`

const messageAppearDesktop = keyframes`
	from {
		margin-left: 412px;
	}
	to {
		margin-left: 0;
	}
`

const messageDisappear = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`

const disappearDuration = 300

export const MessageContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 12px;

  align-items: center;

  padding: 21px 16px;

  font-weight: 400;
  font-size: 17px;
  line-height: 25px;

  box-shadow: 0 8px 29px rgba(41, 91, 48, 0.14);

  animation-name: ${messageAppearMobile},
    ${messageDisappear};
  animation-delay: 0ms,
    ${(_) =>
		useContext(NotificationContext).timeout -
		disappearDuration}ms;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-duration: 300ms, ${disappearDuration}ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  @media ${devices.tablet} {
    animation-name: ${messageAppearTablet},
      ${messageDisappear};
    animation-timing-function: ease-in-out;
  }

  @media ${devices.desktop} {
    animation-name: ${messageAppearDesktop},
      ${messageDisappear};
  }
`

const progressAnimation = keyframes`
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
`

const Progressbar = styled.div<{ duration: number }>`
  position: absolute;
  bottom: 0;
  left: 0;

  height: 2px;
  border-radius: 2px;

  z-index: 2;

  animation-name: ${progressAnimation};
  animation-delay: 0ms;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-duration: ${(_) =>
		useContext(NotificationContext).timeout}ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
`

const ProgressbarBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  z-index: 1;

  height: 2px;
  width: 100%;

  background-color: var(--color-background-cell);
`

const SuccessMessageContainer = styled(MessageContainer)`
  .light & {
    background: #edf7ef;
  }

  .dark & {
    background: #112c23;
  }

  & ${Progressbar} {
    background-color: var(--color-success);
  }
`

const SuccessIconContainer = styled.div`
  svg * {
    fill: var(--color-success);
    stroke: var(--color-success);
  }
`

const SuccessIcon = () => (
	<SuccessIconContainer>
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.71426 18L6.955 18.6508C7.13313 18.8586 7.38867 18.9843 7.66201 18.9986C7.93535 19.0129 8.20261 18.9146 8.40146 18.7265L7.71426 18ZM21.0872 6.72647C21.4884 6.34694 21.506 5.71402 21.1264 5.3128C20.7469 4.91158 20.114 4.894 19.7128 5.27353L21.0872 6.72647ZM4.35923 12.5492C3.99981 12.1299 3.36851 12.0813 2.94918 12.4407C2.52986 12.8002 2.4813 13.4315 2.84072 13.8508L4.35923 12.5492ZM8.40146 18.7265L21.0872 6.72647L19.7128 5.27353L7.02706 17.2735L8.40146 18.7265ZM8.47352 17.3492L4.35923 12.5492L2.84072 13.8508L6.955 18.6508L8.47352 17.3492Z"
				fill="#09C185"
			/>
		</svg>
	</SuccessIconContainer>
)

export interface MessageProps {
	children: React.ReactNode
	title?: string
}

export const SuccessMessage = (props: MessageProps) => {
	const { title } = props

	return (
		<SuccessMessageContainer>
			<SuccessIcon />
			<div
				className={clsx(
					title && "flex flex-col",
					"w-[360px] break-words"
				)}>
				{title && (
					<span className="text-[17px] font-600 text-blue-700">
						{title}
					</span>
				)}
				<p>{props.children}</p>
			</div>
			<Progressbar duration={4000} />
			<ProgressbarBackground />
		</SuccessMessageContainer>
	)
}

const WarningMessageContainer = styled(MessageContainer)`
  .light & {
    background: #e7eff9;
  }

  .dark & {
    background: #1b2134;
  }

  & ${Progressbar} {
    background-color: var(--color-warning);
  }
`

const WarningIconContainer = styled.div`
  svg *[fill="#4D76F6"] {
    fill: var(--color-warning);
  }
`

const WarningIcon = () => (
	<WarningIconContainer>
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12"
				cy="17.5393"
				r="0.923077"
				fill="#4D76F6"
			/>
			<line
				x1="12"
				y1="6.46214"
				x2="12"
				y2="13.8468"
				stroke="#4D76F6"
				strokeWidth="1.84615"
				strokeLinecap="round"
			/>
			<circle
				cx="12"
				cy="11.9998"
				r="10.1538"
				stroke="#4D76F6"
				strokeWidth="1.84615"
			/>
		</svg>
	</WarningIconContainer>
)

export const WarningMessage = (props: MessageProps) => {
	return (
		<WarningMessageContainer>
			<WarningIcon />
			<MessageWrapper>{props.children}</MessageWrapper>
			<Progressbar duration={4000} />
			<ProgressbarBackground />
		</WarningMessageContainer>
	)
}

const ErrorMessageContainer = styled(MessageContainer)`
  .light & {
    background: #f8edea;
  }

  .dark & {
    background: #351616;
  }

  & ${Progressbar} {
    background-color: var(--color-error);
  }
`

const ErrorIconContainer = styled.div`
  svg * {
    fill: var(--color-error);
    stroke: var(--color-error);
  }
`

const ErrorIcon = () => (
	<ErrorIconContainer>
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289Z"
				fill="#FC4040"
			/>
		</svg>
	</ErrorIconContainer>
)

export const ErrorMessage = (props: MessageProps) => {
	return (
		<ErrorMessageContainer>
			<ErrorIcon />
			<MessageWrapper>{props.children}</MessageWrapper>
			<Progressbar duration={4000} />
			<ProgressbarBackground />
		</ErrorMessageContainer>
	)
}
