import Button from "@/comps/button"
import { CustomThemedResource } from "@/comps/image"

import {
  getCurrentPlan,
  getCurrentPlanText,
  TOKENS_TO_VIDEOS_DIVISOR,
} from "@/comps/subscription-popup"
import { useAdjustLogger } from "@/utils/adjust"
import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import BlockSkelethon from "./block-skelethon"
import { PlansType, Variant } from "./pricing-or-coin"

interface RenderBlockProps {
  plans: PlansType
  isYearly: boolean
  handlePurchaseClick: () => void
  setPackId: (id: string) => void
  setPackIndex: (index: number) => void
  packIndex: number
}

export default function RenderBlock(
  props: RenderBlockProps,
) {
  const {
    plans,
    isYearly,
    handlePurchaseClick,
    setPackId,
    setPackIndex,
    packIndex,
  } = props

  const { t } = useTranslation()

  const { userInfo } = useAuth()
  const logAdjust = useAdjustLogger()
  const router = useRouter()

  const yearlyPrice =
    plans.advanced.variants?.[packIndex].yearly_price || 0
  const yearlyPriceDivided = yearlyPrice / 12
  const yearlyPriceFormatted = Math.floor(
    yearlyPriceDivided,
  )

  const currentPlanText = getCurrentPlanText(
    userInfo.entitlements,
    plans.advanced.variants?.[packIndex].pack_id ?? "",
    isYearly,
  )

  const currentPlan = getCurrentPlan(userInfo.entitlements)

  switch (plans) {
    case undefined:
      return <BlockSkelethon />
    default:
      return (
        <div className="mt-10 flex w-full flex-col items-center justify-center gap-6 px-4 tablet:px-5 desktop:flex-row desktop:gap-[27px]">
          {/* Free Plan */}

          {/* <div
            className={clsx(
              "flex w-full flex-col overflow-hidden rounded-[8px] border border-color-separator",
              "tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
              "[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
            )}>
            <div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between p-[18px]">
              <div className="flex flex-col">
                <p className="text-[24px] font-600 text-blue-900">
                  {t("txt_just_try")}
                </p>
                <p className="font-300 text-[14px] text-blue-600">
                  {t("txt_free_subtitle")}
                </p>
                <p className="mt-1 text-[32px] font-800 uppercase text-blue-800 tablet:text-[36px] desktop:mt-4 desktop:text-[32px]">
                  {t("txt_free")}
                </p>
              </div>
              <div className="mt-4 flex w-full tablet:mt-9 desktop:mt-0">
                <Button
                  outlineSecondary
                  href="/chat"
                  className={clsx(
                    "w-full !font-500",
                    currentPlan.id !== "free" &&
                      "pointer-events-none !cursor-not-allowed !border-green-100 !text-green-200",
                  )}
                  text="txt_get_started"></Button>
              </div>
            </div>
            <div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
            <div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
              {plans.free.points.map((point) => {
                return (
                  <div
                    className="flex items-center gap-[10px] "
                    key={point}>
                    <CustomThemedResource
                      format="svg"
                      source="/pricing/check"
                    />
                    <span className="text-[14px] font-400 text-blue-800">
                      {point}
                    </span>
                  </div>
                )
              })}
            </div>
          </div> */}

          {/*PRIME */}

          <div
            className={clsx(
              "flex w-full flex-col overflow-hidden rounded-[8px] border border-green-400",
              "tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
              "[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
            )}>
            <div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between p-[18px]">
              <div className="flex flex-col">
                <p className="text-[24px] font-600 text-blue-900">
                  {t("txt_pro_title")}
                </p>
                <p className="font-300 text-[14px] text-blue-600">
                  {t("txt_pro_subtitle")}
                </p>
                <div className="relative mt-3 h-[50px] w-full overflow-hidden">
                  <div
                    className={clsx(
                      "absolute inset-0",
                      "flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
                      isYearly && "-top-16",
                      "transition-all duration-500",
                    )}>
                    <span
                      className={clsx(
                        "font-700 text-blue-900",
                        "text-[38px]",
                      )}>
                      $
                      {String(
                        plans.advanced.variants?.[packIndex]
                          .monthly_price,
                      ).slice(0, -2)}
                      .
                      <small>
                        {String(
                          plans.advanced.variants?.[
                            packIndex
                          ].monthly_price,
                        ).slice(-2)}
                      </small>
                    </span>
                    <span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
                      /{t("txt_month")}
                    </span>
                  </div>

                  <div
                    className={clsx(
                      "absolute inset-0",
                      "flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
                      !isYearly && "top-16",
                      "transition-all duration-500",
                    )}>
                    <span
                      className={clsx(
                        "font-700 text-blue-900",
                        "text-[38px]",
                      )}>
                      $
                      {String(yearlyPriceFormatted).slice(
                        0,
                        -2,
                      ).length !== 0
                        ? String(
                            yearlyPriceFormatted,
                          ).slice(0, -2)
                        : 0}
                      .
                      <small>
                        {String(yearlyPriceFormatted).slice(
                          -2,
                        )}
                      </small>
                    </span>
                    <span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
                      /{t("txt_month")}*
                    </span>
                  </div>
                </div>
              </div>

              {plans.advanced.variants && (
                <SliderComponent
                  variants={plans.advanced.variants}
                  isYearly={isYearly}
                  setPackId={setPackId}
                  value={packIndex}
                  setValue={setPackIndex}
                />
              )}

              <div className="flex w-full">
                <Button
                  secondary
                  disabled={
                    currentPlanText === "txt_current_plan"
                  }
                  onClick={handlePurchaseClick}
                  className="w-full !font-500 disabled:!bg-green-100 disabled:!text-color-inactive-text disabled:hover:!bg-green-100"
                  text={currentPlanText}></Button>
              </div>
            </div>
            <div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
            <div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
              <div className="flex items-center gap-[10px] ">
                <CustomThemedResource
                  format="svg"
                  source="/pricing/check"
                />
                <span className="text-[14px] font-400 text-blue-800">
                  {isYearly
                    ? t("fs_tokens_to_videos_year", {
                        tokens:
                          plans.advanced.variants?.[
                            packIndex
                          ].yearly_tokens,
                        videos: Math.ceil(
                          (plans.advanced.variants?.[
                            packIndex
                          ].yearly_tokens ?? 0) /
                            TOKENS_TO_VIDEOS_DIVISOR,
                        ),
                      })
                    : t("fs_tokens_to_videos_month", {
                        tokens:
                          plans.advanced.variants?.[
                            packIndex
                          ].monthly_tokens,
                        videos: Math.ceil(
                          (plans.advanced.variants?.[
                            packIndex
                          ].monthly_tokens ?? 0) /
                            TOKENS_TO_VIDEOS_DIVISOR,
                        ),
                      })}
                </span>
              </div>
              {plans.advanced.points.map((point) => {
                return (
                  <div
                    className="flex items-center gap-[10px] "
                    key={point}>
                    <CustomThemedResource
                      format="svg"
                      source="/pricing/check"
                    />
                    <span className="text-[14px] font-400 text-blue-800">
                      {point}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>

          {/* Enterprise */}

          <div
            className={clsx(
              "flex w-full flex-col overflow-hidden rounded-[8px] border border-color-separator",
              "tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
              "[box-shadow:0px_5px_12px_0px_rgba(183,183,183,0.2)] dark:[box-shadow:unset]",
            )}>
            <div className="bg-color-surface-cell flex w-full flex-1 flex-col justify-between p-[18px]">
              <div className="flex flex-col">
                <p className="text-[24px] font-600 text-blue-900">
                  {t("txt_enterprise_title")}
                </p>
                <p className="font-300 text-[14px] text-blue-600">
                  {t("txt_enterprise_subtitle")}
                </p>
                <p className="mt-3 text-[38px] font-700 text-blue-800">
                  {t("txt_custom")}
                </p>
              </div>
              <div className="mt-8 flex w-full tablet:mt-12 desktop:mt-0">
                <Button
                  outlineSecondary
                  href={plans.enterprise.contact_url}
                  onClick={() =>
                    logAdjust?.logEventWithPayload(
                      "w_dp_cs",
                      { from: router.asPath },
                    )
                  }
                  className="w-full !font-500"
                  text="txt_contact_sales"></Button>
              </div>
            </div>
            <div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
            <div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
              {plans.enterprise.points.map((point) => {
                return (
                  <div
                    className="flex items-center gap-[10px] "
                    key={point}>
                    <CustomThemedResource
                      format="svg"
                      source="/pricing/check"
                    />
                    <span className="text-[14px] font-400 text-blue-800">
                      {point}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
  }
}

interface SliderProps {
  variants: Variant[]
  isYearly: boolean
  setPackId: (pack_id: string) => void
  value: number
  setValue: (index: number) => void
}

export function SliderComponent(props: SliderProps) {
  const { variants, isYearly, setPackId, value, setValue } =
    props

  const values = variants.map((a) =>
    isYearly ? a.yearly_tokens : a.monthly_tokens,
  )

  const min = 0
  const max = values.length - 1

  const selectedPart = (value / (values.length - 1)) * 100

  let selectedPosition = `${selectedPart}%`

  if (selectedPart === 0) {
    selectedPosition = `22px`
  } else if (selectedPart !== 0 && selectedPart < 100) {
    selectedPosition = `calc(${selectedPart}% + 10px)`
  }

  return (
    <div className="my-10 flex flex-col tablet:my-10 desktop:my-0">
      <div className="relative">
        <input
          type="range"
          min={min}
          max={max}
          step={1}
          value={value}
          className="relative z-[100] w-full cursor-grab opacity-0 active:cursor-grabbing"
          onChange={(e) => {
            const newSelected = parseInt(
              e.currentTarget.value,
            )
            const variant = variants[newSelected]
            const packId = variant.pack_id

            setValue(newSelected)
            setPackId(packId)
          }}
        />

        <div>
          <div className="absolute left-0 top-1/2 z-10 h-5 w-full -translate-y-1/2 rounded-[18px] border border-color-separator bg-color-background" />

          <div className="absolute left-0 top-1/2 z-30 h-5 w-full -translate-y-1/2 overflow-hidden rounded-[18px] p-[2px]">
            <div
              className="h-full rounded-[18px] bg-green-500 transition-all"
              style={{
                width: selectedPosition,
              }}
            />
          </div>

          {values.map((_, index) => {
            const part = index / (values.length - 1)
            let position = `${part * 100}%`

            if (part === 0) {
              position = `13px`
            } else if (part === 1) {
              position = `calc(${part * 100}% - 13px)`
            }

            return (
              <div
                key={index}
                className={clsx(
                  "absolute top-1/2 z-50 h-[4px] w-[4px] -translate-y-1/2 rounded-full transition-all",
                  value === index && " scale-[2]",
                  index > value
                    ? "bg-blue-300"
                    : "bg-color-white",
                )}
                style={{
                  left: position,
                }}
              />
            )
          })}
        </div>
      </div>
      <div className="relative mt-1 h-4">
        {values.map((token, index) => {
          const part = index / (values.length - 1)
          const position = part

          const firstPoint = index === 0
          const lastPoint = index === values.length - 1
          const middlePoint = !firstPoint && !lastPoint

          return (
            <button
              key={token}
              onClick={(e) => {
                e.preventDefault()
                const variant = variants[index]
                const packId = variant.pack_id

                setValue(index)
                setPackId(packId)
              }}
              className={clsx(
                "absolute bottom-0",
                "text-[13px] transition-colors",
                middlePoint && "-translate-x-1/2",
                lastPoint && "-translate-x-full",
                value === index
                  ? "font-600 text-blue-800"
                  : "font-500 text-blue-400",
              )}
              style={{ left: `${position * 100}%` }}>
              {token}
            </button>
          )
        })}
      </div>
    </div>
  )
}
