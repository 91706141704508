import {
	PromptState,
	useVideoClipper,
} from "@/comps/ai-config"
import { AiMusicGeneratorStorageSchema } from "@/comps/editors/ai-music-generator"
import { mimeTypes } from "@/comps/simple-drop-area"
import { CameraStyle } from "@/ssr/ai-tools"
import { useAdjustLogger } from "@/utils/adjust"
import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { IndexedDBStores } from "@/utils/indexed"
import { changeMimeType } from "@/utils/mime-types"
import {
	getImageDimensions,
	getVideoDimensions,
	loadUrlToBlob,
	resizeDimensions,
} from "@/utils/video"
import axios from "axios"
import { DeforumImageUrlResponse } from "pages/api/deforum-image-url"
import {
	AllParams,
	ParamsResponse,
	PromptOption,
	PromptParams,
} from "pages/api/deforum-params"
import {
	AISubmitRequest,
	AISubmitResponse,
} from "pages/api/deforum-submit"
import { DeforumVideoUrlResponse } from "pages/api/deforum-video-url"
import { aiToolSchema } from "pages/api/gallery-all"
import { FeatureTools } from "pages/feature/[feature]"
import {
	Reducer,
	useCallback,
	useEffect,
	useReducer,
	useRef,
} from "react"
import { useQueryClient } from "react-query"
import { z, ZodSchema, ZodTypeDef } from "zod"

export const MIN_RESOLUTION = 450

export const DEFORUM_STORAGE_KEY = "last"
const channelsSchema = z.object({
	available_bitrates: z.array(
		z.object({
			bitrate: z.number(),
		}),
	),
	available_intensities: z.array(
		z.object({
			intensity: z.union([
				z.literal("low"),
				z.literal("medium"),
				z.literal("high"),
			]),
		}),
	),
	categories: z.array(
		z.object({
			category_id: z.number(),
			name: z.string(),
			groups: z.array(
				z.object({
					name: z.string(),
					playlist: z.string(),
				}),
			),
		}),
	),
})

export const promptSchema = z.union([
	z.object({
		type: z.literal("custom"),
		content: z.array(z.string()),
	}),
	z.object({
		type: z.literal("predefined"),
		id: z.string(),
	}),
])

const channelsResponseSchema = z.object({
	data: channelsSchema,
})

const remoteStorageSchema = z.object({
	location: z.literal("remote"),
	url: z.string(),
})

const localStorageSchema = z.object({
	location: z.literal("local"),
	blob: z.unknown(),
})

const deforumStorageSchema = z
	.object({
		tool: z.literal("deform").default("deform"),
		id: z.literal(DEFORUM_STORAGE_KEY),
		promptType: z.string(),
		inputs: z.array(
			z.object({
				id: z.string(),
				text: z.string(),
			}),
		),
	})
	.and(z.union([remoteStorageSchema, localStorageSchema]))

const restyleStorageSchema = z
	.object({
		tool: z.literal("ai_restyle").default("ai_restyle"),
		id: z.literal(DEFORUM_STORAGE_KEY),
		promptType: z.string().nullable(),
		input: z.string(),
	})
	.and(z.union([remoteStorageSchema, localStorageSchema]))

const photoAiStorageSchema = z
	.object({
		tool: z.literal("ai_photo").default("ai_photo"),
		id: z.literal(DEFORUM_STORAGE_KEY),
		promptType: z.string().nullable(),
		input: z.string(),
	})
	.and(z.union([remoteStorageSchema, localStorageSchema]))

const textToImageStorageSchema = z
	.object({
		tool: z
			.literal("text_to_image")
			.default("text_to_image"),
		id: z.literal(DEFORUM_STORAGE_KEY),
		promptType: z.string().nullable(),
		text: z.string(),
	})
	.and(z.union([remoteStorageSchema, localStorageSchema]))

const textToVideoStorageSchema = z
	.object({
		tool: z
			.literal("text_to_video")
			.default("text_to_video"),
		id: z.literal(DEFORUM_STORAGE_KEY),
		promptType: z.string().nullable(),
		text: z.string(),
	})
	.and(z.union([remoteStorageSchema, localStorageSchema]))

export type AITool = z.infer<typeof aiToolSchema>

export type Prompt = z.infer<typeof promptSchema>

export type ChannelsParams = z.infer<typeof channelsSchema>

export type DeformEditorProps = z.infer<
	typeof deforumStorageSchema
>

export type RestyleEditorProps = z.infer<
	typeof restyleStorageSchema
>

export type PhotoAiEditorProps = z.infer<
	typeof photoAiStorageSchema
>

export type TextToImageEditorProps = z.infer<
	typeof textToImageStorageSchema
>

export type TextToVideoEditorProps = z.infer<
	typeof textToVideoStorageSchema
>
export type AiMusicGeneratorEditorProps = z.infer<
	typeof AiMusicGeneratorStorageSchema
>

export type DynamicEditorProps =
	| DeformEditorProps
	| RestyleEditorProps
	| PhotoAiEditorProps
	| (TextToImageEditorProps & {
			isModelUse?: {
				name: string
				id: number
			}
	  })
	| TextToVideoEditorProps
	| AiMusicGeneratorEditorProps

export interface InputWithId {
	id: string
	text: string
}

export type Filters = {
	styles: PromptOption[]
	otherParams: AllParams
}

export type SubmitImageGetter = {
	getter: () => Promise<Blob>
}

export interface MediaPreviewProps {
	file: Blob
	setDataGetter: (callback: SubmitImageGetter) => void
	removeUploaded: () => void
}

function createPromptStateFrom(
	params: PromptParams[] | null,
): PromptState | null {
	if (!params) return null
	return params.reduce((acc, param) => {
		switch (param.type) {
			case "switcher":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "slider":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "cameras":
				return {
					...acc,
					[param.id]: "",
				}
			case "checkbox":
				return {
					...acc,
					[param.id]: Boolean(param.defaultValue),
				}
			case "select":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "dynamic":
				return acc
		}
	}, {})
}

export type EditorsReducerAction =
	| {
			name: "initial_state"
	  }
	| {
			name: "set_filters_and_channels"
			styles: PromptOption[]
			params: AllParams
			channels: ChannelsParams | null
			blob: Blob | null
	  }
	| {
			name: "add_user_uploaded_file"
			blob: Blob | null
	  }
	| {
			name: "set_data_getter"
			data: null | SubmitImageGetter
	  }
	| {
			name: "change_propmp_type"
			type: string
	  }
	| {
			name: "change_camera_style"
			style: CameraStyle | null
	  }
	| {
			name: "change_current_prompt_state"
			newState: PromptState
	  }
	| {
			name: "set_user_prompt"
			prompt: string
	  }
	| {
			name: "set_selected_model"
			model: { name: string; id: number } | null
	  }
	| {
			name: "set_user_custom_style"
			prompt: string
	  }
	| {
			name: "reset_tool_filters"
	  }
	| {
			name: "select_aspect"
			aspect: ToolAspect
	  }
	| {
			name: "change_keep_face_value"
	  }
	| {
			name: "change_selected_tool"
			content: DynamicEditorProps
	  }

function getStateForTool(
	state: EditorsReducerState,
	action: DynamicEditorProps,
): EditorsReducerState {
	switch (action.tool) {
		case "deform":
			return {
				...state,
				tool: action.tool,
				promptType: action.promptType,
			}
		case "ai_photo":
		case "ai_restyle":
			return {
				...state,
				tool: action.tool,
				promptType: action.promptType,
			}
		case "text_to_image":
		case "text_to_video":
			return {
				...state,
				tool: action.tool,
				userPrompt: action.text,
				promptType: action.promptType ?? "photoreal_tti",
			}
		default:
			return state
	}
}

const dashboardReducer: Reducer<
	EditorsReducerState,
	EditorsReducerAction
> = (state, action): EditorsReducerState => {
	switch (action.name) {
		case "initial_state":
			return {
				...state,
			}

		case "change_selected_tool":
			return getStateForTool(state, action.content)

		case "set_filters_and_channels":
			return {
				...state,
				styles: action.styles,
				params: action.params,
				channels: action.channels,
				blob: action.blob,
				promptState: action.params.params
					? action.params.params.reduce((acc, a) => {
							if (
								a.type !== "cameras" &&
								a.type !== "dynamic"
							) {
								acc[a.id] = a.defaultValue
							} else {
								acc[a.id] = ""
							}
							return acc
					  }, {} as PromptState)
					: null,
			}

		case "add_user_uploaded_file":
			return {
				...state,
				blob: action.blob,
			}

		case "set_data_getter":
			return {
				...state,
				getData: action.data,
			}

		case "change_propmp_type":
			return {
				...state,
				promptType: action.type,
			}

		case "change_camera_style":
			return {
				...state,
				promptState: {
					...state.promptState,
					cameras: action.style?.id ?? "",
				},
				cameraStyle: action.style,
			}

		case "change_current_prompt_state":
			return { ...state, promptState: action.newState }

		case "set_user_prompt":
			return { ...state, userPrompt: action.prompt }

		case "set_selected_model":
			return { ...state, selectedModel: action.model }

		case "set_user_custom_style":
			return { ...state, userCustomStyle: action.prompt }

		case "select_aspect":
			return { ...state, selectedAspect: action.aspect }

		case "change_keep_face_value":
			return { ...state, keepFace: !state.keepFace }

		case "reset_tool_filters":
			if (state.styles === null || state.params === null) {
				return state
			}

			return {
				tool: state.tool,
				blob: state.blob,
				channels: state.channels,
				prompts: state.prompts,
				styles: state.styles,
				params: state.params,
				getData: null,
				promptType: "custom",
				cameraStyle: null,
				promptState: createPromptStateFrom(
					state.params.params,
				),
				selectedModel: null,
				userPrompt: "",
				userCustomStyle: "",
				selectedAspect: aspects[0],
				keepFace: false,
			}
	}
}

export interface EditorsControllerInitialData {
	tool: AITool | null
}

export type EditorsReducerState = {
	tool: AITool | null
	styles: PromptOption[] | null
	params: AllParams | null
	channels: ChannelsParams | null
	blob: Blob | null
	getData: null | SubmitImageGetter
	promptType: string | null
	prompts: Prompt | null
	cameraStyle: CameraStyle | null
	promptState: PromptState | null
	selectedModel: { name: string; id: number } | null
	userPrompt: string
	userCustomStyle: string
	selectedAspect: ToolAspect | null
	keepFace: boolean
}

export interface EditorsController {
	styles: PromptOption[] | null
	params: AllParams | null
	channels: ChannelsParams | null
	uploadedFile: Blob | null
	hasNotSelectedTool: boolean
	promptType: string
	promptState: PromptState | null
	cameraStyle: CameraStyle | null
	selectedModel: {
		name: string
		id: number
	} | null
	userPrompt: string
	userCustomStyle: string
	isKeepFaceNotAllowed: boolean
	setSelectedModel: (
		v: {
			name: string
			id: number
		} | null,
	) => void
	setUserPrompt: (v: string) => void
	selectCameraStyle: (v: CameraStyle | null) => void
	loadToolFilters: (
		props: DynamicEditorProps,
		isPro: boolean,
	) => Promise<void>
	setFile: (file: File | null) => void
	setDataGetter: (v: null | SubmitImageGetter) => void
	generateResult: () => Promise<void>
	changePromptType: (type: string) => void
	setCurrentPromptState: (v: PromptState) => void
	setUserCustomStyle: (v: string) => void
	resetToolFilters: () => void
	selectAspect: (v: ToolAspect) => void
	changeKeepFaceValue: () => void
	changeSelectedTool: (content: DynamicEditorProps) => void
	getPayloadFromTool: () => DynamicEditorProps
	// getDefaults: () => void
}

export function useEditorsController(
	initialData: EditorsControllerInitialData,
): EditorsController {
	const [state, dispatch] = useReducer(dashboardReducer, {
		tool: initialData.tool,
		styles: null,
		params: null,
		channels: null,
		blob: null,
		getData: null,
		promptType:
			initialData.tool === "text_to_image"
				? null
				: "custom",
		prompts: null,
		cameraStyle: null,
		promptState: null,
		selectedModel: null,
		userPrompt: "",
		userCustomStyle: "",
		selectedAspect: aspects[0],
		keepFace: false,
	})
	const controller = useVideoClipper({
		file: state.blob,
	})

	const logAdjust = useAdjustLogger()
	const queryClient = useQueryClient()

	const isKeepFaceNotAllowed = useRef<boolean>(true)

	useEffect(() => {
		if (state.styles) {
			isKeepFaceNotAllowed.current =
				state.styles.find(
					(style) => style.id === state.promptType,
				)?.ignoreFace ?? false
		}
	}, [state.promptType, state.styles])

	const initialConfig = createPromptStateFrom(
		state.params?.params ?? null,
	)

	const sectionsConfig = state.params?.sections
		? state.params?.sections.map((section) =>
				createPromptStateFrom(section.components),
		  )
		: null

	const config = sectionsConfig
		? sectionsConfig.reduce((acc, section) => {
				return section ? { ...acc, ...section } : acc
		  }, initialConfig || null)
		: initialConfig || null

	function changeKeepFaceValue() {
		dispatch({ name: "change_keep_face_value" })
	}

	function selectAspect(aspect: ToolAspect) {
		dispatch({ name: "select_aspect", aspect })
	}

	function resetToolFilters() {
		dispatch({ name: "reset_tool_filters" })
	}

	function setUserCustomStyle(v: string) {
		dispatch({ name: "set_user_custom_style", prompt: v })
	}

	function setUserPrompt(v: string) {
		dispatch({ name: "set_user_prompt", prompt: v })
	}

	function setSelectedModel(
		v: { name: string; id: number } | null,
	) {
		dispatch({ name: "set_selected_model", model: v })
	}

	function setCurrentPromptState(v: PromptState) {
		dispatch({
			name: "change_current_prompt_state",
			newState: v,
		})
	}

	function selectCameraStyle(v: CameraStyle | null) {
		dispatch({ name: "change_camera_style", style: v })
	}

	function changePromptType(type: string) {
		dispatch({ name: "change_propmp_type", type })
	}

	const setDataGetter = useCallback(
		(data: null | SubmitImageGetter) => {
			dispatch({ name: "set_data_getter", data })
		},
		[],
	)

	const setFile = (file: File | null) => {
		dispatch({
			name: "add_user_uploaded_file",
			blob: file,
		})
	}

	async function loadToolFilters(
		props: DynamicEditorProps,
		// isPro: boolean,
	) {
		const pat =
			props.tool === "ai_music_generator" ? props.pat : null
		const [params, channels] = await Promise.all([
			getOtherParams(props.tool),
			getChannels(props.tool, true, pat),
		])

		let blob: Blob | null
		if (props.tool !== "ai_music_generator") {
			if (props.location === "remote" && props.url) {
				blob = await loadUrlToBlob(props.url)
			} else {
				if (
					props.location === "local" &&
					props.blob instanceof Blob
				) {
					blob = props.blob
				} else {
					blob = null
				}
			}
		} else {
			blob = null
		}

		if (params) {
			dispatch({
				name: "set_filters_and_channels",
				styles: params?.styles,
				params: {
					sections: params.params.sections,
					price: params.params.price,
					params: params.params.params,
				},
				channels,
				blob,
			})
		}
	}

	function changeSelectedTool(content: DynamicEditorProps) {
		dispatch({
			name: "change_selected_tool",
			content,
		})
	}

	async function getOtherParams(tool: FeatureTools) {
		if (tool !== "ai_music_generator") {
			const result = await axios
				.post<ParamsResponse>("/api/deforum-params", {
					tool,
				})
				.then((res) => res.data)

			return { ...result, channels: null }
		} else {
			return null
		}
	}

	async function getChannels(
		tool: FeatureTools,
		isPro: boolean,
		pat: string | null,
	) {
		if (tool === "ai_music_generator" && isPro && pat) {
			try {
				const submitData = {
					method: "GetPlayMusic",
					params: {
						pat,
					},
				}

				const response = await axios
					.post<unknown>(
						"https://api-b2b.mubert.com/v2/GetPlayMusic",
						submitData,
					)
					.then((res) => res.data)

				const result =
					channelsResponseSchema.parse(response)

				const {
					available_bitrates,
					available_intensities,
					categories,
				} = result.data

				return {
					available_bitrates,
					available_intensities,
					categories,
				}
			} catch {
				const result = channelsResponseSchema.parse(
					defaultChannelsList,
				)

				const {
					available_bitrates,
					available_intensities,
					categories,
				} = result.data

				return {
					available_bitrates,
					available_intensities,
					categories,
				}
			}
		} else {
			const result = channelsResponseSchema.parse(
				defaultChannelsList,
			)

			const {
				available_bitrates,
				available_intensities,
				categories,
			} = result.data

			return {
				available_bitrates,
				available_intensities,
				categories,
			}
		}
	}

	function createInput(text = ""): InputWithId {
		return {
			text,
			id: Math.random().toString(),
		}
	}

	function createInputs(initial: Prompt): InputWithId[] {
		if (initial.type === "predefined") {
			return [createInput()]
		}

		return initial.content.map(createInput)
	}

	function getPayloadFromTool(): DynamicEditorProps {
		switch (state.tool) {
			case "deform":
				const inputs =
					state.promptType === "custom"
						? createInputs({
								type: "custom",
								content: [""],
						  })
						: createInputs({
								type: "predefined",
								id: state.promptType ?? "",
						  })
				return {
					tool: "deform",
					id: DEFORUM_STORAGE_KEY,
					inputs,
					promptType: state.promptType ?? "custom",
					location: "local",
					blob: state.blob,
				}

			case "ai_photo":
			case "ai_restyle":
				return {
					tool: state.tool,
					id: DEFORUM_STORAGE_KEY,
					input: "",
					promptType: state.promptType ?? "custom",
					location: "local",
					blob: state.blob,
				}

			case "text_to_video":
			case "text_to_image":
				return {
					tool: state.tool,
					id: DEFORUM_STORAGE_KEY,
					promptType: state.promptType,
					text: state.userPrompt,
					location: "local",
					blob: state.blob,
				}
			default:
				const prompts =
					state.promptType === "custom"
						? createInputs({
								type: "custom",
								content: [""],
						  })
						: createInputs({
								type: "predefined",
								id: state.promptType ?? "",
						  })
				return {
					tool: "deform",
					id: DEFORUM_STORAGE_KEY,
					inputs: prompts,
					promptType: state.promptType ?? "custom",
					location: "local",
					blob: state.blob,
				}
		}
	}

	async function generateResult() {
		switch (state.tool) {
			case "ai_photo":
				try {
					if (!state.getData) {
						throw new Error("Should have set getData")
					}

					const file = await state.getData.getter()

					let res = null
					if (config && config["resolution"]) {
						res = Number(config["resolution"])
					}
					const resolution = res ?? MIN_RESOLUTION

					const { upload, download, contentType } =
						await axios
							.post<DeforumImageUrlResponse>(
								"/api/deforum-image-url",
								{ content: file.type },
							)
							.then((res) => res.data)

					await axios.put(upload, file, {
						headers: {
							"Content-Type": contentType,
							"web-version": HEADER_WEB_VERSION,
						},
					})
					const dimensions = await getImageDimensions(file)

					const changedDimensions = resizeDimensions(
						dimensions,
						resolution,
					)

					let face = null
					if (!isKeepFaceNotAllowed && state.keepFace)
						face = download

					const submitData: AISubmitRequest = {
						url: download,
						style:
							state.promptType === "custom"
								? null
								: state.promptType,
						additional: state.userCustomStyle,
						tool: "ai_photo",
						params: state.promptState ?? {
							resolution: 720,
						},
						face_object_url: face,
						...changedDimensions,
					}

					await axios
						.post<AISubmitResponse>(
							"/api/deforum-submit",
							submitData,
						)
						.then((res) => res.data)
				} catch (error) {
					console.error(error)
				}

				break

			case "ai_restyle":
				try {
					if (!state.getData) {
						throw new Error("Should have set getData")
					}

					const file = await state.getData.getter()

					let res = null
					if (config && config["resolution"]) {
						res = Number(config["resolution"])
					}
					const resolution = res ?? MIN_RESOLUTION

					const { upload, download, contentType } =
						await axios
							.post<DeforumVideoUrlResponse>(
								"/api/deforum-video-url",
								{ content: file.type },
							)
							.then((res) => res.data)

					await axios.put(upload, file, {
						headers: {
							"Content-Type": contentType,
							"web-version": HEADER_WEB_VERSION,
						},
					})
					if (!controller) {
						throw new Error("Controller is not ready")
					}

					const dimensions = await getVideoDimensions(file)
					const changedDimensions = resizeDimensions(
						dimensions,
						resolution,
					)

					const submitData: AISubmitRequest = {
						url: download,
						additional: state.userCustomStyle,
						style: state.promptType,
						tool: "ai_restyle",
						start: controller.startTime,
						duration: controller.selectedDuration,
						params: state.promptState ?? {
							resolution: 720,
						},
						price: calculateDurationPrice(
							controller.selectedDuration,
						),
						...changedDimensions,
					}

					await axios
						.post<AISubmitResponse>(
							"/api/deforum-submit",
							submitData,
						)
						.then((res) => res.data)
				} catch (error) {
					console.error(error)
				}

				break

			case "deform":
				try {
					if (!state.getData) {
						throw new Error("Should have set getData")
					}

					const file = await state.getData.getter()

					let res = null
					if (config && config["resolution"]) {
						res = Number(config["resolution"])
					}
					const resolution = res ?? MIN_RESOLUTION

					const { upload, download, contentType } =
						await axios
							.post<DeforumImageUrlResponse>(
								"/api/deforum-image-url",
								{ content: file.type },
							)
							.then((res) => res.data)

					await axios.put(upload, file, {
						headers: {
							"Content-Type": contentType,
							"web-version": HEADER_WEB_VERSION,
						},
					})
					const dimensions = await getImageDimensions(file)
					const changedDimensions = resizeDimensions(
						dimensions,
						resolution,
					)

					const prompts: Prompt =
						state.prompts?.type === "custom"
							? {
									type: "custom",
									content: state.prompts.content.map(
										(input) => input,
									),
							  }
							: {
									type: "predefined",
									id: state.promptType ?? "",
							  }

					const submitData: AISubmitRequest = {
						url: download,
						prompts: prompts,
						tool: "deform",
						params: state.promptState ?? {
							resolution: 720,
						},
						...changedDimensions,
					}

					await axios
						.post<AISubmitResponse>(
							"/api/deforum-submit",
							submitData,
						)
						.then((res) => res.data)

					if (state.promptType === "custom") {
						logAdjust?.logEvent("deform_ai_prompt_writing")
					} else {
						logAdjust?.logEvent("deform_ai_select_style")
					}

					logAdjust?.logEvent("deform_ai_generate")
				} catch (error) {
					console.error(error)
				}
				break

			case "text_to_image":
				try {
					const file = state.blob

					let res = null
					if (config && config["resolution"]) {
						res = Number(config["resolution"])
					}
					const resolution = res ?? MIN_RESOLUTION

					const { upload, download, contentType } = file
						? await axios
								.post<DeforumImageUrlResponse>(
									"/api/deforum-image-url",
									{ content: file.type },
								)
								.then((res) => res.data)
						: {
								upload: null,
								download: null,
								contentType: null,
						  }

					upload &&
						(await axios.put(upload, file, {
							headers: {
								"Content-Type": contentType,
								"web-version": HEADER_WEB_VERSION,
							},
						}))
					const aspect = state.selectedAspect
						? state.selectedAspect?.aspectWidth /
						  state.selectedAspect?.aspectHeight
						: 1
					const calculatedDimensions = calculateDimensions(
						resolution,
						aspect,
					)

					const submitData: AISubmitRequest = {
						prompt: state.userPrompt,
						style: state.promptType,
						tool: "text_to_image",
						params: state.promptState ?? {
							resolution: 720,
						},
						face_object_url: download,
						// compositions:
						// 	state.selectedModel &&
						// 		imagePosition &&
						// 		selectedComposition
						// 		? [
						// 			{
						// 				url: selectedComposition,
						// 				rotation: rotation,
						// 				bg_aspect: aspect,
						// 				c_x: centerX,
						// 				c_y: centerY,
						// 				width_ratio:
						// 					imagePosition?.widthRatio,
						// 			},
						// 		]
						// 		: undefined,
						prompt_model_tags: state.selectedModel
							? [
									{
										id: state.selectedModel.id,
										tag: state.selectedModel.name,
									},
							  ]
							: undefined,
						...calculatedDimensions,
					}

					await axios
						.post<AISubmitResponse>(
							"/api/deforum-submit",
							submitData,
						)
						.then((res) => res.data)
				} catch (error) {
					console.error(error)
				}

				break
		}

		queryClient.invalidateQueries("deforum-last")
	}

	return {
		setDataGetter,
		changeSelectedTool,
		styles: state.styles,
		params: state.params,
		channels: state.channels,
		promptType:
			state.promptType === null
				? state.styles?.[0].id ?? "photoreal_tti"
				: state.promptType,
		loadToolFilters,
		uploadedFile: state.blob,
		hasNotSelectedTool: state.tool === null,
		generateResult,
		changePromptType,
		promptState: config,
		cameraStyle: state.cameraStyle,
		selectCameraStyle,
		setCurrentPromptState,
		selectedModel: state.selectedModel,
		setUserPrompt,
		setSelectedModel,
		userPrompt: state.userPrompt,
		setFile,
		userCustomStyle: state.userCustomStyle,
		setUserCustomStyle,
		resetToolFilters,
		selectAspect,
		isKeepFaceNotAllowed: isKeepFaceNotAllowed.current,
		changeKeepFaceValue,
		getPayloadFromTool,
	}
}

async function isImageFile(file: File) {
	return new Promise<boolean>((resolve) => {
		if (!file.type.startsWith("image/")) {
			resolve(false)
			return
		}
		const reader = new FileReader()
		reader.onload = function () {
			if (!reader.result) {
				resolve(false)
				return
			}

			const blob = new Blob([reader.result])

			createImageBitmap(blob).then(
				(_ok) => resolve(true),
				(_err) => resolve(false),
			)
		}

		reader.onerror = function () {
			resolve(false)
		}

		reader.readAsArrayBuffer(file)
	})
}

export async function isVideoFile(file: Blob) {
	return new Promise<boolean>((resolve) => {
		if (file.type === "video/mp4") {
			file = changeMimeType(file, "video/mp4")
		}

		if (!file.type.startsWith("video/")) {
			resolve(false)
			return
		}

		const element = document.createElement("video")
		element.preload = "metadata"
		element.muted = true
		element.onloadedmetadata = () => resolve(true)
		element.onerror = () => {
			resolve(false)
		}

		const url = URL.createObjectURL(file)
		element.src = url
	})
}

export async function isImageOrVideo(file: File) {
	const isImage = await isImageFile(file)

	return isImage || (await isVideoFile(file))
}

interface MediaDimensions {
	width: number
	height: number
}

function calculateDimensions(
	resolutionWidth: number,
	aspectRatio: number,
): MediaDimensions {
	const height = resolutionWidth / aspectRatio
	return {
		width: resolutionWidth,
		height: Math.ceil(height),
	}
}

const MAX_FREE_MILLIS = 1_000
const COINS_PER_SECOND = 50
export function calculateDurationPrice(
	duration?: number,
): number {
	if (!duration) {
		return 0
	}

	if (duration <= MAX_FREE_MILLIS) {
		return 0
	}

	const additionalDuration = duration - MAX_FREE_MILLIS

	return Math.floor(
		(additionalDuration * COINS_PER_SECOND) / 1_000,
	)
}

const actionMimes = {
	deform: ["images", "videos"],
	restyle: ["videos"],
	photo_ai: ["images"],
} as const

export function getAcceptedMimes(): string {
	const mimes = [...actionMimes["photo_ai"]]
	const empty: string[] = []
	const allMimes = mimes.reduce(
		(all, single) => [...all, ...mimeTypes[single]],
		empty,
	)

	return allMimes.join(", ")
}

type GetStorageResponse = {
	tool: IndexedDBStores
	storage: ZodSchema<
		DynamicEditorProps,
		ZodTypeDef,
		unknown
	>
}

export function getStorage(
	tool: AITool,
): GetStorageResponse {
	switch (tool) {
		case "ai_photo":
			return {
				tool,
				storage: photoAiStorageSchema,
			}
		case "ai_restyle":
			return {
				tool,
				storage: restyleStorageSchema,
			}
		case "deform":
			return {
				tool,
				storage: deforumStorageSchema,
			}
		case "text_to_image":
			return {
				tool,
				storage: textToImageStorageSchema,
			}
		case "ai_music_generator":
			return {
				tool,
				storage: AiMusicGeneratorStorageSchema,
			}
		case "text_to_video":
			return {
				tool,
				storage: textToVideoStorageSchema,
			}
		default:
			return {
				tool: "deform" as const,
				storage: deforumStorageSchema,
			}
	}
}

const defaultChannelsList = {
	data: {
		available_bitrates: [
			{
				bitrate: 32,
			},
			{
				bitrate: 96,
			},
			{
				bitrate: 128,
			},
			{
				bitrate: 192,
			},
			{
				bitrate: 256,
			},
			{
				bitrate: 320,
			},
		],
		available_intensities: [
			{
				intensity: "low",
			},
			{
				intensity: "medium",
			},
			{
				intensity: "high",
			},
		],
		categories: [
			{
				category_id: 0,
				name: "Moods",
				groups: [
					{
						group_id: 0,
						name: "Calm",
						playlist: "0.0",
					},
					{
						group_id: 1,
						name: "Energizing",
						playlist: "0.1",
					},
					{
						group_id: 2,
						name: "Joyful",
						playlist: "0.2",
					},
					{
						group_id: 3,
						name: "Sad",
						playlist: "0.3",
					},
					{
						group_id: 4,
						name: "Tense",
						playlist: "0.4",
					},
					{
						group_id: 5,
						name: "Beautiful",
						playlist: "0.5",
					},
					{
						group_id: 6,
						name: "Erotic",
						playlist: "0.6",
					},
					{
						group_id: 7,
						name: "Dreamy",
						playlist: "0.7",
					},
					{
						group_id: 8,
						name: "Heroic",
						playlist: "0.8",
					},
					{
						group_id: 10,
						name: "Scary",
						playlist: "0.10",
					},
				],
			},
			{
				category_id: 1,
				name: "Focus",
				groups: [
					{
						group_id: 0,
						name: "Minimal",
						playlist: "1.0",
					},
				],
			},
			{
				category_id: 2,
				name: "Sleep",
				groups: [
					{
						group_id: 0,
						name: "Ambient",
						playlist: "2.0",
					},
					{
						group_id: 1,
						name: "Classical",
						playlist: "2.1",
					},
					{
						group_id: 2,
						name: "Lullaby",
						playlist: "2.2",
					},
					{
						group_id: 3,
						name: "Noise",
						playlist: "2.3",
					},
					{
						group_id: 4,
						name: "Sounds",
						playlist: "2.4",
					},
				],
			},
			{
				category_id: 3,
				name: "Calm",
				groups: [
					{
						group_id: 0,
						name: "Ambient",
						playlist: "3.0",
					},
					{
						group_id: 1,
						name: "Chill",
						playlist: "3.1",
					},
				],
			},
			{
				category_id: 4,
				name: "Chill",
				groups: [
					{
						group_id: 0,
						name: "Chillout",
						playlist: "4.0",
					},
					{
						group_id: 1,
						name: "Ethnic",
						playlist: "4.1",
					},
					{
						group_id: 2,
						name: "Summer",
						playlist: "4.2",
					},
					{
						group_id: 3,
						name: "Travel",
						playlist: "4.3",
					},
				],
			},
			{
				category_id: 5,
				name: "Sport",
				groups: [
					{
						group_id: 0,
						name: "Fitness",
						playlist: "5.0",
					},
					{
						group_id: 1,
						name: "Cardio",
						playlist: "5.1",
					},
					{
						group_id: 2,
						name: "Running",
						playlist: "5.2",
					},
					{
						group_id: 3,
						name: "Yoga",
						playlist: "5.3",
					},
				],
			},
			{
				category_id: 6,
				name: "Genres",
				groups: [
					{
						group_id: 0,
						name: "EDM",
						playlist: "6.0",
					},
					{
						group_id: 1,
						name: "House",
						playlist: "6.1",
					},
					{
						group_id: 2,
						name: "Techno",
						playlist: "6.2",
					},
					{
						group_id: 3,
						name: "Chill",
						playlist: "6.3",
					},
					{
						group_id: 4,
						name: "Hiphop",
						playlist: "6.4",
					},
					{
						group_id: 5,
						name: "Ambient",
						playlist: "6.5",
					},
					{
						group_id: 6,
						name: "Electronica",
						playlist: "6.6",
					},
					{
						group_id: 7,
						name: "Trance",
						playlist: "6.7",
					},
					{
						group_id: 8,
						name: "Drumnbass",
						playlist: "6.8",
					},
					{
						group_id: 9,
						name: "Dub",
						playlist: "6.9",
					},
					{
						group_id: 10,
						name: "Bass",
						playlist: "6.10",
					},
					{
						group_id: 11,
						name: "Breakbeat",
						playlist: "6.11",
					},
					{
						group_id: 12,
						name: "Jazz & Funk",
						playlist: "6.12",
					},
					{
						group_id: 13,
						name: "Disco",
						playlist: "6.13",
					},
					{
						group_id: 15,
						name: "Pop",
						playlist: "6.15",
					},
					{
						group_id: 16,
						name: "Rock & Metal",
						playlist: "6.16",
					},
					{
						group_id: 17,
						name: "Classical",
						playlist: "6.17",
					},
					{
						group_id: 18,
						name: "Folk",
						playlist: "6.18",
					},
				],
			},
			{
				category_id: 10,
				name: "Countries",
				groups: [
					{
						group_id: 0,
						name: "India",
						playlist: "10.0",
					},
				],
			},
		],
	},
}

export type ToolAspect = {
	width: number
	height: number
	aspectWidth: number
	aspectHeight: number
	name?: string
}

export const aspects: ToolAspect[] = [
	{
		width: 27,
		height: 27,
		aspectWidth: 1,
		aspectHeight: 1,
		name: "Square",
	},
	{
		width: 24,
		height: 38,
		aspectWidth: 9,
		aspectHeight: 16,
		name: "Portrait",
	},
	{
		width: 37,
		height: 24,
		aspectWidth: 16,
		aspectHeight: 9,
		name: "Landscape",
	},
	{
		width: 23,
		height: 29,
		aspectWidth: 3,
		aspectHeight: 4,
	},
	{
		width: 29,
		height: 22,
		aspectWidth: 4,
		aspectHeight: 3,
	},
	{
		width: 24,
		height: 26,
		aspectWidth: 4,
		aspectHeight: 5,
	},
	{
		width: 26,
		height: 24,
		aspectWidth: 5,
		aspectHeight: 4,
	},
]
