import { CustomThemedResource } from "@/comps/image"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { TrainingStatusResponse } from "pages/api/tools-models-status"
import { ModelStatus } from "pages/api/training-list"
import {
  DeletePopup,
  getModelType,
  getMonthDayFromDate,
  TrainMoldelDraftsType,
} from "pages/models"
import { ModelType } from "pages/models/create"
import { useMemo, useState } from "react"
import { useQueryClient } from "react-query"
import { SelectModelProps } from "./select-model-popup"

type JobType = {
  status: ModelStatus
  id: number
  name: string
  thumb_url: string
  has_unseen_job: boolean
  created_at: string
  model_type: string
  samples?:
    | {
        thumbs: string[]
        epoch: number
        model_file_url: string
      }[]
}

interface FilterProps {
  state: "models" | "drafts" | "training"
  jobs: JobType[]
  drafts: TrainMoldelDraftsType[] | null
  deleteDraft: (id: string) => void
  trainings: TrainingStatusResponse | null
  setSelectPopupState: (v: SelectModelProps | null) => void
}

export default function FilterContent(
  props: FilterProps,
): JSX.Element {
  const {
    state,
    jobs,
    drafts,
    trainings,
    deleteDraft,
    setSelectPopupState,
  } = props
  const { t } = useTranslation()
  const router = useRouter()
  const queryClient = useQueryClient()

  switch (state) {
    case "models":
      return (
        <div className="grid grid-cols-5 gap-[16px] pb-[100px]">
          <button
            className={clsx(
              "flex w-[200px] animate-scale flex-col rounded-[10px] bg-color-cell",
            )}
            onClick={() => router.push("/models/create")}>
            <div className="h-[200px] w-full rounded-t-[10px] p-[16px] hover:bg-blue-200">
              <div
                className="flex h-[168px] w-[168px] items-center justify-center rounded-[9px]"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23C6C9DCFF' stroke-width='4' stroke-dasharray='6%2c10' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e")`,
                }}>
                <CustomThemedResource
                  source="/general/plus-gray"
                  format="svg"
                />
              </div>
            </div>
            <div className="flex h-[64px] w-full items-center justify-center gap-[10px] border-t border-color-separator">
              <img
                src={assetUrl("/general/model-plus.svg")}
                alt="model icon"
              />
              <span className="text-[16px] font-600 text-blue-800">
                {t("txt_add_new")}
              </span>
            </div>
          </button>

          {jobs.map((job) => (
            <ModelCard
              samples={job.samples}
              setSelectPopupState={setSelectPopupState}
              modelType={job.model_type}
              key={job.id}
              image={job.thumb_url}
              name={job.name}
              date={job.created_at}
              status={job.status}
              id={job.id}
              functionAfterDelete={() =>
                queryClient.invalidateQueries("all-models")
              }
            />
          ))}
        </div>
      )

    case "drafts":
      return (
        <div className="grid w-[1063px] grid-cols-4 gap-[13px]">
          {drafts === null || drafts.length === 0 ? (
            <div className="flex w-[1063px] flex-col items-center justify-center gap-[13px] pt-[100px]">
              <img
                src={assetUrl("/general/empty-model.svg")}
                alt="ilustration for empty draft state"
              />
              <span className="text-[16px] font-400 text-color-placeholder">
                {t("txt_empty_prompt_draft")}
              </span>
            </div>
          ) : (
            drafts.map((draft) => (
              <DraftCard
                key={draft.id}
                id={draft.id}
                date={draft.date}
                modelName={draft.modelName}
                modelType={draft.modelType}
                image={draft.uploadedFiles[0].file}
                functionAfterDelete={() => {
                  return
                }}
                deleteDraft={deleteDraft}
              />
            ))
          )}
        </div>
      )

    case "training":
      return (
        <div className="grid w-[1063px] grid-cols-4 gap-[13px]">
          {trainings === null ||
          trainings.models.filter(
            (m) => m.status !== "failed",
          ).length === 0 ? (
            <div className="flex w-[1063px] flex-col items-center justify-center gap-[13px] pt-[100px]">
              <img
                src={assetUrl("/general/empty-model.svg")}
                alt="ilustration for empty draft state"
              />
              <span className="text-[16px] font-400 text-color-placeholder">
                {t("txt_empty_prompt_training")}
              </span>
            </div>
          ) : (
            trainings.models.map((model) => (
              <TrainingCard
                status={model.status}
                key={model.id}
                image={model.thumb_url}
                modelName={model.name}
              />
            ))
          )}
        </div>
      )
  }
}

interface ModelCardProps {
  image: string
  name: string
  date: string
  status: string
  modelType: string
  id: number
  functionAfterDelete: () => void
  setSelectPopupState: (v: SelectModelProps | null) => void
  samples:
    | {
        thumbs: string[]
        epoch: number
        model_file_url: string
      }[]
    | undefined
}

function ModelCard(props: ModelCardProps) {
  const {
    image,
    name,
    date,
    modelType,
    id,
    functionAfterDelete,
    setSelectPopupState,
    samples,
  } = props
  const router = useRouter()
  const { t } = useTranslation()
  const [deletePopup, setDeletePopup] = useState(false)

  return (
    <>
      <div className={clsx("group relative")}>
        <button
          className={clsx(
            "flex w-[200px] animate-scale flex-col rounded-[10px] bg-color-cell",
          )}
          onClick={() => {
            if (!samples) {
              router.push(`/models/single?id=${id}`)
            } else {
              setSelectPopupState({ samples: samples, id })
            }
          }}>
          <div className="h-[200px] w-full overflow-hidden">
            <img
              src={image}
              alt="model single image"
              className="rounded-t-[10px]"
            />
          </div>
          <div className="flex h-[64px] w-full flex-col pl-[10px] pt-[10px]">
            <div className="flex items-center gap-[6px]">
              <img
                src={assetUrl("/general/model-icon.svg")}
                alt="model icon"
              />
              <span className="truncate text-[16px] font-600 text-blue-800">
                {name}
              </span>

              {samples && (
                <div className="h-[6px] w-[6px] rounded-full bg-primary-500" />
              )}
            </div>

            <div className="flex items-center gap-[8px]">
              <span className="text-[14px] font-400 text-color-placeholder">
                {modelType}
              </span>
              <div className="h-[5px] w-[5px] rounded-full bg-color-placeholder" />
              <span className="text-[14px] font-400 text-color-placeholder">
                {getMonthDayFromDate(date)}
              </span>
            </div>
          </div>
        </button>

        <div className="peer absolute right-2 top-2 hidden group-hover:block">
          <div className="mb-[10px] flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-[4px] bg-color-black/20 hover:bg-color-black/50">
            <img
              src={assetUrl("/ai-tools/history/more.svg")}
              alt="more icon"
            />
          </div>
        </div>
        <div
          className={clsx(
            "absolute z-50 hidden w-[180px] flex-col gap-[2px] rounded-[10px] bg-color-cell hover:flex peer-hover:flex",
            "left-[15px] top-[40px] cursor-pointer px-[5px] py-[4px]",
          )}>
          <button
            className="flex items-center gap-[12px] rounded-[10px] py-[12px] pl-[16px] hover:bg-color-background"
            onClick={() => setDeletePopup(true)}>
            <img
              src={assetUrl("/ai-tools/history/trash.svg")}
              alt="trash icon"
            />
            <span className=" text-[15px] font-500 text-color-error">
              {t("txt_remove")}
            </span>
          </button>
        </div>
      </div>

      <DeletePopup
        modelName={name}
        setDeletePopup={setDeletePopup}
        deletePopup={deletePopup}
        id={id}
        functionAfterDelete={functionAfterDelete}
      />
    </>
  )
}

interface DraftCardProps {
  id: string
  image: File
  modelName: string
  modelType: ModelType
  date: string
  functionAfterDelete: () => void
  deleteDraft: (id: string) => void
}

function DraftCard(props: DraftCardProps) {
  const {
    id,
    modelType,
    modelName,
    date,
    functionAfterDelete,
    deleteDraft,
  } = props

  const router = useRouter()
  const [deletePopup, setDeletePopup] = useState(false)
  const { t } = useTranslation()

  const image = useMemo(() => {
    return URL.createObjectURL(props.image)
  }, [props.image])

  return (
    <>
      <div className={clsx("relative animate-scale")}>
        <button
          onClick={() =>
            router.push(`/models/create?draftId=${id}`)
          }
          className={clsx(
            "flex h-[80px] items-center justify-between rounded-[10px] bg-color-cell p-[5px] pr-[12px]",
            "relative w-full hover:bg-blue-200",
          )}>
          <div className="flex items-center gap-[10px]">
            <img
              src={image}
              alt="draft first image"
              className="h-[70px] w-[70px] rounded-[6px] object-cover"
            />
            <div className="flex flex-col items-start">
              <img
                src={assetUrl("/general/draft-icon.svg")}
                alt="draft icon"
                className="h-[20px] w-[20px]"
              />
              <span className="max-w-[120px] truncate text-[16px] font-600 text-blue-800">
                {modelName}
              </span>
              <div className="flex items-center gap-[6px]">
                <span className="text-[14px] font-400 text-color-placeholder">
                  {getModelType(modelType)}
                </span>
                <div className="h-[5px] w-[5px] rounded-full bg-color-placeholder" />
                <span className="text-[14px] font-400 text-color-placeholder">
                  {getMonthDayFromDate(date)}
                </span>
              </div>
            </div>
          </div>
        </button>

        <div className="peer absolute right-2 top-[25px]">
          <div
            className={clsx(
              "mb-[10px] flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-[4px]",
              "hover:bg-color-black/10",
            )}>
            <img
              src={assetUrl("/general/more-gray.svg")}
              alt="more icon"
            />
          </div>
        </div>
        <div
          className={clsx(
            "absolute z-50 hidden w-[180px] flex-col gap-[2px] rounded-[10px] bg-color-cell hover:flex peer-hover:flex",
            "right-0 top-[60px] cursor-pointer px-[5px] py-[4px]",
          )}>
          <button
            className="flex items-center gap-[12px] rounded-[10px] py-[12px] pl-[16px] hover:bg-color-background"
            onClick={() => setDeletePopup(true)}>
            <img
              src={assetUrl("/ai-tools/history/trash.svg")}
              alt="trash icon"
            />
            <span className=" text-[15px] font-500 text-color-error">
              {t("txt_remove")}
            </span>
          </button>
        </div>
      </div>

      <DeletePopup
        modelName={modelName}
        setDeletePopup={setDeletePopup}
        deletePopup={deletePopup}
        id={Number(id)}
        functionAfterDelete={functionAfterDelete}
        deleteDraft={() => deleteDraft(id)}
      />
    </>
  )
}

interface TrainingCardProps {
  image: string
  modelName: string
  status: ModelStatus
}

function TrainingCard(props: TrainingCardProps) {
  const { modelName, image, status } = props

  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        status === "failed" && "hidden",
        "h-[80px] animate-scale rounded-[10px] bg-color-cell p-[5px] pr-[12px]",
        "flex items-center gap-[10px]",
      )}>
      <img
        src={image}
        alt="draft first image"
        className="h-[70px] w-[70px] rounded-[6px]"
      />
      <div className="flex flex-col items-start">
        <img
          src={assetUrl("/general/draft-icon.svg")}
          alt="draft icon"
          className="h-[20px] w-[20px]"
        />
        <span className="max-w-[120px] truncate text-[16px] font-600 text-blue-800">
          {modelName}
        </span>
        <span className="text-[12px] font-500 text-[#2F91FD]">
          {t("txt_training")}
        </span>
      </div>
    </div>
  )
}
