import axios from "axios"
import { parse } from "cookie"
import {
	AuthProvider,
	confirmPasswordReset as confirmPasswordResetFirebase,
	createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
	getAuth,
	GoogleAuthProvider,
	sendPasswordResetEmail as sendPasswordResetEmailFirebase,
	signInWithCredential,
	signInWithEmailAndPassword,
	signInWithRedirect,
	UserCredential,
	verifyPasswordResetCode as verifyPasswordResetCodeFirebase,
} from "firebase/auth"
import { useRouter } from "next/router"
import { UserShortUidResponse } from "pages/api/user-short-uid"
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react"
import { z } from "zod"
import setMaxCookie from "./maxcookie"
import { WithChildren } from "./types"
import { getUtmIds } from "./utm"

export const AUTH_COOKIE_KEY = "_zoom_auth"

export interface UserInfo {
	accessToken: string
	refreshToken: string
	expiresAt: string
	issuedAt: string
	userId: string
	entitlements: string[]
	isAnonymous: boolean
	details: UserDetails
}

export interface UserDetails {
	username: string
	promptOnboarding: boolean
	profilePic: string | null
	fullName: string
}

export interface CookieUserInfo {
	accessToken: string
	refreshToken: string
	expiresAt: string
	issuedAt: string
	userId: string
	entitlements: string
	isAnonymous: string
	details?: Partial<UserDetails>
}

export interface UserEntitlements {
	isPro: boolean
	hasConcurrentJobs: boolean
	isCoinFree: boolean
	isAdvanced: boolean
}

export function getUserEntitlements(
	entitlements: string[],
): UserEntitlements {
	const isPro = entitlements.includes("pro")
	const hasConcurrentJobs = entitlements.includes(
		"ai_concurrent_jobs",
	)
	const isCoinFree = entitlements.includes("ai_coin_free")
	const isAdvanced = entitlements.includes(
		"plan_advanced_10",
	)

	return {
		isPro,
		hasConcurrentJobs,
		isCoinFree,
		isAdvanced,
	}
}

export const cookieUserInfoSchema = z.object({
	accessToken: z.string(),
	refreshToken: z.string(),
	expiresAt: z.string(),
	issuedAt: z.string(),
	userId: z.string(),
	entitlements: z.string(),
	isAnonymous: z.string(),
	details: z
		.object({
			username: z.string().optional(),
			promptOnboarding: z.boolean().optional(),
			profilePic: z.string().nullable().optional(),
			fullName: z.string().optional(),
		})
		.default({}),
})

export const refreshTokenResponseSchema = z.object({
	access_token: z.string(),
	expires_in: z.coerce.number(),
	token_type: z.literal("Bearer"),
	refresh_token: z.string(),
	user_id: z.string(),
})

export const customTokenResponseSchema = z.object({
	idToken: z.string(),
	refreshToken: z.string(),
	expiresIn: z.coerce.number(),
})

export const tokenResponseSchema = z.object({
	status: z.literal(true),
	result: z.string(),
	uid: z.string(),
})

export const claimsSchema = z.object({
	revenueCatEntitlements: z.array(z.string()).optional(),
	is_anonymous: z.boolean().optional(),
})

export async function forceRefreshToken(): Promise<UserInfo> {
	return await axios
		.post<UserInfo>("/api/auth-refresh")
		.then((res) => res.data)
}

export function deserializeUserInfo(
	info: CookieUserInfo,
	details: UserDetails,
): UserInfo {
	return {
		...info,
		issuedAt: new Date(info.issuedAt).toISOString(),
		expiresAt: new Date(info.expiresAt).toISOString(),
		entitlements: info.entitlements
			.split(",")
			.filter(Boolean),
		isAnonymous: info.isAnonymous === "true",
		details,
	}
}

export interface SSRAuthContextResult {
	userInfo: UserInfo
	setUserInfo: (i: UserInfo) => void
}

export const SSRAuthContext =
	createContext<SSRAuthContextResult>({
		userInfo: null as unknown as UserInfo,
		setUserInfo() {
			throw new Error("Provider not used")
		},
	})

export interface SSRAuthProviderProps {
	userInfo: UserInfo
	children: ReactNode
}

export function SSRAuthProvider(
	props: SSRAuthProviderProps,
) {
	const { userInfo: originalUserInfo, children } = props

	const [userInfo, setUserInfo] = useState(originalUserInfo)

	return (
		<SSRAuthContext.Provider
			value={{ userInfo, setUserInfo }}>
			{children}
		</SSRAuthContext.Provider>
	)
}

interface ClientSideAuthContextResult {
	userInfo: UserInfo | null
	setUserInfo: (u: UserInfo) => void
}

const ClientSideAuthContext =
	createContext<ClientSideAuthContextResult>({
		userInfo: null,
		setUserInfo: () => {
			throw new Error("Not used")
		},
	})

async function getUserDetailsClient(
	userId: string,
	accessToken: string,
): Promise<UserDetails> {
	const info = await axios
		.get<UserShortUidResponse>(
			`/api/user-short-uid?uid=${userId}`,
			{
				headers: { Authorization: `Bearer ${accessToken}` },
			},
		)
		.then((res) => res.data)

	if (info.promptOnboarding === undefined) {
		throw new Error()
	}

	return {
		profilePic: info.picture,
		promptOnboarding: info.promptOnboarding,
		username: info.username,
		fullName: info.name,
	}
}

export function ClientSideAuthProvider(
	props: WithChildren,
) {
	const { children } = props
	const [userInfo, setUserInfo] = useState<UserInfo | null>(
		null,
	)

	useEffect(() => {
		async function findAuth() {
			try {
				const cookies = parse(document.cookie)
				const userCookies = cookies[AUTH_COOKIE_KEY]
				const userObject = JSON.parse(userCookies)
				const cookieUserInfo =
					cookieUserInfoSchema.parse(userObject)
				const details = await getUserDetailsClient(
					cookieUserInfo.userId,
					cookieUserInfo.accessToken,
				)
				const newUserInfo = deserializeUserInfo(
					cookieUserInfo,
					details,
				)
				setUserInfo(newUserInfo)
			} catch (error) {
				console.error(error)
			}
		}

		findAuth()
	}, [])

	return (
		<ClientSideAuthContext.Provider
			value={{ userInfo, setUserInfo }}>
			{children}
		</ClientSideAuthContext.Provider>
	)
}

export interface AuthContextResult {
	userInfo: UserInfo
	setUserInfo: (u: UserInfo) => void
}

export function useAuth(): AuthContextResult {
	return useContext(SSRAuthContext)
}

export interface ClientAuthContextResult {
	userInfo: UserInfo | null
	setUserInfo: (u: UserInfo) => void
}

export function useClientAuth(): ClientAuthContextResult {
	const ssrState = useContext(SSRAuthContext)
	const clientState = useContext(ClientSideAuthContext)

	if (ssrState.userInfo === null) {
		return clientState
	}

	return ssrState
}

export async function applyUserCredentials(
	cred: UserCredential,
): Promise<UserInfo> {
	const userInfo = await userInfoFromUserCredential(cred)
	const serialized = serializeUserInfo(userInfo)

	setMaxCookie(AUTH_COOKIE_KEY, JSON.stringify(serialized))

	return userInfo
}

export function serializeUserInfo(
	info: UserInfo,
): CookieUserInfo {
	return {
		accessToken: info.accessToken,
		refreshToken: info.refreshToken,
		expiresAt: info.expiresAt.toString(),
		issuedAt: info.issuedAt.toString(),
		userId: info.userId,
		entitlements: info.entitlements.join(","),
		isAnonymous: String(info.isAnonymous),
		details: info.details,
	}
}

export const getRegionClient = () => {
	return navigator.language.split("-")[1] ?? "US"
}

export async function userInfoFromUserCredential(
	cred: UserCredential,
): Promise<UserInfo> {
	const refreshToken = cred.user.refreshToken
	const userId = cred.user.uid
	const decodedToken = await cred.user.getIdTokenResult()
	const accessToken = decodedToken.token
	const expiresAt = new Date(
		decodedToken.expirationTime,
	).toISOString()
	const issuedAt = new Date(
		decodedToken.issuedAtTime,
	).toISOString()
	const parsedEntitlements = claimsSchema.parse(
		decodedToken.claims,
	)
	const entitlements =
		parsedEntitlements.revenueCatEntitlements ?? []
	const isAnonymous =
		parsedEntitlements.is_anonymous ?? false

	const utm = getUtmIds()

	const userData = {
		uid: userId,
		agent: navigator.userAgent,
		region: getRegionClient(),
		device: userId,
		token: `Bearer ${accessToken}`,
		is_anonymous: isAnonymous,
		utm,
	}

	await axios
		.put("/api/user-auth", userData)
		.catch(console.error)

	const details = await getUserDetailsClient(
		userId,
		accessToken,
	)

	return {
		refreshToken,
		accessToken,
		userId,
		expiresAt,
		issuedAt,
		isAnonymous,
		entitlements,
		details,
	}
}

export interface UseAuthUpdaterResult {
	logOut(): Promise<void>
	logInWithEmailAndPassword(
		email: string,
		password: string,
	): Promise<UserInfo>
	logInWithProvider(credential: AuthProvider): Promise<void>
	logInWithCredential(credential: string): Promise<UserInfo>
	sendPasswordResetEmail(email: string): Promise<void>
	createUserWithEmailAndPassword(
		email: string,
		password: string,
	): Promise<UserInfo>
	verifyPasswordResetCode(
		oobCode: string,
	): Promise<string | null>
	confirmPasswordReset(
		oobCode: string,
		newPassword: string,
	): Promise<boolean>
}

export async function logInWithUserCredential(
	cred: UserCredential,
): Promise<UserInfo> {
	await getAuth().signOut()
	const userInfo = await applyUserCredentials(cred)
	return userInfo
}

type SensitivePathAction =
	| {
			path: string
			action: "refresh"
	  }
	| {
			path: string
			action: "redirect"
			target: string
	  }
const sensitivePaths: SensitivePathAction[] = [
	{
		path: "/profile/generations",
		action: "redirect",
		target: "/",
	},
	{
		path: "/models",
		action: "redirect",
		target: "/tools/ai-photoshoot-generator",
	},
	{
		path: "/tools",
		action: "refresh",
	},
]
export function useAuthUpdater(): UseAuthUpdaterResult {
	const { setUserInfo } = useClientAuth()
	const router = useRouter()

	const onUserChange = useCallback(
		(_: UserInfo) => {
			const sensitivePath = sensitivePaths.find((page) =>
				router.asPath.startsWith(page.path),
			)

			if (!sensitivePath) {
				return
			}

			switch (sensitivePath.action) {
				case "redirect":
					router.push(sensitivePath.target)
					break
				case "refresh":
					router.reload()
					break
			}
		},
		[router],
	)

	const logOut = useCallback(async () => {
		const info = await axios
			.post<UserInfo>("/api/auth-logout")
			.then((res) => res.data)
		setUserInfo(info)
		onUserChange(info)
	}, [onUserChange, setUserInfo])

	const logInWithEmailAndPassword = useCallback(
		async (email: string, password: string) => {
			const auth = getAuth()
			const cred = await signInWithEmailAndPassword(
				auth,
				email,
				password,
			)

			const info = await logInWithUserCredential(cred)
			setUserInfo(info)
			onUserChange(info)

			return info
		},
		[onUserChange, setUserInfo],
	)

	const logInWithProvider = useCallback(
		async (credential: AuthProvider) => {
			const auth = getAuth()
			signInWithRedirect(auth, credential)
			// const cred = await signInWithPopup(auth, credential)
			// const info = await logInWithUserCredential(cred)
			// setUserInfo(info)
			// onUserChange(info)

			// return { userInfo: info, email: cred.user.email }
		},
		[],
	)

	const logInWithCredential = useCallback(
		async (credentialString: string) => {
			const credential = GoogleAuthProvider.credential(
				credentialString,
			)
			const cred = await signInWithCredential(
				getAuth(),
				credential,
			)
			const info = await logInWithUserCredential(cred)
			setUserInfo(info)
			onUserChange(info)

			return info
		},
		[onUserChange, setUserInfo],
	)

	const sendPasswordResetEmail = useCallback(
		async (email: string) => {
			const auth = getAuth()
			await sendPasswordResetEmailFirebase(auth, email)
		},
		[],
	)

	const createUserWithEmailAndPassword = useCallback(
		async (email: string, password: string) => {
			const auth = getAuth()
			const cred =
				await createUserWithEmailAndPasswordFirebase(
					auth,
					email,
					password,
				)

			const info = await applyUserCredentials(cred)
			setUserInfo(info)
			onUserChange(info)

			return info
		},
		[onUserChange, setUserInfo],
	)

	const verifyPasswordResetCode = useCallback(
		async (oobCode: string) => {
			const auth = getAuth()
			return await verifyPasswordResetCodeFirebase(
				auth,
				oobCode,
			)
				.then((res) => res)
				.catch((_) => null)
		},
		[],
	)

	const confirmPasswordReset = useCallback(
		async (oobCode: string, newPassword: string) => {
			const auth = getAuth()
			return await confirmPasswordResetFirebase(
				auth,
				oobCode,
				newPassword,
			)
				.then((_) => true)
				.catch((_) => false)
		},
		[],
	)

	return {
		logOut,
		logInWithEmailAndPassword,
		logInWithProvider,
		sendPasswordResetEmail,
		createUserWithEmailAndPassword,
		logInWithCredential,
		verifyPasswordResetCode,
		confirmPasswordReset,
	}
}

export interface AuthPopupStateResult {
	setOpen: (open: boolean) => void
	open: boolean
}

export const AuthPopupStateContext =
	createContext<AuthPopupStateResult | null>(null)

export function AuthPopupStateProvider(
	props: WithChildren,
) {
	const { children } = props
	const [open, setOpen] = useState(false)

	return (
		<AuthPopupStateContext.Provider
			value={{ open, setOpen }}>
			{children}
		</AuthPopupStateContext.Provider>
	)
}

export default useAuth
