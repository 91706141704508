import { ReactNode, useEffect, useState } from "react"

export const useProgressive = (image?: string): boolean => {
  const [ready, setReady] = useState<string | null>(null)

  useEffect(() => {
    if (image === undefined) return

    const loader = new Image()
    loader.src = image

    loader.onload = () => setReady(image)
  }, [image])

  useEffect(() => {
    if (ready === image) {
      return
    }

    if (ready === null) {
      return
    }

    setReady(null)
  }, [image, ready])

  return ready !== null
}

interface ProgressiveProps {
  source?: string
  placeholder: ReactNode
}

const Progressive = (
  props: ProgressiveProps
): JSX.Element => {
  const { source, placeholder } = props

  const ready = useProgressive(source)

  if (ready) return <img src={source} />
  return <>{placeholder}</>
}

export default Progressive
