import { z } from "zod"

export const loginPageSchema = z.enum([
  "signin",
  "signup",
  "verify",
  "username",
  "forgot",
  "success",
  "quiz",
  "plan",
])

export type LoginPage = z.infer<typeof loginPageSchema>

export function redirectToLogin(page?: LoginPage) {
  const path = window.location.pathname
  const search = window.location.search

  const loginRedirectUrl = `/login?redirect_url=${encodeURIComponent(
    path + search,
  )}${page !== undefined ? "&type=" + page : ""}`

  window.location.href = loginRedirectUrl
}
