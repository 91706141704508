import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useMemo } from "react"
import { Accept, DropzoneState } from "react-dropzone"
import { CustomThemedResource } from "./image"

export const mimeTypes = {
	images: ["image/jpeg", "image/png", "image/webp"],
	videos: ["video/mp4", "video/quicktime"],
} as const

export type MimeTypes =
	(typeof mimeTypes)[keyof typeof mimeTypes][number]

export function getFormatsFromMimeTypes(
	mimeTypes: MimeTypes[],
): Accept {
	return mimeTypes.reduce(
		(acc, format) => ({ ...acc, [format]: [] }),
		{},
	)
}

export interface SimpleDropAreaProps {
	className?: string
	state: DropzoneState
	formats: MimeTypes[]
	openExplorer: () => void
	border?: boolean
	disabled?: boolean
	borderImage?: string
	loading?: boolean
	backgroundImage?: string
	noText?: boolean
	small?: boolean
	button?: boolean
}

export function SimpleDropArea(props: SimpleDropAreaProps) {
	const {
		className,
		state,
		formats,
		openExplorer,
		disabled,
		loading,
	} = props
	const { getRootProps, getInputProps, isDragActive } =
		state

	const { t } = useTranslation("common")

	const supportedFormats = useMemo(
		() =>
			formats
				.map((format) =>
					format.split("/")[1].includes("quicktime")
						? "MOV"
						: format.split("/")[1].toUpperCase(),
				)
				.join(", "),
		[formats],
	)

	return (
		<div
			// 		style={{
			// 			backgroundImage: `repeating-linear-gradient(
			//   180deg,
			//   var(--color-primary-200),
			//   var(--color-primary-200) 12px,
			//   transparent 12px,
			//   transparent 20px,
			//   var(--color-primary-200) 20px
			// ),
			// repeating-linear-gradient(
			//   270deg,
			//   var(--color-primary-200),
			//   var(--color-primary-200) 12px,
			//   transparent 12px,
			//   transparent 20px,
			//   var(--color-primary-200) 20px
			// ),
			// repeating-linear-gradient(
			//   0deg,
			//   var(--color-primary-200),
			//   var(--color-primary-200) 12px,
			//   transparent 12px,
			//   transparent 20px,
			//   var(--color-primary-200) 20px
			// ),
			// repeating-linear-gradient(
			//   90deg,
			//   var(--color-primary-200),
			//   var(--color-primary-200) 12px,
			//   transparent 12px,
			//   transparent 20px,
			//   var(--color-primary-200) 20px
			// )`,
			// 			backgroundSize: `2px 100%, 100% 2px, 2px 100%, 100% 2px`,
			// 			backgroundPosition: `0 0, 0 0, 100% 0, 0 100%`,
			// 			backgroundRepeat: `no-repeat`,
			// 		}}
			{...getRootProps({
				className: clsx([
					className,
					disabled === true &&
						!loading &&
						"opacity-30 pointer-events-none",
					"relative rounded-[12px] cursor-pointer",
					"select-none flex flex-col items-center justify-center border-dashed border hover:border-primary-500 border-primary-200",
					isDragActive
						? "bg-blue-100"
						: "bg-blue-50 hover:bg-blue-100",
					"transition-colors duration-200",
					loading && "pointer-events-none",
				]),
			})}
			onClick={openExplorer}>
			{/* Loading State */}
			<div
				className={clsx(
					"absolute left-0 top-0 z-10 h-full w-full rounded-[22px] border border-primary-100 bg-blue-100 transition-opacity",
					"flex items-center justify-center",
					loading ? "opacity-100" : "opacity-0",
				)}>
				<div className="flex h-16 w-16 items-center justify-center rounded-full bg-color-cell">
					<img
						src={assetUrl("/general/loader.webp")}
						alt="loading image"
						className="animate-[spin_1s_infinite_linear]"
					/>
				</div>
			</div>

			{/* Drag Active State */}
			<div
				className={clsx(
					"absolute left-0 top-0 z-10 h-full w-full rounded-[12px] border-2 border-primary-100 bg-blue-200 transition-opacity",
					"flex items-center justify-center",
					"text-[28px] font-700 text-blue-800",
					isDragActive ? "opacity-100" : "opacity-0",
				)}>
				{t("txt_drop_here")}
			</div>

			<div className="mb-3">
				<svg
					width="40"
					height="40"
					viewBox="0 0 64 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="32"
						cy="32"
						r="32"
						fill="var(--color-primary-100)"
					/>
					<path
						d="M28.999 24.5L31.999 21.5L34.999 24.5"
						stroke="var(--color-primary-700)"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M31.999 33.5V21.5"
						stroke="var(--color-primary-700)"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M20.5684 32.543V37.5932C20.5684 40.0031 22.522 41.9568 24.932 41.9568H39.0669C41.4769 41.9568 43.4305 40.0031 43.4305 37.5932V32.543"
						stroke="var(--color-primary-700)"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>

			<span className="text-[16px] font-500">
				{t("txt_drag_and_drop")}{" "}
				<span className="text-primary-500">
					{t("lbl_upload")}
				</span>
			</span>

			<input {...getInputProps()} />
			<div className="text-[13px] font-400">
				<span>{t("txt_supported_files")}</span>
				<span className="ml-1">{supportedFormats}</span>
			</div>
		</div>
	)
}

export function DropAreaSmall(props: SimpleDropAreaProps) {
	const {
		className,
		state,
		openExplorer,
		border,
		disabled,
		borderImage,
	} = props
	const { getRootProps, getInputProps, isDragActive } =
		state

	const { t } = useTranslation("common")

	return (
		<div
			{...getRootProps({
				className: clsx([
					className,
					disabled === true &&
						"opacity-30 pointer-events-none",
					"relative rounded-[12px] cursor-pointer",
					"select-none flex flex-col items-center justify-center border-dashed border hover:border-primary-500 border-primary-200",
					isDragActive
						? "bg-blue-100"
						: "bg-blue-50 hover:bg-blue-100",
					"transition-colors duration-200",
				]),
			})}
			onClick={openExplorer}>
			{border && (
				<CustomThemedResource
					source={borderImage ?? "/general/border-dropzone"}
					format="svg"
					className="pointer-events-none absolute left-0 top-0 h-full w-full"
				/>
			)}
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<circle cx="16" cy="16" r="16" fill="white" />
				<path
					d="M14.5002 12.25L16.0002 10.75L17.5002 12.25"
					stroke="#6498EE"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.0002 16.75V10.75"
					stroke="#6498EE"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.2849 16.2715V16.6148C10.2849 19.0247 12.2386 20.9784 14.6485 20.9784H17.3524C19.7623 20.9784 21.716 19.0247 21.716 16.6148V16.2715"
					stroke="#6498EE"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>

			<span className="text-[13px] font-500 text-blue-700">
				{t("txt_upload_or_drag_and_drop")}
			</span>

			<input {...getInputProps()} />
		</div>
	)
}

export function DropAreaForModel(
	props: SimpleDropAreaProps,
) {
	const {
		className,
		state,
		formats,
		openExplorer,
		disabled,
		noText,
		small,
		button,
	} = props
	const { getRootProps, getInputProps, isDragActive } =
		state

	const { t } = useTranslation("common")

	const supportedFormats = useMemo(
		() =>
			formats
				.map((format) => format.split("/")[1].toUpperCase())
				.join(", "),
		[formats],
	)

	return (
		<div
			{...getRootProps({
				className: clsx(
					className,
					disabled && "pointer-events-none opacity-30",
					small ? "gap-[4px] py-[16px]" : "gap-[14px]",
					isDragActive && "!bg-primary-50",
					button
						? "bg-blue-200 rounded-[6px] h-[44px] w-full hover:bg-blue-300"
						: "bg-blue-50 hover:bg-primary-50",
					"transition-colors duration-200 relative cursor-pointer",
					"flex select-none flex-col items-center justify-center",
					"border-dashed border hover:border-primary-500 border-primary-200",
				),
			})}
			onClick={openExplorer}>
			{button ? (
				<div className="!flex w-full items-center justify-center gap-[6px]">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none">
						<path
							d="M7.915 5.42236C8.77038 4.15183 10.1712 3.33398 11.7409 3.33398C14.1733 3.33398 16.2196 5.31013 16.4209 7.88916C17.8019 8.34416 18.8327 9.7074 18.8327 11.3636C18.8327 13.406 17.2677 15.0007 15.4073 15.0007L15.4033 15.0006L15.3779 15.0005L15.3741 15.0005L13.2502 15.0001C12.8097 15.0001 12.4541 14.6085 12.4542 14.1276C12.4542 13.6467 12.8099 13.2552 13.2504 13.2553L15.382 13.2556L15.4073 13.2558L15.4091 13.2558C16.4517 13.2548 17.2404 12.3747 17.2404 11.3636C17.2404 10.4037 16.5315 9.5638 15.5666 9.47846C15.129 9.43977 14.8037 9.02087 14.8367 8.54295C14.8421 8.46525 14.8448 8.38664 14.8448 8.30722C14.8448 6.74427 13.7607 5.39733 12.285 5.12782C12.1088 5.09564 11.9271 5.07882 11.7409 5.07882C10.4886 5.07882 9.4247 5.84727 8.93254 6.93053C8.83064 7.15483 8.64552 7.32053 8.4241 7.38493C8.20266 7.44934 7.96671 7.40608 7.77652 7.26638C7.56198 7.10879 7.29814 7.01404 7.00829 7.01404C6.2742 7.01404 5.72413 7.61175 5.68838 8.29421C5.66974 8.65017 5.45515 8.95848 5.14573 9.07171C4.31927 9.37415 3.75832 10.1856 3.75832 11.1025C3.75832 12.3047 4.52772 13.2225 5.61626 13.2558H7.94728C8.3878 13.2558 8.74343 13.6473 8.74343 14.1282C8.74343 14.6092 8.3878 15.0007 7.94728 15.0007H5.60602L5.58749 15.0004C3.56947 14.9475 2.16602 13.1876 2.16602 11.1025C2.16602 9.56797 2.99641 8.26282 4.17814 7.62531C4.49432 6.25229 5.66218 5.2692 7.00829 5.2692C7.32382 5.2692 7.6288 5.32279 7.915 5.42236Z"
							fill="var(--color-blue-800)"
						/>
						<path
							d="M9.77456 9.54692C9.91791 9.32021 10.1888 9.16732 10.4993 9.16732C10.8099 9.16732 11.0808 9.32021 11.2241 9.54693L12.7553 11.0781C13.0807 11.4035 13.0807 11.9311 12.7553 12.2566C12.4298 12.582 11.9022 12.582 11.5768 12.2566L11.3327 12.0125V15.9173C11.3327 16.3315 10.9596 16.6673 10.4993 16.6673C10.0391 16.6673 9.66602 16.3315 9.66602 15.9173V12.0125L9.42194 12.2566C9.0965 12.582 8.56886 12.582 8.24343 12.2566C7.91799 11.9311 7.91799 11.4035 8.24343 11.0781L9.77456 9.54692Z"
							fill="var(--color-blue-800)"
						/>
					</svg>

					<span className="text-[15px] font-500 leading-[19px] text-blue-800">
						{t("txt_upload_media")}
					</span>
				</div>
			) : (
				<>
					<div
						className={clsx(
							small
								? "h-[24px] w-[24px]"
								: "h-[54px] w-[54px]",
							"flex items-center justify-center rounded-full bg-primary-100",
						)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={small ? "11" : "20"}
							height={small ? "11" : "16"}
							viewBox="0 0 20 16"
							fill="none">
							<path
								d="M7.84375 3.23181L10 1.07556L12.1563 3.23181"
								stroke="var(--color-primary-700)"
								strokeWidth="1.7037"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10.0005 9.70056V1.07556"
								stroke="var(--color-primary-700)"
								strokeWidth="1.7037"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M1.78345 8.39026V12.0201C1.78345 13.7523 3.18764 15.1565 4.91981 15.1565H15.0793C16.8114 15.1565 18.2156 13.7523 18.2156 12.0201V8.39026"
								stroke="var(--color-primary-700)"
								strokeWidth="1.7037"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<div
						className={clsx(
							noText ? "hidden" : "flex",
							"flex-col items-center gap-[4px]",
						)}>
						<span
							className={clsx(
								"text-[16px] font-500 text-blue-600",
							)}>
							{t("txt_drag_and_drop")}{" "}
							<span className="text-[18px] font-500 text-primary-500">
								{t("lbl_upload")}
							</span>
						</span>

						<input {...getInputProps()} />
						<div>
							<span className="text-[12px] font-400 text-blue-500">
								{t("txt_supported_files")}
							</span>
							<span className="ml-1 text-[12px] font-500 text-blue-600 ">
								{supportedFormats}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
