import { ReactNode } from "react"

interface PhonyProps {
	children: ReactNode
}

const Phony = (props: PhonyProps) => {
	return <div style={{ visibility: "hidden" }}>{props.children}</div>
}

export default Phony
