import clsx from "clsx"
import {
	HTMLAttributes,
	InputHTMLAttributes,
	useRef,
} from "react"

export interface BorderInputProps
	extends InputHTMLAttributes<HTMLInputElement> {
	hasErrors?: boolean
	hasIcon?: boolean
}

export function BorderInput(props: BorderInputProps) {
	const {
		className,
		hasErrors,
		hasIcon,
		disabled,
		...otherProps
	} = props

	return (
		<input
			{...otherProps}
			disabled={disabled}
			className={clsx(
				"border-[1.5px]",
				"bg-color-cell outline-none",
				"rounded-[8px]",
				"py-3 placeholder-color-placeholder",
				"no-autofill transition-all duration-200",
				hasErrors
					? "border-color-error"
					: "border-color-separator focus:border-blue-400",
				hasIcon ? "pl-10 pr-5" : "px-5",
				disabled &&
					"pointer-events-none cursor-crosshair opacity-30",
				className,
			)}
		/>
	)
}

export interface BorderTextareaProps
	extends InputHTMLAttributes<HTMLTextAreaElement> {
	hasErrors?: boolean
	hasIcon?: boolean
}

export function BorderTextarea(props: BorderTextareaProps) {
	const {
		className,
		hasErrors,
		hasIcon,
		disabled,
		...otherProps
	} = props

	return (
		<textarea
			{...otherProps}
			disabled={disabled}
			className={clsx(
				"border-[1.5px] border-color-separator",
				"focus:border-blue-500",
				"bg-color-cell outline-none",
				"rounded-[8px]",
				"py-3 placeholder-color-placeholder",
				"no-autofill transition-all duration-200",
				hasErrors && "border-color-error",
				hasIcon ? "pl-10 pr-5" : "px-5",
				disabled &&
					"pointer-events-none cursor-crosshair opacity-30",
				className,
			)}
		/>
	)
}

interface BorderTextareaResizableProps
	extends HTMLAttributes<HTMLSpanElement> {
	hasErrors?: boolean
	hasIcon?: boolean
	setValue: (value: string) => void
	disabled?: boolean
}

export function BorderTextareaResizable(
	props: BorderTextareaResizableProps,
) {
	const {
		className,
		hasErrors,
		hasIcon,
		placeholder,
		disabled,
		setValue,
		...otherProps
	} = props

	const ref = useRef<HTMLSpanElement>(null)

	return (
		<span
			{...otherProps}
			ref={ref}
			contentEditable={!disabled}
			data-placeholder={placeholder}
			onInput={function () {
				setValue(ref.current?.innerText ?? "")
			}}
			className={clsx(
				"border-[1.5px] border-color-separator",
				"focus:border-blue-500",
				"bg-color-cell outline-none",
				"break-all rounded-[8px]",
				"py-3 placeholder-color-placeholder",
				"no-autofill transition-all duration-200",
				hasErrors && "border-color-error",
				hasIcon ? "pl-10 pr-5" : "px-5",
				disabled &&
					"pointer-events-none cursor-crosshair opacity-30",
				"inline-placeholder cursor-text",
				className,
			)}
		/>
	)
}
