import { initializeApp } from "firebase/app"

if (process.env.NEXT_PUBLIC_FIREBASE_API_KEY === undefined)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_API_KEY is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN === undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ===
	undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_DATABASE_URL is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_PROJECT_ID is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ===
	undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ===
	undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID is not defined`,
	)

if (process.env.NEXT_PUBLIC_FIREBASE_APP_ID === undefined)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_APP_ID is not defined`,
	)

if (
	process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ===
	undefined
)
	throw new Error(
		`Environment variable NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID is not defined`,
	)

const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
const authDomain =
	process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
const databaseURL =
	process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL
const projectId =
	process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
const storageBucket =
	process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
const messagingSenderId =
	process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID
const appId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID
const measurementId =
	process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID

const firebaseConfig = {
	apiKey,
	authDomain,
	databaseURL,
	projectId,
	storageBucket,
	messagingSenderId,
	appId,
	measurementId,
}

export const app = initializeApp(firebaseConfig)
