import { useEffect } from "react"

const useUnflash = () => {
  useEffect(() => {
    document.documentElement.style.visibility = "visible"
  }, [])

  useEffect(() => {
    function handleResize() {
      try {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        outer.parentNode?.removeChild(outer);

        document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
      } catch (e) {
        console.error(e)
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [])
}

export default useUnflash
