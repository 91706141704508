import Floating, { Relative } from "@/comps/floating"

import Phony from "@/comps/phony"
import React from "react"
import { Box } from "react-styled-flex"

import AppearBottom from "./appear-bottom"
import DisappearTop from "./disappear-top"

type SwitchOnChangeProps = {
	children: React.ReactNode[]
	index: number
	short?: boolean
	solid?: boolean
	opaque?: boolean
}

const SwitchOnChange = (props: SwitchOnChangeProps) => {
	const { children, index, short, solid, opaque } = props

	const previous = (index + children.length - 1) % children.length

	return solid ? (
		<Box>{children[index]}</Box>
	) : (
		<Relative>
			<Phony>
				<Box>{children[index]}</Box>
			</Phony>

			<Floating position={{ top: 0, right: 0 }}>
				<DisappearTop key={previous} short={short} opaque={opaque}>
					<Box>{children[previous]}</Box>
				</DisappearTop>
			</Floating>

			<Floating position={{ top: 0, left: 0 }}>
				<AppearBottom key={index} short={short} opaque={opaque}>
					<Box>{children[index]}</Box>
				</AppearBottom>
			</Floating>
		</Relative>
	)
}

export default SwitchOnChange
