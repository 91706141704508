import {
  LockIcon,
  LockIconContainer,
} from "@/comps/advanced-profile"
import { ButtonArrowIcon } from "@/comps/ai-tools/icons"
import Button from "@/comps/button"
import { DropDown } from "@/comps/dropdown"
import Loader from "@/comps/loader"
import { TextTooltip } from "@/comps/tooltip"
import { assetUrl } from "@/utils/cdn"
import {
  arraysAreEqual,
  useAdExamplesViewContext,
} from "@/utils/discover"
import useInfiniteScroll from "@/utils/infinite"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { AdExamplesSingle } from "pages/ad-examples"
import {
  AdExamplesHomeSingle,
  AspectAdExamples,
} from "pages/ai-gallery"
import { useEffect, useMemo, useRef, useState } from "react"
import Masonry, {
  ResponsiveMasonry,
} from "react-responsive-masonry"
import { skeletonColors } from "sections/dashboard/generated-video"
import { AdExamplesPopupProps } from "./ad-examples-popup"
import { getUrlFromString } from "./all-gallery"

interface AdExamplesGridProps {
  setPopupState: (a: AdExamplesPopupProps | null) => void
  isDashboard?: boolean
  startNewSessionWithMessage?: (
    message: string,
  ) => Promise<void>
}

export function AdExamplesGrid(props: AdExamplesGridProps) {
  const {
    setPopupState,
    isDashboard,
    startNewSessionWithMessage,
  } = props
  const { query, state, dispatch } =
    useAdExamplesViewContext()
  const lastRowRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const [name, setName] = useState<string | null>(
    state.applyFilters.searchQuery,
  )

  useInfiniteScroll(query, lastRowRef, 1000)

  const prevQueryKeyComponentsRef = useRef([
    state.applyFilters,
  ])

  useEffect(() => {
    const currentQueryKeyComponents = [state.applyFilters]

    setName(state.applyFilters.searchQuery)

    if (
      !arraysAreEqual(
        currentQueryKeyComponents,
        prevQueryKeyComponentsRef.current,
      )
    ) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })

      prevQueryKeyComponentsRef.current =
        currentQueryKeyComponents
    }
  }, [state.applyFilters])

  const flatHome = useMemo(() => {
    const flatHomeArray: AdExamplesHomeSingle[] = []

    query.data?.pages.forEach((page) => {
      if (page.data.type === "home") {
        page.data.content.forEach((section) => {
          const flattenedSection: AdExamplesSingle[] = []
          const seenIds = new Set<string>()

          section.results.forEach((template) => {
            if (!seenIds.has(template.id)) {
              seenIds.add(template.id)
              flattenedSection.push(template)
            }
          })

          flatHomeArray.push({
            title: section.title,
            query: section.query,
            results: flattenedSection,
          })
        })
      }
    })

    return flatHomeArray
  }, [query.data])

  const flatSearched = useMemo(() => {
    const flattenedSection: AdExamplesSingle[] = []

    query.data?.pages.forEach((page) => {
      if (page.data.type === "searched") {
        const seenIds = new Set<string>()
        page.data.content.forEach((section) => {
          if (!seenIds.has(section.id)) {
            seenIds.add(section.id)
            flattenedSection.push(section)
          }
        })
      }
    })

    return flattenedSection
  }, [query.data])

  const noTemplates =
    query.isSuccess &&
    !query.isFetching &&
    flatHome.length === 0 &&
    flatSearched.length === 0

  function handleSeeMore(id: string) {
    dispatch({
      type: "set_search_query",
      searchQuery: id,
    })

    dispatch({
      type: "apply_filters",
    })

    setName(id)

    const searchUrl = getUrlFromString(id)

    let newUrl = `/ad-examples/${encodeURIComponent(
      searchUrl,
    )}`

    if (isDashboard) {
      newUrl = `/discover?filter=ad-examples&q=${encodeURIComponent(
        searchUrl,
      )}`
    }
    window.history.pushState({ path: newUrl }, "", newUrl)
  }

  return (
    <>
      {!noTemplates && (
        <div
          className={clsx(
            "mx-auto my-5 flex w-full flex-col gap-3 tablet:gap-4 desktop:my-0 desktop:gap-5 desktop:px-0",
            isDashboard
              ? "overflow-hidden tablet:w-full"
              : "tablet:w-[768px] desktop:w-[1175px]",
          )}>
          {name && (
            <div
              className={clsx(
                "flex flex-row items-center gap-1 px-4 desktop:mt-5",
                isDashboard
                  ? "desktop:px-5"
                  : "desktop:px-0",
              )}>
              <a
                href={
                  isDashboard
                    ? "/discover?filter=ad-examples"
                    : "/ad-examples"
                }
                className="cursor-pointer text-[17px] font-500 text-blue-600">
                {t("lbl_ad_examples")}
              </a>
              <div className="flex [--icon-color:var(--color-blue-500)]">
                {BreadCrumb()}
              </div>
              <p className=" text-[17px] font-500 text-blue-800">
                {name.charAt(0).toUpperCase() +
                  name.slice(1)}
              </p>
            </div>
          )}
          {flatHome.length !== 0 &&
            flatHome.map((item, index) => {
              return (
                <div
                  key={item.title + index}
                  className="flex w-full flex-col gap-3 tablet:px-4 desktop:px-0">
                  <div
                    className={clsx(
                      "flex w-full flex-row justify-between px-4 tablet:px-0 desktop:mt-5",
                      isDashboard
                        ? "desktop:px-5"
                        : "desktop:px-0",
                    )}>
                    <h2 className=" text-[14px] font-700 text-blue-700 tablet:text-[18px]">
                      {String(item.title).toUpperCase()}
                    </h2>
                    <a
                      href={
                        "/ad-examples/" +
                        encodeURIComponent(
                          getUrlFromString(item.title),
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        handleSeeMore(item.title)
                      }}
                      className="text-[14px] font-500 text-primary-600 tablet:text-[16px]">
                      {t("txt_see_more")}
                    </a>
                  </div>
                  <div
                    className={clsx(
                      "no-scrollbar w-full gap-[10px] overflow-x-scroll px-4",
                      "-mb-[1000px] pb-[1000px] tablet:gap-3",
                      isDashboard
                        ? "grid grid-flow-col overflow-y-hidden tablet:px-0 desktop:px-5"
                        : "flex flex-row tablet:overflow-hidden tablet:px-0 desktop:grid desktop:grid-cols-5 desktop:gap-5 desktop:px-0",
                    )}>
                    {isDashboard
                      ? item.results.map((single) => (
                          <SingleAd
                            key={single.id}
                            entity={single}
                            setPopupState={setPopupState}
                            isDashboard
                            startNewSessionWithMessage={
                              startNewSessionWithMessage
                            }
                          />
                        ))
                      : item.results
                          .slice(0, 5)
                          .map((single) => (
                            <SingleAd
                              key={single.id}
                              entity={single}
                              setPopupState={setPopupState}
                              isGrid
                              startNewSessionWithMessage={
                                startNewSessionWithMessage
                              }
                            />
                          ))}
                  </div>
                </div>
              )
            })}
          {flatSearched.length !== 0 && (
            <ResponsiveMasonry
              className={clsx(
                "mx-auto gap-3 p-4 tablet:gap-4 desktop:gap-[15px] desktop:px-0 desktop:pt-4",
                isDashboard
                  ? "w-full desktop:px-5"
                  : "w-full tablet:w-[768px] desktop:w-[1175px]",
              )}
              columnsCountBreakPoints={
                isDashboard
                  ? {
                      1: 2,
                      760: 3,
                      900: 4,
                      1200: 5,
                      1500: 6,
                    }
                  : {
                      1: 2,
                      760: 4,
                      1280: 5,
                    }
              }>
              <Masonry gutter="12px">
                {flatSearched.map((single) => (
                  <SingleAd
                    key={single.id}
                    entity={single}
                    setPopupState={setPopupState}
                    startNewSessionWithMessage={
                      startNewSessionWithMessage
                    }
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      )}

      <div ref={lastRowRef} />

      <div className="flex w-full justify-center p-8">
        {query.isFetching && <Loader />}
      </div>

      {noTemplates && (
        <LockIconContainer className="pb-32">
          <LockIcon source={"/general/empty-templates"} />
          {t("txt_no_result_yet")}
        </LockIconContainer>
      )}
    </>
  )
}

interface SingleAdProps {
  entity: AdExamplesSingle
  isGrid?: boolean
  setPopupState?: (a: AdExamplesPopupProps | null) => void
  isDashboard?: boolean
  startNewSessionWithMessage?: (
    message: string,
  ) => Promise<void>
}

export function SingleAd(props: SingleAdProps) {
  const {
    entity,
    isGrid,
    setPopupState,
    isDashboard,
    startNewSessionWithMessage,
  } = props

  const {
    id,
    aspect,
    description,
    preview_thumbnail_url,
    preview_video_url,
    owner,
  } = entity

  const [isHovered, setIsHovered] = useState(false)

  const thumbnail = preview_thumbnail_url.startsWith(
    "https://",
  )
    ? preview_thumbnail_url
    : undefined

  const randomNumber = Math.floor(aspect * 100)

  const isLandscape = aspect > 1.5

  return (
    <div
      style={{
        backgroundColor:
          skeletonColors[
            randomNumber % skeletonColors.length
          ],
        aspectRatio: aspect,
      }}
      className={clsx(
        "group relative shrink-0 rounded-[6px]",
        isGrid &&
          "w-[164px] tablet:w-[172px] desktop:w-full",
        isDashboard &&
          "w-[164px] tablet:w-[172px] desktop:w-[215px]",
        !isDashboard && !isGrid && "w-full",
      )}>
      {description && (
        <div
          className={clsx(
            "absolute bottom-0 left-0 z-10 hidden w-full pt-3 desktop:block",
            "pointer-events-none opacity-0 transition-all group-hover:pointer-events-auto group-hover:opacity-100",
            "rounded-b-[6px]",
            "bg-gradient-to-t from-color-black/80 to-[transparent]",
            "flex cursor-auto flex-col items-center",
          )}>
          {description.trim().length > 0 && (
            <div
              onMouseEnter={() => {
                if (isLandscape) return

                setIsHovered(true)
              }}
              onMouseLeave={() => {
                if (isLandscape) return

                setIsHovered(false)
              }}
              className={clsx(
                "peer relative flex w-full flex-row items-end justify-between p-3 pt-0",
              )}>
              <TextTooltip
                showAlways={description.length > 144}
                className={clsx("max-w-full pr-6")}
                title={description}>
                <p
                  className={clsx(
                    "text-sm font-400 text-color-white transition-[max-height]",
                    isHovered
                      ? "line-clamp-4 max-h-[80px] whitespace-normal"
                      : "max-h-[20px] truncate",
                    isLandscape && "truncate",
                  )}>
                  {description}
                </p>
              </TextTooltip>
            </div>
          )}
          {owner && (
            <a
              href={`/${owner.username}`}
              className="flex flex-row items-center gap-[6px] p-3">
              <img
                src={
                  owner.profile_pic?.original ??
                  assetUrl("/general/avatar.svg")
                }
                onError={(e) =>
                  (e.currentTarget.src = assetUrl(
                    "/general/avatar.svg",
                  ))
                }
                alt={`${owner.username} avatar`}
                className="h-[24px] w-[24px] rounded-full border border-color-separator bg-color-background object-cover"
              />
              <p className="hidden w-[70px] truncate text-[12px] font-600 text-color-white desktop:block">
                {owner.username}
              </p>
            </a>
          )}
        </div>
      )}
      <a
        href={`/ad-examples/single/${id}`}
        onClick={(e) => {
          if (!setPopupState) return

          function onClose() {
            if (!setPopupState) return

            if (isDashboard) {
              window.history.pushState(
                { path: "/discover?filter=ad-examples" },
                "",
                "/discover?filter=ad-examples",
              )
            } else {
              window.history.pushState(
                { path: "/ad-examples" },
                "",
                "/ad-examples",
              )
            }
            setPopupState(null)
          }
          e.preventDefault()
          const newUrl = `/ad-examples/single/${id}`
          window.history.pushState(
            { path: newUrl },
            "",
            newUrl,
          )

          setPopupState({
            entity: entity,
            onClose: onClose,
            startNewSessionWithMessage:
              startNewSessionWithMessage,
          })
        }}
        className="cursor-pointer">
        <div
          className={clsx(
            "w-full overflow-hidden object-cover",
            "rounded-[6px]",
          )}>
          <div className="flex h-full flex-row gap-12">
            {preview_video_url ? (
              <video
                src={preview_video_url}
                poster={thumbnail}
                playsInline
                loop
                preload="none"
                muted
                onPointerEnter={(e) => {
                  e.currentTarget
                    .play()
                    .catch(console.error)
                }}
                onPointerLeave={(e) => {
                  e.currentTarget.pause()
                  e.currentTarget.currentTime = 0.001
                }}
                style={{ aspectRatio: aspect }}
                className={clsx(
                  "h-full w-full object-cover",
                  "rounded-[6px]",
                )}
              />
            ) : (
              <img
                src={thumbnail}
                style={{ aspectRatio: aspect }}
                className={clsx(
                  "h-full w-full object-cover",
                  "rounded-[6px]",
                )}
              />
            )}
          </div>
        </div>
      </a>
    </div>
  )
}

const aspectLabels = {
  "99": { width: null, height: null, label: "txt_all" },
  "9:16": {
    width: 11.6,
    height: 20,
    label: "txt_portrait",
  },
  "16:9": {
    width: 20,
    height: 11.6,
    label: "txt_landscape",
  },
  "1:1": { width: 20, height: 20, label: "txt_square" },
  "4:5": {
    width: 12.9,
    height: 16,
    label: "txt_four_on_five",
  },
  "5:4": {
    width: 16,
    height: 12.9,
    label: "txt_five_on_four",
  },
}

const aspectOptions = [
  "99",
  "9:16",
  "16:9",
  "1:1",
  "4:5",
  "5:4",
] as const

export function RatioAdExamples(props: {
  isDashboard?: boolean
}) {
  const { isDashboard } = props
  const { state, dispatch } = useAdExamplesViewContext()
  const { aspect } = state

  const { t } = useTranslation()

  function setAspect(aspect: AspectAdExamples) {
    dispatch({ type: "set_aspect", aspect })
    dispatch({
      type: "apply_filters",
    })
  }

  const currentLabel = aspectLabels[aspect ?? "99"]

  return (
    <DropDown
      hover
      className={clsx(
        "group tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]",
        isDashboard
          ? "bg-blue-50"
          : "bg-color-cell dark:bg-color-background",
      )}
      trigger={
        <div
          className={clsx(
            "flex flex-row items-center justify-between px-[10px]",
            "tablet:h-10 tablet:rounded-[6px]",
            "tablet:w-[194px] desktop:h-11 desktop:rounded-[10px]",
          )}>
          <p className="text-[14px] font-500 text-blue-500">
            {t("txt_ratio")}
          </p>
          <div className="flex flex-row items-center gap-[6px]">
            <p className="text-[14px] font-500 text-blue-700">
              {t(currentLabel.label)}
            </p>
            <div
              className={clsx(
                "flex h-[18px] w-[18px] -rotate-90 items-center justify-center",
                "transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
              )}>
              <ButtonArrowIcon />
            </div>
          </div>
        </div>
      }>
      <div className="absolute z-10 pt-2 tablet:w-[194px]">
        <div className="w-full rounded-[8px] bg-color-popup-cell p-[10px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
          <div className="flex w-full flex-col gap-1">
            {aspectOptions.map((option) => {
              const label = aspectLabels[option].label
              const width = aspectLabels[option].width
              const height = aspectLabels[option].height
              return (
                <div
                  key={option}
                  onClick={() =>
                    setAspect(
                      option !== "99" ? option : null,
                    )
                  }
                  className={clsx(
                    "flex h-10 w-full cursor-pointer items-center gap-[10px] rounded-[4px] px-[6px] text-[14px] font-500 text-blue-900 transition-all",
                    (
                      aspect !== null
                        ? aspect === option
                        : option === "99"
                    )
                      ? " bg-color-surface"
                      : " hover:bg-color-surface",
                  )}>
                  {width && height ? (
                    <div className="flex h-5 w-5 items-center justify-center">
                      <div
                        style={{
                          width,
                          height,
                        }}
                        className="rounded-[3px] border-[1.7px] border-blue-900"></div>
                    </div>
                  ) : (
                    <div className="flex h-5 w-5 items-center justify-center [--icon-color:var(--color-blue-900)]">
                      {AllRatio()}
                    </div>
                  )}
                  {t(label)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </DropDown>
  )
}

interface FilterDialogProps {
  isOpen: boolean
  setIsOpen: (a: boolean) => void
}

export function FilterDialogAdExamples(
  props: FilterDialogProps,
) {
  const { isOpen, setIsOpen } = props
  const { state, dispatch } = useAdExamplesViewContext()
  const { t } = useTranslation()

  const { aspect } = state

  const clearAll = () => {
    dispatch({ type: "clear_all" })
    setIsOpen(false)
  }

  const setRatio = (aspect: AspectAdExamples) => {
    dispatch({ type: "set_aspect", aspect })
  }

  const apply = () => {
    dispatch({ type: "apply_filters" })
    setIsOpen(false)
  }
  return (
    <>
      <div
        className={clsx(
          "fixed inset-0 flex items-end justify-start bg-color-popup tablet:hidden",
          !isOpen && "pointer-events-none opacity-0",
          "z-[99] transition-opacity duration-300",
        )}
        id="filter-popup"
        onClick={(event) => {
          if (event.target instanceof Element) {
            if (
              event.target.id === event.currentTarget.id
            ) {
              setIsOpen(false)
            }
          }
        }}>
        <div
          className={clsx(
            "relative h-[30svh] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
            isOpen ? "translate-y-0" : "translate-y-full",
          )}>
          <div className="no-scrollbar flex h-full w-full flex-col gap-[18px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-4 py-2">
            {/* Price Section */}
            <div className="flex w-full flex-col gap-3">
              <div className="flex w-full flex-row items-center justify-between">
                <p className="text-[14px] font-600 text-blue-800">
                  {t("txt_ratio")}
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setRatio(null)
                  }}
                  className="text-[12px] font-600 text-blue-500">
                  {t("txt_clear")}
                </button>
              </div>
              <div
                className={clsx(
                  "flex w-full flex-wrap gap-[10px]",
                )}>
                {aspectOptions.map((option) => (
                  <button
                    key={option}
                    onClick={(e) => {
                      e.preventDefault()
                      setRatio(
                        option !== "99" ? option : null,
                      )
                    }}
                    className={clsx(
                      "flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
                      (
                        aspect !== null
                          ? aspect === option
                          : option === "99"
                      )
                        ? "border-blue-100 bg-blue-100 text-blue-800"
                        : "border-color-separator text-blue-700",
                    )}>
                    <div
                      className={clsx(
                        "flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
                        (
                          aspect !== null
                            ? aspect === option
                            : option === "99"
                        )
                          ? "border-primary-500"
                          : "border-[#9195AB]",
                      )}>
                      <div
                        className={clsx(
                          "h-2 w-2 rounded-full bg-primary-500 transition-opacity",
                          (
                            aspect !== null
                              ? aspect === option
                              : option === "99"
                          )
                            ? "opacity-100"
                            : "opacity-0",
                        )}></div>
                    </div>
                    {t(aspectLabels[option].label)}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 left-0 w-full border-t border-color-separator bg-color-popup-cell px-4 py-[14px]">
            <div className="flex h-11 w-full flex-row gap-[10px]">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  clearAll()
                }}
                className="h-full px-[18px] py-3 text-[16px] font-600 text-blue-500">
                {t("txt_clear_all")}
              </button>
              <Button
                onClick={() => {
                  apply()
                }}
                text={t("lbl_apply")}
                className="h-full flex-1"></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BreadCrumb = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.19518 3.52827C5.93483 3.78862 5.93483 4.21073 6.19518 4.47108L9.72378 7.99967L6.19518 11.5283C5.93483 11.7886 5.93483 12.2107 6.19518 12.4711C6.45553 12.7314 6.87764 12.7314 7.13799 12.4711L11.138 8.47108C11.263 8.34605 11.3333 8.17649 11.3333 7.99967C11.3333 7.82286 11.263 7.65329 11.138 7.52827L7.13799 3.52827C6.87764 3.26792 6.45553 3.26792 6.19518 3.52827Z"
      fill="var(--icon-color)"
    />
  </svg>
)

const AllRatio = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.625"
      y="18.375"
      width="16.75"
      height="7.75"
      rx="1.04167"
      transform="rotate(-90 1.625 18.375)"
      stroke="var(--icon-color)"
      strokeWidth="1.25"
    />
    <rect
      x="11.625"
      y="7.375"
      width="5.75"
      height="6.75"
      rx="1.04167"
      transform="rotate(-90 11.625 7.375)"
      stroke="var(--icon-color)"
      strokeWidth="1.25"
    />
    <rect
      x="11.625"
      y="18.375"
      width="8.75"
      height="6.75"
      rx="1.04167"
      transform="rotate(-90 11.625 18.375)"
      stroke="var(--icon-color)"
      strokeWidth="1.25"
    />
  </svg>
)
