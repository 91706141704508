import { useMediaQuery } from "react-responsive"
import styled, {
	css,
	FlattenInterpolation,
	ThemedStyledProps,
} from "styled-components"

export const breakpoints = {
	desktop: 1224,
	tablet: 768,
}

export enum Device {
	Desktop = "desktop",
	Tablet = "tablet",
	Mobile = "mobile",
}

export const useDeviceSize = () => {
	const isDesktop = useMediaQuery({
		query: `(min-width: ${breakpoints.desktop}px)`,
	})

	const isTablet = useMediaQuery({
		query: `(min-width: ${breakpoints.tablet}px)`,
	})

	if (isDesktop) return Device.Desktop
	if (isTablet) return Device.Tablet
	return Device.Mobile
}

export const Desktop = styled.div`
	@media (max-width: ${breakpoints.desktop - 1}px) {
		display: none !important;
	}
`

export const Tablet = styled.div`
	@media (max-width: ${breakpoints.tablet - 1}px),
		(min-width: ${breakpoints.desktop}px) {
		display: none !important;
	}
`

export const Mobile = styled.div`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none !important;
	}
`

export const TabletOrMobile = styled.div`
	@media (min-width: ${breakpoints.desktop}px) {
		display: none !important;
	}
`

export const DesktopOrTablet = styled.div`
	@media (max-width: ${breakpoints.tablet - 1}px) {
		display: none !important;
	}
`

export const onDesktop = <T,>(
	style: FlattenInterpolation<ThemedStyledProps<object, T>>
) => css`
	@media (min-width: ${breakpoints.desktop}px) {
		${style}
	}
`

export const onTablet = <T,>(
	style: FlattenInterpolation<ThemedStyledProps<object, T>>
) => css`
	@media (min-width: ${breakpoints.tablet}px) {
		${style}
	}
`

export const devices = {
	tablet: `(min-width: ${breakpoints.tablet}px)`,
	desktop: `(min-width: ${breakpoints.desktop}px)`,
}

export const wrapped = css`
	@media ${devices.tablet} {
		width: 728px;
	}

	@media ${devices.desktop} {
		width: 1175px;
	}
`
