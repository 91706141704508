import { serialize } from "cookie"

const setMaxCookie = (key: string, value: string) => {
  const maxInt = Math.pow(2, 31) - 1

  document.cookie = serialize(key, "", {
    maxAge: -1,
    sameSite: "lax",
    secure: false,
    path: "/",
  })

  document.cookie = serialize(key, value, {
    maxAge: maxInt,
    sameSite: "lax",
    secure: false,
    path: "/",
  })
}

export default setMaxCookie
