import { SingleGalleryInfo } from "@/ssr/gallery"
import { appStoreConstant } from "@/utils/branch"
import useAuth from "@/utils/client-auth"
import { useIndexedDB } from "@/utils/indexed"
import { redirectToLogin } from "@/utils/login-redirect"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import {
  createInputs,
  DEFORUM_STORAGE_KEY,
} from "comps/editors/deform"
import { getStorage } from "controllers/editors"
import { useRouter } from "next/router"
import { getFirstFiveWords } from "pages/ai-gallery/single/[id]"
import { ActionOptions } from "pages/api/gallery-view"
import { useEffect, useRef, useState } from "react"
import {
  ImageObject,
  VideoObject,
  WithContext,
} from "schema-dts"
import AISinglePage from "sections/history/ai-single-page"
import { DialogProps } from "sections/history/generations"

export interface ShareDialogProps {
  open: boolean
  link: string
}

export const skeletonList = [150, 200, 136, 120, 130, 180]

function getJsonLdData(
  id: number,
  image: string,
  uploadDate: string,
  username: string,
  prompt: string,
  isVideo: boolean,
  preview: string,
  name: string,
  description: string,
): WithContext<ImageObject | VideoObject>[] {
  if (isVideo) {
    return [
      {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name,
        description,
        thumbnailUrl: [preview],
        license: "https://zoomerang.app/privacy-policy",
        acquireLicensePage:
          "https://zoomerang.app/ai-gallery/single/" + id,
        copyrightNotice: "Zoomerang",
        uploadDate,
        author: {
          "@type": "Person",
          name: username,
        },
        creator: {
          "@type": "Person",
          name: username,
        },
        contentUrl: image,
      },
    ]
  }
  return [
    {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      contentUrl: image,
      license: "https://zoomerang.app/privacy-policy",
      acquireLicensePage:
        "https://zoomerang.app/ai-gallery/single/" + id,
      copyrightNotice: "Zoomerang",
      uploadDate,
      author: {
        "@type": "Person",
        name: username,
      },
      creator: {
        "@type": "Person",
        name: username,
      },
      description: prompt,
      caption: prompt,
      url: "https://zoomerang.app/ai-gallery/single/" + id,
    },
  ]
}

interface GallerySingleProps {
  job: SingleGalleryInfo
}

export function GallerySingle(props: GallerySingleProps) {
  const { job } = props
  const router = useRouter()

  const {
    result,
    tool,
    id,
    promptName,
    previewImage,
    customPrompt,
    prompt,
    likes,
    views,
    isVideo,
    accepted_at,
  } = job.job

  const {
    username,
    picture,
    followers,
    uid,
    followStatus,
  } = job.user

  const { userInfo } = useAuth()

  const data = getStorage(
    tool !== "ai_look" && tool !== "ai_suggest"
      ? tool
      : "deform",
  )
  const indexed = useIndexedDB(
    "ai_art",
    data.tool,
    data.storage,
  )

  const viewRef = useRef(false)

  useEffect(() => {
    if (!viewRef.current) {
      viewRef.current = true
      const options: ActionOptions = {
        action: "view",
        gallery_id: id,
      }
      axios
        .post("/api/gallery-view", options)
        .catch((e) => console.error(e))
    }
  }, [id, viewRef])

  function setAsShared() {
    const options: ActionOptions = {
      action: "share",
      gallery_id: id,
    }
    axios
      .post("/api/gallery-view", options)
      .catch((e) => console.error(e))
  }

  async function handleRetry() {
    if (userInfo.isAnonymous) {
      redirectToLogin()
      return
    }

    if (indexed.status !== "ready") return

    const promptType = prompt
    const inputs =
      promptType === "custom"
        ? createInputs({ type: "custom", content: [""] })
        : createInputs({
            type: "predefined",
            id: promptType ?? "",
          })

    switch (tool) {
      case "deform":
        await indexed.setData({
          tool: "deform",
          id: DEFORUM_STORAGE_KEY,
          inputs,
          promptType: prompt ?? "custom",
          location: "local",
          blob: null,
        })
        router.push("/ai-studio?tool=deform")
        break

      case "ai_photo":
        await indexed.setData({
          tool: "ai_photo",
          id: DEFORUM_STORAGE_KEY,
          input: "",
          promptType: prompt,
          location: "local",
          blob: null,
        })
        router.push("/ai-studio?tool=ai_photo")
        break

      case "ai_restyle":
        await indexed.setData({
          tool: "ai_restyle",
          id: DEFORUM_STORAGE_KEY,
          input: "",
          promptType: prompt,
          location: "local",
          blob: null,
        })
        router.push("/ai-studio?tool=ai_restyle")
        break

      case "text_to_image":
        await indexed.setData({
          tool: "text_to_image",
          id: DEFORUM_STORAGE_KEY,
          promptType: prompt,
          text: customPrompt ?? "",
          location: "local",
          blob: null,
        })
        router.push("/ai-studio?tool=text_to_image")
        break
      default:
        const link = document.createElement("a")
        link.href = appStoreConstant
        link.target = "_blank"
        link.click()
        break
    }
  }

  const [shareDialog, setShareDialog] =
    useState<DialogProps>({
      open: false,
      isPublic: true,
      link: `${APP_HOST}/ai-gallery/single/${id}`,
      id: null,
    })

  function scrollToImage(index: number) {
    const nextImage = document
      .getElementsByClassName(`ai-art-generation-${index}`)
      .item(0)
    if (!nextImage) {
      return
    }
    const parent = nextImage.parentElement
    if (!parent) {
      return
    }

    const childX = nextImage.getBoundingClientRect().x
    const parentX = parent.getBoundingClientRect().x
    const childOffset = childX - parentX
    parent.scrollBy({
      behavior: "smooth",
      left: childOffset,
    })
  }

  useEffect(() => {
    scrollToImage(0)
  }, [result])

  return (
    <AISinglePage
      onClose={() => {
        router.push(`/ai-gallery`)
      }}
      createdAt={0}
      imageOrVideo={result.map((elem) => elem.object)}
      isVideo={isVideo}
      previewImage={previewImage}
      tool={tool}
      cover={result[0].thumb}
      id={id ?? 0}
      customPrompt={customPrompt ?? ""}
      style={promptName}
      shareDialog={shareDialog}
      setShareDialog={setShareDialog}
      handleRetry={handleRetry}
      closeLink="/ai-gallery"
      username={username}
      profile_pic={picture}
      followers={followers}
      notShallow
      showRelated={true}
      ownerId={uid}
      followStatus={followStatus ?? "not_following"}
      setAsShared={setAsShared}
      likes={likes}
      views={views}
      jsonLdData={getJsonLdData(
        id,
        result[0].object,
        new Date(accepted_at ?? 0).toISOString(),
        username,
        prompt ?? promptName,
        isVideo,
        result[0].thumb,
        "AI Art" +
          (username ? " by " + username + " - " : " - ") +
          getFirstFiveWords(
            customPrompt && customPrompt.trim().length !== 0
              ? customPrompt
              : promptName,
          ),
        customPrompt
          ? promptName + " - " + customPrompt
          : promptName + " AI Art By " + username,
      )}
    />
  )
}

type SocialButtonIconProps =
  | {
      dark?: undefined
      light?: undefined
      icon: string
    }
  | {
      dark: string
      light: string
      icon?: undefined
    }

type SocialButtonProps = SocialsData & {
  dialogContent: ShareDialogProps
  title: string
  tooltipTitle?: string
  handleSocialShare: (
    desktop?: boolean,
    desktopFunction?: (link: string) => string,
    url?: (link: string) => string,
  ) => Promise<string>
}

type SocialsData = SocialButtonIconProps &
  UrlOrOnClick & {
    desktop?: (link: string) => string
    title: string
  }

type UrlOrOnClick =
  | {
      url: (link: string) => string
      onClick?: undefined
    }
  | {
      onClick: (link: string) => unknown
      url?: undefined
    }

export function SocialButtonGallery(
  props: SocialButtonProps,
) {
  const {
    url,
    dialogContent,
    desktop,
    title,
    handleSocialShare,
    tooltipTitle,
  } = props

  const imageClasses =
    "bg-color-background transition-colors hover:bg-blue-300 p-3 w-[52px] h-[52px]"

  const anchorClasses =
    "flex rounded-full overflow-hidden shrink-0 pointer-events-auto w-[52px] h-[52px]"

  return (
    <>
      <div className="group relative flex flex-col items-center gap-2">
        <button
          onClick={async () => {
            const anchor = document.createElement("a")
            anchor.target = "_blank"
            anchor.rel = "noreferrer"
            const desktopUrl = await handleSocialShare(
              true,
              desktop,
              url,
            )
            anchor.href = desktopUrl

            anchor.click()
          }}
          className={clsx(
            "hidden desktop:block",
            !dialogContent.open
              ? "pointer-events-none"
              : "cursor-pointer",
            anchorClasses,
          )}>
          {props.icon ? (
            <img
              src={props.icon}
              className={imageClasses}
            />
          ) : (
            <>
              <img
                src={props.dark}
                data-hide-on-theme="light"
                className={imageClasses}
              />
              <img
                src={props.light}
                data-hide-on-theme="dark"
                className={imageClasses}
              />
            </>
          )}
        </button>
        <button
          onClick={async () => {
            const anchor = document.createElement("a")
            anchor.target = "_blank"
            anchor.rel = "noreferrer"

            const mobileUrl = await handleSocialShare(
              false,
              undefined,
              url,
            )
            anchor.href = mobileUrl

            anchor.click()
          }}
          className={clsx(
            "desktop:hidden",
            anchorClasses,
            !dialogContent.open
              ? "pointer-events-none"
              : "cursor-pointer",
          )}>
          {props.icon ? (
            <img
              src={props.icon}
              className={imageClasses}
            />
          ) : (
            <>
              <img
                src={props.dark}
                data-hide-on-theme="light"
                className={imageClasses}
              />
              <img
                src={props.light}
                data-hide-on-theme="dark"
                className={imageClasses}
              />
            </>
          )}
        </button>
        <p className="text-[14px] font-600 text-blue-600">
          {title}
        </p>
        <div
          className={clsx(
            "opacity-0 hover:opacity-0 group-hover:opacity-100",
            "absolute -bottom-[22px]",
            "rounded-[4px] px-3 py-1",
            "bg-color-tooltip transition-all",
            "text-xs font-500 text-blue-900",
          )}>
          {tooltipTitle}
        </div>
      </div>
    </>
  )
}
