import { useOutsideClick } from "@/utils/outside-click"
import clsx from "clsx"
import { ReactNode, useRef } from "react"

export interface DropDownProps {
	children: ReactNode
	trigger: ReactNode
	className?: string
	contentClassName?: string
	onFocus?: () => void
	hover?: boolean
	closeOnClick?: boolean
	isOpen?: boolean
	zIndex?: string
	triggerClassName?: string
	closeInSelect?: boolean
	mobileBackground?: boolean
}

export function DropDown(props: DropDownProps) {
	const {
		trigger,
		children,
		className,
		contentClassName,
		onFocus,
		hover,
		closeOnClick,
		isOpen,
		zIndex,
		triggerClassName,
		closeInSelect,
		mobileBackground,
	} = props

	const buttonRef = useRef<HTMLButtonElement>(null)

	const handleClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation()
		onFocus && onFocus()

		// Thanks Safari
		buttonRef.current?.focus()
	}

	return (
		<>
			<div
				className={clsx(
					zIndex ? zIndex : "z-[66px]",
					className,
					"relative",
				)}>
				<button
					ref={buttonRef}
					type="button"
					onClick={handleClick}
					className={clsx(
						"peer focus:border-none focus:outline-none",
						triggerClassName,
					)}>
					{trigger}
				</button>
				{mobileBackground && (
					<div
						className={clsx(
							isOpen
								? "pointer-events-auto opacity-100"
								: "pointer-events-none opacity-0",
							"fixed z-10 h-screen w-screen bg-color-popup tablet:hidden",
							"left-0 top-[58px] opacity-0 peer-focus:opacity-100",
						)}
					/>
				)}
				<div
					className={clsx(
						!closeOnClick &&
							"focus-within:pointer-events-auto focus-within:opacity-100",
						"hover:pointer-events-auto",
						!closeInSelect && "hover:opacity-100",
						hover
							? "peer-hover:pointer-events-auto peer-hover:opacity-100"
							: "peer-focus:pointer-events-auto peer-focus:opacity-100",
						"z-[66] transition-all",
						isOpen
							? "pointer-events-auto opacity-100"
							: "pointer-events-none opacity-0",
						contentClassName,
					)}>
					{children}
				</div>
			</div>
		</>
	)
}

export function DropDownAlternative(props: DropDownProps) {
	const {
		trigger,
		children,
		className,
		contentClassName,
		onFocus,
		hover,
		closeOnClick,
	} = props

	return (
		<>
			<div className={clsx("relative", className)}>
				<button
					type="button"
					onClick={(event) => {
						onFocus && onFocus()
						// thanks Safari
						event.target instanceof HTMLElement &&
							event.target.focus()
					}}
					className="peer z-[66]">
					{trigger}
				</button>
				<div
					className={clsx(
						!closeOnClick &&
							"focus-within:pointer-events-auto focus-within:opacity-100",
						"hover:pointer-events-auto hover:opacity-100",
						hover
							? "peer-hover:pointer-events-auto peer-hover:opacity-100"
							: "peer-focus:pointer-events-auto peer-focus:opacity-100",
						"pointer-events-none opacity-0",
						"z-[66] transition-all",
						contentClassName,
					)}>
					{children}
				</div>
			</div>
		</>
	)
}

interface DropDownWithStateProps {
	isOpen: boolean
	setIsOpen: (v: boolean) => void
	children: ReactNode
	trigger: ReactNode
	className?: string
	contentClassName?: string
	triggerClassName?: string
}
export function DropDownWithState(
	props: DropDownWithStateProps,
) {
	const {
		trigger,
		children,
		className,
		contentClassName,
		isOpen,
		triggerClassName,
		setIsOpen,
	} = props

	const buttonRef = useRef<HTMLButtonElement>(null)

	const handleClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation()
		setIsOpen(!isOpen)

		// Thanks Safari
		buttonRef.current?.focus()
	}

	const dropdownRef = useRef<HTMLDivElement>(null)
	useOutsideClick({
		ref: dropdownRef,
		handler: () => setIsOpen(false),
	})

	return (
		<>
			<div
				ref={dropdownRef}
				className={clsx("z-[66px]", className, "relative")}>
				<button
					ref={buttonRef}
					type="button"
					onClick={handleClick}
					className={clsx(
						"focus:border-none focus:outline-none",
						triggerClassName,
					)}>
					{trigger}
				</button>
				<div
					className={clsx(
						"z-[66] transition-all",
						isOpen
							? "pointer-events-auto opacity-100"
							: "pointer-events-none opacity-0",
						contentClassName,
					)}>
					{children}
				</div>
			</div>
		</>
	)
}
