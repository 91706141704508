import Floating from "@/comps/floating"
import { useClientAuth } from "@/utils/client-auth"

import {
  DesktopOrTablet,
  devices,
  Mobile,
  onDesktop,
  onTablet,
} from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import { useCoins, useCoinsPopup } from "@/utils/coins"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

import { useAdjustLogger } from "@/utils/adjust"
import { appStoreConstant } from "@/utils/branch"
import { useCallback, useEffect, useState } from "react"
import { Box, FlexBox } from "react-styled-flex"
import styled, { css } from "styled-components"
import {
  aiAdsGenratorIcon,
  aiScriptGenerator,
  galleryIcon,
  productUrlToAdsIcon,
  scriptToAdsGenerator,
  templatesIcon,
  videoGptIcon,
} from "../ai-tools/icons"
import { DropDown } from "../dropdown"
import {
  getCurrentPackId,
  SubscriptionPopup,
} from "../subscription-popup"
import { AvatarWithMenu } from "./avatar-with-menu"
import {
  aiPhotoshootIcon,
  ArrowIcon,
  deforumIcon,
  generativeFillIcon,
  HamburgerAnimated,
  Logo,
  LogoSmallScaled,
  lookAiIcon,
  musicGeneratorIcon,
  photoAiIcon,
  restlyeIcon,
  textToImageIcon,
  textToReelIcon,
  textToVideoIcon,
  ThemeToggle,
  videoBGRemoverIcon,
} from "./icon"
import Menu from "./menu"
import HeaderTab from "./tab"

const HeaderPhony = styled.div`
  height: 70px;
  background-color: #ecedef00;
`

const HeaderBackground = styled.div<{
  outside: boolean
  solid?: boolean
  transparent?: boolean
}>`
  .light && {
    ${(props) =>
      props.solid
        ? css`
            background-color: rgba(253, 253, 254, 1);
          `
        : css`
            background-color: rgba(255, 255, 255, 0.8);
          `};
  }

  .dark && {
    ${(props) =>
      props.solid
        ? css`
            background-color: rgba(28, 28, 28, 1);
          `
        : css`
            background-color: rgba(18, 18, 18, 0.7);
          `};
  }

  ${(props) =>
    props.transparent
      ? css`
          background-color: transparent !important;
        `
      : css`
          backdrop-filter: blur(10px);
          border-bottom: 1px solid var(--color-separator);
        `}

  width: var(--w-screen);
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: visible;

  position: fixed;
  top: ${(props) => (props.outside ? -90 : 0)}px;
  left: 0;
  z-index: 99;

  transition: top 300ms;
  transition-timing-function: ease-in-out;
`

const HeaderContainer = styled.div`
  height: 70px;

  display: flex;

  ${onTablet(css`
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
  `)}

  ${onDesktop(css`
    width: 1392px;
    padding: 0 24px;
  `)}
`

const Stretchable = styled.div`
  display: none;

  @media ${devices.desktop} {
    flex: 1;
    display: inline;
  }
`

const MobileHeader = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  ${onTablet(css`
    display: none;
  `)}
`

const TabUnderline = styled.div`
  position: absolute;
  z-index: 49;
  bottom: -24px;
  left: 50%;
  right: 50%;

  height: 2px;
  border-radius: 2px;
  background-color: var(--color-primary-500);

  transition: left 300ms ease-in-out,
    right 300ms ease-in-out;
`

const HeaderTabWrapper = styled.div`
  position: relative;

  &&:hover ${TabUnderline} {
    left: 12px;
    right: 12px;
  }
`

type HeaderItemType = {
  icon: JSX.Element
  name: string
  href: string
  desc?: string
  isNew?: boolean
}

const videoGptContent: HeaderItemType[] = [
  {
    icon: aiAdsGenratorIcon(),
    name: "txt_ai_ads_generator",
    desc: "txt_ai_ads_generator_desc",
    href: "/tools/ai-ad-generator",
  },
  {
    icon: productUrlToAdsIcon(),
    name: "txt_product_url_to_ads",
    desc: "txt_product_url_to_ads_desc",
    href: "/tools/url-to-video-maker",
  },
  {
    icon: videoGptIcon(),
    name: "txt_video_gpt",
    desc: "txt_video_gpt_desc",
    href: "/tools/video-gpt",
  },
  {
    icon: aiScriptGenerator(),
    name: "txt_script_generator",
    desc: "txt_script_generator_desc",
    href: "/tools/ai-script-generator",
  },
  {
    icon: scriptToAdsGenerator(),
    name: "txt_script_to_ads_generator",
    desc: "txt_script_to_ads_generator_desc",
    href: "/tools/ai-script-to-video-generator",
  },
]

type AiStudioContentGroup = {
  groupName: string
  groupElements: HeaderItemType[]
}

const aiStudioContent: AiStudioContentGroup[] = [
  {
    groupName: "lbl_video",
    groupElements: [
      {
        href: "/tools/video-gpt",
        name: "txt_text_to_reel",
        icon: textToReelIcon(),
        isNew: true,
      },
      {
        href: "/tools/ai-video-transformation",
        name: "txt_deforum_ai",
        icon: deforumIcon(),
      },
      {
        href: "/tools/ai-animation-generator",
        name: "txt_restyle_ai",
        icon: restlyeIcon(),
      },
      {
        href: "/tools/text-to-video",
        name: "txt_text_to_video",
        icon: textToVideoIcon(),
      },
      {
        href: "/tools/video-background-remover",
        name: "txt_video_bg_remover",
        icon: videoBGRemoverIcon(),
      },
      {
        href: "/tools/royalty-free-music",
        name: "txt_ai_music_generator",
        icon: musicGeneratorIcon(),
      },
    ],
  },
  {
    groupName: "lbl_image",
    groupElements: [
      {
        href: "/tools/ai-photoshoot-generator",
        name: "txt_ai_photoshoot",
        icon: aiPhotoshootIcon(),
      },
      {
        href: "/tools/ai-photo-editor",
        name: "txt_photo_ai",
        icon: photoAiIcon(),
      },
      {
        href: "/tools/text-to-image",
        name: "txt_text_to_image",
        icon: textToImageIcon(),
      },
      {
        href: "/tools/ai-image-replacer",
        name: "txt_generative_fill",
        icon: generativeFillIcon(),
      },
      {
        href: "/tools/ai-portrait-generator",
        name: "txt_look_ai",
        icon: lookAiIcon(),
      },
    ],
  },
]

interface HeaderProps {
  isStatic?: boolean
  isSolid?: boolean
  isTransparent?: boolean
  hideHiring?: boolean
}

const Header = (props: HeaderProps) => {
  const { isStatic, isSolid, isTransparent, hideHiring } =
    props

  const [show, setShow] = useState(false)
  const [scroll, setScroll] = useState(0)
  const [outside, setOutside] = useState(false)
  const [showNew, setShowNew] = useState(false)

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const { userInfo } = useClientAuth()
  const logAdjust = useAdjustLogger()

  const [active, setActive] = useState("")

  const startHiding = 200

  const handleScroll = useCallback(() => {
    const current = window.scrollY

    if (current < startHiding) {
      if (outside) setOutside(false)
      return
    }

    if (scroll >= current) {
      setOutside(false)
    } else {
      setOutside(true)
    }

    setScroll(window.scrollY)
  }, [scroll, outside])

  const router = useRouter()
  useEffect(() => {
    setActive(router.route)
  }, [router])

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () =>
      document.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  const { t } = useTranslation()

  useEffect(() => {
    if (typeof window !== "undefined" && userInfo) {
      const visited =
        localStorage.getItem(
          `visited-video-gpt-${userInfo.userId}`,
        ) !== null
      setShowNew(!visited)
    }
  }, [userInfo])

  const handleClick = () => {
    if (userInfo) {
      localStorage.setItem(
        `visited-video-gpt-${userInfo.userId}`,
        "true",
      )
      setShowNew(false)
    }
  }

  return (
    <>
      <DesktopOrTablet>
        {!isTransparent && <HeaderPhony />}
        <HeaderBackground
          outside={!isStatic && outside}
          solid={isSolid}
          transparent={isTransparent}>
          <HeaderContainer>
            <Logo hideHiring={hideHiring} />
            <div className="hidden flex-row items-center gap-[18px] tablet:flex desktop:gap-6">
              <HeaderTabWrapper>
                <DropDown
                  hover
                  className="group"
                  trigger={
                    <div
                      className={clsx(
                        "flex items-center gap-1 pl-2 font-500 text-blue-700 transition-colors duration-300 ease-in-out group-hover:text-primary-500",
                      )}>
                      {t("txt_text_to_reel")}

                      <div
                        className={clsx(
                          "transition-all [--icon-color:var(--color-blue-700)]",
                          "group-hover:-rotate-180 group-hover:[--icon-color:var(--color-primary-500)]",
                        )}>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 6L8 10L4 6"
                            stroke="var(--icon-color)"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <New />
                    </div>
                  }>
                  <div className="absolute z-50 pt-3">
                    <div
                      className={clsx(
                        "rounded-[10px] bg-color-popup-cell p-[10px]",
                        "[box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]",
                        "flex w-[395px] flex-col font-500",
                      )}>
                      {videoGptContent.map(
                        (item, index) => {
                          return (
                            <a
                              key={index}
                              href={item.href}
                              className={clsx(
                                "flex items-start gap-3 self-stretch rounded-[8px]",
                                "px-[10px] py-[15px] transition-colors [--icon-color:var(--color-blue-600)]",
                                "hover:bg-color-surface",
                              )}>
                              {item.icon}
                              <div className="flex flex-col items-start gap-[1px]">
                                <p
                                  className={clsx(
                                    "text-[16px] font-500 leading-[19px] text-blue-800",
                                  )}>
                                  {t(item.name)}
                                </p>
                                {item.desc && (
                                  <p
                                    className={clsx(
                                      "w-[239px] text-[13px] font-400 leading-[19px] text-blue-600",
                                    )}>
                                    {t(item.desc)}
                                  </p>
                                )}
                              </div>
                            </a>
                          )
                        },
                      )}
                    </div>
                  </div>
                </DropDown>
              </HeaderTabWrapper>
              <HeaderTabWrapper>
                <DropDown
                  hover
                  className="group"
                  trigger={
                    <div
                      className={clsx(
                        "flex items-center gap-1 pl-2 font-500 text-blue-700 transition-colors",
                        "duration-300 ease-in-out group-hover:text-primary-500",
                      )}>
                      {t("lbl_ai_studio")}

                      <div
                        className={clsx(
                          "transition-all [--icon-color:var(--color-blue-700)]",
                          "group-hover:-rotate-180 group-hover:[--icon-color:var(--color-primary-500)]",
                        )}>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 6L8 10L4 6"
                            stroke="var(--icon-color)"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  }>
                  <div className="absolute z-50 pt-3">
                    <div
                      className={clsx(
                        "rounded-[10px] bg-color-popup-cell px-[10px] pb-[10px] pt-4 text-blue-700",
                        "[box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]",
                        "flex flex-row gap-10",
                      )}>
                      {aiStudioContent.map((group) => {
                        return (
                          <div
                            key={group.groupName}
                            className="flex w-[235px] flex-col gap-3">
                            <span className="px-[10px] text-[15px] font-500 text-blue-600">
                              {t(group.groupName)}
                            </span>

                            <div className="flex flex-col">
                              {group.groupElements.map(
                                (item, index) => {
                                  return (
                                    <a
                                      key={index}
                                      href={item.href}
                                      onClick={() =>
                                        handleClick()
                                      }
                                      className={clsx(
                                        "flex h-[36px] flex-row items-center",
                                        "gap-2 rounded-[8px] px-[10px] text-[16px]",
                                        "font-400 text-blue-700 transition-colors",
                                        "hover:bg-color-surface",
                                      )}>
                                      <div className="[--icon-color:var(--color-blue-700)]">
                                        {item.icon}
                                      </div>
                                      {t(item.name)}
                                      {item.isNew &&
                                        showNew && <New />}
                                    </a>
                                  )
                                },
                              )}
                              <div className="flex h-[33px] flex-row items-center rounded-[8px] px-[10px]">
                                <a
                                  href="/tools"
                                  className="peer text-[14px] font-400 text-blue-600">
                                  {t("txt_all_tools")}
                                </a>
                                <div
                                  className={clsx(
                                    "opacity-0 transition-all [--icon-color:var(--color-blue-600)]",
                                    "peer-hover:translate-x-2 peer-hover:opacity-100",
                                  )}>
                                  {ArrowIcon()}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </DropDown>
              </HeaderTabWrapper>
              <HeaderTabWrapper>
                <DropDown
                  hover
                  className="group"
                  trigger={
                    <div
                      className={clsx(
                        "flex items-center gap-1 pl-2 font-500 text-blue-700 transition-colors duration-300 ease-in-out group-hover:text-primary-500",
                      )}>
                      {t("lbl_discover")}

                      <div className="transition-all [--icon-color:var(--color-blue-700)] group-hover:-rotate-180 group-hover:[--icon-color:var(--color-primary-500)]">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 6L8 10L4 6"
                            stroke="var(--icon-color)"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  }>
                  <div className="absolute z-50 w-[395px] pt-3">
                    <div
                      className={clsx(
                        "rounded-[10px] bg-color-popup-cell px-[10px] py-[10px]",
                        "[box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]",
                        "flex flex-col",
                        "font-500",
                      )}>
                      <a
                        href="/ai-gallery"
                        className={clsx(
                          "flex flex-row items-start gap-3 self-stretch rounded-[8px] px-[10px] py-[15px] transition-colors [--icon-color:var(--color-blue-600)] hover:bg-color-surface",
                        )}>
                        {galleryIcon()}
                        <div className="flex flex-col items-start gap-[1px]">
                          <p
                            className={clsx(
                              "text-[16px] font-500 leading-[19px] text-blue-800",
                            )}>
                            {t("lbl_ai_gallery")}
                          </p>
                          <p
                            className={clsx(
                              "w-[239px] text-[13px] font-400 leading-[19px] text-blue-600",
                            )}>
                            {t("txt_explore_ai_art_videos")}
                          </p>
                        </div>
                      </a>

                      <a
                        href="/video-templates"
                        className={clsx(
                          "flex flex-row items-start gap-3 self-stretch rounded-[8px] px-[10px] py-[15px] transition-colors [--icon-color:var(--color-blue-600)] hover:bg-color-surface",
                        )}>
                        {templatesIcon()}
                        <div className="flex flex-col items-start gap-[1px]">
                          <p
                            className={clsx(
                              "text-[16px] font-500 leading-[19px] text-blue-800",
                            )}>
                            {t("lbl_templates")}
                          </p>
                          <p
                            className={clsx(
                              "w-[245px] text-[13px] font-400 leading-[19px] text-blue-600",
                            )}>
                            {t(
                              "txt_discover_industry_leading_template",
                            )}
                          </p>
                        </div>
                      </a>

                      <a
                        href="/ad-examples"
                        className={clsx(
                          "flex flex-row items-start gap-3 self-stretch rounded-[8px] px-[10px] py-[15px] transition-colors [--icon-color:var(--color-blue-600)] hover:bg-color-surface",
                        )}>
                        {aiAdsGenratorIcon()}
                        <div className="flex flex-col items-start gap-[1px]">
                          <p
                            className={clsx(
                              "text-[16px] font-500 leading-[19px] text-blue-800",
                            )}>
                            {t("lbl_ad_examples")}
                          </p>
                          <p
                            className={clsx(
                              "w-[245px] text-[13px] font-400 leading-[19px] text-blue-600",
                            )}>
                            {t(
                              "txt_ad_examples_description",
                            )}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </DropDown>
              </HeaderTabWrapper>
              <HeaderTabWrapper className="hidden desktop:block">
                <HeaderTab
                  link={appStoreConstant}
                  newTab
                  name="lbl_mobile_app"
                />
                <TabUnderline />
              </HeaderTabWrapper>
              <HeaderTabWrapper className="hidden desktop:block">
                <HeaderTab
                  link="/pricing"
                  name="lbl_pricing"
                  active={"/pricing" === active}
                />
                <TabUnderline />
              </HeaderTabWrapper>
            </div>
            <Stretchable />
            <div className="flex min-w-[200px] justify-end gap-4">
              {userInfo?.isAnonymous ? (
                <AvatarWithMenu
                  setSubscriptionPopupOpen={
                    setSubscriptionPopupOpen
                  }
                />
              ) : (
                <a
                  href="/home"
                  onClick={() =>
                    logAdjust?.logEventWithPayload(
                      "w_dp_dashboard",
                      { from: router.asPath },
                    )
                  }
                  className={clsx(
                    "bg-primary-500 px-4 py-[6px] text-[16px] font-600 hover:bg-primary-600",
                    "rounded-[6px] text-color-white",
                    "animate-appear transition-colors",
                  )}>
                  {t("lbl_dashboard")}
                </a>
              )}
              {userInfo !== null &&
                userInfo.isAnonymous && <ThemeToggle />}
            </div>
          </HeaderContainer>
        </HeaderBackground>
      </DesktopOrTablet>
      <Mobile>
        <Box height={48} />
        <MobileHeader className="bg-[#ecedef] dark:bg-[#121212]">
          <FlexBox
            center
            justifyContent="space-between"
            width="var(--w-screen)"
            padding="4px 14px">
            <LogoSmallScaled />
            <div className="flex items-center gap-4">
              {userInfo?.isAnonymous ? (
                <AvatarWithMenu
                  setSubscriptionPopupOpen={
                    setSubscriptionPopupOpen
                  }
                  onFocus={() => setShow(false)}
                />
              ) : (
                <a
                  href="/home"
                  onClick={() =>
                    logAdjust?.logEventWithPayload(
                      "w_dp_dashboard",
                      { from: router.asPath },
                    )
                  }
                  className={clsx(
                    "bg-primary-500 px-4 py-[6px] text-[16px] font-600 hover:bg-primary-600",
                    "rounded-[6px] text-color-white",
                    "animate-appear transition-colors",
                  )}>
                  {t("lbl_dashboard")}
                </a>
              )}
              <HamburgerAnimated
                onClick={() => setShow(!show)}
                show={show}
              />
            </div>
          </FlexBox>
          <Floating position={{ top: 8, left: 0 }}>
            <FlexBox center></FlexBox>
          </Floating>
        </MobileHeader>
        <Menu show={show} hide={() => setShow(false)} />
      </Mobile>

      <SubscriptionPopup
        isOpen={subscriptionPopupOpen}
        close={() => setSubscriptionPopupOpen(false)}
        location={router.asPath}
      />
    </>
  )
}

interface UpgradeButtonProps {
  setSubscriptionPopupOpen: (v: boolean) => void
}

export function UpgradeButton(props: UpgradeButtonProps) {
  const { setSubscriptionPopupOpen } = props
  const { t } = useTranslation()
  const { userInfo } = useClientAuth()
  const logAdjust = useAdjustLogger()
  const router = useRouter()

  const isFreeUser =
    userInfo !== null &&
    !userInfo.isAnonymous &&
    getCurrentPackId(userInfo.entitlements).packId ===
      "free"

  return (
    <div
      className={clsx(
        isFreeUser ? "flex" : "hidden",
        "items-center",
      )}>
      <button
        onClick={(e) => {
          e.preventDefault()
          logAdjust?.logEventWithPayload("w_dp_upgrade", {
            from: router.asPath,
          })
          setSubscriptionPopupOpen(true)
        }}
        className={clsx(
          "flex h-8 w-[92px] items-center justify-center rounded-[8px]",
          "bg-green-100 text-[16px] font-600 text-green-800",
          "transition-colors duration-300 hover:bg-green-200",
          "tablet:h-9 tablet:w-[103px]",
        )}>
        {t("txt_upgrade")}
      </button>
    </div>
  )
}

function formatCoins(coins: number): string {
  if (coins < 100_000) {
    return coins.toLocaleString()
  }

  if (coins < 1_000_000) {
    return Math.floor(coins / 1_000) + "K"
  }

  return Math.floor(coins / 1_000_000) + "M"
}

interface CoinsButtonProps {
  className?: string
}

function CoinsButton(props: CoinsButtonProps) {
  const { className } = props

  const coins = useCoins()
  const openPopup = useCoinsPopup()

  const coinsFormatted = coins
    ? formatCoins(coins.coins)
    : coins

  return (
    <button
      onClick={() => openPopup()}
      className={clsx(
        "group flex h-[34px] w-[135px] items-center gap-2 rounded-[10px] px-3",
        "border border-blue-100 bg-color-background",
        coins === null &&
          "pointer-events-none hidden opacity-0",
        className,
      )}>
      <div className="flex flex-1 gap-1">
        <img
          src={assetUrl("/general/coin.svg")}
          alt="coin icon"
        />
        <span className="flex-1 text-left font-600 text-blue-800">
          {coinsFormatted}
        </span>
      </div>
      <div className="h-full w-[2px] bg-blue-200" />
      <img
        src={assetUrl("/general/green-plus.svg")}
        alt="add coins"
        className="aspect-square w-5 transition-all group-hover:brightness-95"
      />
    </button>
  )
}

export function New() {
  const { t } = useTranslation()

  return (
    <div className="ml-1 flex h-5 w-[37px] items-center justify-center rounded bg-primary-100 text-center text-[10px] font-700 text-primary-500">
      {t("lbl_new")}
    </div>
  )
}

export default Header
