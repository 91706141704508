import { useRouter } from "next/router"
import { useEffect } from "react"

export type UtmIds = {
	gclid?: string
	utm_source?: string
	utm_campaign?: string
	utm_content?: string
	utm_term?: string
}

const UTM_KEY = "utm"

export function getUtmIds(): UtmIds | null {
	const value = sessionStorage.getItem(UTM_KEY)
	if (value) {
		try {
			return JSON.parse(value) as UtmIds
		} catch (error) {
			console.error(
				"Failed to parse UTM IDs from session storage",
				error,
			)
			return null
		}
	}
	return null
}

const useUtmIdentifiers = () => {
	const router = useRouter()

	useEffect(() => {
		if (router.isReady) {
			const {
				gclid,
				utm_source,
				utm_campaign,
				utm_content,
				utm_term,
			} = router.query

			const hasOneProperty =
				gclid ||
				utm_source ||
				utm_campaign ||
				utm_content ||
				utm_term

			if (
				typeof gclid !== "object" &&
				typeof utm_source !== "object" &&
				typeof utm_campaign !== "object" &&
				typeof utm_content !== "object" &&
				typeof utm_term !== "object" &&
				hasOneProperty
			) {
				const utmJson: UtmIds = {
					gclid,
					utm_campaign,
					utm_content,
					utm_source,
					utm_term,
				}
				sessionStorage.setItem(
					UTM_KEY,
					JSON.stringify(utmJson),
				)
			}
		}
	}, [router.isReady, router.query])
}

export default useUtmIdentifiers
