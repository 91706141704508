import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import { NotificationFunction } from "./notification"

export async function mutateWithNotify(
	notify: NotificationFunction,
	result: () => Promise<unknown>,
	successMessage: string,
	errorMessage: string
) {
	try {
		await result()
		notify(
			<SuccessMessage>{successMessage}</SuccessMessage>
		)
	} catch (error) {
		console.error(error)
		notify(<ErrorMessage>{errorMessage}</ErrorMessage>)
	}
}

type Result<T> = [true, T] | [false, unknown]

export async function withSkeleton<T>(
	action: Promise<T>,
	setter: (status: boolean) => void
): Promise<Result<T>> {
	setter(true)
	try {
		const result = await action
		setter(false)
		return [true, result]
	} catch (e) {
		console.error("Failed to perform action")
		setter(false)
		return [false, e]
	}
}
