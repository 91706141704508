import { NewButton } from "@/comps/button"
import Input from "@/comps/input"
import { SuccessMessage } from "@/comps/message"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import { redirectToLogin } from "@/utils/login-redirect"
import { NotificationContext } from "@/utils/notification"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { type GalleryReportRequest } from "pages/api/gallery-report"
import { type TutorialReportRequest } from "pages/api/tutorial-report"
import { type UserReportRequest } from "pages/api/user-report"
import { useContext, useState } from "react"

interface PayloadMap {
	gallery: GalleryReportRequest
	user: UserReportRequest
	tutorial: TutorialReportRequest
}

interface ReportPopupProps<T extends keyof PayloadMap> {
	endpoint: string
	setReportPopup: (v: boolean) => void
	className?: string
	payloadLocation: T
	payload: PayloadMap[T]
	setPayload: (value: PayloadMap[T]) => void
	galleryOrTemplate: "gallery" | "template"
	closeFn?: () => void
}

export function ReportPopup<T extends keyof PayloadMap>(
	props: ReportPopupProps<T>
) {
	const {
		payloadLocation,
		payload,
		setReportPopup,
		className,
		endpoint,
		galleryOrTemplate,
		setPayload,
		closeFn
	} = props

  const [selectedReason, setSelectedReason] =
    useState<string>("")
  const [message, setMessage] = useState("")
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  const auth = useAuth()

  async function handleReport() {
    if (auth.userInfo && auth.userInfo.isAnonymous) {
      redirectToLogin()
    }

    try {
      await axios.post(`/api/${endpoint}`, payload)

      notify(
        <SuccessMessage>
          {t("txt_report_success")}
        </SuccessMessage>
      )
      setReportPopup(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div
      className={clsx(
        className ??
          clsx(
            "fixed bottom-0 left-0 right-0 tablet:-right-[40px] tablet:bottom-auto tablet:left-auto",
            "z-[100] tablet:top-[740px] tablet:z-[200] desktop:-right-[150px] desktop:top-[80px]"
          ),

        "flex flex-col items-center p-[24px] tablet:w-[364px] tablet:rounded-[12px]",
        "w-full gap-[18px] rounded-t-[12px] bg-color-popup-cell shadow-xl"
      )}>
      <div className="w-full">
        <span className="text-[24px] font-600 text-blue-800">
          {t("txt_report")}
        </span>
        <p className="text-[14px] font-500 text-blue-600">
          {t("txt_report_desc")}
        </p>
      </div>
      <div className="w-full">
        {new Array(galleryOrTemplate === "gallery" ? 5 : 6)
          .fill(null)
          .map((_, index) => {
            return (
              <div key={index}>
                <button
                  onClick={() =>
                    setSelectedReason(
                      t(
                        `txt_${galleryOrTemplate}_report_reason_${index}`
                      )
                    )
                  }
                  className={clsx(
                    selectedReason ===
                      t(
                        `txt_${galleryOrTemplate}_report_reason_${index}`
                      )
                      ? "bg-blue-300"
                      : "hover:bg-blue-cell",
                    "flex items-center justify-between",
                    "w-full rounded-[8px] px-[10px] py-[12px]"
                  )}>
                  <p className="text-[14px] font-500 text-blue-600">
                    {t(
                      `txt_${galleryOrTemplate}_report_reason_${index}`
                    )}
                  </p>
                  {selectedReason ===
                  t(
                    `txt_${galleryOrTemplate}_report_reason_${index}`
                  ) ? (
                    <img
                      src={assetUrl(
                        "/general/check-primary.svg"
                      )}
                      alt="check icon primary"
                      className="h-[20px] w-[20px]"
                    />
                  ) : (
                    <div className="h-[20px] w-[20px] rounded-full border border-blue-300" />
                  )}
                </button>
                {selectedReason ===
                  t(
                    `txt_${galleryOrTemplate}_report_reason_${index}`
                  ) && (
                  <Input
                    value={message}
                    setValue={setMessage}
                    label={t("txt_report_placeholder")}
                  />
                )}
              </div>
            )
          })}
      </div>
      <div className="flex w-full items-center gap-[10px]">
        <button
          onClick={() =>
            setReportPopup && setReportPopup(false)
          }
          className="w-[100px] rounded-[10px] px-[18px] py-[12px] text-[16px] font-600 text-blue-600">
          {t("lbl_cancel")}
        </button>
        <NewButton
          text={t("txt_report")}
          variant="secondary"
          disabled={
            selectedReason === "" ||
            (selectedReason === "Other" && message === "")
          }
          onClick={async () => {
            setPayload({
              ...payload,
              info: {
                reason: selectedReason,
                message,
              },
            })
            await handleReport()
            closeFn?.()
          }}
        />
      </div>
    </div>
  )
}
