import { moreIcon } from "@/comps/ai-tools/icons"
import { DropDown } from "@/comps/dropdown"
import { CustomThemedResource } from "@/comps/image"
import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import { APP_HOST } from "@/utils/variables"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { DialogProps } from "sections/history/generations"

interface MoreButtonProps {
  setShowDropdown: (v: boolean) => void
  showDropdown: boolean
  handleMakePublic?: (
    privacy: "public" | "private",
    id: number,
  ) => Promise<void>
  handleDelete?: (id: number) => void
  id: number
  href: string
  privacy?: "public" | "private"
  setShareDialog: (obj: DialogProps) => void
  dark: boolean
  isPublic?: boolean
  isSinglePage?: boolean
  setPublic?: (v: boolean) => void
  username?: string
  closeLink?: string
  setReportPopup?: (v: boolean) => void
  reportPopup?: boolean
}

export function MoreButton(props: MoreButtonProps) {
  const {
    handleMakePublic,
    id,
    href,
    handleDelete,
    privacy,
    setShareDialog,
    isPublic,
    setPublic,
    isSinglePage,
    username,
    closeLink,
    setReportPopup,
    reportPopup,
  } = props

  const logAdjust = useAdjustLogger()
  const { t } = useTranslation()

  return (
    <DropDown
      hover
      closeOnClick
      className={clsx(
        "h-[40px] w-[40px] tablet:h-[45px] tablet:w-[45px]",
      )}
      trigger={
        <div
          className={clsx(
            "flex items-center justify-center rounded-full bg-color-white/25 p-2",
            "hover:bg-color-black/50 tablet:rounded-xl tablet:bg-[inherit] tablet:hover:bg-blue-100",
            "cursor-pointer [--icon-color:var(--color-white)] dark:[--icon-color:var(--color-white)]",
            "tablet:z-[102] tablet:h-[45px] tablet:w-[45px] tablet:p-[7px] tablet:[--icon-color:var(--color-blue-900)]",
          )}>
          {moreIcon()}
        </div>
      }>
      <div
        className={clsx(
          reportPopup && "hidden",
          "absolute right-0 z-[101] w-[231px] pt-2",
          "desktop:left-1/2 desktop:right-[unset] desktop:-translate-x-1/2",
        )}>
        <div
          className={clsx(
            "flex flex-col gap-1 rounded-[12px] bg-color-popup-cell p-2 shadow-xl [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]",
          )}>
          {handleMakePublic &&
            privacy &&
            setPublic &&
            !username && (
              <button
                className={clsx(
                  !isPublic && "tablet:hidden",
                  isSinglePage && "hidden",
                  "flex w-full cursor-pointer items-center gap-2 px-4 py-3 tablet:gap-3",
                  "rounded-lg [--icon-color:var(--color-blue-600)] hover:bg-blue-300",
                )}
                onClick={async () => {
                  setPublic(!isPublic)
                  await handleMakePublic(privacy, id)
                }}>
                <CustomThemedResource
                  format="svg"
                  source={
                    isPublic
                      ? "/ai-tools/history/lock"
                      : "/ai-tools/history/globus"
                  }
                />

                <span className="text-[14px] font-500 text-blue-800">
                  {isPublic
                    ? t("txt_make_private")
                    : t("txt_make_public")}
                </span>
              </button>
            )}

          {!username && (
            <div
              className={clsx(
                !isPublic && "tablet:hidden",
                isSinglePage && "hidden",
                "h-[1px] w-full bg-blue-100",
              )}></div>
          )}

          <a
            onClick={() => {
              logAdjust?.logEvent("deform_ai_video_save")
            }}
            href={href}
            download={true}
            className={clsx(
              "flex items-center gap-3 px-4 py-3 desktop:hidden",
              "w-full rounded-[8px] transition-colors hover:bg-blue-300",
              "disabled:hover:bg-transparent disabled:opacity-50",
              "tablet:hidden",
            )}>
            <CustomThemedResource
              format="svg"
              source="/ai-tools/history/save"
            />
            <span className="text-[14px] font-500 text-blue-800">
              {t("txt_save")}
            </span>
          </a>

          <button
            onClick={() =>
              setShareDialog({
                open: true,
                isPublic: isPublic ?? true,
                link: closeLink
                  ? `${APP_HOST}/ai-gallery/single/${id}`
                  : `${APP_HOST}/profile/generations/${id}`,
                id: id,
              })
            }
            className={clsx(
              "flex items-center gap-3 px-4 py-3 desktop:hidden",
              "w-full rounded-[8px] transition-colors hover:bg-blue-300",
              "disabled:hover:bg-transparent disabled:opacity-50",
              "tablet:hidden",
            )}>
            <CustomThemedResource
              format="svg"
              source="/ai-tools/history/share"
              className="w-[23px]"
            />
            <span className="text-[14px] font-500 text-blue-800">
              {t("txt_share")}
            </span>
          </button>

          {setReportPopup && (
            <button
              onClick={() => setReportPopup(true)}
              className={clsx(
                "flex items-center gap-3 px-4 py-3 tablet:hidden",
                "w-full rounded-[8px] transition-colors hover:bg-blue-300",
                "disabled:hover:bg-transparent disabled:opacity-50",
              )}>
              <CustomThemedResource
                source="/general/report-gray"
                format="svg"
              />
              <span className="text-[14px] font-500 text-blue-800">
                {t("txt_report")}
              </span>
            </button>
          )}

          {handleDelete !== undefined && !username && (
            <button
              onClick={() => {
                handleDelete(id)
              }}
              className={clsx(
                isSinglePage && "hidden",
                "flex items-center gap-3 px-4 py-3",
                "w-full rounded-[8px] transition-colors hover:bg-blue-300",
                "disabled:hover:bg-transparent disabled:opacity-50",
              )}>
              <img
                src={assetUrl(
                  "/ai-tools/history/trash.svg",
                )}
                alt="trash"
              />
              <span className="text-[14px] font-500 text-color-error">
                {t("txt_remove")}
              </span>
            </button>
          )}
        </div>
      </div>
    </DropDown>
  )
}
