import { Validator } from "@/utils/validators"
import { useTranslation } from "next-i18next"
import {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useState,
} from "react"
import styled, { css } from "styled-components"

const Line = styled.div`
  position: relative;
  display: block;
  width: 100%;

  &&:before {
    content: "";
    height: 1px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: var(--color-blue-900);
    transition: width 300ms ease-in-out;
    left: 0;
  }
`

const Container = styled.div`
  position: relative;
  margin: 12px 0;
`

const Label = styled.label`
  color: var(--color-placeholder);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;

  letter-spacing: 0.07em;
  transition: font-size 300ms, top 300ms, color 300ms;
  transition-timing-function: ease-in-out;
`

const Invalid = styled.span`
  position: absolute;
  bottom: -20px;
  right: 4px;

  font-size: 12px;
  letter-spacing: 0.07em;

  color: var(--color-error);
`

const inputStyle = css`
  background: none;
  color: var(--color-blue-900);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  line-height: 1.2em;

  &&:focus ~ ${Line}:before {
    width: 100%;
  }
`

// noinspection CssInvalidPseudoSelector
const MainInput = styled.input<{ errors: boolean }>`
  ${inputStyle};

  letter-spacing: 0.04em;
  border-bottom: 1px solid
    ${(props) =>
      !props.errors
        ? "var(--color-separator)"
        : "var(--color-error)"};

  &&:focus {
    outline: none;
    color: var(--color-blue-900);
  }

  &&:focus ~ ${Label}, &&:not([value=""]) ~ ${Label} {
    top: -10px;
    font-size: 12px;
    color: var(--color-blue-900);
  }

  :-webkit-autofill,
  :-webkit-autofill:focus,
  :autofill {
    color: var(--color-blue-900) !important;
    background-color: var(
      --color-background-cell
    ) !important;

    background-clip: content-box !important;
    -webkit-text-fill-color: var(--color-blue-900);
    -webkit-box-shadow: 0 0 0 60px
      var(--color-background-cell) inset !important;
  }
`

const MainTextarea = styled.textarea<{ errors: boolean }>`
  ${inputStyle};

  border-bottom: 1px solid
    ${(props) =>
      !props.errors
        ? "var(--color-separator)"
        : "var(--color-error)"};

  resize: none;

  &&:focus {
    outline: none;
    color: var(--color-blue-900);
  }

  &&:focus ~ ${Label}, &&:not(:empty) ~ ${Label} {
    top: -10px;
    font-size: 12px;
    color: var(--color-blue-900);
  }
`

interface UseValidatorResponse {
  errors: string
  validate: () => void
}

export const useValidator = (
  value: string,
  validators: Array<Validator>,
): UseValidatorResponse => {
  const [errors, setErrors] = useState("")
  const { t } = useTranslation()

  const validate = useCallback(() => {
    if (validators) {
      const anyError = validators.find((validator) => {
        const result = validator(value, t)
        if (result) {
          setErrors(result)
          return true
        }

        return false
      })

      if (!anyError) setErrors("")
    }
  }, [t, validators, value])

  useEffect(() => {
    if (errors) {
      validate()
    }
  }, [value, errors, validate])

  return { validate, errors }
}

interface InputProps {
  value: string
  setValue: (current: string) => void
  label: string
  mode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
  type?: HTMLInputTypeAttribute
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  validate?: () => void
  errors?: string
  name?: string
}

const Input = (props: InputProps) => {
  const {
    value,
    setValue,
    label,
    className,
    onChange,
    type,
    mode,
    validate,
    errors,
    name,
  } = props
  return (
    <Container className={className}>
      <MainInput
        onBlur={validate}
        onChange={(e) => {
          setValue(e.target.value)
          onChange && onChange(e)
        }}
        name={name}
        inputMode={mode}
        type={type}
        value={value}
        errors={Boolean(errors)}
      />
      <Line />
      <Label>{label}</Label>
      <Invalid>{errors ?? ""}</Invalid>
    </Container>
  )
}

interface TextareaProps {
  label: string
  className?: string
  value: string
  setValue: (current: string) => void
  rows: number
  validate?: () => void
  errors?: string
}

export const Textarea = (props: TextareaProps) => {
  const {
    label,
    className,
    value,
    setValue,
    rows,
    validate,
    errors,
  } = props

  return (
    <Container className={className}>
      <MainTextarea
        onBlur={validate}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        rows={rows}
        errors={Boolean(errors)}
      />
      <Line />
      <Label>{label}</Label>
      <Invalid>{errors ?? ""}</Invalid>
    </Container>
  )
}

export default Input
