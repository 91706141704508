import { ErrorMessage } from "@/comps/message"
import { SizedSkeleton } from "@/comps/skeleton"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { PaymentCoinPacksResponse } from "pages/api/payment-coin-packs"
import {
	PaymentPackUrlRequest,
	PaymentPackUrlResponse,
} from "pages/api/payment-pack-url"
import { WalletCoinsResponse } from "pages/api/wallet-balance"
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from "react"
import { useQuery } from "react-query"
import { v4 } from "uuid"
import {
	PaymentSessionData,
	PAYMENT_DATA_KEY,
} from "./adjust"
import { assetUrl } from "./cdn"
import { useClientAuth } from "./client-auth"
import { redirectToLogin } from "./login-redirect"
import { NotificationContext } from "./notification"

export interface CoinsContextValue {
	wallet: WalletCoinsResponse | null
	openPopup: (minimumCoins?: number) => void
}

export const CoinsContext =
	createContext<CoinsContextValue>({
		wallet: null,
		openPopup: (minimumCoins?: number) => ({}),
	})

function fetchCoins() {
	return async function (): Promise<WalletCoinsResponse> {
		const result = await axios
			.get<WalletCoinsResponse>("/api/wallet-balance")
			.then((res) => res.data)

		return result
	}
}

interface CoinsProviderProps {
	children: React.ReactNode
}

export function CoinsProvider(props: CoinsProviderProps) {
	const { children } = props
	const [isOpen, setIsOpen] = useState(false)
	const [minimum, setMinimum] = useState<
		number | undefined
	>(undefined)

	const { userInfo } = useClientAuth()

	const query = useQuery({
		queryKey: ["coins", "user", userInfo?.userId],
		queryFn: fetchCoins(),
		enabled: userInfo !== null && !userInfo.isAnonymous,
	})

	return (
		<CoinsContext.Provider
			value={{
				wallet: query.data ?? null,
				openPopup: (minimumCoins?: number) => {
					setMinimum(minimumCoins)
					setIsOpen(true)
				},
			}}>
			<CoinsPopup
				minimumCoins={minimum}
				coins={query.data?.coins ?? 0}
				open={isOpen}
				setOpen={setIsOpen}
			/>
			{children}
		</CoinsContext.Provider>
	)
}

export function useCoins(): WalletCoinsResponse | null {
	return useContext(CoinsContext).wallet
}

export function useCoinsPopup() {
	return useContext(CoinsContext).openPopup
}

async function fetchPrices() {
	return await axios
		.get<PaymentCoinPacksResponse>(
			"/api/payment-coin-packs",
		)
		.then((res) => res.data)
}

interface CoinsPopupProps {
	minimumCoins?: number
	coins: number
	open: boolean
	setOpen: (value: boolean) => void
}

function CoinsPopup(props: CoinsPopupProps) {
	const { open, setOpen, coins, minimumCoins } = props
	const [selected, setSelected] = useState<string | null>(
		null,
	)
	const { t } = useTranslation("common")
	const [isProcessing, setIsProcessing] = useState(false)

	const router = useRouter()

	const { notify } = useContext(NotificationContext)

	async function handlePurchase() {
		if (!selected) {
			return
		}

		setIsProcessing(true)

		const sessionId = v4()
		try {
			const payload: PaymentPackUrlRequest = {
				id: selected,
				location: router.asPath,
				sessionId,
			}

			const { url, value, currency } = await axios
				.post<PaymentPackUrlResponse>(
					"/api/payment-pack-url",
					payload,
				)
				.then((res) => res.data)

			const paymentData: PaymentSessionData = {
				value,
				currency,
				session: sessionId,
			}

			localStorage.setItem(
				PAYMENT_DATA_KEY,
				JSON.stringify(paymentData),
			)

			await router.push(url)
		} catch (e) {
			console.error(e)
			notify(
				<ErrorMessage>
					{t("txt_cannot_buy_now")}
				</ErrorMessage>,
			)
		}

		setIsProcessing(false)
	}

	const { userInfo } = useClientAuth()

	const CACHE_MINUTES = 10
	const prices = useQuery({
		queryKey: ["coins", "prices"],
		queryFn: fetchPrices,
		enabled: Boolean(userInfo) && open,
		staleTime: Infinity,
		cacheTime: CACHE_MINUTES * 60 * 1000,
	})

	useEffect(() => {
		if (!prices.data) {
			return
		}

		if (selected) {
			return
		}

		if (minimumCoins === undefined) {
			const popular = prices.data.find(
				(price) => price.popular,
			)

			if (popular) {
				setSelected(popular.id)
			}
			return
		}

		const minimumPack = prices.data.find(
			(price) => price.coins >= minimumCoins,
		)

		if (!minimumPack) {
			return
		}

		setSelected(minimumPack.id)
	}, [minimumCoins, prices.data, prices, selected])

	useEffect(() => {
		if (!prices.data) {
			return
		}

		if (selected) {
			return
		}

		const popular = prices.data.find(
			(price) => price.popular,
		)

		if (popular) {
			setSelected(popular.id)
		}
	}, [prices, selected])

	return (
		<div
			id="popup-background"
			className={clsx(
				"fixed inset-0 flex items-center justify-center",
				"z-[199] bg-color-popup transition-all",
				"p-4 tablet:p-0",
				open
					? "pointer-events-auto opacity-100"
					: "pointer-events-none opacity-0",
			)}
			onClick={(e) => {
				if (e.target instanceof Element) {
					if (e.target.id === "popup-background") {
						setOpen(false)
					}
				}
			}}>
			<div className="relative flex w-full flex-col rounded-[12px] bg-color-cell tablet:w-[665px]">
				<button
					className={clsx(
						"h-[34px] w-[34px] rounded-full bg-color-cell hover:bg-blue-100",
						"absolute -right-12 top-0 items-center justify-center",
						"hidden transition-all desktop:flex",
					)}
					onClick={() => setOpen(false)}>
					<img
						src={assetUrl("/general/close.svg")}
						alt="close popup"
					/>
				</button>

				<section className="flex justify-between border-b border-b-color-separator px-4 py-2 tablet:px-6 tablet:py-4">
					<span className="text-[16px] font-700 text-blue-800 tablet:text-[24px] tablet:font-500">
						{t("txt_purchase_coins")}
					</span>

					<div className="flex items-center gap-1 text-[14px] tablet:text-[22px]">
						<span className="font-500 text-blue-500">
							{t("txt_balance_is")}
						</span>
						<span className="font-700 text-blue-800">
							{coins.toLocaleString()}
						</span>
						<img
							src={assetUrl("/general/coin.svg")}
							alt="coin icon"
							className="aspect-square w-3 tablet:w-5"
						/>
					</div>
				</section>

				<section className="flex w-full flex-row flex-wrap items-center gap-1 self-center p-4 tablet:gap-3 tablet:p-5">
					{prices.data?.map((price) => (
						<PriceOption
							key={price.id}
							{...price}
							selected={price.id === selected}
							onSelected={() => {
								if (!userInfo || userInfo.isAnonymous) {
									setSelected(price.id)
									redirectToLogin()
									return
								}
								if (selected === price.id) {
									handlePurchase()
								} else {
									setSelected(price.id)
								}
							}}
						/>
					))}
					{prices.isLoading && (
						<>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
							<SizedSkeleton
								className={clsx(
									"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",
									"basis-1/4 bg-color-cell inner-border-blue-200",
								)}
							/>
						</>
					)}
				</section>

				<div className="flex w-full justify-center p-4 pt-0 font-700 text-color-white tablet:px-6">
					<button
						disabled={!prices.isSuccess || isProcessing}
						onClick={handlePurchase}
						className={clsx(
							"w-full bg-primary-500 px-2 py-2 text-[14px] tablet:text-[16px]",
							"rounded-full hover:bg-primary-600 tablet:max-w-[300px] tablet:rounded-[10px]",
							"select-none transition-all duration-300",
							"disabled:opacity-30",
						)}>
						{t("txt_purchase_coins")}
					</button>
				</div>
			</div>
		</div>
	)
}

type PaymentCoinPack = PaymentCoinPacksResponse[number]
export interface PriceOptionProps extends PaymentCoinPack {
	selected: boolean
	onSelected: () => void
}

function PriceOption(props: PriceOptionProps) {
	const {
		coins,
		price,
		popular,
		best,
		selected,
		onSelected,
	} = props

	const { t } = useTranslation()

	const priceTag = (price / 100).toFixed(2)

	return (
		<button
			onClick={onSelected}
			className={clsx(
				"relative flex flex-col items-center justify-center",
				"h-[72px] flex-1 rounded-[4px] inner-border tablet:h-[145px] tablet:w-[200px] tablet:flex-none tablet:rounded-[12px]",

				selected
					? "bg-primary-50 inner-border-primary-500"
					: "bg-color-cell inner-border-blue-200",
				"basis-1/4 transition-all",
			)}>
			<div className="flex flex-col items-center gap-1">
				<div className="flex gap-1">
					<span className="text-[13px] font-700 text-blue-800 tablet:text-[24px]">
						+{coins.toLocaleString()}
					</span>
					<img
						src={assetUrl("/general/coin.svg")}
						alt="coin icon"
						className="aspect-square pointer-events-none w-5"
					/>
				</div>

				<span className="text-[12px] text-[#888CA0] tablet:text-[22px]">
					${priceTag}
				</span>
			</div>

			{popular && (
				<div
					className={clsx(
						"absolute right-0 top-0",
						"rounded-bl-[4px] tablet:rounded-bl-[12px]",
						"rounded-tr-[4px] tablet:rounded-tr-[12px]",
						"px-1 tablet:px-4",
						"text-[8px] tablet:text-[16px]",
						"font-700 uppercase text-color-white",
						"bg-[radial-gradient(431.07%_238.21%_at_10.87%_100.00%,#6E00FF_0%,#A700FF_37.28%,#FF4E00_100%)]",
					)}>
					{t("lbl_popular")}
				</div>
			)}

			{best && (
				<div
					className={clsx(
						"absolute right-0 top-0 bg-color-success",
						"rounded-bl-[4px] tablet:rounded-bl-[12px]",
						"rounded-tr-[4px] tablet:rounded-tr-[12px]",
						"px-1 tablet:px-4",
						"text-[8px] tablet:text-[16px]",
						"font-700 uppercase text-color-white",
					)}>
					{t("lbl_best")}
				</div>
			)}
		</button>
	)
}
